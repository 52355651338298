import React from "react";
import { NavHeader, NavWithSubmenu } from "containers/SideNav/components";

const CRM = () => (
  <>
    <NavHeader title="sidebar.crm" />
    <NavWithSubmenu
      title="sidebar.reports"
      icon="zmdi zmdi-youtube-play"
      subMenuList={[
        {
          title: "sidebar.sale.report",
          url: "/app/crm/sale-report",
        },
        {
          title: "sidebar.support.report",
          url: "/app/crm/support-report",
        },
      ]}
    />
  </>
);

export default CRM;
