import React from "react";
import {
  NavHeader,
  NavMenu,
  NavWithSubmenu,
} from "containers/SideNav/components";

const Reports = () => (
  <>
    <NavHeader title="sidebar.reports" />

    <NavMenu
      title="sidebar.support.report.tickets"
      icon="zmdi zmdi-accounts-list zmdi-hc-fw"
      url="/app/reports/tickets"
    />

    <NavWithSubmenu
      title="sidebar.reports.financial"
      icon="zmdi zmdi-money-box zmdi-hc-fw"
      subMenuList={[
        { title: "sidebar.financial.Income", url: "/app/financial/income" },
        { title: "sidebar.financial.Banks", url: "/app/financial/banks" },
        {
          title: "sidebar.transactions.sendMoney",
          url: "/app/financial/send-money",
        },
        {
          title: "sidebar.financial.TransactionsFlow",
          url: "/app/financial/transactions-flow",
        },
        {
          title: "sidebar.financial.DebtsReports",
          url: "/app/financial/debts-reports",
        },
        {
          title: "sidebar.financial.ExchangeRate",
          url: "/app/financial/exchange-rate",
        },
        {
          title: "sidebar.financial.TransactionsBills",
          url: "/app/financial/transactions-bills",
        },
        {
          title: "sidebar.financial.TransactionsBills2",
          url: "/app/financial/transactions-bills2",
        },
        {
          title: "sidebar.financial.UsersBalance",
          url: "/app/financial/users-balance",
        },
        {
          title: "sidebar.financial.total",
          url: "/app/financial/total",
        },
      ]}
    />

    <NavWithSubmenu
      title="sidebar.reports.gateway"
      icon="zmdi zmdi-assignment zmdi-hc-fw"
      subMenuList={[
        { title: "sidebar.gateway.eur", url: "/app/report/gateway/EUR" },
        {
          title: "sidebar.gateway.irr",
          url: "/app/report/gateway/IRR",
        },
        {
          title: "sidebar.gateway.try",
          url: "/app/report/gateway/TRY",
        },
        {
          title: "sidebar.gateway.gbp",
          url: "/app/report/gateway/GBP",
        },
        {
          title: "sidebar.gateway.rub",
          url: "/app/report/gateway/RUB",
        },
      ]}
    />
  </>
);

export default Reports;
