import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const Users = ({ match }) => {
  return (
    <div className="app-wrapper">
      <Switch>
        <Redirect
          exact
          from={`${match.url}/`}
          to={`${match.url}/PendingItems`}
        />
        <Route
          path={`${match.url}/pending-items`}
          component={asyncComponent(() => import("./routes/PendingItems"))}
        />
        <Route
          path={`${match.url}/card-requests`}
          component={asyncComponent(() => import("./routes/CardRequests"))}
        />
        <Route
          path={`${match.url}/fraud-suspect-users`}
          component={asyncComponent(() => import("./routes/FraudSuspectUsers"))}
        />
        <Route
          path={`${match.url}/blocked-users`}
          component={asyncComponent(() => import("./routes/BlockedUsers"))}
        />
        <Route
          path={`${match.url}/all-users`}
          component={asyncComponent(() => import("./routes/AllUsers"))}
        />
        <Route
          path={`${match.url}/business-users`}
          component={asyncComponent(() => import("./routes/BusinessUsers"))}
        />
        <Route
          path={`${match.url}/emails`}
          component={asyncComponent(() => import("./routes/Emails"))}
        />
        <Route
          path={`${match.url}/itsaaz`}
          component={asyncComponent(() => import("./routes/Itsaaz"))}
        />
        <Route
          path={`${match.url}/reports`}
          component={asyncComponent(() => import("./routes/Reports"))}
        />
      </Switch>
    </div>
  );
};

export default Users;
