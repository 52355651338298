import { put, takeLatest } from "redux-saga/effects";
import {
  createYoutubeChannelFailure,
  createYoutubeChannelStarted,
  createYoutubeChannelSuccess,
  getAllYoutubeChannelsFailure,
  getAllYoutubeChannelsRequest,
  getAllYoutubeChannelsStarted,
  getAllYoutubeChannelsSuccess,
  getArzishoConsultantFormFailure,
  getArzishoConsultantFormStarted,
  getArzishoConsultantFormSuccess,
  getYektuberSignupFailure,
  getYektuberSignupStarted,
  getYektuberSignupSuccess,
  getYoutubeArzishoFormFailure,
  getYoutubeArzishoFormStarted,
  getYoutubeArzishoFormSuccess,
  getYoutubeCheckingListFailure,
  getYoutubeCheckingListStarted,
  getYoutubeCheckingListSuccess,
  getYoutubeContactListFailure,
  getYoutubeContactListStarted,
  getYoutubeContactListSuccess,
  getYoutubeCounselingListFailure,
  getYoutubeCounselingListStarted,
  getYoutubeCounselingListSuccess,
  getYoutubePartnerListFailure,
  getYoutubePartnerListStarted,
  getYoutubePartnerListSuccess,
  getYoutubePayoutsFailure,
  getYoutubePayoutsStarted,
  getYoutubePayoutsSuccess,
} from "redux/actions";
import {
  CREATE_YOUTUBE_CHANNEL_REQUEST,
  GET_ALL_YOUTUBE_CHANNELS_REQUEST,
  GET_ARZISHO_CONSULTANT_FORM_REQUEST,
  GET_YEKTUBER_SIGNUP_REQUEST,
  GET_YOUTUBE_ARZISHO_FORM_REQUEST,
  GET_YOUTUBE_CHECKING_LIST_REQUEST,
  GET_YOUTUBE_CONTACT_LIST_REQUEST,
  GET_YOUTUBE_COUNSELING_LIST_REQUEST,
  GET_YOUTUBE_PARTNER_LIST_REQUEST,
  GET_YOUTUBE_PAYOUTS_REQUEST,
} from "redux/types";
import {
  createYoutubeChannelAPI,
  getAllYoutubeChannelsAPI,
  getArzishoConsultantFormAPI,
  getYektuberSignupAPI,
  getYoutubeArzishoFormAPI,
  getYoutubeCheckingListAPI,
  getYoutubeContactListAPI,
  getYoutubeCounselingListAPI,
  getYoutubePartnerListAPI,
  getYoutubePayoutsAPI,
} from "services/API/Youtube";
// --------------------------------------------------------------------------------
function* onGetAllYoutubeChannels({ payload }) {
  yield put(getAllYoutubeChannelsStarted());
  try {
    let response = yield getAllYoutubeChannelsAPI(payload);
    if (response.status === "success") {
      yield put(
        getAllYoutubeChannelsSuccess({ youtubeChannels: response.data })
      );
    } else {
      // yield put(getUserProfileFailure("Token Incorrect!"));
    }
  } catch (error) {
    yield put(
      getAllYoutubeChannelsFailure({
        youtubeChannels: [],
        error: error.message,
      })
    );
  }
}
// --------------------------------------------------------------------------------
function* onCreateYoutubeChannel({ payload }) {
  yield put(createYoutubeChannelStarted());
  try {
    let response = yield createYoutubeChannelAPI(payload);
    if (response.status === "success") {
      yield put(createYoutubeChannelSuccess());
      yield put(getAllYoutubeChannelsRequest());
    } else {
      // yield put(getUserProfileFailure("Token Incorrect!"));
    }
  } catch (error) {
    yield put(createYoutubeChannelFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onGetArzishoConsultantForm({ payload }) {
  yield put(getArzishoConsultantFormStarted());
  try {
    let response = yield getArzishoConsultantFormAPI(payload);
    if (response.status === "success") {
      yield put(
        getArzishoConsultantFormSuccess({
          arzishoConsultantForm: response.data,
        })
      );
    } else {
      yield put(
        getArzishoConsultantFormFailure({
          error: response.data,
        })
      );
    }
  } catch (error) {
    yield put(
      getArzishoConsultantFormFailure({
        error: error.message,
      })
    );
  }
}
// --------------------------------------------------------------------------------
function* onGetYoutubeArzishoForm({ payload }) {
  yield put(getYoutubeArzishoFormStarted());
  try {
    let response = yield getYoutubeArzishoFormAPI(payload);
    if (response.status === "success") {
      yield put(getYoutubeArzishoFormSuccess({ arzishoForms: response.data }));
    } else {
      // yield put(getUserProfileFailure("Token Incorrect!"));
    }
  } catch (error) {
    yield put(
      getYoutubeArzishoFormFailure({
        arzishoForms: [],
        error: error.message,
      })
    );
  }
}
// --------------------------------------------------------------------------------
function* onGetYoutubePayouts() {
  yield put(getYoutubePayoutsStarted());
  try {
    let response = yield getYoutubePayoutsAPI();
    if (response.status === "success") {
      yield put(getYoutubePayoutsSuccess({ youtubePayouts: response.data }));
    } else {
      // yield put(getUserProfileFailure("Token Incorrect!"));
    }
  } catch (error) {
    yield put(
      getYoutubePayoutsFailure({
        youtubePayouts: [],
        error: error.message,
      })
    );
  }
}
// --------------------------------------------------------------------------------
function* onGetYektuberSignup() {
  yield put(getYektuberSignupStarted());
  try {
    let response = yield getYektuberSignupAPI();
    if (response.status === "success") {
      yield put(getYektuberSignupSuccess({ yektuberSignup: response.data }));
    } else {
      // yield put(getUserProfileFailure("Token Incorrect!"));
    }
  } catch (error) {
    yield put(
      getYektuberSignupFailure({
        yektuberSignup: [],
        error: error.message,
      })
    );
  }
}
// --------------------------------------------------------------------------------
function* onGetYoutubeCounselingList() {
  yield put(getYoutubeCounselingListStarted());
  try {
    let response = yield getYoutubeCounselingListAPI();
    if (response.status === "success") {
      yield put(
        getYoutubeCounselingListSuccess({
          youtubeCounselingList: response.data,
        })
      );
    } else {
      // yield put(getUserProfileFailure("Token Incorrect!"));
    }
  } catch (error) {
    yield put(
      getYoutubeCounselingListFailure({
        youtubeCounselingList: [],
        error: error.message,
      })
    );
  }
}
// --------------------------------------------------------------------------------
function* onGetYoutubeCheckingList() {
  yield put(getYoutubeCheckingListStarted());
  try {
    let response = yield getYoutubeCheckingListAPI();
    if (response.status === "success") {
      yield put(
        getYoutubeCheckingListSuccess({
          youtubeCheckingList: response.data,
        })
      );
    } else {
      // yield put(getUserProfileFailure("Token Incorrect!"));
    }
  } catch (error) {
    yield put(
      getYoutubeCheckingListFailure({
        youtubeCheckingList: [],
        error: error.message,
      })
    );
  }
}
// --------------------------------------------------------------------------------
function* onGetYoutubeContactList() {
  yield put(getYoutubeContactListStarted());
  try {
    let response = yield getYoutubeContactListAPI();
    if (response.status === "success") {
      yield put(
        getYoutubeContactListSuccess({
          youtubeContactList: response.data,
        })
      );
    } else {
      // yield put(getUserProfileFailure("Token Incorrect!"));
    }
  } catch (error) {
    yield put(
      getYoutubeContactListFailure({
        youtubeContactList: [],
        error: error.message,
      })
    );
  }
}
// --------------------------------------------------------------------------------
function* onGetYoutubePartnerList() {
  yield put(getYoutubePartnerListStarted());
  try {
    let response = yield getYoutubePartnerListAPI();
    if (response.status === "success") {
      yield put(
        getYoutubePartnerListSuccess({
          youtubePartnerList: response.data,
        })
      );
    } else {
      // yield put(getUserProfileFailure("Token Incorrect!"));
    }
  } catch (error) {
    yield put(
      getYoutubePartnerListFailure({
        youtubePartnerList: [],
        error: error.message,
      })
    );
  }
}
// ================================================================================
function* youtubeSaga() {
  yield takeLatest(GET_ALL_YOUTUBE_CHANNELS_REQUEST, onGetAllYoutubeChannels);
  yield takeLatest(CREATE_YOUTUBE_CHANNEL_REQUEST, onCreateYoutubeChannel);
  yield takeLatest(
    GET_ARZISHO_CONSULTANT_FORM_REQUEST,
    onGetArzishoConsultantForm
  );
  yield takeLatest(GET_YOUTUBE_ARZISHO_FORM_REQUEST, onGetYoutubeArzishoForm);
  yield takeLatest(GET_YOUTUBE_PAYOUTS_REQUEST, onGetYoutubePayouts);
  yield takeLatest(GET_YEKTUBER_SIGNUP_REQUEST, onGetYektuberSignup);
  yield takeLatest(
    GET_YOUTUBE_COUNSELING_LIST_REQUEST,
    onGetYoutubeCounselingList
  );
  yield takeLatest(GET_YOUTUBE_CHECKING_LIST_REQUEST, onGetYoutubeCheckingList);
  yield takeLatest(GET_YOUTUBE_CONTACT_LIST_REQUEST, onGetYoutubeContactList);
  yield takeLatest(GET_YOUTUBE_PARTNER_LIST_REQUEST, onGetYoutubePartnerList);
}

export default youtubeSaga;
