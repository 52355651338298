import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { NotificationContainer } from 'react-notifications';
import SweetAlert from 'react-bootstrap-sweetalert';

function ShaparakHaghighi({ acceptorInfo }) {
  console.log(acceptorInfo, 'hhh');

  const [loadingStatus, setLoadingStatus] = useState(false);
  const [values, setValues] = useState({
    success: false,
    error: false,

    content: JSON.stringify(
      [
        {
          trackingNumberPsp: '139906161437',
          requestType: 5,
          merchant: {
            firstNameFa: 'میرناصر',
            lastNameFa: 'حسینی',
            fatherNameFa: 'میر نورالدین',
            firstNameEn: 'Mir Nasser',
            lastNameEn: 'Hosseini',
            fatherNameEn: 'Mir Nooreddin',
            gender: acceptorInfo.customer_gender,
            birthDate: 481021586000,
            registerDate: null,
            nationalCode: '2802928988',
            registerNumber: null,
            comNameFa: null,
            comNameEn: null,
            merchantType: 0,
            residencyType: 0,
            vitalStatus: 0,
            birthCrtfctNumber: null,
            birthCrtfctSerial: null,
            birthCrtfctSeriesLetter: null,
            birthCrtfctSeriesNumber: null,
            nationalLegalCode: null,
            commercialCode: '',
            countryCode: '',
            foreignPervasiveCode: null,
            passportNumber: null,
            passportExpireDate: null,
            description: '',
            telephoneNumber: '021-88802160',
            cellPhoneNumber: '09122594771',
            emailAddress: 'info@yekpays.ir',
            webSite: 'yekpays.ir',
            fax: null,
            merchantIbans: [
              {
                merchantIban: 'IR240170000000107765770007',
                description: ''
              }
            ],
            merchantOfficers: null
          },
          relatedMerchants: null,
          contract: {
            contractDate: 1599379586000,
            expiryDate: '',
            serviceStartDate: 1599465986000,
            contractNumber: '99061601',
            description: ''
          },
          pspRequestShopAcceptors: [
            {
              shop: {
                farsiName: 'پایه یک',
                englishName: 'Yekpays',
                telephoneNumber: '021-88802160',
                postalCode: '1415895371',
                businessCertificateNumber: null,
                certificateExpiryDate: null,
                description: '-',
                businessCategoryCode: '5941',
                businessSubCategoryCode: '0',
                certificateIssueDate: null,
                rentalExpiryDate: null,
                rentalContractNumber: null,
                address: '',
                countryCode: 'IR',
                provinceCode: null,
                cityCode: null,
                ownershipType: 0,
                businessType: 2,
                etrustCertificateType: null,
                etrustCertificateIssueDate: null,
                etrustCertificateExpiryDate: null,
                emailAddress: 'info@yekpays.ir',
                websiteAddress: 'yekpays.ir'
              },
              acceptors: [
                {
                  iin: '581672111',
                  acceptorCode: '992180002041140',
                  acceptorType: 2,
                  facilitatorAcceptorCode: '992180002014135',
                  cancelable: false,
                  refundable: false,
                  blockable: false,
                  chargeBackable: false,
                  settledSeparately: false,
                  allowScatteredSettlement: 0,
                  acceptCreditCardTransaction: false,
                  allowIranianProductsTrx: false,
                  allowKaraCardTrx: false,
                  allowGoodsBasketTrx: false,
                  allowFoodSecurityTrx: false,
                  allowJcbCardTrx: false,
                  allowUpiCardTrx: false,
                  allowMasterCardTrx: false,
                  allowVisaCardTrx: false,
                  allowAmericanExpressTrx: false,
                  allowOtherInternationaCardsTrx: false,
                  description: '',
                  shaparakSettlementIbans: ['IR240170000000107765770007'],
                  terminals: [
                    {
                      sequence: null,
                      terminalNumber: '02041140',
                      serialNumber: null,
                      setupDate: 1599465986000,
                      terminalType: 1,
                      hardwareBrand: null,
                      hardwareModel: null,
                      accessAddress: 'yekpays.ir',
                      accessPort: 80,
                      callbackAddress: 'yekpays.ir/payment/callback',
                      callbackPort: 80,
                      description: ''
                    }
                  ]
                }
              ]
            }
          ],
          description: null
        }
      ],
      undefined,
      2
    )
  });
  let pretty = JSON.stringify(values.content, undefined, 2);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  function onConfirm() {
    setValues({ ...values, success: false, error: false });
  }

  function handleSave() {
    // setLoadingStatus(true);
    //
    // const formData = new FormData();
    // formData.append('acceptor_type_id', values.acceptor_type_id)
    //
    //
    // axios.post(API_CREATE_ACCEPTOR, formData, token)
    //
    //     .then(response => {
    //         if (response.status === 200 && response.data.status === 'success') {
    //             setValues({ ...values, success: true });
    //         } else {
    //             setValues({ ...values, error: true });
    //         }
    //         setLoadingStatus(false);
    //     }).catch(error => {
    //     let failedValue = '';
    //     if (typeof (error.response.data.data) === 'string') {
    //         failedValue = error.response.data.data;
    //     } else {
    //         for (const prop in error.response.data.data) {
    //             failedValue += prop + ': ' + error.response.data.data[prop] + ' - ';
    //         }
    //     }
    //     setValues({ ...values, error: true, code: failedValue });
    //     setLoadingStatus(false);
    // });
  }

  return (
    <div>
      <div className="jr-card">
        <div className=" col-xl-12 col-lg-12 col-md-12 col-12 ">
          <div className="media mb-2">
            <div className="media-body">
              <TextField
                id="exampleTextarea"
                placeholder="Shaparak Json"
                label="Leave a message..."
                value={values.content}
                onChange={handleChange('content')}
                multiline
                fullWidth
                className="jr-wall-textarea"
                margin="none"
                variant="outlined"
                rows="50"
                inputProps={{ dir: 'auto' }}
              />
            </div>
          </div>
        </div>
        <div className="mt-4 text-center">
          {
            <Button
              style={{ width: '100px' }}
              color="primary"
              className=" jr-btn jr-btn-lg bg-primary text-white MuiButton-contained"
              onClick={handleSave}>
              {loadingStatus ? <CircularProgress color="inherit" size={14} /> : 'Submit'}
            </Button>
          }
        </div>
        <div className="jr-main-content mt-4">
          <NotificationContainer />
          <SweetAlert
            show={values.success}
            success
            title={'Success'}
            confirmBtnText={'Close'}
            onConfirm={() => window.location.replace('/app/payment-assistance/acceptors-list')}>
            Successfully Changed!
          </SweetAlert>
          <SweetAlert
            show={values.error}
            error
            title={'Error'}
            confirmBtnText={'Close'}
            onConfirm={onConfirm}>
            Error! {values.code ? values.code : ''}
          </SweetAlert>
        </div>
      </div>
    </div>
  );
}

export default ShaparakHaghighi;
