import { all } from "redux-saga/effects";
import authSaga from "./Auth";
import financialSaga from "./Financial";
import mainSaga from "./Main";
import modalSaga from "./Modal";
import supportSaga from "./Support";
import userProfileSaga from "./UserProfile";
import youtubeSaga from "./Youtube";
import crmSaga from "./CRM";
// --------------------------------------------------------------------------------
export default function* rootSaga() {
  yield all([
    authSaga(),
    mainSaga(),
    youtubeSaga(),
    supportSaga(),
    financialSaga(),
    userProfileSaga(),
    modalSaga(),
    crmSaga(),
  ]);
}
