import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { boot } from "constants/constants";
import {
  createDateLookup,
  lookup,
  lookup2,
  lookup3,
  lookup4,
  updateDateLookup,
} from "constants/usefulFunctions";
import { withRouter } from "react-router-dom";
import AppButton from "components/AppButton";
import { useDispatch, useSelector } from "react-redux";
import { getUserTicketsRequest } from "redux/actions/UserProfile";
import { GET_USER_TICKETS_STARTED } from "redux/types";

function Tickets(props) {
  const dispatch = useDispatch();
  const { rType, userData, userTickets } = useSelector(
    (state) => state.userProfileReducer
  );
  const [state, setState] = useState({});
  const loading = rType === GET_USER_TICKETS_STARTED;

  const { email, mobile } = userData.basic;
  const params = new URLSearchParams();

  if (email) params.append("email", email);
  if (mobile) params.append("mobile", mobile);

  const url = `/app/tickets/new-ticket?${params.toString()}`;

  const options = {
    columnsButton: true,
    actionsColumnIndex: state.columns && state.columns.length,
    search: true,
    exportButton: true,
    grouping: false,
    filtering: true,
    exportAllData: true,
    selection: false,
    editable: false,
    paging: true,
  };

  const columns = [
    {
      title: "Subject",
      field: "subject",
      editable: "never",
      render: (rowData) => getTicket(rowData),
    },
    {
      title: "Assignee",
      field: "assignee_id",
      render: (rowData) =>
        boot.admins && rowData.assignee_id
          ? boot.admins.find((o) => o.id === rowData.assignee_id).first_name +
            " " +
            boot.admins.find((o) => o.id === rowData.assignee_id).last_name
          : "-",
      lookup: lookup2(boot.admins),
    },
    {
      title: "Department",
      field: "department_id",
      render: (rowData) =>
        boot.departments && rowData.department_id
          ? boot.departments.find((o) => o.id === rowData.department_id).name_en
          : "-",
      lookup: lookup(boot.departments),
    },
    { title: "Status", field: "status" },
    {
      title: "Elapsed",
      field: "timeago_en",
      render: (rowData) =>
        rowData.status !== "Close" ? rowData.timeago_en : "-",
    },
    createDateLookup(),
    updateDateLookup(),
    { title: "Priority", field: "priority" },
    { title: "Resolutions", hidden: true, field: "resolutions" },
    { title: "Tags", field: "tags", hidden: true },
    { title: "Source", field: "source" },
    { title: "Ticket ID", field: "ticket_id", hidden: true },
    {
      title: "Category",
      field: "parent_category.category_en",
      lookup: lookup4(boot.ticketCategories),
    },
    {
      title: "Sub Category",
      field: "category.category_en",
      lookup: lookup3(boot.ticketCategories),
    },
    { title: "Last Update", field: "last_update", editable: "never" },
    { title: "Resolution", field: "resolution" },
  ];

  function getTicket(ticket) {
    return (
      <a target="_blank" href={"/app/tickets/ticket-page/" + ticket.id}>
        {ticket.subject}
      </a>
    );
  }

  useEffect(() => {
    userData.basic?.id &&
      dispatch(getUserTicketsRequest({ user_id: userData?.basic?.id }));
  }, [userData]);

  return (
    <div className="card">
      <AppButton
        onClick={() => window.open(url)}
        text="Send New Ticket"
        size="large"
        customClassName="m-3"
      />

      <MaterialTable
        isLoading={loading}
        options={options}
        columns={columns}
        data={userTickets}
        title="Tickets"
      />
    </div>
  );
}

export default withRouter(Tickets);
