import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { Flags, Currencies } from "constants/constants";
import StatusLoading from "../StatusLoading";
import { useDispatch, useSelector } from "react-redux";
import { getUserFinanceRequest } from "redux/actions/UserProfile";
import { GET_USER_FINANCE_STARTED } from "redux/types";

const UserTotalReport = ({ user_id }) => {
  const dispatch = useDispatch();
  const { rType, userFinance } = useSelector(
    (state) => state.userProfileReducer
  );
  const loading = rType === GET_USER_FINANCE_STARTED;

  const options = {
    columnsButton: true,
    search: true,
    exportButton: true,
    grouping: false,
    filtering: true,
    exportAllData: true,
    selection: false,
    editable: false,
    paging: false,
    pageSize: 9,
  };
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    dispatch(getUserFinanceRequest({ user_id }));
  };

  const columns = [
    {
      title: "Currency",
      field: "id",
      render: (rowData) => (
        <>
          <div className="jr-profile-banner-top-left">
            <div className="jr-profile-banner-avatar">
              <i className={`flag flag-24 flag-${Flags[rowData.id]}`} />
            </div>
            <div
              className="jr-profile-banner-avatar-info"
              style={{ marginTop: -4, marginLeft: -10 }}
            >
              {Currencies[rowData.id]}
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Top Up",
      field: "topup",
    },
    {
      title: "Website Gateway",
      field: "website_gateway",
    },
    {
      title: "Yekpay Link",
      field: "yekpay_link",
    },
    {
      title: "Personal Gateway",
      field: "personal_gateway",
    },
    {
      title: "Payout",
      field: "payout",
    },
    {
      title: "Refund",
      field: "refund",
    },
    {
      title: "Plugin",
      field: "plugin",
    },
    {
      title: "Subscription",
      field: "subscription",
    },
    {
      title: "Commission",
      field: "commission",
    },
  ];

  return (
    <MaterialTable
      isLoading={loading}
      options={options}
      columns={columns}
      data={userFinance}
      title="Total"
    />
  );
};

export default UserTotalReport;
