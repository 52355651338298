import {
  CLOSE_MODAL_REQUEST,
  CLOSE_MODAL_SUCCESS,
  OPEN_MODAL_REQUEST,
  OPEN_MODAL_SUCCESS,
} from "redux/types";
// --------------------------------------------------------------------------------
export const openModalRequest = ({ title, description, status, onConfirm }) => {
  return {
    type: OPEN_MODAL_REQUEST,
    payload: {
      title,
      description,
      status,
      onConfirm,
    },
  };
};

export const openModalSuccess = (data) => ({
  type: OPEN_MODAL_SUCCESS,
  payload: data,
});
// --------------------------------------------------------------------------------
export const closeModalRequest = () => {
  return {
    type: CLOSE_MODAL_REQUEST,
  };
};

export const closeModalSuccess = () => {
  return {
    type: CLOSE_MODAL_SUCCESS,
  };
};
