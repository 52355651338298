import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const Tools = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Route
        path={`${match.url}/iban-inquiry`}
        component={asyncComponent(() => import("./routes/IBANInquiry"))}
      />
      <Route
        path={`${match.url}/card-inquiry`}
        component={asyncComponent(() => import("./routes/CardInquiry"))}
      />
      <Route
        path={`${match.url}/shahkar-inquiry`}
        component={asyncComponent(() => import("./routes/ShahkarInquiry"))}
      />

      <Route
        path={`${match.url}/postalcode-inquiry`}
        component={asyncComponent(() => import("./routes/PostalCodeInquiry"))}
      />
    </Switch>
  </div>
);

export default Tools;
