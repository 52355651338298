import React from "react";
import {
  NavHeader,
  NavMenu,
  NavWithSubmenu,
} from "containers/SideNav/components";

const Youtube = () => (
  <>
    <NavHeader title="sidebar.youtube" />

    <NavMenu
      title="sidebar.allChannels"
      icon="zmdi zmdi-youtube-play"
      url="/app/youtube/YoutubeChannels"
    />
    <NavMenu
      title="sidebar.arzishoConsultantForm"
      icon="zmdi zmdi-youtube-play"
      url="/app/youtube/arzisho-consultant-form"
    />
    <NavMenu
      title="sidebar.arzishoForms"
      icon="zmdi zmdi-youtube-play"
      url="/app/youtube/arzisho-form"
    />
    <NavMenu
      title="sidebar.youtubePayouts"
      icon="zmdi zmdi-youtube-play"
      url="/app/youtube/payouts"
    />
    <NavMenu
      title="sidebar.yektuberSignup"
      icon="zmdi zmdi-youtube-play"
      url="/app/youtube/yektuber-signup"
    />

    <NavWithSubmenu
      title="sidebar.websiteForms"
      icon="zmdi zmdi-youtube-play"
      subMenuList={[
        {
          title: "sidebar.youtubeCounseling",
          url: "/app/youtube/counseling-list",
        },
        {
          title: "sidebar.youtubeChecking",
          url: "/app/youtube/checking-list",
        },
        {
          title: "sidebar.youtubeContact",
          url: "/app/youtube/contact-list",
        },
        {
          title: "sidebar.youtubePartner",
          url: "/app/youtube/partner-list",
        },
      ]}
    />
    <NavMenu
      title="sidebar.CheckChannels"
      icon="zmdi zmdi-youtube-play"
      url="/app/youtube/check-channels"
    />
  </>
);

export default Youtube;
