import React, { useEffect, useState } from "react";
import { boot } from "constants/constants";
import Button from "@mui/material/Button";
import { createDateLookup } from "constants/usefulFunctions";
import MainTable from "components/MainTable";
import { useDispatch, useSelector } from "react-redux";
import {
  createUserCommentRequest,
  deleteUserCommentRequest,
  getUserCommentsRequest,
} from "redux/actions/UserProfile";
import {
  CREATE_USER_COMMENT_STARTED,
  DELETE_USER_COMMENT_STARTED,
  GET_USER_COMMENTS_STARTED,
} from "redux/types";

function AdminComments({ user_id, basicInfo }) {
  const dispatch = useDispatch();
  const { rType, userComments } = useSelector(
    (state) => state.userProfileReducer
  );
  const { adminId } = useSelector((state) => state.authReducer);
  const loading =
    rType === GET_USER_COMMENTS_STARTED ||
    rType === CREATE_USER_COMMENT_STARTED ||
    rType === DELETE_USER_COMMENT_STARTED;

  useEffect(() => {
    if (Object.keys(basicInfo).length !== 0) fetchData();
  }, []);

  const fetchData = async () => {
    dispatch(getUserCommentsRequest({ user_id }));
  };

  const columns = [
    {
      title: "Admin",
      field: "admin_id",
      editable: "never",
      initialEditValue: Number(adminId),
      render: (rowData) =>
        boot.admins && rowData.admin_id
          ? boot.admins.find((o) => o.id === rowData.admin_id).first_name +
            " " +
            boot.admins.find((o) => o.id === rowData.admin_id).last_name
          : boot.admins.find((o) => o.id === Number(adminId)).first_name +
            " " +
            boot.admins.find((o) => o.id === Number(adminId)).last_name,
    },
    {
      title: "Comment",
      field: "comment",
      editComponent: (props) => (
        <textarea
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
          rows={7}
        />
      ),
    },
    {
      title: "Attachment",
      field: "attachment",
      render: (rowData) => {
        if (rowData.attachment) {
          return (
            <p className="text-center">
              <a
                className="pr-3"
                target="_blank"
                href={"https://adminapi.yekpay.com/" + rowData?.attachment}
              >
                <i
                  style={{ fontSize: "24px" }}
                  className="zmdi zmdi-attachment zmdi-hc-fw zmdi-hc-lg text-primary"
                />
              </a>
            </p>
          );
        }
        return null;
      },
      editComponent: (props) => (
        <>
          <Button
            className="bg-light jr-btn jr-btn-sm text-dark mt-2"
            component="label"
          >
            <i className="zmdi zmdi-file-plus mr-2 jr-fs-xl d-inline-flex align-middle" />
            Choose File
            <input
              type="file"
              onChange={(e) => props.onChange(e.target.files[0])}
              style={{ display: "none" }}
            />
          </Button>
          {props.rowData.attachment && (
            <div className="mt-3">
              File Name:{" "}
              <span className="text-success mr-2">
                {props.rowData.attachment.name}
              </span>
              File Size:{" "}
              <span className="text-success">
                {Math.trunc(props.rowData.attachment.size / 1024)}KB
              </span>
            </div>
          )}
        </>
      ),
    },
    createDateLookup(),
  ];

  const options = {
    actionsColumnIndex: columns.length,
    addRowPosition: "first",
  };

  const deleteRow = async (id) => {
    dispatch(deleteUserCommentRequest({ user_id, id }));
  };

  const addRow = async (comment, attachment, updatedData) => {
    const newData = new FormData();
    newData.append("comment", comment);
    newData.append("file", attachment);
    newData.append("user_id", user_id);

    dispatch(createUserCommentRequest(newData));
  };

  return (
    <div className="card">
      <MainTable
        isLocalization
        loading={loading}
        customOptions={options}
        columns={columns}
        data={userComments}
        title="Comments"
        onRowDelete={(oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              deleteRow(oldData.id);
              resolve();
            }, 600);
          })
        }
        onRowAdd={(newData) =>
          new Promise((resolve) => {
            setTimeout(async () => {
              await addRow(newData.comment, newData?.attachment);
              resolve();
            }, 500);
          })
        }
      />
    </div>
  );
}

export default React.memo(AdminComments);
