import {
  CREATE_YOUTUBE_CHANNEL_FAILURE,
  CREATE_YOUTUBE_CHANNEL_STARTED,
  CREATE_YOUTUBE_CHANNEL_SUCCESS,
  GET_ALL_YOUTUBE_CHANNELS_DEFAULT,
  GET_ALL_YOUTUBE_CHANNELS_FAILURE,
  GET_ALL_YOUTUBE_CHANNELS_STARTED,
  GET_ALL_YOUTUBE_CHANNELS_SUCCESS,
  GET_ARZISHO_CONSULTANT_FORM_FAILURE,
  GET_ARZISHO_CONSULTANT_FORM_STARTED,
  GET_ARZISHO_CONSULTANT_FORM_SUCCESS,
  GET_YEKTUBER_SIGNUP_FAILURE,
  GET_YEKTUBER_SIGNUP_STARTED,
  GET_YEKTUBER_SIGNUP_SUCCESS,
  GET_YOUTUBE_ARZISHO_FORM_FAILURE,
  GET_YOUTUBE_ARZISHO_FORM_STARTED,
  GET_YOUTUBE_ARZISHO_FORM_SUCCESS,
  GET_YOUTUBE_CHANNEL_COMMENTS_FAILURE,
  GET_YOUTUBE_CHANNEL_COMMENTS_STARTED,
  GET_YOUTUBE_CHANNEL_COMMENTS_SUCCESS,
  GET_YOUTUBE_CHECKING_LIST_FAILURE,
  GET_YOUTUBE_CHECKING_LIST_STARTED,
  GET_YOUTUBE_CHECKING_LIST_SUCCESS,
  GET_YOUTUBE_CONTACT_LIST_FAILURE,
  GET_YOUTUBE_CONTACT_LIST_STARTED,
  GET_YOUTUBE_CONTACT_LIST_SUCCESS,
  GET_YOUTUBE_COUNSELING_LIST_FAILURE,
  GET_YOUTUBE_COUNSELING_LIST_STARTED,
  GET_YOUTUBE_COUNSELING_LIST_SUCCESS,
  GET_YOUTUBE_PARTNER_LIST_FAILURE,
  GET_YOUTUBE_PARTNER_LIST_STARTED,
  GET_YOUTUBE_PARTNER_LIST_SUCCESS,
  GET_YOUTUBE_PAYOUTS_FAILURE,
  GET_YOUTUBE_PAYOUTS_STARTED,
  GET_YOUTUBE_PAYOUTS_SUCCESS,
} from "redux/types";
// --------------------------------------------------------------------------------
const initialState = {
  rType: GET_ALL_YOUTUBE_CHANNELS_DEFAULT,
  youtubeChannels: [],
  arzishoConsultantForm: [],
  arzishoForms: [],
  youtubePayouts: [],
  youtubeCounselingList: [],
  youtubeCheckingList: [],
  youtubeContactList: [],
  youtubePartnerList: [],
  yektuberSignup: [],
  channelComments: [],
  error: "",
};
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_YOUTUBE_CHANNELS_STARTED:
    case CREATE_YOUTUBE_CHANNEL_STARTED:
    case GET_ARZISHO_CONSULTANT_FORM_STARTED:
    case GET_YOUTUBE_ARZISHO_FORM_STARTED:
    case GET_YOUTUBE_PAYOUTS_STARTED:
    case GET_YEKTUBER_SIGNUP_STARTED:
    case GET_YOUTUBE_COUNSELING_LIST_STARTED:
    case GET_YOUTUBE_CHECKING_LIST_STARTED:
    case GET_YOUTUBE_CONTACT_LIST_STARTED:
    case GET_YOUTUBE_PARTNER_LIST_STARTED:
    case GET_YOUTUBE_CHANNEL_COMMENTS_STARTED:
      return {
        ...state,
        rType: action.type,
      };
    case GET_ALL_YOUTUBE_CHANNELS_SUCCESS:
    case CREATE_YOUTUBE_CHANNEL_SUCCESS:
    case GET_ARZISHO_CONSULTANT_FORM_SUCCESS:
    case GET_YOUTUBE_ARZISHO_FORM_SUCCESS:
    case GET_YOUTUBE_PAYOUTS_SUCCESS:
    case GET_YEKTUBER_SIGNUP_SUCCESS:
    case GET_YOUTUBE_COUNSELING_LIST_SUCCESS:
    case GET_YOUTUBE_CHECKING_LIST_SUCCESS:
    case GET_YOUTUBE_CONTACT_LIST_SUCCESS:
    case GET_YOUTUBE_PARTNER_LIST_SUCCESS:
      return {
        ...state,
        rType: action.type,
        ...action.payload,
        error: "",
      };
    case GET_YOUTUBE_CHANNEL_COMMENTS_SUCCESS:
      return {
        ...state,
        rType: action.type,
        channelComments: action.payload.channelComments,
        error: "",
      };
    case GET_ALL_YOUTUBE_CHANNELS_FAILURE:
    case CREATE_YOUTUBE_CHANNEL_FAILURE:
    case GET_ARZISHO_CONSULTANT_FORM_FAILURE:
    case GET_YOUTUBE_ARZISHO_FORM_FAILURE:
    case GET_YOUTUBE_PAYOUTS_FAILURE:
    case GET_YEKTUBER_SIGNUP_FAILURE:
    case GET_YOUTUBE_COUNSELING_LIST_FAILURE:
    case GET_YOUTUBE_CHECKING_LIST_FAILURE:
    case GET_YOUTUBE_CONTACT_LIST_FAILURE:
    case GET_YOUTUBE_PARTNER_LIST_FAILURE:
    case GET_YOUTUBE_CHANNEL_COMMENTS_FAILURE:
      return {
        ...state,
        rType: action.type,
        ...action.payload,
      };
    default:
      return state;
  }
};
