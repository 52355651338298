import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import authReducer from "./Auth";
import mainReducer from "./Main";
import youtubeReducer from "./Youtube";
import supportReducer from "./Support";
import financialReducer from "./Financial";
import userProfileReducer from "./UserProfile";
import Settings from "./Settings";
import modalReducer from "./Modal";
import crmReducer from "./CRM";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    authReducer,
    mainReducer,
    youtubeReducer,
    supportReducer,
    financialReducer,
    userProfileReducer,
    modalReducer,
    settings: Settings,
    crm: crmReducer,
  });
