import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import SidenavContent from './SidenavContent';
import AdminInfo from 'app/routes/Settings/Profile/routes/AdminInfo';
import { COLLAPSED_DRAWER, FIXED_DRAWER, HORIZONTAL_NAVIGATION } from 'constants/ActionTypes';
import { toggleCollapsedNav, updateWindowWidth } from 'redux/actions/Setting';

const SideNav = (props) => {
  const {
    navCollapsed,
    drawerType,
    width,
    navigationStyle,
    toggleCollapsedNav,
    updateWindowWidth
  } = props;

  const onToggleCollapsedNav = () => {
    const val = !navCollapsed;
    toggleCollapsedNav(val);
  };

  useEffect(() => {
    window.addEventListener('resize', () => {
      updateWindowWidth(window.innerWidth);
    });
  }, []);

  let drawerStyle = drawerType.includes(FIXED_DRAWER)
    ? 'd-xl-flex'
    : drawerType.includes(COLLAPSED_DRAWER)
    ? ''
    : 'd-flex';

  let type = 'permanent';
  if (
    drawerType.includes(COLLAPSED_DRAWER) ||
    (drawerType.includes(FIXED_DRAWER) && width < 1200)
  ) {
    type = 'temporary';
  }

  if (navigationStyle === HORIZONTAL_NAVIGATION) {
    drawerStyle = '';
    type = 'temporary';
  }

  return (
    <div className={`app-sidebar d-none ${drawerStyle}`}>
      <Drawer
        className="app-sidebar-content"
        variant={type}
        open={type.includes('temporary') ? navCollapsed : true}
        onClose={onToggleCollapsedNav}
        classes={{
          paper: 'side-nav'
        }}>
        <AdminInfo />
        <SidenavContent />
      </Drawer>
    </div>
  );
};

const mapStateToProps = ({ settings }) => {
  return {
    navCollapsed: settings.navCollapsed,
    drawerType: settings.drawerType,
    width: settings.width,
    navigationStyle: settings.navigationStyle
  };
};

export default withRouter(
  connect(mapStateToProps, { toggleCollapsedNav, updateWindowWidth })(SideNav)
);
