import { put, takeLatest } from "redux-saga/effects";
import {
  ADD_YOUTUBE_ARCHIVE_CONTRACT_REQUEST,
  CHANGE_BUSINESS_CATEGORY_REQUEST,
  CHANGE_PERSONAL_PAYLINK_STATUS_REQUEST,
  CHANGE_PRODUCT_PAYLINK_STATUS_REQUEST,
  CHANGE_USER_ADDRESS_STATUS_REQUEST,
  CHANGE_USER_BANK_ACCOUNT_STATUS_REQUEST,
  CHANGE_USER_COMPANY_STATUS_REQUEST,
  CHANGE_USER_GATEWAY_STATUS_REQUEST,
  CHANGE_USER_STATUS_REQUEST,
  CHANGE_USER_YEKPAY_LINK_STATUS_REQUEST,
  CLEAR_USER_PROFILE_REQUEST,
  CREATE_PAYOUT_REQUEST,
  CREATE_USER_BANK_ACCOUNT_REQUEST,
  CREATE_USER_COMMENT_REQUEST,
  CREATE_USER_PLAN_REQUEST,
  CREATE_USER_WALLET_REQUEST,
  CREATE_YOUTUBE_CHANNEL_COMMENT_REQUEST,
  CREATE_YOUTUBE_CHANNEL_CONTRACT_REQUEST,
  DELETE_USER_BANK_ACCOUNT_REQUEST,
  DELETE_USER_COMMENT_REQUEST,
  DELETE_USER_WALLET_REQUEST,
  DELETE_USER_YEKPAY_LINK_REQUEST,
  DELETE_YOUTUBE_ARCHIVE_CONTRACT_REQUEST,
  DELETE_YOUTUBE_CHANNEL_COMMENT_REQUEST,
  EDIT_USER_PROFILE_REQUEST,
  EDIT_YOUTUBE_CHANNEL_CONTRACT_REQUEST,
  EDIT_YOUTUBE_CHANNEL_INFO_REQUEST,
  EXCHANGE_USER_CURRENCY_REQUEST,
  GET_PERSONAL_PAYLINKS_REQUEST,
  GET_PRODUCT_PAYLINKS_REQUEST,
  GET_USER_ADDRESS_REQUEST,
  GET_USER_BANK_ACCOUNTS_REQUEST,
  GET_USER_CHANGE_LOGS_REQUEST,
  GET_USER_COMMENTS_REQUEST,
  GET_USER_COMPANY_REQUEST,
  GET_USER_EXCHANGE_DETAIL_REQUEST,
  GET_USER_FAILED_TRANSACTIONS_REQUEST,
  GET_USER_FINANCE_REQUEST,
  GET_USER_GATEWAYS_REQUEST,
  GET_USER_ITSAAZ_REQUEST,
  GET_USER_LOGS_REQUEST,
  GET_USER_PLANS_REQUEST,
  GET_USER_PROFILE_REQUEST,
  GET_USER_TEXT_MESSAGES_REQUEST,
  GET_USER_TICKETS_REQUEST,
  GET_USER_TRANSACTIONS_REQUEST,
  GET_USER_UID_REQUEST,
  GET_USER_WALLETS_REQUEST,
  GET_USER_YEKPAY_LINKS_REQUEST,
  GET_USER_YOUTUBE_CHANNELS_REQUEST,
  GET_YOUTUBE_ARCHIVE_CONTRACTS_REQUEST,
  GET_YOUTUBE_CHANNEL_COMMENTS_REQUEST,
  RESTORE_USER_BANK_ACCOUNT_REQUEST,
  TOPUP_USER_REQUEST,
} from "redux/types";
import {
  addYoutubeArchiveContractFailure,
  addYoutubeArchiveContractStarted,
  addYoutubeArchiveContractSuccess,
  changeBusinessCategoryFailure,
  changeBusinessCategoryStarted,
  changeBusinessCategorySuccess,
  changePersonalPaylinkStatusFailure,
  changePersonalPaylinkStatusStarted,
  changePersonalPaylinkStatusSuccess,
  changeProductPaylinkStatusFailure,
  changeProductPaylinkStatusStarted,
  changeProductPaylinkStatusSuccess,
  changeUserAddressStatusFailure,
  changeUserAddressStatusStarted,
  changeUserAddressStatusSuccess,
  changeUserBankAccountStatusFailure,
  changeUserBankAccountStatusStarted,
  changeUserBankAccountStatusSuccess,
  changeUserCompanyStatusFailure,
  changeUserCompanyStatusStarted,
  changeUserCompanyStatusSuccess,
  changeUserGatewayStatusFailure,
  changeUserGatewayStatusStarted,
  changeUserGatewayStatusSuccess,
  changeUserStatusFailure,
  changeUserStatusStarted,
  changeUserStatusSuccess,
  changeUserYekpayLinkStatusFailure,
  changeUserYekpayLinkStatusStarted,
  changeUserYekpayLinkStatusSuccess,
  clearUserProfileSuccess,
  createPayoutFailure,
  createPayoutStarted,
  createPayoutSuccess,
  createUserBankAccountFailure,
  createUserBankAccountStarted,
  createUserBankAccountSuccess,
  createUserCommentFailure,
  createUserCommentSuccess,
  createUserPlanFailure,
  createUserPlanRequest,
  createUserPlanStarted,
  createUserPlanSuccess,
  createUserWalletFailure,
  createUserWalletStarted,
  createYoutubeChannelCommentFailure,
  createYoutubeChannelCommentStarted,
  createYoutubeChannelCommentSuccess,
  createYoutubeChannelContractFailure,
  createYoutubeChannelContractStarted,
  createYoutubeChannelContractSuccess,
  deleteUserBankAccountFailure,
  deleteUserBankAccountStarted,
  deleteUserBankAccountSuccess,
  deleteUserCommentFailure,
  deleteUserCommentStarted,
  deleteUserCommentSuccess,
  deleteUserWalletFailure,
  deleteUserWalletStarted,
  deleteUserYekpayLinkFailure,
  deleteUserYekpayLinkStarted,
  deleteUserYekpayLinkSuccess,
  deleteYoutubeArchiveContractFailure,
  deleteYoutubeArchiveContractStarted,
  deleteYoutubeArchiveContractSuccess,
  deleteYoutubeChannelCommentFailure,
  deleteYoutubeChannelCommentStarted,
  deleteYoutubeChannelCommentSuccess,
  editUserProfileFailure,
  editUserProfileStarted,
  editUserProfileSuccess,
  editYoutubeChannelContractFailure,
  editYoutubeChannelContractStarted,
  editYoutubeChannelContractSuccess,
  editYoutubeChannelInfoFailure,
  editYoutubeChannelInfoStarted,
  editYoutubeChannelInfoSuccess,
  exchangeUserCurrencyFailure,
  exchangeUserCurrencyStarted,
  exchangeUserCurrencySuccess,
  getPersonalPaylinksFailure,
  getPersonalPaylinksRequest,
  getPersonalPaylinksStarted,
  getPersonalPaylinksSuccess,
  getProductPaylinksFailure,
  getProductPaylinksRequest,
  getProductPaylinksStarted,
  getProductPaylinksSuccess,
  getUserAddressFailure,
  getUserAddressRequest,
  getUserAddressStarted,
  getUserAddressSuccess,
  getUserBankAccountsFailure,
  getUserBankAccountsRequest,
  getUserBankAccountsStarted,
  getUserBankAccountsSuccess,
  getUserChangeLogsFailure,
  getUserChangeLogsStarted,
  getUserChangeLogsSuccess,
  getUserCommentsFailure,
  getUserCommentsRequest,
  getUserCommentsStarted,
  getUserCommentsSuccess,
  getUserCompanyFailure,
  getUserCompanyRequest,
  getUserCompanyStarted,
  getUserCompanySuccess,
  getUserExchangeDetailFailure,
  getUserExchangeDetailStarted,
  getUserExchangeDetailSuccess,
  getUserFailedTransactionsFailure,
  getUserFailedTransactionsStarted,
  getUserFailedTransactionsSuccess,
  getUserFinanceFailure,
  getUserFinanceStarted,
  getUserFinanceSuccess,
  getUserGatewaysFailure,
  getUserGatewaysRequest,
  getUserGatewaysStarted,
  getUserGatewaysSuccess,
  getUserItsaazFailure,
  getUserItsaazStarted,
  getUserItsaazSuccess,
  getUserLogsFailure,
  getUserLogsStarted,
  getUserLogsSuccess,
  getUserPlansFailure,
  getUserPlansRequest,
  getUserPlansStarted,
  getUserPlansSuccess,
  getUserProfileFailure,
  getUserProfileRequest,
  getUserProfileStarted,
  getUserProfileSuccess,
  getUserTextMessagesFailure,
  getUserTextMessagesStarted,
  getUserTextMessagesSuccess,
  getUserTicketsFailure,
  getUserTicketsStarted,
  getUserTicketsSuccess,
  getUserTransactionsFailure,
  getUserTransactionsStarted,
  getUserTransactionsSuccess,
  getUserUIdFailure,
  getUserUIdStarted,
  getUserUIdSuccess,
  getUserWalletsFailure,
  getUserWalletsRequest,
  getUserWalletsStarted,
  getUserWalletsSuccess,
  getUserYekpayLinksFailure,
  getUserYekpayLinksRequest,
  getUserYekpayLinksStarted,
  getUserYekpayLinksSuccess,
  getUserYoutubeChannelsFailure,
  getUserYoutubeChannelsRequest,
  getUserYoutubeChannelsStarted,
  getUserYoutubeChannelsSuccess,
  getYoutubeArchiveContractsFailure,
  getYoutubeArchiveContractsStarted,
  getYoutubeArchiveContractsSuccess,
  getYoutubeChannelCommentsFailure,
  getYoutubeChannelCommentsRequest,
  getYoutubeChannelCommentsStarted,
  getYoutubeChannelCommentsSuccess,
  restoreUserBankAccountFailure,
  restoreUserBankAccountStarted,
  restoreUserBankAccountSuccess,
  topupUserFailure,
  topupUserStarted,
  topupUserSuccess,
} from "redux/actions/UserProfile";
import {
  addYoutubeArchiveContractAPI,
  changeBusinessCategoryAPI,
  changePersonalPaylinkStatusAPI,
  changeProductPaylinkStatusAPI,
  changeUserAddressStatusAPI,
  changeUserBankAccountStatusAPI,
  changeUserCompanyStatusAPI,
  changeUserGatewayStatusAPI,
  changeUserStatusAPI,
  changeUserYekpayLinkStatusAPI,
  createPayoutAPI,
  createUserBankAccountAPI,
  createUserCommentAPI,
  createUserPlanAPI,
  createUserWalletAPI,
  createYoutubeChannelCommentAPI,
  createYoutubeChannelContractAPI,
  deleteUserBankAccountAPI,
  deleteUserCommentAPI,
  deleteUserWalletAPI,
  deleteUserYekpayLinkAPI,
  deleteYoutubeArchiveContractAPI,
  deleteYoutubeChannelCommentAPI,
  editUserProfileAPI,
  editYoutubeChannelContractAPI,
  editYoutubeChannelInfoAPI,
  exchangeUserCurrencyAPI,
  getPersonalPaylinksAPI,
  getProductPaylinksAPI,
  getUserAddressAPI,
  getUserBankAccountsAPI,
  getUserChangeLogsAPI,
  getUserCommentsAPI,
  getUserCompanyAPI,
  getUserExchangeDetailAPI,
  getUserFailedTransactionsAPI,
  getUserFinanceAPI,
  getUserGatewaysAPI,
  getUserItsaazAPI,
  getUserLogsAPI,
  getUserPlansAPI,
  getUserProfileAPI,
  getUserTextMessagesAPI,
  getUserTicketsAPI,
  getUserTransactionsAPI,
  getUserUIdAPI,
  getUserWalletsAPI,
  getUserYekpayLinksAPI,
  getUserYoutubeChannelsAPI,
  getYoutubeArchiveContractsAPI,
  getYoutubeChannelCommentsAPI,
  restoreUserBankAccountAPI,
  topupUserAPI,
} from "services/API/UserProfile";
import DefaultAvatar from "assets/images/default/company-logo.png";
// --------------------------------------------------------------------------------
function* onGetUserProfile({ payload }) {
  yield put(getUserProfileStarted());
  try {
    let response = yield getUserProfileAPI(payload);
    if (response.status === "success") {
      yield put(getUserProfileSuccess({ userData: response.data }));
    } else {
      yield put(
        getUserProfileFailure({
          userData: {},
          error: { error: response.errorMessage },
        })
      );
    }
  } catch (error) {
    yield put(getUserProfileFailure({ userData: {}, error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onClearUserProfile() {
  yield put(clearUserProfileSuccess({ userData: {} }));
}
// --------------------------------------------------------------------------------
function* onEditUserProfile({ payload }) {
  yield put(editUserProfileStarted());
  try {
    let response = yield editUserProfileAPI(payload);
    if (response.status === "success") {
      let user_id = payload.user_id;
      yield put(editUserProfileSuccess(response.data));
      yield put(getUserProfileRequest({ user_id }));
    } else {
      yield put(editUserProfileFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(editUserProfileFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onGetUserItsaaz({ payload }) {
  yield put(getUserItsaazStarted());
  try {
    let response = yield getUserItsaazAPI(payload);
    if (response.status === "success") {
      yield put(getUserItsaazSuccess({ itsaazData: response.data }));
    } else {
      yield put(
        getUserItsaazFailure({ itsaazData: [], error: response.errorMessage })
      );
    }
  } catch (error) {
    yield put(getUserItsaazFailure({ itsaazData: [], error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onGetUserUId({ payload }) {
  yield put(getUserUIdStarted());
  try {
    let response = yield getUserUIdAPI(payload);
    if (response.status === "success") {
      yield put(getUserUIdSuccess({ uIdData: response.data }));
    } else {
      yield put(
        getUserUIdFailure({ uIdData: [], error: response.errorMessage })
      );
    }
  } catch (error) {
    yield put(getUserUIdFailure({ uIdData: [], error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onChangeUserStatus({ payload }) {
  yield put(changeUserStatusStarted());
  try {
    let response = yield changeUserStatusAPI(payload);
    if (response.status === "success") {
      let user_id = payload.get("user_id");
      yield put(changeUserStatusSuccess());
      yield put(getUserProfileRequest({ user_id }));
      yield put(getUserCommentsRequest({ user_id }));
    } else {
      yield put(changeUserStatusFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(changeUserStatusFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onChangeBusinessCategory({ payload }) {
  yield put(changeBusinessCategoryStarted());
  try {
    let response = yield changeBusinessCategoryAPI(payload);
    if (response.status === "success") {
      yield put(changeBusinessCategorySuccess());
      payload.setEditBusinessCategory(false);
      // yield put(getUserProfileRequest({ user_id }));
      // yield put(getUserCommentsRequest({ user_id }));
    } else {
      yield put(
        changeBusinessCategoryFailure({ error: response.errorMessage })
      );
    }
  } catch (error) {
    yield put(changeBusinessCategoryFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onGetUserComments({ payload }) {
  yield put(getUserCommentsStarted());
  try {
    let response = yield getUserCommentsAPI(payload);
    if (response.status === "success") {
      yield put(getUserCommentsSuccess({ userComments: response.data }));
    } else {
      getUserCommentsFailure({
        userComments: [],
        error: response.errorMessage,
      });
    }
  } catch (error) {
    yield put(
      getUserCommentsFailure({ userComments: [], error: error.message })
    );
  }
}
// --------------------------------------------------------------------------------
function* onCreateUserComment({ payload }) {
  yield put(deleteUserCommentStarted());
  try {
    let response = yield createUserCommentAPI(payload);
    if (response.status === "success") {
      let user_id = payload.get("user_id");
      yield put(createUserCommentSuccess());
      yield put(getUserCommentsRequest({ user_id }));
    } else {
      yield put(createUserCommentFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(createUserCommentFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onDeleteUserComment({ payload }) {
  yield put(deleteUserCommentStarted());
  try {
    let response = yield deleteUserCommentAPI(payload);
    if (response.status === "success") {
      let user_id = payload.user_id;
      yield put(deleteUserCommentSuccess());
      yield put(getUserCommentsRequest({ user_id }));
    } else {
      yield put(deleteUserCommentFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(deleteUserCommentFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onGetUserWallets({ payload }) {
  yield put(getUserWalletsStarted());
  try {
    let response = yield getUserWalletsAPI(payload);
    if (response.status === "success") {
      yield put(getUserWalletsSuccess({ userWallets: response.data }));
    } else {
      yield put(
        getUserWalletsFailure({ userWallets: [], error: response.errorMessage })
      );
    }
  } catch (error) {
    yield put(getUserWalletsFailure({ userWallets: [], error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onCreateUserWallet({ payload }) {
  yield put(createUserWalletStarted());
  try {
    let response = yield createUserWalletAPI(payload);
    if (response.status === "success") {
      let user_id = payload.user_id;
      yield put(createUserCommentSuccess());
      yield put(getUserWalletsRequest({ user_id }));
    } else {
      yield put(createUserWalletFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(createUserWalletFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onDeleteUserWallet({ payload }) {
  yield put(deleteUserWalletStarted());
  try {
    let response = yield deleteUserWalletAPI(payload);
    if (response.status === "success") {
      let user_id = payload.user_id;
      yield put(deleteUserCommentSuccess());
      yield put(getUserWalletsRequest({ user_id }));
    } else {
      yield put(deleteUserWalletFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(deleteUserWalletFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onGetUserFinance({ payload }) {
  yield put(getUserFinanceStarted());
  try {
    let response = yield getUserFinanceAPI(payload);
    if (response.status === "success") {
      yield put(getUserFinanceSuccess({ userFinance: response.data }));
    } else {
      yield put(
        getUserFinanceFailure({ userFinance: [], error: response.errorMessage })
      );
    }
  } catch (error) {
    yield put(getUserFinanceFailure({ userFinance: [], error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onGetUserYoutubeChannels({ payload }) {
  yield put(getUserYoutubeChannelsStarted());
  try {
    let response = yield getUserYoutubeChannelsAPI(payload);
    if (response.status === "success") {
      yield put(
        getUserYoutubeChannelsSuccess({ userYoutubeChannels: response.data })
      );
    } else {
      getUserYoutubeChannelsFailure({
        userYoutubeChannels: [],
        error: response.errorMessage,
      });
    }
  } catch (error) {
    yield put(
      getUserYoutubeChannelsFailure({
        userYoutubeChannels: [],
        error: error.message,
      })
    );
  }
}
// --------------------------------------------------------------------------------
function* onEditYoutubeChannelInfo({ payload: { formData, userId } }) {
  yield put(editYoutubeChannelInfoStarted());
  try {
    let response = yield editYoutubeChannelInfoAPI(formData);
    if (response.status === "success") {
      yield put(editYoutubeChannelInfoSuccess());
      yield put(getUserYoutubeChannelsRequest({ user_id: userId }));
    } else {
      yield put(
        editYoutubeChannelInfoFailure({ error: response.errorMessage })
      );
    }
  } catch (error) {
    yield put(editYoutubeChannelInfoFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onAddYoutubeArchiveContract({ payload: { formData, userId } }) {
  yield put(addYoutubeArchiveContractStarted());
  try {
    let response = yield addYoutubeArchiveContractAPI(formData);
    if (response.status === "success") {
      yield put(addYoutubeArchiveContractSuccess());
      yield put(
        getYoutubeArchiveContractsSuccess({
          archiveContracts: response.data,
        })
      );
    } else {
      yield put(
        addYoutubeArchiveContractFailure({ error: response.errorMessage })
      );
    }
  } catch (error) {
    yield put(addYoutubeArchiveContractFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onGetYoutubeArchiveContracts({ payload: { channelId } }) {
  yield put(getYoutubeArchiveContractsStarted());
  try {
    let response = yield getYoutubeArchiveContractsAPI({
      channel_id: channelId,
    });
    if (response.status === "success") {
      yield put(
        getYoutubeArchiveContractsSuccess({ archiveContracts: response.data })
      );
    } else {
      yield put(
        getYoutubeArchiveContractsFailure({ error: response.errorMessage })
      );
    }
  } catch (error) {
    yield put(getYoutubeArchiveContractsFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onDeleteYoutubeArchiveContract({
  payload: { contract_id, channel_id },
}) {
  yield put(deleteYoutubeArchiveContractStarted());
  try {
    let response = yield deleteYoutubeArchiveContractAPI({
      contract_id,
      channel_id,
    });
    if (response.status === "success") {
      yield put(deleteYoutubeArchiveContractSuccess());
      yield put(
        getYoutubeArchiveContractsSuccess({
          archiveContracts: response.data,
        })
      );
    } else {
      yield put(
        deleteYoutubeArchiveContractFailure({ error: response.errorMessage })
      );
    }
  } catch (error) {
    yield put(deleteYoutubeArchiveContractFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onCreateYoutubeChannelContract({ payload: { formData, userId } }) {
  yield put(createYoutubeChannelContractStarted());
  try {
    let response = yield createYoutubeChannelContractAPI(formData);
    if (response.status === "success") {
      yield put(createYoutubeChannelContractSuccess());
      yield put(getUserYoutubeChannelsRequest({ user_id: userId }));
    } else {
      yield put(
        createYoutubeChannelContractFailure({ error: response.errorMessage })
      );
    }
  } catch (error) {
    yield put(createYoutubeChannelContractFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onEditYoutubeChannelContract({ payload: { formData, userId } }) {
  yield put(editYoutubeChannelContractStarted());
  try {
    let response = yield editYoutubeChannelContractAPI(formData);
    if (response.status === "success") {
      yield put(editYoutubeChannelContractSuccess());
      yield put(getUserYoutubeChannelsRequest({ user_id: userId }));
    } else {
      yield put(
        editYoutubeChannelContractFailure({ error: response.errorMessage })
      );
    }
  } catch (error) {
    yield put(editYoutubeChannelContractFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onCreateYoutubeChannelComment({ payload }) {
  yield put(createYoutubeChannelCommentStarted());
  try {
    let response = yield createYoutubeChannelCommentAPI(payload);
    if (response.status === "success") {
      yield put(createYoutubeChannelCommentSuccess());
      yield put(
        getYoutubeChannelCommentsRequest({ channel_id: payload.channel_id })
      );
    } else {
      yield put(
        createYoutubeChannelCommentFailure({ error: response.errorMessage })
      );
    }
  } catch (error) {
    yield put(createYoutubeChannelCommentFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onGetYoutubeChannelComments({ payload: { channel_id } }) {
  yield put(getYoutubeChannelCommentsStarted());
  try {
    let response = yield getYoutubeChannelCommentsAPI({ channel_id });
    if (response.status === "success") {
      yield put(
        getYoutubeChannelCommentsSuccess({ channelComments: response.data })
      );
    } else {
      yield put(
        getYoutubeChannelCommentsFailure({ error: response.errorMessage })
      );
    }
  } catch (error) {
    yield put(getYoutubeChannelCommentsFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onDeleteYoutubeChannelComment({ payload }) {
  yield put(deleteYoutubeChannelCommentStarted());
  try {
    let response = yield deleteYoutubeChannelCommentAPI(payload);
    if (response.status === "success") {
      yield put(deleteYoutubeChannelCommentSuccess());
      yield put(
        getYoutubeChannelCommentsRequest({ channel_id: payload.channel_id })
      );
    } else {
      yield put(
        deleteYoutubeChannelCommentFailure({ error: response.errorMessage })
      );
    }
  } catch (error) {
    yield put(deleteYoutubeChannelCommentFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onGetUserCompany({ payload }) {
  yield put(getUserCompanyStarted());
  try {
    let response = yield getUserCompanyAPI(payload);
    if (response.status === "success") {
      yield put(getUserCompanySuccess({ userCompany: response.data }));
    } else {
      yield put(
        getUserCompanyFailure({ userCompany: [], error: response.errorMessage })
      );
    }
  } catch (error) {
    yield put(getUserCompanyFailure({ userCompany: [], error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onChangeUserCompanyStatus({ payload }) {
  yield put(changeUserCompanyStatusStarted());
  try {
    let response = yield changeUserCompanyStatusAPI(payload);
    if (response.status === "success") {
      let user_id = payload.user_id;
      yield put(changeUserCompanyStatusSuccess());
      yield put(getUserCompanyRequest({ user_id }));
    } else {
      yield put(
        changeUserCompanyStatusFailure({ error: response.errorMessage })
      );
    }
  } catch (error) {
    yield put(changeUserCompanyStatusFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onGetUserAddress({ payload }) {
  yield put(getUserAddressStarted());
  try {
    let response = yield getUserAddressAPI(payload);
    if (response.status === "success") {
      yield put(getUserAddressSuccess({ userAddress: response.data }));
    } else {
      yield put(
        getUserAddressFailure({ userAddress: [], error: response.errorMessage })
      );
    }
  } catch (error) {
    yield put(getUserAddressFailure({ userAddress: [], error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onChangeUserAddressStatus({ payload }) {
  yield put(changeUserAddressStatusStarted());
  try {
    let response = yield changeUserAddressStatusAPI(payload);
    if (response.status === "success") {
      let user_id = payload.user_id;
      yield put(changeUserAddressStatusSuccess());
      yield put(getUserAddressRequest({ user_id }));
    } else {
      yield put(
        changeUserAddressStatusFailure({ error: response.errorMessage })
      );
    }
  } catch (error) {
    yield put(changeUserAddressStatusFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onGetUserPlans({ payload }) {
  yield put(getUserPlansStarted());
  try {
    let response = yield getUserPlansAPI(payload);
    if (response.status === "success") {
      yield put(getUserPlansSuccess({ userPlans: response.data }));
    } else {
      yield put(
        getUserPlansFailure({ userPlans: [], error: response.errorMessage })
      );
    }
  } catch (error) {
    yield put(getUserPlansFailure({ userPlans: [], error: error.message }));
  }
}
// --------------------------------------------------------------------------------
// function* onCreateUserPlan({ payload }) {
//   yield put(createUserPlanStarted());
//   try {
//     let response = yield createUserPlanAPI(payload);
//     if (response.status === "success") {
//       yield put(createUserPlanSuccess());
//       let user_id = payload.user_id;
//       yield put(getUserPlansRequest(user_id));
//     } else {
//       yield put(createUserPlanFailure({ error: response.errorMessage }));
//     }
//   } catch (error) {
//     yield put(createUserPlanFailure({ error: error.message }));
//   }
// }
// --------------------------------------------------------------------------------
function* onGetUserTransactions({ payload }) {
  yield put(getUserTransactionsStarted());
  try {
    let response = yield getUserTransactionsAPI(payload);
    if (response.status === "success") {
      yield put(
        getUserTransactionsSuccess({ userTransactions: response.data })
      );
    } else {
      getUserTransactionsFailure({
        userTransactions: [],
        error: response.errorMessage,
      });
    }
  } catch (error) {
    yield put(
      getUserTransactionsFailure({ userTransactions: [], error: error.message })
    );
  }
}
// --------------------------------------------------------------------------------
function* onGetUserFailedTransactions({ payload }) {
  yield put(getUserFailedTransactionsStarted());
  try {
    let response = yield getUserFailedTransactionsAPI(payload);
    if (response.status === "success") {
      yield put(
        getUserFailedTransactionsSuccess({
          userFailedTransactions: response.data,
        })
      );
    } else {
      getUserFailedTransactionsFailure({
        userFailedTransactions: [],
        error: response.errorMessage,
      });
    }
  } catch (error) {
    yield put(
      getUserFailedTransactionsFailure({
        userFailedTransactions: [],
        error: error.message,
      })
    );
  }
}
// --------------------------------------------------------------------------------
function* onGetUserBankAccounts({ payload }) {
  yield put(getUserBankAccountsStarted());
  try {
    let response = yield getUserBankAccountsAPI(payload);
    if (response.status === "success") {
      let data = response.data.map((item) => ({
        ...item,
        response_full_name:
          item.bank_response_first_name + " " + item.bank_response_last_name,
      }));
      yield put(getUserBankAccountsSuccess({ userBankAccounts: data }));
    } else {
      getUserBankAccountsFailure({
        userBankAccounts: [],
        error: response.errorMessage,
      });
    }
  } catch (error) {
    yield put(
      getUserBankAccountsFailure({ userBankAccounts: [], error: error.message })
    );
  }
}
// --------------------------------------------------------------------------------
function* onChangeUserBankAccountStatus({ payload }) {
  yield put(changeUserBankAccountStatusStarted());
  try {
    let response = yield changeUserBankAccountStatusAPI(payload);
    if (response.status === "success") {
      let user_id = payload.user_id;
      yield put(changeUserBankAccountStatusSuccess());
      yield put(getUserBankAccountsRequest({ user_id }));
    } else {
      yield put(
        changeUserBankAccountStatusFailure({ error: response.errorMessage })
      );
    }
  } catch (error) {
    yield put(changeUserBankAccountStatusFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onDeleteUserBankAccount({ payload }) {
  yield put(deleteUserBankAccountStarted());
  try {
    let response = yield deleteUserBankAccountAPI(payload);
    if (response.status === "success") {
      let user_id = payload.user_id;
      yield put(deleteUserBankAccountSuccess());
      yield put(getUserBankAccountsRequest({ user_id }));
    } else {
      yield put(deleteUserBankAccountFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(deleteUserBankAccountFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onRestoreUserBankAccount({ payload }) {
  yield put(restoreUserBankAccountStarted());
  try {
    let response = yield restoreUserBankAccountAPI(payload);
    if (response.status === "success") {
      let user_id = payload.user_id;
      yield put(restoreUserBankAccountSuccess());
      yield put(getUserBankAccountsRequest({ user_id }));
    } else {
      yield put(
        restoreUserBankAccountFailure({ error: response.errorMessage })
      );
    }
  } catch (error) {
    yield put(restoreUserBankAccountFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onCreateUserBankAccount({ payload }) {
  yield put(createUserBankAccountStarted());
  try {
    let response = yield createUserBankAccountAPI(payload);
    if (response.status === "success") {
      let user_id = payload.user_id;
      yield put(createUserBankAccountSuccess());
      yield put(getUserBankAccountsRequest({ user_id }));
    } else {
      yield put(createUserBankAccountFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(createUserBankAccountFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onGetUserGateways({ payload }) {
  yield put(getUserGatewaysStarted());
  try {
    let response = yield getUserGatewaysAPI(payload);
    if (response.status === "success") {
      let data = response.data.map((item) => ({
        ...item,
        website_logo: item.website_logo,
        agent: item.agent_first_name + " " + item.agent_last_name,
        agent_contact: item.agent_email + " " + item.agent_mobile,
      }));
      yield put(getUserGatewaysSuccess({ userGateways: data }));
    } else {
      getUserGatewaysFailure({
        userGateways: [],
        error: response.errorMessage,
      });
    }
  } catch (error) {
    yield put(
      getUserGatewaysFailure({ userGateways: [], error: error.message })
    );
  }
}
// --------------------------------------------------------------------------------
function* onChangeUserGatewayStatus({ payload }) {
  yield put(changeUserGatewayStatusStarted());
  try {
    let response = yield changeUserGatewayStatusAPI(payload);
    if (response.status === "success") {
      let user_id = payload.user_id;
      yield put(changeUserGatewayStatusSuccess());
      yield put(getUserGatewaysRequest({ user_id }));
    } else {
      yield put(
        changeUserGatewayStatusFailure({ error: response.errorMessage })
      );
    }
  } catch (error) {
    yield put(changeUserGatewayStatusFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onGetUserYekpayLinks({ payload }) {
  yield put(getUserYekpayLinksStarted());
  try {
    let response = yield getUserYekpayLinksAPI(payload);
    if (response.status === "success") {
      let data = response.data.map((item) => ({
        ...item,
        website_logo: item.image ? item.image : DefaultAvatar,
      }));
      yield put(getUserYekpayLinksSuccess({ userYekpayLinks: data }));
    } else {
      getUserYekpayLinksFailure({
        userYekpayLinks: [],
        error: response.errorMessage,
      });
    }
  } catch (error) {
    yield put(
      getUserYekpayLinksFailure({ userYekpayLinks: [], error: error.message })
    );
  }
}
// --------------------------------------------------------------------------------
function* onGetPersonalPaylinks({ payload }) {
  yield put(getPersonalPaylinksStarted());
  try {
    let response = yield getPersonalPaylinksAPI(payload);
    if (response.status === "success") {
      // let data = response.data.map((item) => ({
      //   ...item,
      //   website_logo: item.image ? ApiURL + item.image : DefaultAvatar,
      // }));
      yield put(
        getPersonalPaylinksSuccess({ personalPaylinks: response.data })
      );
    } else {
      getPersonalPaylinksFailure({
        personalPaylinks: [],
        error: response.errorMessage,
      });
    }
  } catch (error) {
    yield put(
      getPersonalPaylinksFailure({ personalPaylinks: [], error: error.message })
    );
  }
}
// --------------------------------------------------------------------------------
function* onGetProductPaylinks({ payload }) {
  yield put(getProductPaylinksStarted());
  try {
    let response = yield getProductPaylinksAPI(payload);
    if (response.status === "success") {
      yield put(getProductPaylinksSuccess({ productPaylinks: response.data }));
    } else {
      getProductPaylinksFailure({
        productPaylinks: [],
        error: response.errorMessage,
      });
    }
  } catch (error) {
    yield put(
      getProductPaylinksFailure({ productPaylinks: [], error: error.message })
    );
  }
}
// --------------------------------------------------------------------------------
function* onChangePersonalPaylinkStatus({ payload }) {
  yield put(changePersonalPaylinkStatusStarted());
  try {
    let response = yield changePersonalPaylinkStatusAPI(payload);
    if (response.status === "success") {
      yield put(changePersonalPaylinkStatusSuccess());
      yield put(getPersonalPaylinksRequest({ user_id: payload.user_id }));
      payload.setOpen(false);
    } else {
      put(changePersonalPaylinkStatusFailure());
    }
  } catch (error) {
    yield put(changePersonalPaylinkStatusFailure());
  }
}
// --------------------------------------------------------------------------------
function* onChangeProductPaylinkStatus({ payload }) {
  yield put(changeProductPaylinkStatusStarted());
  try {
    let response = yield changeProductPaylinkStatusAPI(payload);
    if (response.status === "success") {
      yield put(changeProductPaylinkStatusSuccess());
      yield put(getProductPaylinksRequest({ user_id: payload.user_id }));
      payload.setOpen(false);
    } else {
      put(changeProductPaylinkStatusFailure());
    }
  } catch (error) {
    yield put(changeProductPaylinkStatusFailure());
  }
}
// --------------------------------------------------------------------------------
function* onChangeUserYekpayLinkStatus({ payload }) {
  yield put(changeUserYekpayLinkStatusStarted());
  try {
    let response = yield changeUserYekpayLinkStatusAPI(payload);
    if (response.status === "success") {
      let user_id = payload.user_id;
      yield put(changeUserYekpayLinkStatusSuccess());
      yield put(getUserYekpayLinksRequest({ user_id }));
    } else {
      yield put(
        changeUserYekpayLinkStatusFailure({ error: response.errorMessage })
      );
    }
  } catch (error) {
    yield put(changeUserYekpayLinkStatusFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onDeleteUserYekpayLink({ payload }) {
  yield put(deleteUserYekpayLinkStarted());
  try {
    let response = yield deleteUserYekpayLinkAPI(payload);
    if (response.status === "success") {
      let user_id = payload.user_id;
      yield put(deleteUserYekpayLinkSuccess());
      yield put(getUserYekpayLinksRequest({ user_id }));
    } else {
      yield put(deleteUserYekpayLinkFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(deleteUserYekpayLinkFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onGetUserTickets({ payload }) {
  yield put(getUserTicketsStarted());
  try {
    let response = yield getUserTicketsAPI(payload);
    if (response.status === "success") {
      yield put(getUserTicketsSuccess({ userTickets: response.data }));
    } else {
      yield put(
        getUserTicketsFailure({ userTickets: [], error: response.errorMessage })
      );
    }
  } catch (error) {
    yield put(getUserTicketsFailure({ userTickets: [], error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onGetUserLogs({ payload }) {
  yield put(getUserLogsStarted());
  try {
    let response = yield getUserLogsAPI(payload);
    if (response.status === "success") {
      yield put(getUserLogsSuccess({ userLogs: response.data }));
    } else {
      yield put(
        getUserLogsFailure({ userLogs: [], error: response.errorMessage })
      );
    }
  } catch (error) {
    yield put(getUserLogsFailure({ userLogs: [], error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onCreatePayout({ payload }) {
  yield put(createPayoutStarted());
  try {
    let response = yield createPayoutAPI(payload);
    if (response.status === "success") {
      yield put(createPayoutSuccess(response.data));
    } else {
      yield put(createPayoutFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(createPayoutFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onGetUserTextMessages({ payload }) {
  yield put(getUserTextMessagesStarted());
  try {
    let response = yield getUserTextMessagesAPI(payload);
    if (response.status === "success") {
      yield put(
        getUserTextMessagesSuccess({ userTextMessages: response.data })
      );
    } else {
      getUserTextMessagesFailure({
        userTextMessages: [],
        error: response.errorMessage,
      });
    }
  } catch (error) {
    yield put(
      getUserTextMessagesFailure({ userTextMessages: [], error: error.message })
    );
  }
}
// --------------------------------------------------------------------------------
function* onGetUserChangeLogs({ payload }) {
  yield put(getUserChangeLogsStarted());
  try {
    let response = yield getUserChangeLogsAPI(payload);
    if (response.status === "success") {
      yield put(getUserChangeLogsSuccess({ userChangeLogs: response.data }));
    } else {
      getUserChangeLogsFailure({
        userChangeLogs: [],
        error: response.errorMessage,
      });
    }
  } catch (error) {
    yield put(
      getUserChangeLogsFailure({ userChangeLogs: [], error: error.message })
    );
  }
}
// --------------------------------------------------------------------------------
function* onTopupUser({ payload }) {
  yield put(topupUserStarted());
  try {
    let response = yield topupUserAPI(payload);
    if (response.status === "success") {
      yield put(topupUserSuccess());
    } else {
      topupUserFailure({ error: response.errorMessage });
    }
  } catch (error) {
    yield put(topupUserFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onGetUserExchangeDetail({ payload }) {
  yield put(getUserExchangeDetailStarted());
  try {
    let response = yield getUserExchangeDetailAPI(payload);
    if (response.status === "success") {
      yield put(
        getUserExchangeDetailSuccess({ userExchangeDetail: response.data })
      );
    } else {
      getUserExchangeDetailFailure({
        userExchangeDetail: {},
        error: response.errorMessage,
      });
    }
  } catch (error) {
    yield put(
      getUserExchangeDetailFailure({
        userExchangeDetail: {},
        error: error.message,
      })
    );
  }
}
// --------------------------------------------------------------------------------
function* onExchangeUserCurrency({ payload }) {
  yield put(exchangeUserCurrencyStarted());
  try {
    let response = yield exchangeUserCurrencyAPI(payload);
    if (response.status === "success") {
      yield put(exchangeUserCurrencySuccess());
    } else {
      exchangeUserCurrencyFailure({ error: response.errorMessage });
    }
  } catch (error) {
    yield put(exchangeUserCurrencyFailure({ error: error.message }));
  }
}
// ================================================================================
function* userProfileSaga() {
  yield takeLatest(GET_USER_PROFILE_REQUEST, onGetUserProfile);
  yield takeLatest(CLEAR_USER_PROFILE_REQUEST, onClearUserProfile);
  yield takeLatest(EDIT_USER_PROFILE_REQUEST, onEditUserProfile);
  yield takeLatest(GET_USER_ITSAAZ_REQUEST, onGetUserItsaaz);
  yield takeLatest(GET_USER_UID_REQUEST, onGetUserUId);
  yield takeLatest(CHANGE_USER_STATUS_REQUEST, onChangeUserStatus);
  yield takeLatest(CHANGE_BUSINESS_CATEGORY_REQUEST, onChangeBusinessCategory);
  yield takeLatest(GET_USER_COMMENTS_REQUEST, onGetUserComments);
  yield takeLatest(CREATE_USER_COMMENT_REQUEST, onCreateUserComment);
  yield takeLatest(DELETE_USER_COMMENT_REQUEST, onDeleteUserComment);
  yield takeLatest(GET_USER_WALLETS_REQUEST, onGetUserWallets);
  yield takeLatest(CREATE_USER_WALLET_REQUEST, onCreateUserWallet);
  yield takeLatest(DELETE_USER_WALLET_REQUEST, onDeleteUserWallet);
  yield takeLatest(GET_USER_FINANCE_REQUEST, onGetUserFinance);
  yield takeLatest(GET_USER_YOUTUBE_CHANNELS_REQUEST, onGetUserYoutubeChannels);
  yield takeLatest(EDIT_YOUTUBE_CHANNEL_INFO_REQUEST, onEditYoutubeChannelInfo);
  yield takeLatest(
    CREATE_YOUTUBE_CHANNEL_CONTRACT_REQUEST,
    onCreateYoutubeChannelContract
  );
  yield takeLatest(
    ADD_YOUTUBE_ARCHIVE_CONTRACT_REQUEST,
    onAddYoutubeArchiveContract
  );
  yield takeLatest(
    GET_YOUTUBE_ARCHIVE_CONTRACTS_REQUEST,
    onGetYoutubeArchiveContracts
  );
  yield takeLatest(
    DELETE_YOUTUBE_ARCHIVE_CONTRACT_REQUEST,
    onDeleteYoutubeArchiveContract
  );
  yield takeLatest(
    EDIT_YOUTUBE_CHANNEL_CONTRACT_REQUEST,
    onEditYoutubeChannelContract
  );
  yield takeLatest(GET_USER_COMPANY_REQUEST, onGetUserCompany);
  yield takeLatest(
    CHANGE_USER_COMPANY_STATUS_REQUEST,
    onChangeUserCompanyStatus
  );
  yield takeLatest(GET_USER_ADDRESS_REQUEST, onGetUserAddress);
  yield takeLatest(
    CHANGE_USER_ADDRESS_STATUS_REQUEST,
    onChangeUserAddressStatus
  );
  yield takeLatest(GET_USER_PLANS_REQUEST, onGetUserPlans);
  // yield takeLatest(CREATE_USER_PLAN_REQUEST, createUserPlanRequest);
  yield takeLatest(GET_USER_TRANSACTIONS_REQUEST, onGetUserTransactions);
  yield takeLatest(
    GET_USER_FAILED_TRANSACTIONS_REQUEST,
    onGetUserFailedTransactions
  );
  yield takeLatest(GET_USER_BANK_ACCOUNTS_REQUEST, onGetUserBankAccounts);
  yield takeLatest(
    CHANGE_USER_BANK_ACCOUNT_STATUS_REQUEST,
    onChangeUserBankAccountStatus
  );
  yield takeLatest(DELETE_USER_BANK_ACCOUNT_REQUEST, onDeleteUserBankAccount);
  yield takeLatest(RESTORE_USER_BANK_ACCOUNT_REQUEST, onRestoreUserBankAccount);
  yield takeLatest(CREATE_USER_BANK_ACCOUNT_REQUEST, onCreateUserBankAccount);
  yield takeLatest(GET_USER_GATEWAYS_REQUEST, onGetUserGateways);
  yield takeLatest(
    CHANGE_USER_GATEWAY_STATUS_REQUEST,
    onChangeUserGatewayStatus
  );
  yield takeLatest(GET_USER_YEKPAY_LINKS_REQUEST, onGetUserYekpayLinks);
  yield takeLatest(GET_PERSONAL_PAYLINKS_REQUEST, onGetPersonalPaylinks);
  yield takeLatest(GET_PRODUCT_PAYLINKS_REQUEST, onGetProductPaylinks);
  yield takeLatest(
    CHANGE_PERSONAL_PAYLINK_STATUS_REQUEST,
    onChangePersonalPaylinkStatus
  );
  yield takeLatest(
    CHANGE_PRODUCT_PAYLINK_STATUS_REQUEST,
    onChangeProductPaylinkStatus
  );
  yield takeLatest(
    CHANGE_USER_YEKPAY_LINK_STATUS_REQUEST,
    onChangeUserYekpayLinkStatus
  );
  yield takeLatest(DELETE_USER_YEKPAY_LINK_REQUEST, onDeleteUserYekpayLink);
  yield takeLatest(GET_USER_TICKETS_REQUEST, onGetUserTickets);
  yield takeLatest(GET_USER_LOGS_REQUEST, onGetUserLogs);
  yield takeLatest(CREATE_PAYOUT_REQUEST, onCreatePayout);
  yield takeLatest(GET_USER_TEXT_MESSAGES_REQUEST, onGetUserTextMessages);
  yield takeLatest(GET_USER_CHANGE_LOGS_REQUEST, onGetUserChangeLogs);
  yield takeLatest(TOPUP_USER_REQUEST, onTopupUser);
  yield takeLatest(GET_USER_EXCHANGE_DETAIL_REQUEST, onGetUserExchangeDetail);
  yield takeLatest(EXCHANGE_USER_CURRENCY_REQUEST, onExchangeUserCurrency);
  yield takeLatest(
    GET_YOUTUBE_CHANNEL_COMMENTS_REQUEST,
    onGetYoutubeChannelComments
  );
  yield takeLatest(
    CREATE_YOUTUBE_CHANNEL_COMMENT_REQUEST,
    onCreateYoutubeChannelComment
  );
  yield takeLatest(
    DELETE_YOUTUBE_CHANNEL_COMMENT_REQUEST,
    onDeleteYoutubeChannelComment
  );
}

export default userProfileSaga;
