import React, { useState, useEffect } from "react";
import GatewayHeader from "./components/GatewayHeader";
import GatewayWebsite from "./components/GatewayWebsite";
import GatewayCurrencies from "./components/GatewayCurrencies";
import GatewayHistory from "./components/GatewayHistory";
import GatewayIps from "./components/GatewayIps";
import axios from "axios";
import { token, API_GET_GATEWAY_SHOW } from "constants/constants";
import GatewayDocuments from "./components/GatewayDocuments";
import GatewayAgent from "./components/GatewayAgent";

const UserGateway = () => {
  const urlObject = new URL(window.location);
  const parts1 = urlObject.pathname.split("/");
  const gateway_id = parts1[parts1.length - 1];
  const [state, setState] = useState({});

  const userId = new URLSearchParams(window.location?.search)?.get("userId");

  useEffect(() => {
    async function fetchData() {
      await axios.post(API_GET_GATEWAY_SHOW, { gateway_id }, token()).then((res) => {
        let data = res.data.data;
        setState(data);
      });
    }

    fetchData();
  }, []);

  return (
    <div className="app-wrapper">
      <GatewayHeader gateway_id={gateway_id} gatewayInfo={state} userId={userId} />
      <div className="jr-profile-content">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            <GatewayWebsite gateway_id={gateway_id} gatewayInfo={state} />
          </div>
          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            <GatewayDocuments gateway_id={gateway_id} gatewayInfo={state} />
          </div>
          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            <GatewayIps gateway_id={gateway_id} />
          </div>
          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            <GatewayCurrencies gateway_id={gateway_id} />
          </div>
          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            <GatewayAgent gateway_id={gateway_id} />
          </div>
          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            <GatewayHistory gateway_id={gateway_id} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserGateway;
