import React from "react";
import Avatar from "@mui/material/Avatar";
import DefaultUser from "assets/images/default/default-user.png";
import { getUser } from "constants/usefulFunctions";
import { withRouter } from "react-router-dom";

const GatewayHeader = (props) => {
  const { gatewayInfo, userId, history } = props;
  return (
    <div className="jr-profile-banner">
      <div className="jr-profile-container">
        <div className="jr-profile-banner-top">
          <div className="jr-profile-banner-top-left">
            <div
              className="jr-profile-banner-avatar ml-3 pointer"
              onClick={() =>
                history.replace(`/app/profile/user/${userId}/Gateway`)
              }
            >
              <Avatar
                className="size-90"
                src={
                  gatewayInfo?.website_logo
                    ? gatewayInfo?.website_logo
                    : DefaultUser
                }
                alt={gatewayInfo?.website_email}
              />
            </div>
            <div className="jr-profile-banner-avatar-info">
              <h2 className="mb-2 jr-mb-sm-3 jr-fs-xxl jr-font-weight-light">
                {gatewayInfo?.website_name}
              </h2>
              <p className="mb-1 text-grey ">
                Profile Name: {gatewayInfo?.id && getUser(gatewayInfo?.user)}
              </p>
            </div>
          </div>
          <div className="jr-profile-banner-top-right">
            <ul className="jr-follower-list">
              <li>
                <span className="jr-follower-title jr-fs-lg jr-font-weight-medium">
                  {gatewayInfo?.user_plan &&
                    gatewayInfo?.user_plan.plan.name_en}
                </span>
                <span className="jr-fs-sm">
                  {gatewayInfo?.user_plan && gatewayInfo?.user_plan.end_date}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(GatewayHeader);
