import React, { useEffect, useState } from 'react';
import Switch from '@mui/material/Switch';
import WidgetHeader from 'components/WidgetHeader';
import axios from 'axios';
import { API_UPDATE_ALL_SETTING, API_GET_ALL_SETTING, boot, token } from 'constants/constants';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const ExchangeStatus = () => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(0);

  useEffect(() => {
    getStatus();
  }, []);

  const getStatus = () => {
    setLoading(true);
    axios
      .post(API_GET_ALL_SETTING, {}, token())
      .then((response) => {
        const res = response.data.data.filter((item) => item.key === 'EXCHANGE-STATUS');
        let data = res[0].value;

        setStatus(Number(data));
        setLoading(false);
      })
      .catch((error) => {
        console.log('error:', error);
        setLoading(false);
      });
  };

  const handleSwitch = () => {
    if (status === 1) {
      setStatus(0);
    } else {
      setStatus(1);
    }
  };

  const handleSave = () => {
    setLoading(true);
    axios
      .post(API_UPDATE_ALL_SETTING, { key: 'EXCHANGE-STATUS', value: status }, token())
      .then((response) => {
        setLoading(false);
        console.log('rrrr', response);
      })
      .catch((error) => {
        console.log('error:', error);
        setLoading(false);
      });
  };

  return (
    <div className="jr-entry-sec">
      <WidgetHeader title="Exchange Status" />
      <div className="jr-card p-0">
        <div className="card-body d-flex align-items-center flex-column">
          <Switch
            className="mt-2"
            checked={!!status}
            onChange={handleSwitch}
            color="primary"
            size="small"
          />

          <Button
            disabled={loading}
            style={{ width: '100px' }}
            className="jr-btn jr-btn-lg bg-primary text-white MuiButton-contained mt-3"
            onClick={handleSave}>
            {loading ? <CircularProgress color="inherit" size={15} /> : 'Save'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ExchangeStatus;
