import React, { useEffect, useState } from 'react';
import {
  API_GET_PAYOUT_USER,
  API_CHANGE_STATUS_PAYOUT,
  tokenWithUpload,
  token
} from 'constants/constants';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import SweetAlert from 'react-bootstrap-sweetalert';
import { getUser, getIdentityStatus } from 'constants/usefulFunctions';
import MenuItem from '@mui/material/MenuItem';

const Payout = (user) => {
  const urlObject = new URL(window.location);
  const parts = urlObject.pathname.split('/');
  const id = parts[parts.length - 1];
  const [values, setValues] = useState({ success: false, error: false });
  const [state, setState] = useState({});
  const [file, setFile] = useState();
  useEffect(() => {
    async function fetchData() {
      await axios.post(API_GET_PAYOUT_USER, { id }, token()).then((res) => {
        let data = res.data.data;
        setState(data);
        setValues({ ...values, id: data.id, amount: data.amount });
      });
    }
    fetchData();
  }, []);
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  function handleSaveUser() {
    const formData = new FormData();
    formData.append('amount', values.amount);
    formData.append('description', values.daescription);
    formData.append('attachment', file);
    formData.append('status', values.status);
    formData.append('id', values.id);

    axios
      .post(API_CHANGE_STATUS_PAYOUT, formData, tokenWithUpload())
      .then((response) => {
        if (response.status === 200 && response.data.status === 'success') {
          setValues({ ...values, success: true });
        } else {
          setValues({ ...values, error: true });
        }
      })
      .catch((error) => {
        let failedValue = '';
        if (typeof error.response.data.data === 'string') {
          failedValue = error.response.data.data;
        } else {
          for (var prop in error.response.data.data) {
            failedValue += prop + ': ' + error.response.data.data[prop] + ' - ';
          }
        }
        setValues({ ...values, error: true, code: failedValue });
      });
  }
  function onConfirm() {
    setValues({ ...values, success: false, error: false });
  }
  function onChange(e) {
    setFile(e.target.files[0]);
  }
  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '300px'
      }
    }
  }));
  const classes = useStyles();

  return (
    <div className="app-wrapper pb-0">
      <div className="jr-card jr-full-card">
        <div className="card-header">
          <h4 className="card-title mb-0">Payout Information</h4>
        </div>
        <div style={{ padding: 24 }}>
          <div className="row">
            <div className="col-xl-4 col-lg-12 col-md-12 col-12 ">
              <div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
                <span className="mb-0 text-light jr-fs-sm pr-2">User: </span>
                <p className="mb-0">{getUser(state.user)}</p>
              </div>
              <div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
                <span className="mb-0 text-light jr-fs-sm pr-2">Amount: </span>
                <p className="mb-0">
                  {state.amount} {state.currency && state.currency.code}
                </p>
              </div>
              <div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
                <span className="mb-0 text-light jr-fs-sm pr-2">Type: </span>
                <p className="mb-0">{state.type}</p>
              </div>
              <div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
                <span className="mb-0 text-light jr-fs-sm pr-2">Service: </span>
                <p className="mb-0">{state.service}</p>
              </div>
              <div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
                <span className="mb-0 text-light jr-fs-sm pr-2">Status: </span>
                <p className="mb-0">{getIdentityStatus(state.status)}</p>
              </div>
              <div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
                <span className="mb-0 text-light jr-fs-sm pr-2">Pay Date: </span>
                <p className="mb-0">{state.pay_date}</p>
              </div>
              <div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
                <span className="mb-0 text-light jr-fs-sm pr-2">Created at: </span>
                <p className="mb-0">{state.created_at}</p>
              </div>
              <div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
                <span className="mb-0 text-light jr-fs-sm pr-2">Updated at: </span>
                <p className="mb-0">{state.updated_at}</p>
              </div>
            </div>

            <div className="col-xl-4 col-lg-12 col-md-12 col-12 ">
              <div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
                <span className="mb-0 text-light jr-fs-sm pr-2">Account Name: </span>
                <p className="mb-0">{state.account && state.account.account_holder_name}</p>
              </div>
              <div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
                <span className="mb-0 text-light jr-fs-sm pr-2">Account Iban: </span>
                <p className="mb-0">{state.account && state.account.iban}</p>
              </div>
              <div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
                <span className="mb-0 text-light jr-fs-sm pr-2">Account Response: </span>
                <p className="mb-0">
                  {state.account && state.account.bank_response_account_number}
                </p>
              </div>
              <div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
                <span className="mb-0 text-light jr-fs-sm pr-2">Account Created at: </span>
                <p className="mb-0">{state.account && state.account.created_at}</p>
              </div>
              <div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
                <span className="mb-0 text-light jr-fs-sm pr-2">Account Updated at: </span>
                <p className="mb-0">{state.account && state.account.updated_at}</p>
              </div>
            </div>

            {state.status === 'Pending' && (
              <div className="col-xl-4 col-lg-12 col-md-12 col-12">
                <div className={classes.root}>
                  <TextField
                    label="Amount"
                    value={values.amount}
                    onChange={handleChange('amount')}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </div>
                <div className={classes.root}>
                  <TextField
                    label="Description"
                    value={values.description}
                    onChange={handleChange('description')}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </div>

                <div className={classes.root}>
                  <TextField
                    select
                    fullWidth
                    label="Status"
                    value={values.status}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleChange('status')}
                    margin="normal">
                    <MenuItem key="Approved" value="Approved">
                      {' '}
                      <span className="text-success">Approved</span>{' '}
                    </MenuItem>
                    <MenuItem key="Declined" value="Declined">
                      {' '}
                      <span className="text-danger">Declined</span>{' '}
                    </MenuItem>
                  </TextField>
                </div>
                <div className={classes.root}>
                  <Button className="bg-light jr-btn jr-btn-sm text-dark" component="label">
                    Choose File
                    <input type="file" onChange={onChange} style={{ display: 'none' }} />
                  </Button>
                  {file && (
                    <div className="mt-3">
                      File Name: <span className="text-success mr-2">{file.name}</span>
                      File Size:{' '}
                      <span className="text-success">{Math.trunc(file.size / 1024)}KB</span>
                    </div>
                  )}
                </div>
                <div className={classes.root}>
                  <div className="d-flex flex-row align-items-center mt-2">
                    <Button
                      color="primary"
                      className=" jr-btn jr-btn-lg bg-primary text-white MuiButton-contained"
                      onClick={handleSaveUser}>
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <SweetAlert
        show={values.success}
        success
        title={'Success'}
        confirmBtnText={'Close'}
        onConfirm={onConfirm}>
        Successfully Changed!
      </SweetAlert>
      <SweetAlert
        show={values.error}
        error
        title={'Error'}
        confirmBtnText={'Close'}
        onConfirm={onConfirm}>
        Error! {values.code ? values.code : ''}
      </SweetAlert>
    </div>
  );
};

export default Payout;
