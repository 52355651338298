import React, { useState, useEffect } from 'react';
import ProfileHeader from './ProfileHeader';
import Transactions from './Transactions';
import Auxiliary from '../../../../../util/Auxiliary';
import axios from 'axios';
import { token, API_EXPANEL_USER_INFO } from 'constants/constants';
import SwapHoriz from '@mui/icons-material/SwapHoriz';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { withRouter } from 'react-router-dom';
import Summary from './Summary/index';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import StatusLoading from './StatusLoading';
import UserBalance from './UserBalance';
import PersonPinIcon from '@mui/icons-material/PersonPin';

function TabContainer(props) {
  return <div className="pt-3">{props.children}</div>;
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};
const Profile = (props) => {
  const user_id = props.match.params.id;

  const baseUrl = `/app/exchange-panel/profile/user/${user_id}`;

  const [user, setUser] = useState({});
  const [value, setValue] = useState();
  const [loading, setLoading] = useState(true);

  const handleChange = (event, value) => {
    setValue(value);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios
      .post(API_EXPANEL_USER_INFO, { user_id }, token())
      .then((res) => res.data.data)
      .then((res) => {
        document.title = 'Profile | ' + res.user.first_name + ' ' + res.user.last_name;

        setUser(res.user);
        setLoading(false);
      });
  };
  const activeTab = {
    Summary: 0,
    Transactions: 1,
    Balance: 2
  };

  const section = props.match.params?.section;

  return (
    <Auxiliary>
      <ProfileHeader user={user} />
      <div className="jr-profile-content">
        <div className="w-100">
          <AppBar position="static" color="default">
            <Tabs
              value={activeTab[section]}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="on"
              indicatorColor="primary"
              textColor="primary">
              <Tab
                label="Summary"
                icon={<PersonPinIcon />}
                onClick={() => props.history.push(`${baseUrl}/Summary`)}
              />
              <Tab
                label="Transactions"
                icon={<SwapHoriz />}
                onClick={() => props.history.push(`${baseUrl}/Transactions`)}
              />
              <Tab
                label="Balance"
                icon={<AttachMoneyIcon />}
                onClick={() => props.history.push(`${baseUrl}/Balance`)}
              />
            </Tabs>
          </AppBar>
          {loading ? (
            <div className="mt-5">
              <StatusLoading />
            </div>
          ) : (
            <>
              {section === 'Summary' && (
                <TabContainer>
                  <Summary user_id={user_id} />
                </TabContainer>
              )}
              {section === 'Transactions' && (
                <TabContainer>
                  <Transactions user_id={user_id} />
                </TabContainer>
              )}
              {section === 'Balance' && (
                <TabContainer>
                  <UserBalance user_id={user_id} />
                </TabContainer>
              )}
            </>
          )}
        </div>
      </div>
    </Auxiliary>
  );
};

export default withRouter(Profile);
