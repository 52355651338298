import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { boot } from "constants/constants";
import { createDateLookup, updateDateLookup } from "constants/usefulFunctions";
import { useDispatch, useSelector } from "react-redux";
import { getUserChangeLogsRequest } from "redux/actions/UserProfile";
import { GET_USER_CHANGE_LOGS_STARTED } from "redux/types";

function UserLog({ user_id }) {
  const dispatch = useDispatch();
  const { rType, userChangeLogs } = useSelector(
    (state) => state.userProfileReducer
  );
  const loading = rType === GET_USER_CHANGE_LOGS_STARTED;
  const [state, setState] = useState({});

  const options = {
    columnsButton: true,
    actionsColumnIndex: state.columns && state.columns.length,
    search: true,
    exportButton: true,
    grouping: false,
    filtering: true,
    exportAllData: true,
    selection: false,
    editable: false,
    paging: true,
  };
  let columns = [
    { title: "Key", field: "key", editable: "never" },
    { title: "Value", field: "value", editable: "never" },
    {
      title: "Type",
      field: "type",
      editable: "never",
      render: (rowData) =>
        boot.admins && rowData.admin_id
          ? boot.admins.find((o) => o.id === rowData.admin_id).first_name +
            " " +
            boot.admins.find((o) => o.id === rowData.admin_id).last_name
          : "user",
    },
    createDateLookup(),
    updateDateLookup(),
  ];

  useEffect(() => {
    dispatch(getUserChangeLogsRequest({ user_id }));
  }, []);

  return (
    <div className="card">
      <MaterialTable
        isLoading={loading}
        options={options}
        columns={columns}
        data={userChangeLogs}
        title="UserLog"
      />
    </div>
  );
}

export default UserLog;
