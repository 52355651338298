import axiosMiddleware from "services/API";
// --------------------------------------------------------------------------------
export const getChartActionsAPI = ({ urlPrefix }) =>
  axiosMiddleware({
    method: "get",
    path: `charts/${urlPrefix}`,
  });

export const getChartAPI = ({
  urlPrefix,
  category,
  method,
  fromDate,
  toDate,
  type,
}) =>
  axiosMiddleware({
    method: "get",
    path: `charts/${urlPrefix}/${category}/${method}/${fromDate}/${toDate}/${type}`,
  });
