import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import IntlMessages from "util/IntlMessages";
import { loginAdminRequest, verifyAdminRequest } from "redux/actions";
import AppButton from "components/AppButton";
import {
  LOGIN_ADMIN_FAILURE,
  LOGIN_ADMIN_STARTED,
  LOGIN_ADMIN_SUCCESS,
  VERIFY_ADMIN_FAILURE,
  VERIFY_ADMIN_STARTED,
} from "redux/types";
import packageJson from "../../package.json";
import LogoFull from "assets/images/logo-full.svg";
// --------------------------------------------------------------------------------
const SignIn = () => {
  const dispatch = useDispatch();
  const { rType } = useSelector((state) => state.authReducer);
  const loading =
    rType === LOGIN_ADMIN_STARTED || rType === VERIFY_ADMIN_STARTED;
  const initialState = {
    email: "",
    password: "",
    verify_token: "",
    provider: "admins",
    showVerify: false,
    emailDisabled: false,
    passwordDisabled: false,
  };
  const [
    {
      email,
      password,
      verify_token,
      provider,
      showVerify,
      emailDisabled,
      passwordDisabled,
    },
    setInitState,
  ] = useState(initialState);

  useEffect(() => {
    switch (rType) {
      case LOGIN_ADMIN_SUCCESS:
        setState({
          showVerify: true,
          emailDisabled: true,
          passwordDisabled: true,
        });
        break;
      case LOGIN_ADMIN_FAILURE:
        NotificationManager.error("Authentication Failed, Please try again!");
        break;
      case VERIFY_ADMIN_FAILURE:
        NotificationManager.error("Token Incorrect!");
        break;
      default:
        break;
    }
  }, [rType]);

  const setState = (state) => {
    setInitState((prevState) => ({ ...prevState, ...state }));
  };

  //   document.addEventListener("keydown", this._handleKeyDown);

  const handleFormSubmit = () => {
    if (verify_token) {
      dispatch(
        verifyAdminRequest({
          email,
          password,
          provider,
          verify_token,
        })
      );
    } else {
      dispatch(loginAdminRequest({ email, password, provider }));
    }
  };

  const handleButtonPress = () => {
    handleFormSubmit();
  };

  // _handleKeyDown = (event) => {
  //   if (event.key == "Enter") {
  //     this.handleFormSubmit();
  //   }
  // };

  return (
    <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="app-login-main-content">
        <div
          style={{ backgroundColor: "#03B585" }}
          className="app-logo-content d-flex align-items-center justify-content-center flex-column"
        >
          <img
            src={LogoFull}
            alt="Yekpay"
            className="mt-auto"
            title="Yekpay"
            height={120}
          />
          <span className="text-white mt-auto">
            Version: {packageJson.version}
          </span>
        </div>
        <div className="app-login-content">
          <div className="app-login-header mb-4">
            <h3>Login To Administration Area</h3>
          </div>
          <div className="app-login-form">
            <form>
              <fieldset>
                <TextField
                  label={<IntlMessages id="appModule.email" />}
                  fullWidth
                  onChange={(event) => setState({ email: event.target.value })}
                  defaultValue={email}
                  disabled={emailDisabled}
                  margin="normal"
                  className="mt-1 my-sm-3"
                />
                <TextField
                  type="password"
                  label={<IntlMessages id="appModule.password" />}
                  fullWidth
                  onChange={(event) =>
                    setState({ password: event.target.value })
                  }
                  defaultValue={password}
                  disabled={passwordDisabled}
                  margin="normal"
                  className="mt-1 my-sm-3"
                />

                {showVerify && (
                  <TextField
                    label="Token"
                    fullWidth
                    onChange={(event) =>
                      setState({ verify_token: event.target.value })
                    }
                    defaultValue={verify_token}
                    margin="normal"
                    autoFocus
                    className="mt-1 my-sm-3"
                  />
                )}
                <div className="mb-3 d-flex align-items-center justify-content-between">
                  <AppButton
                    text={<IntlMessages id="appModule.signIn" />}
                    onClick={loading ? undefined : handleButtonPress}
                    variant="contained"
                    color="primary"
                    loading={loading}
                  />
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
      <NotificationContainer />
    </div>
  );
};

export default SignIn;
