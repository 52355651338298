import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import {
  API_GATEWAY_EDIT,
  API_GATEWAY_CHANGE_STATUS,
  token,
  boot,
  Currencies,
} from "constants/constants";
import Button from "@mui/material/Button";
import { NotificationContainer } from "react-notifications";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from "axios";
import makeStyles from "@mui/styles/makeStyles";
import CircularProgress from "@mui/material/CircularProgress";

const GatewayInfo = ({ gatewayInfo }) => {
  const [values, setValues] = useState({
    website_name: gatewayInfo.website_name,
    agent_first_name: gatewayInfo.agent_first_name,
    agent_last_name: gatewayInfo.agent_last_name,
    website_domain: gatewayInfo.website_domain,
    agent_email: gatewayInfo.agent_email,
    agent_mobile: gatewayInfo.agent_mobile,
    website_email: gatewayInfo.website_email,
    stage: gatewayInfo.last_status.stage,
    status: gatewayInfo.last_status.status,
    admin_description: gatewayInfo.last_status.admin_description,
    success: false,
    error: false,
  });

  const [loadingUser, setLoadingUser] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  function handleSave() {
    setLoadingStatus(true);
    axios
      .post(
        API_GATEWAY_CHANGE_STATUS,
        {
          stage: values.stage,
          status: values.status,
          id: gatewayInfo.id,
          user_id: gatewayInfo.user_id,
          description: values.admin_description,
        },
        token()
      )
      .then((response) => {
        if (response.status === 200 && response.data.status === "success") {
          setValues({ ...values, success: true });
        } else {
          setValues({ ...values, error: true });
        }
        setLoadingStatus(false);
      })
      .catch((error) => {
        let failedValue = "";
        if (typeof error.response.data.data === "string") {
          failedValue = error.response.data.data;
        } else {
          for (var prop in error.response.data.data) {
            failedValue += prop + ": " + error.response.data.data[prop] + " - ";
          }
        }
        setValues({ ...values, error: true, code: failedValue });
        setLoadingStatus(false);
      });
  }

  function handleSaveUser() {
    setLoadingUser(true);
    axios
      .post(
        API_GATEWAY_EDIT,
        {
          website_name: values.website_name,
          agent_first_name: values.agent_first_name,
          agent_last_name: values.agent_last_name,
          website_domain: values.website_domain,
          website_email: values.website_email,
          agent_email: values.agent_email,
          agent_mobile: values.agent_mobile,
          gateway_id: gatewayInfo.id,
        },
        token()
      )
      .then((response) => {
        if (response.status === 200 && response.data.status === "success") {
          setValues({ ...values, success: true });
        }
        setLoadingUser(false);
      })
      .catch((error) => {
        let failedValue = "";
        if (typeof error.response.data.data === "string") {
          failedValue = error.response.data.data;
        } else {
          for (var prop in error.response.data.data) {
            failedValue += prop + ": " + error.response.data.data[prop] + " - ";
          }
        }
        setValues({
          website_name: gatewayInfo.website_name,
          agent_first_name: gatewayInfo.agent_first_name,
          agent_last_name: gatewayInfo.agent_last_name,
          website_domain: gatewayInfo.website_domain,
          agent_email: gatewayInfo.agent_email,
          agent_mobile: gatewayInfo.agent_mobile,
          website_email: gatewayInfo.website_email,
          stage: gatewayInfo.last_status.stage,
          status: gatewayInfo.last_status.status,
          admin_description: gatewayInfo.last_status.admin_description,
          success: false,
          code: failedValue,
          error: true,
        });

        setLoadingUser(false);
      });
  }

  function onConfirm() {
    setValues({ ...values, success: false, error: false });
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      "& > *": {
        margin: theme.spacing(1),
        width: "100%",
      },
    },
  }));
  const classes = useStyles();
  return (
    <div className="row">
      <NotificationContainer />
      <SweetAlert
        show={values.success}
        success
        title={"Success"}
        confirmBtnText={"Close"}
        onConfirm={onConfirm}>
        Successfully Changed!
      </SweetAlert>
      <SweetAlert
        show={values.error}
        error
        title={"Error"}
        confirmBtnText={"Close"}
        onConfirm={onConfirm}>
        Error! {values.code ? values.code : ""}
      </SweetAlert>
      <div className="col-xl-2 col-lg-4 col-md-12 col-12">
        <div>
          <form className={classes.root}>
            <TextField
              label="Website Name"
              value={values.website_name}
              onChange={handleChange("website_name")}
              InputLabelProps={{ shrink: true }}
            />
          </form>
        </div>
        <div>
          <form className={classes.root}>
            <TextField
              label="Website Domain"
              value={values.website_domain}
              onChange={handleChange("website_domain")}
              InputLabelProps={{ shrink: true }}
            />
          </form>
        </div>
        <div>
          <form className={classes.root}>
            <TextField
              label="Country"
              value={
                boot?.countries && gatewayInfo.country_id
                  ? boot?.countries?.find((o) => o.id === gatewayInfo.country_id).name_en
                  : "-"
              }
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            />
          </form>
        </div>
        <div>
          <form className={classes.root}>
            <TextField
              label="Organization Email"
              value={values.website_email}
              onChange={handleChange("website_email")}
              InputLabelProps={{ shrink: true }}
            />
          </form>
        </div>
        <div>
          <form className={classes.root}>
            <TextField
              label="Agent First Name"
              value={values.agent_first_name}
              onChange={handleChange("agent_first_name")}
              InputLabelProps={{ shrink: true }}
            />
          </form>
        </div>
        <div>
          <form className={classes.root}>
            <TextField
              label="Agent Last Name"
              value={values.agent_last_name}
              onChange={handleChange("agent_last_name")}
              InputLabelProps={{ shrink: true }}
            />
          </form>
        </div>
        <div>
          <form className={classes.root}>
            <TextField
              label="Agent Email"
              value={values.agent_email}
              onChange={handleChange("agent_email")}
              InputLabelProps={{ shrink: true }}
            />
          </form>
        </div>
        <div>
          <form className={classes.root}>
            <TextField
              label="Agent Mobile"
              value={values.agent_mobile}
              onChange={handleChange("agent_mobile")}
              InputLabelProps={{ shrink: true }}
            />
          </form>
        </div>
        <div>
          <form className={classes.root}>
            <TextField
              label="Register Date"
              value={gatewayInfo.created_at}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
              disabled
            />
          </form>
        </div>
        <div className="d-flex flex-row align-items-center mt-2">
          {
            <Button
              disabled={loadingUser}
              style={{ width: "100px" }}
              color="primary"
              className=" jr-btn jr-btn-lg bg-primary text-white MuiButton-contained"
              onClick={handleSaveUser}>
              {loadingUser ? <CircularProgress color="inherit" size={14} /> : "Save"}
            </Button>
          }
        </div>
      </div>
      <div className="col-xl-2 col-lg-4 col-md-12 col-12">
        <div className="mt-3">
          <TextField
            select
            fullWidth
            label="Status"
            value={values.status}
            InputLabelProps={{ shrink: true }}
            onChange={handleChange("status")}
            margin="normal"
            variant="outlined">
            <MenuItem key="Approved" value="Approved">
              {" "}
              <span className="text-success">Approved</span>
            </MenuItem>
            <MenuItem key="Pending" value="Pending">
              {" "}
              <span className="text-warning">Pending</span>
            </MenuItem>
            <MenuItem key="Declined" value="Declined">
              {" "}
              <span className="text-danger">Declined</span>
            </MenuItem>
            <MenuItem key="Canceled" value="Canceled">
              {" "}
              <span className="text-danger">Canceled</span>
            </MenuItem>
            <MenuItem key="Suspend" value="Suspend">
              {" "}
              <span className="text-danger">Suspend</span>
            </MenuItem>
            <MenuItem key="Trial" value="Trial">
              {" "}
              <span className="text-danger">Trial</span>{" "}
            </MenuItem>
          </TextField>
        </div>
        <div className="mt-3">
          <TextField
            select
            fullWidth
            label="Stage"
            value={values.stage}
            InputLabelProps={{ shrink: true }}
            onChange={handleChange("stage")}
            margin="normal"
            variant="outlined">
            <MenuItem key="Submitted" value="Submitted">
              {" "}
              <span className="text-success">Submitted</span>
            </MenuItem>
            <MenuItem key="Declined" value="Declined">
              {" "}
              <span className="text-danger">Declined</span>
            </MenuItem>
            <MenuItem key="Cancel By Customer" value="Cancel By Customer">
              {" "}
              <span className="text-danger">Cancel By Customer</span>
            </MenuItem>
            <MenuItem key="Not Accessible" value="Not Accessible">
              {" "}
              <span className="text-warning">Not Accessible</span>
            </MenuItem>
            <MenuItem key="Implementing By Customer" value="Implementing By Customer">
              {" "}
              <span className="text-success">Implementing By Customer</span>{" "}
            </MenuItem>
            <MenuItem key="Implementing By Yekpay" value="Implementing By Yekpay">
              {" "}
              <span className="text-success">Implementing By Yekpay</span>{" "}
            </MenuItem>
            <MenuItem key="Requesting Docs" value="Requesting Docs">
              {" "}
              <span className="text-danger">Requesting Docs</span>
            </MenuItem>
            <MenuItem key="Invalid Documents" value="Invalid Documents">
              {" "}
              <span className="text-danger">Invalid Documents</span>
            </MenuItem>
            <MenuItem key="Contract" value="Contract">
              {" "}
              <span className="text-danger">Contract</span>
            </MenuItem>
            <MenuItem key="Finalized" value="Finalized">
              {" "}
              <span className="text-success">Finalized</span>
            </MenuItem>
          </TextField>
        </div>
        <div className="mt-3">
          <TextField
            label="Description"
            multiline
            fullWidth
            InputLabelProps={{ shrink: true }}
            onChange={handleChange("admin_description")}
            value={values.admin_description}
            margin="normal"
            variant="outlined"
          />
        </div>
        <div className="d-flex flex-row align-items-center mt-2">
          {
            <Button
              disabled={loadingStatus}
              style={{ width: "100px" }}
              color="primary"
              className=" jr-btn jr-btn-lg bg-primary text-white MuiButton-contained"
              onClick={handleSave}>
              {loadingStatus ? <CircularProgress color="inherit" size={14} /> : "Save"}
            </Button>
          }
        </div>
      </div>
      <div className="col-xl-8 col-lg-4 col-md-12 col-12">
        <iframe
          style={{ border: "1px solid #c4c4c4", borderRadius: "4px" }}
          width="100%"
          height="100%"
          src={
            gatewayInfo.website_domain && gatewayInfo.website_domain.substring(0, 4) === "http"
              ? gatewayInfo.website_domain
              : "http://" + gatewayInfo.website_domain
          }
          allowfullscreen></iframe>
      </div>
    </div>
  );
};

export default GatewayInfo;
