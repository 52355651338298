import React, { useState, useEffect } from "react";
import UserInfo from "./UserInfo";
import axios from "axios";
import { API_EXPANEL_USER_INFO, token } from "constants/constants";
import CompanyInfo from "./CompanyInfo";
import StatusLoading from "../StatusLoading";

const Summary = ({ user_id }) => {
  const [user, setUser] = useState({});
  const [company, setCompany] = useState({});

  const [color, setColor] = useState();
  const [status, setStatus] = useState();

  useEffect(() => {
    async function fetchData() {
      await axios
        .post(API_EXPANEL_USER_INFO, { user_id }, token())
        .then((res) => res.data.data)
        .then((res) => {
          setStatus(res.user.status);
          setUser(res.user);
          setCompany(res.company);
        });
    }
    fetchData();
  }, []);

  useEffect(() => {
    switch (status) {
      case "Active":
        setColor("green");
        break;
      case "Fraud Suspect":
        setColor("orange");
        break;
      case "Blocked":
        setColor("red");
        break;

      default:
        break;
    }
  }, [status]);
  if (!user.id)
    return (
      <div className="jr-card jr-full-card pt-5 ">
        <StatusLoading />
      </div>
    );
  return (
    <>
      <div className="d-flex justify-content-around">
        <div className="col-lg-6 col-sm-12 jr-card jr-full-card p-4 mx-1">
          {user.id && <UserInfo user={user} />}
        </div>
        <div className="col-lg-6 col-sm-12  jr-card jr-full-card  mx-1 p-4">
          {user.id && <CompanyInfo company={company} />}
        </div>
      </div>
    </>
  );
};

export default Summary;
