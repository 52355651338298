import React, { useEffect, useState } from "react";
import Auxiliary from "util/Auxiliary";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import YoutubeDetails from "./YoutubeDetails";
import { useDispatch, useSelector } from "react-redux";
import { getUserYoutubeChannelsRequest } from "redux/actions/UserProfile";
import AdminComments from "../AdminComments/index";

function Youtube({ user_id, basicInfo }) {
  const dispatch = useDispatch();
  const { userYoutubeChannels } = useSelector(
    (state) => state.userProfileReducer
  );
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    Request();
  }, []);

  const Request = () => {
    dispatch(getUserYoutubeChannelsRequest({ user_id }));
  };

  const handleChange = (event, activeTab) => {
    setActiveTab(activeTab);
  };

  return (
    <>
      <Auxiliary>
        <div className="jr-profile-content">
          <div className="w-100">
            <AppBar position="static" color="default">
              <Tabs
                value={activeTab}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="on"
                indicatorColor="primary"
                textColor="primary"
              >
                {userYoutubeChannels.map((channel) => (
                  <Tab label={channel.channel_name} />
                ))}
              </Tabs>
            </AppBar>
            {userYoutubeChannels.map((channel, idx) => {
              if (idx === activeTab) {
                return (
                  <div className="row my-3">
                    <div className="col-12">
                      <YoutubeDetails
                        basicInfo={basicInfo}
                        user_id={user_id}
                        data={channel}
                      />
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </Auxiliary>
    </>
  );
}

export default Youtube;
