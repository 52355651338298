// ---------------------------------------- AUTH ----------------------------------------
export const LOGIN_ADMIN_DEFAULT = "LOGIN_ADMIN_DEFAULT";
export const LOGIN_ADMIN_REQUEST = "LOGIN_ADMIN_REQUEST";
export const LOGIN_ADMIN_STARTED = "LOGIN_ADMIN_STARTED";
export const LOGIN_ADMIN_SUCCESS = "LOGIN_ADMIN_SUCCESS";
export const LOGIN_ADMIN_FAILURE = "LOGIN_ADMIN_FAILURE";

export const VERIFY_ADMIN_REQUEST = "VERIFY_ADMIN_REQUEST";
export const VERIFY_ADMIN_STARTED = "VERIFY_ADMIN_STARTED";
export const VERIFY_ADMIN_SUCCESS = "VERIFY_ADMIN_SUCCESS";
export const VERIFY_ADMIN_FAILURE = "VERIFY_ADMIN_FAILURE";

export const GET_CHART_ACTIONS_REQUEST = "GET_CHART_ACTIONS_REQUEST";
export const GET_CHART_ACTIONS_STARTED = "GET_CHART_ACTIONS_STARTED";
export const GET_CHART_ACTIONS_SUCCESS = "GET_CHART_ACTIONS_SUCCESS";
export const GET_CHART_ACTIONS_FAILURE = "GET_CHART_ACTIONS_FAILURE";

export const GET_CHART_REQUEST = "GET_CHART_REQUEST";
export const GET_CHART_STARTED = "GET_CHART_STARTED";
export const GET_CHART_SUCCESS = "GET_CHART_SUCCESS";
export const GET_CHART_FAILURE = "GET_CHART_FAILURE";

export const LOGOUT_ADMIN_REQUEST = "LOGOUT_ADMIN_REQUEST";
export const LOGOUT_ADMIN_SUCCESS = "LOGOUT_ADMIN_SUCCESS";

// ---------------------------------------- MAIN ----------------------------------------
export const GET_DASHBOARD_INFO_DEFAULT = "GET_DASHBOARD_INFO_DEFAULT";
export const GET_DASHBOARD_INFO_REQUEST = "GET_DASHBOARD_INFO_REQUEST";
export const GET_DASHBOARD_INFO_STARTED = "GET_DASHBOARD_INFO_STARTED";
export const GET_DASHBOARD_INFO_SUCCESS = "GET_DASHBOARD_INFO_SUCCESS";
export const GET_DASHBOARD_INFO_FAILURE = "GET_DASHBOARD_INFO_FAILURE";

export const GET_PARSIAN_BALANCE_REQUEST = "GET_PARSIAN_BALANCE_REQUEST";
export const GET_PARSIAN_BALANCE_STARTED = "GET_PARSIAN_BALANCE_STARTED";
export const GET_PARSIAN_BALANCE_SUCCESS = "GET_PARSIAN_BALANCE_SUCCESS";
export const GET_PARSIAN_BALANCE_FAILURE = "GET_PARSIAN_BALANCE_FAILURE";

export const GET_PASARGAD_BALANCE_REQUEST = "GET_PASARGAD_BALANCE_REQUEST";
export const GET_PASARGAD_BALANCE_STARTED = "GET_PASARGAD_BALANCE_STARTED";
export const GET_PASARGAD_BALANCE_SUCCESS = "GET_PASARGAD_BALANCE_SUCCESS";
export const GET_PASARGAD_BALANCE_FAILURE = "GET_PASARGAD_BALANCE_FAILURE";

export const GET_AYANDE_BALANCE_REQUEST = "GET_AYANDE_BALANCE_REQUEST";
export const GET_AYANDE_BALANCE_STARTED = "GET_AYANDE_BALANCE_STARTED";
export const GET_AYANDE_BALANCE_SUCCESS = "GET_AYANDE_BALANCE_SUCCESS";
export const GET_AYANDE_BALANCE_FAILURE = "GET_AYANDE_BALANCE_FAILURE";

export const GET_KAVENEGAR_BALANCE_REQUEST = "GET_KAVENEGAR_BALANCE_REQUEST";
export const GET_KAVENEGAR_BALANCE_STARTED = "GET_KAVENEGAR_BALANCE_STARTED";
export const GET_KAVENEGAR_BALANCE_SUCCESS = "GET_KAVENEGAR_BALANCE_SUCCESS";
export const GET_KAVENEGAR_BALANCE_FAILURE = "GET_KAVENEGAR_BALANCE_FAILURE";

export const GET_ELASTIC_BALANCE_REQUEST = "GET_ELASTIC_BALANCE_REQUEST";
export const GET_ELASTIC_BALANCE_STARTED = "GET_ELASTIC_BALANCE_STARTED";
export const GET_ELASTIC_BALANCE_SUCCESS = "GET_ELASTIC_BALANCE_SUCCESS";
export const GET_ELASTIC_BALANCE_FAILURE = "GET_ELASTIC_BALANCE_FAILURE";

export const GET_ALL_PENDING_IDENTITY_REQUEST =
  "GET_ALL_PENDING_IDENTITY_REQUEST";
export const GET_ALL_PENDING_IDENTITY_STARTED =
  "GET_ALL_PENDING_IDENTITY_STARTED";
export const GET_ALL_PENDING_IDENTITY_SUCCESS =
  "GET_ALL_PENDING_IDENTITY_SUCCESS";
export const GET_ALL_PENDING_IDENTITY_FAILURE =
  "GET_ALL_PENDING_IDENTITY_FAILURE";

export const GET_ALL_PENDING_BANK_ACCOUNT_REQUEST =
  "GET_ALL_PENDING_BANK_ACCOUNT_REQUEST";
export const GET_ALL_PENDING_BANK_ACCOUNT_STARTED =
  "GET_ALL_PENDING_BANK_ACCOUNT_STARTED";
export const GET_ALL_PENDING_BANK_ACCOUNT_SUCCESS =
  "GET_ALL_PENDING_BANK_ACCOUNT_SUCCESS";
export const GET_ALL_PENDING_BANK_ACCOUNT_FAILURE =
  "GET_ALL_PENDING_BANK_ACCOUNT_FAILURE";

export const GET_ALL_PENDING_BILLING_ADDRESS_REQUEST =
  "GET_ALL_PENDING_BILLING_ADDRESS_REQUEST";
export const GET_ALL_PENDING_BILLING_ADDRESS_STARTED =
  "GET_ALL_PENDING_BILLING_ADDRESS_STARTED";
export const GET_ALL_PENDING_BILLING_ADDRESS_SUCCESS =
  "GET_ALL_PENDING_BILLING_ADDRESS_SUCCESS";
export const GET_ALL_PENDING_BILLING_ADDRESS_FAILURE =
  "GET_ALL_PENDING_BILLING_ADDRESS_FAILURE";

export const GET_FRAUD_SUSPECT_USERS_REQUEST =
  "GET_FRAUD_SUSPECT_USERS_REQUEST";
export const GET_FRAUD_SUSPECT_USERS_STARTED =
  "GET_FRAUD_SUSPECT_USERS_STARTED";
export const GET_FRAUD_SUSPECT_USERS_SUCCESS =
  "GET_FRAUD_SUSPECT_USERS_SUCCESS";
export const GET_FRAUD_SUSPECT_USERS_FAILURE =
  "GET_FRAUD_SUSPECT_USERS_FAILURE";

export const GET_BLOCKED_USERS_REQUEST = "GET_BLOCKED_USERS_REQUEST";
export const GET_BLOCKED_USERS_STARTED = "GET_BLOCKED_USERS_STARTED";
export const GET_BLOCKED_USERS_SUCCESS = "GET_BLOCKED_USERS_SUCCESS";
export const GET_BLOCKED_USERS_FAILURE = "GET_BLOCKED_USERS_FAILURE";

export const GET_ALL_USERS_REQUEST = "GET_ALL_USERS_REQUEST";
export const GET_ALL_USERS_STARTED = "GET_ALL_USERS_STARTED";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_FAILURE = "GET_ALL_USERS_FAILURE";

export const GET_BUSINESS_USERS_REQUEST = "GET_BUSINESS_USERS_REQUEST";
export const GET_BUSINESS_USERS_STARTED = "GET_BUSINESS_USERS_STARTED";
export const GET_BUSINESS_USERS_SUCCESS = "GET_BUSINESS_USERS_SUCCESS";
export const GET_BUSINESS_USERS_FAILURE = "GET_BUSINESS_USERS_FAILURE";

export const GET_ALL_TICKETS_REQUEST = "GET_ALL_TICKETS_REQUEST";
export const GET_ALL_TICKETS_STARTED = "GET_ALL_TICKETS_STARTED";
export const GET_ALL_TICKETS_SUCCESS = "GET_ALL_TICKETS_SUCCESS";
export const GET_ALL_TICKETS_FAILURE = "GET_ALL_TICKETS_FAILURE";

export const GET_OPEN_TICKETS_REQUEST = "GET_OPEN_TICKETS_REQUEST";
export const GET_OPEN_TICKETS_STARTED = "GET_OPEN_TICKETS_STARTED";
export const GET_OPEN_TICKETS_SUCCESS = "GET_OPEN_TICKETS_SUCCESS";
export const GET_OPEN_TICKETS_FAILURE = "GET_OPEN_TICKETS_FAILURE";

export const CREATE_NEW_TICKET_REQUEST = "CREATE_NEW_TICKET_REQUEST";
export const CREATE_NEW_TICKET_STARTED = "CREATE_NEW_TICKET_STARTED";
export const CREATE_NEW_TICKET_SUCCESS = "CREATE_NEW_TICKET_SUCCESS";
export const CREATE_NEW_TICKET_FAILURE = "CREATE_NEW_TICKET_FAILURE";

export const GET_TICKET_DETAILS_REQUEST = "GET_TICKET_DETAILS_REQUEST";
export const GET_TICKET_DETAILS_STARTED = "GET_TICKET_DETAILS_STARTED";
export const GET_TICKET_DETAILS_SUCCESS = "GET_TICKET_DETAILS_SUCCESS";
export const GET_TICKET_DETAILS_FAILURE = "GET_TICKET_DETAILS_FAILURE";

export const CHANGE_TICKET_STATUS_REQUEST = "CHANGE_TICKET_STATUS_REQUEST";
export const CHANGE_TICKET_STATUS_STARTED = "CHANGE_TICKET_STATUS_STARTED";
export const CHANGE_TICKET_STATUS_SUCCESS = "CHANGE_TICKET_STATUS_SUCCESS";
export const CHANGE_TICKET_STATUS_FAILURE = "CHANGE_TICKET_STATUS_FAILURE";

export const CREATE_REPLY_TICKET_REQUEST = "CREATE_REPLY_TICKET_REQUEST";
export const CREATE_REPLY_TICKET_STARTED = "CREATE_REPLY_TICKET_STARTED";
export const CREATE_REPLY_TICKET_SUCCESS = "CREATE_REPLY_TICKET_SUCCESS";
export const CREATE_REPLY_TICKET_FAILURE = "CREATE_REPLY_TICKET_FAILURE";

export const CHANGE_TICKET_ROLE_REQUEST = "CHANGE_TICKET_ROLE_REQUEST";
export const CHANGE_TICKET_ROLE_STARTED = "CHANGE_TICKET_ROLE_STARTED";
export const CHANGE_TICKET_ROLE_SUCCESS = "CHANGE_TICKET_ROLE_SUCCESS";
export const CHANGE_TICKET_ROLE_FAILURE = "CHANGE_TICKET_ROLE_FAILURE";

export const DELETE_TICKET_REQUEST = "DELETE_TICKET_REQUEST";
export const DELETE_TICKET_STARTED = "DELETE_TICKET_STARTED";
export const DELETE_TICKET_SUCCESS = "DELETE_TICKET_SUCCESS";
export const DELETE_TICKET_FAILURE = "DELETE_TICKET_FAILURE";

export const ADD_TICKET_COMMENT_REQUEST = "ADD_TICKET_COMMENT_REQUEST";
export const ADD_TICKET_COMMENT_STARTED = "ADD_TICKET_COMMENT_STARTED";
export const ADD_TICKET_COMMENT_SUCCESS = "ADD_TICKET_COMMENT_SUCCESS";
export const ADD_TICKET_COMMENT_FAILURE = "ADD_TICKET_COMMENT_FAILURE";

export const GET_STRIPE_INVOICE_REQUEST = "GET_STRIPE_INVOICE_REQUEST";
export const GET_STRIPE_INVOICE_STARTED = "GET_STRIPE_INVOICE_STARTED";
export const GET_STRIPE_INVOICE_SUCCESS = "GET_STRIPE_INVOICE_SUCCESS";
export const GET_STRIPE_INVOICE_FAILURE = "GET_STRIPE_INVOICE_FAILURE";

// ---------------------------------------- YOUTUBE ----------------------------------------
export const GET_ALL_YOUTUBE_CHANNELS_DEFAULT =
  "GET_ALL_YOUTUBE_CHANNELS_DEFAULT";
export const GET_ALL_YOUTUBE_CHANNELS_REQUEST =
  "GET_ALL_YOUTUBE_CHANNELS_REQUEST";
export const GET_ALL_YOUTUBE_CHANNELS_STARTED =
  "GET_ALL_YOUTUBE_CHANNELS_STARTED";
export const GET_ALL_YOUTUBE_CHANNELS_SUCCESS =
  "GET_ALL_YOUTUBE_CHANNELS_SUCCESS";
export const GET_ALL_YOUTUBE_CHANNELS_FAILURE =
  "GET_ALL_YOUTUBE_CHANNELS_FAILURE";

export const CREATE_YOUTUBE_CHANNEL_REQUEST = "CREATE_YOUTUBE_CHANNEL_REQUEST";
export const CREATE_YOUTUBE_CHANNEL_STARTED = "CREATE_YOUTUBE_CHANNEL_STARTED";
export const CREATE_YOUTUBE_CHANNEL_SUCCESS = "CREATE_YOUTUBE_CHANNEL_SUCCESS";
export const CREATE_YOUTUBE_CHANNEL_FAILURE = "CREATE_YOUTUBE_CHANNEL_FAILURE";

export const GET_YOUTUBE_ARZISHO_FORM_REQUEST =
  "GET_YOUTUBE_ARZISHO_FORM_REQUEST";
export const GET_YOUTUBE_ARZISHO_FORM_STARTED =
  "GET_YOUTUBE_ARZISHO_FORM_STARTED";
export const GET_YOUTUBE_ARZISHO_FORM_SUCCESS =
  "GET_YOUTUBE_ARZISHO_FORM_SUCCESS";
export const GET_YOUTUBE_ARZISHO_FORM_FAILURE =
  "GET_YOUTUBE_ARZISHO_FORM_FAILURE";

export const GET_ARZISHO_CONSULTANT_FORM_REQUEST =
  "GET_ARZISHO_CONSULTANT_FORM_REQUEST";
export const GET_ARZISHO_CONSULTANT_FORM_STARTED =
  "GET_ARZISHO_CONSULTANT_FORM_STARTED";
export const GET_ARZISHO_CONSULTANT_FORM_SUCCESS =
  "GET_ARZISHO_CONSULTANT_FORM_SUCCESS";
export const GET_ARZISHO_CONSULTANT_FORM_FAILURE =
  "GET_ARZISHO_CONSULTANT_FORM_FAILURE";

export const GET_YOUTUBE_PAYOUTS_REQUEST = "GET_YOUTUBE_PAYOUTS_REQUEST";
export const GET_YOUTUBE_PAYOUTS_STARTED = "GET_YOUTUBE_PAYOUTS_STARTED";
export const GET_YOUTUBE_PAYOUTS_SUCCESS = "GET_YOUTUBE_PAYOUTS_SUCCESS";
export const GET_YOUTUBE_PAYOUTS_FAILURE = "GET_YOUTUBE_PAYOUTS_FAILURE";

export const GET_YEKTUBER_SIGNUP_REQUEST = "GET_YEKTUBER_SIGNUP_REQUEST";
export const GET_YEKTUBER_SIGNUP_STARTED = "GET_YEKTUBER_SIGNUP_STARTED";
export const GET_YEKTUBER_SIGNUP_SUCCESS = "GET_YEKTUBER_SIGNUP_SUCCESS";
export const GET_YEKTUBER_SIGNUP_FAILURE = "GET_YEKTUBER_SIGNUP_FAILURE";

export const GET_YOUTUBE_COUNSELING_LIST_REQUEST =
  "GET_YOUTUBE_COUNSELING_LIST_REQUEST";
export const GET_YOUTUBE_COUNSELING_LIST_STARTED =
  "GET_YOUTUBE_COUNSELING_LIST_STARTED";
export const GET_YOUTUBE_COUNSELING_LIST_SUCCESS =
  "GET_YOUTUBE_COUNSELING_LIST_SUCCESS";
export const GET_YOUTUBE_COUNSELING_LIST_FAILURE =
  "GET_YOUTUBE_COUNSELING_LIST_FAILURE";

export const GET_YOUTUBE_CHECKING_LIST_REQUEST =
  "GET_YOUTUBE_CHECKING_LIST_REQUEST";
export const GET_YOUTUBE_CHECKING_LIST_STARTED =
  "GET_YOUTUBE_CHECKING_LIST_STARTED";
export const GET_YOUTUBE_CHECKING_LIST_SUCCESS =
  "GET_YOUTUBE_CHECKING_LIST_SUCCESS";
export const GET_YOUTUBE_CHECKING_LIST_FAILURE =
  "GET_YOUTUBE_CHECKING_LIST_FAILURE";

export const GET_YOUTUBE_CONTACT_LIST_REQUEST =
  "GET_YOUTUBE_CONTACT_LIST_REQUEST";
export const GET_YOUTUBE_CONTACT_LIST_STARTED =
  "GET_YOUTUBE_CONTACT_LIST_STARTED";
export const GET_YOUTUBE_CONTACT_LIST_SUCCESS =
  "GET_YOUTUBE_CONTACT_LIST_SUCCESS";
export const GET_YOUTUBE_CONTACT_LIST_FAILURE =
  "GET_YOUTUBE_CONTACT_LIST_FAILURE";

export const GET_YOUTUBE_PARTNER_LIST_REQUEST =
  "GET_YOUTUBE_PARTNER_LIST_REQUEST";
export const GET_YOUTUBE_PARTNER_LIST_STARTED =
  "GET_YOUTUBE_PARTNER_LIST_STARTED";
export const GET_YOUTUBE_PARTNER_LIST_SUCCESS =
  "GET_YOUTUBE_PARTNER_LIST_SUCCESS";
export const GET_YOUTUBE_PARTNER_LIST_FAILURE =
  "GET_YOUTUBE_PARTNER_LIST_FAILURE";

export const CHECK_YOUTUBE_CHANNELS_REQUEST = "CHECK_YOUTUBE_CHANNELS_REQUEST";
export const CHECK_YOUTUBE_CHANNELS_STARTED = "CHECK_YOUTUBE_CHANNELS_STARTED";
export const CHECK_YOUTUBE_CHANNELS_SUCCESS = "CHECK_YOUTUBE_CHANNELS_SUCCESS";
export const CHECK_YOUTUBE_CHANNELS_FAILURE = "CHECK_YOUTUBE_CHANNELS_FAILURE";

// ---------------------------------------- SUPPORT ----------------------------------------
export const GET_ALL_TEXT_MESSAGES_DEFAULT = "GET_ALL_TEXT_MESSAGES_DEFAULT";
export const GET_ALL_TEXT_MESSAGES_REQUEST = "GET_ALL_TEXT_MESSAGES_REQUEST";
export const GET_ALL_TEXT_MESSAGES_STARTED = "GET_ALL_TEXT_MESSAGES_STARTED";
export const GET_ALL_TEXT_MESSAGES_SUCCESS = "GET_ALL_TEXT_MESSAGES_SUCCESS";
export const GET_ALL_TEXT_MESSAGES_FAILURE = "GET_ALL_TEXT_MESSAGES_FAILURE";

export const SEND_SINGLE_SMS_REQUEST = "SEND_SINGLE_SMS_REQUEST";
export const SEND_SINGLE_SMS_STARTED = "SEND_SINGLE_SMS_STARTED";
export const SEND_SINGLE_SMS_SUCCESS = "SEND_SINGLE_SMS_SUCCESS";
export const SEND_SINGLE_SMS_FAILURE = "SEND_SINGLE_SMS_FAILURE";

// ---------------------------------------- FINANCIAL ----------------------------------------
export const GET_ALL_TRANSACTIONS_DEFAULT = "GET_ALL_TRANSACTIONS_DEFAULT";
export const GET_ALL_TRANSACTIONS_REQUEST = "GET_ALL_TRANSACTIONS_REQUEST";
export const GET_ALL_TRANSACTIONS_STARTED = "GET_ALL_TRANSACTIONS_STARTED";
export const GET_ALL_TRANSACTIONS_SUCCESS = "GET_ALL_TRANSACTIONS_SUCCESS";
export const GET_ALL_TRANSACTIONS_FAILURE = "GET_ALL_TRANSACTIONS_FAILURE";

export const GET_HOLD_TRANSACTIONS_DEFAULT = "GET_HOLD_TRANSACTIONS_DEFAULT";
export const GET_HOLD_TRANSACTIONS_REQUEST = "GET_HOLD_TRANSACTIONS_REQUEST";
export const GET_HOLD_TRANSACTIONS_STARTED = "GET_HOLD_TRANSACTIONS_STARTED";
export const GET_HOLD_TRANSACTIONS_SUCCESS = "GET_HOLD_TRANSACTIONS_SUCCESS";
export const GET_HOLD_TRANSACTIONS_FAILURE = "GET_HOLD_TRANSACTIONS_FAILURE";

export const CHANGE_HOLD_TRANSACTION_STATUS_REQUEST =
  "CHANGE_HOLD_TRANSACTION_STATUS_REQUEST";
export const CHANGE_HOLD_TRANSACTION_STATUS_STARTED =
  "CHANGE_HOLD_TRANSACTION_STATUS_STARTED";
export const CHANGE_HOLD_TRANSACTION_STATUS_SUCCESS =
  "CHANGE_HOLD_TRANSACTION_STATUS_SUCCESS";
export const CHANGE_HOLD_TRANSACTION_STATUS_FAILURE =
  "CHANGE_HOLD_TRANSACTION_STATUS_FAILURE";

export const GET_ALL_PAYOUTS_REQUEST = "GET_ALL_PAYOUTS_REQUEST";
export const GET_ALL_PAYOUTS_STARTED = "GET_ALL_PAYOUTS_STARTED";
export const GET_ALL_PAYOUTS_SUCCESS = "GET_ALL_PAYOUTS_SUCCESS";
export const GET_ALL_PAYOUTS_FAILURE = "GET_ALL_PAYOUTS_FAILURE";

export const GET_PENDING_PAYOUTS_REQUEST = "GET_PENDING_PAYOUTS_REQUEST";
export const GET_PENDING_PAYOUTS_STARTED = "GET_PENDING_PAYOUTS_STARTED";
export const GET_PENDING_PAYOUTS_SUCCESS = "GET_PENDING_PAYOUTS_SUCCESS";
export const GET_PENDING_PAYOUTS_FAILURE = "GET_PENDING_PAYOUTS_FAILURE";

export const GET_TRANSACTIONS_PAYOUT_PAY_REQUEST =
  "GET_TRANSACTIONS_PAYOUT_PAY_REQUEST";
export const GET_TRANSACTIONS_PAYOUT_PAY_STARTED =
  "GET_TRANSACTIONS_PAYOUT_PAY_STARTED";
export const GET_TRANSACTIONS_PAYOUT_PAY_SUCCESS =
  "GET_TRANSACTIONS_PAYOUT_PAY_SUCCESS";
export const GET_TRANSACTIONS_PAYOUT_PAY_FAILURE =
  "GET_TRANSACTIONS_PAYOUT_PAY_FAILURE";

export const GET_VIP_PENDING_PAYOUTS_REQUEST =
  "GET_VIP_PENDING_PAYOUTS_REQUEST";
export const GET_VIP_PENDING_PAYOUTS_STARTED =
  "GET_VIP_PENDING_PAYOUTS_STARTED";
export const GET_VIP_PENDING_PAYOUTS_SUCCESS =
  "GET_VIP_PENDING_PAYOUTS_SUCCESS";
export const GET_VIP_PENDING_PAYOUTS_FAILURE =
  "GET_VIP_PENDING_PAYOUTS_FAILURE";

export const CONFIRM_VIP_PAYOUT_REQUEST = "CONFIRM_VIP_PAYOUT_REQUEST";
export const CONFIRM_VIP_PAYOUT_STARTED = "CONFIRM_VIP_PAYOUT_STARTED";
export const CONFIRM_VIP_PAYOUT_SUCCESS = "CONFIRM_VIP_PAYOUT_SUCCESS";
export const CONFIRM_VIP_PAYOUT_FAILURE = "CONFIRM_VIP_PAYOUT_FAILURE";

export const GET_WIRE_PAYOUTS_REQUEST = "GET_WIRE_PAYOUTS_REQUEST";
export const GET_WIRE_PAYOUTS_STARTED = "GET_WIRE_PAYOUTS_STARTED";
export const GET_WIRE_PAYOUTS_SUCCESS = "GET_WIRE_PAYOUTS_SUCCESS";
export const GET_WIRE_PAYOUTS_FAILURE = "GET_WIRE_PAYOUTS_FAILURE";

export const GET_ALL_TOPUPS_REQUEST = "GET_ALL_TOPUPS_REQUEST";
export const GET_ALL_TOPUPS_STARTED = "GET_ALL_TOPUPS_STARTED";
export const GET_ALL_TOPUPS_SUCCESS = "GET_ALL_TOPUPS_SUCCESS";
export const GET_ALL_TOPUPS_FAILURE = "GET_ALL_TOPUPS_FAILURE";

export const GET_REFUND_REQUESTS_REQUEST = "GET_REFUND_REQUESTS_REQUEST";
export const GET_REFUND_REQUESTS_STARTED = "GET_REFUND_REQUESTS_STARTED";
export const GET_REFUND_REQUESTS_SUCCESS = "GET_REFUND_REQUESTS_SUCCESS";
export const GET_REFUND_REQUESTS_FAILURE = "GET_REFUND_REQUESTS_FAILURE";

export const CHANGE_REFUND_REQUEST_STATUS_REQUEST =
  "CHANGE_REFUND_REQUEST_STATUS_REQUEST";
export const CHANGE_REFUND_REQUEST_STATUS_STARTED =
  "CHANGE_REFUND_REQUEST_STATUS_STARTED";
export const CHANGE_REFUND_REQUEST_STATUS_SUCCESS =
  "CHANGE_REFUND_REQUEST_STATUS_SUCCESS";
export const CHANGE_REFUND_REQUEST_STATUS_FAILURE =
  "CHANGE_REFUND_REQUEST_STATUS_FAILURE";

export const GET_WEBSITE_GATEWAYS_REQUEST = "GET_WEBSITE_GATEWAYS_REQUEST";
export const GET_WEBSITE_GATEWAYS_STARTED = "GET_WEBSITE_GATEWAYS_STARTED";
export const GET_WEBSITE_GATEWAYS_SUCCESS = "GET_WEBSITE_GATEWAYS_SUCCESS";
export const GET_WEBSITE_GATEWAYS_FAILURE = "GET_WEBSITE_GATEWAYS_FAILURE";

export const GET_PAYMENT_LINK_TRANSACTIONS_REQUEST =
  "GET_PAYMENT_LINK_TRANSACTIONS_REQUEST";
export const GET_PAYMENT_LINK_TRANSACTIONS_STARTED =
  "GET_PAYMENT_LINK_TRANSACTIONS_STARTED";
export const GET_PAYMENT_LINK_TRANSACTIONS_SUCCESS =
  "GET_PAYMENT_LINK_TRANSACTIONS_SUCCESS";
export const GET_PAYMENT_LINK_TRANSACTIONS_FAILURE =
  "GET_PAYMENT_LINK_TRANSACTIONS_FAILURE";

export const GET_PERSONAL_TRANSACTIONS_REQUEST =
  "GET_PERSONAL_TRANSACTIONS_REQUEST";
export const GET_PERSONAL_TRANSACTIONS_STARTED =
  "GET_PERSONAL_TRANSACTIONS_STARTED";
export const GET_PERSONAL_TRANSACTIONS_SUCCESS =
  "GET_PERSONAL_TRANSACTIONS_SUCCESS";
export const GET_PERSONAL_TRANSACTIONS_FAILURE =
  "GET_PERSONAL_TRANSACTIONS_FAILURE";

export const GET_ALL_EXCHANGES_REQUEST = "GET_ALL_EXCHANGES_REQUEST";
export const GET_ALL_EXCHANGES_STARTED = "GET_ALL_EXCHANGES_STARTED";
export const GET_ALL_EXCHANGES_SUCCESS = "GET_ALL_EXCHANGES_SUCCESS";
export const GET_ALL_EXCHANGES_FAILURE = "GET_ALL_EXCHANGES_FAILURE";

export const GET_ALL_TRANSFERS_REQUEST = "GET_ALL_TRANSFERS_REQUEST";
export const GET_ALL_TRANSFERS_STARTED = "GET_ALL_TRANSFERS_STARTED";
export const GET_ALL_TRANSFERS_SUCCESS = "GET_ALL_TRANSFERS_SUCCESS";
export const GET_ALL_TRANSFERS_FAILURE = "GET_ALL_TRANSFERS_FAILURE";

export const GET_BANKS_REPORT_REQUEST = "GET_BANKS_REPORT_REQUEST";
export const GET_BANKS_REPORT_STARTED = "GET_BANKS_REPORT_STARTED";
export const GET_BANKS_REPORT_SUCCESS = "GET_BANKS_REPORT_SUCCESS";
export const GET_BANKS_REPORT_FAILURE = "GET_BANKS_REPORT_FAILURE";

// ---------------------------------------- USER PROFILE ----------------------------------------
export const GET_USER_PROFILE_DEFAULT = "GET_USER_PROFILE_DEFAULT";
export const GET_USER_PROFILE_REQUEST = "GET_USER_PROFILE_REQUEST";
export const GET_USER_PROFILE_STARTED = "GET_USER_PROFILE_STARTED";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
export const GET_USER_PROFILE_FAILURE = "GET_USER_PROFILE_FAILURE";

export const CLEAR_USER_PROFILE_REQUEST = "CLEAR_USER_PROFILE_REQUEST";
export const CLEAR_USER_PROFILE_SUCCESS = "CLEAR_USER_PROFILE_SUCCESS";

export const EDIT_USER_PROFILE_REQUEST = "EDIT_USER_PROFILE_REQUEST";
export const EDIT_USER_PROFILE_STARTED = "EDIT_USER_PROFILE_STARTED";
export const EDIT_USER_PROFILE_SUCCESS = "EDIT_USER_PROFILE_SUCCESS";
export const EDIT_USER_PROFILE_FAILURE = "EDIT_USER_PROFILE_FAILURE";

export const GET_USER_ITSAAZ_REQUEST = "GET_USER_ITSAAZ_REQUEST";
export const GET_USER_ITSAAZ_STARTED = "GET_USER_ITSAAZ_STARTED";
export const GET_USER_ITSAAZ_SUCCESS = "GET_USER_ITSAAZ_SUCCESS";
export const GET_USER_ITSAAZ_FAILURE = "GET_USER_ITSAAZ_FAILURE";

export const GET_USER_UID_REQUEST = "GET_USER_UID_REQUEST";
export const GET_USER_UID_STARTED = "GET_USER_UID_STARTED";
export const GET_USER_UID_SUCCESS = "GET_USER_UID_SUCCESS";
export const GET_USER_UID_FAILURE = "GET_USER_UID_FAILURE";

export const CHANGE_USER_STATUS_REQUEST = "CHANGE_USER_STATUS_REQUEST";
export const CHANGE_USER_STATUS_STARTED = "CHANGE_USER_STATUS_STARTED";
export const CHANGE_USER_STATUS_SUCCESS = "CHANGE_USER_STATUS_SUCCESS";
export const CHANGE_USER_STATUS_FAILURE = "CHANGE_USER_STATUS_FAILURE";

export const CHANGE_BUSINESS_CATEGORY_REQUEST =
  "CHANGE_BUSINESS_CATEGORY_REQUEST";
export const CHANGE_BUSINESS_CATEGORY_STARTED =
  "CHANGE_BUSINESS_CATEGORY_STARTED";
export const CHANGE_BUSINESS_CATEGORY_SUCCESS =
  "CHANGE_BUSINESS_CATEGORY_SUCCESS";
export const CHANGE_BUSINESS_CATEGORY_FAILURE =
  "CHANGE_BUSINESS_CATEGORY_FAILURE";

export const GET_USER_COMMENTS_REQUEST = "GET_USER_COMMENTS_REQUEST";
export const GET_USER_COMMENTS_STARTED = "GET_USER_COMMENTS_STARTED";
export const GET_USER_COMMENTS_SUCCESS = "GET_USER_COMMENTS_SUCCESS";
export const GET_USER_COMMENTS_FAILURE = "GET_USER_COMMENTS_FAILURE";

export const CREATE_USER_COMMENT_REQUEST = "CREATE_USER_COMMENT_REQUEST";
export const CREATE_USER_COMMENT_STARTED = "CREATE_USER_COMMENT_STARTED";
export const CREATE_USER_COMMENT_SUCCESS = "CREATE_USER_COMMENT_SUCCESS";
export const CREATE_USER_COMMENT_FAILURE = "CREATE_USER_COMMENT_FAILURE";

export const DELETE_USER_COMMENT_REQUEST = "DELETE_USER_COMMENT_REQUEST";
export const DELETE_USER_COMMENT_STARTED = "DELETE_USER_COMMENT_STARTED";
export const DELETE_USER_COMMENT_SUCCESS = "DELETE_USER_COMMENT_SUCCESS";
export const DELETE_USER_COMMENT_FAILURE = "DELETE_USER_COMMENT_FAILURE";

export const GET_USER_WALLETS_REQUEST = "GET_USER_WALLETS_REQUEST";
export const GET_USER_WALLETS_STARTED = "GET_USER_WALLETS_STARTED";
export const GET_USER_WALLETS_SUCCESS = "GET_USER_WALLETS_SUCCESS";
export const GET_USER_WALLETS_FAILURE = "GET_USER_WALLETS_FAILURE";

export const CREATE_USER_WALLET_REQUEST = "CREATE_USER_WALLET_REQUEST";
export const CREATE_USER_WALLET_STARTED = "CREATE_USER_WALLET_STARTED";
export const CREATE_USER_WALLET_SUCCESS = "CREATE_USER_WALLET_SUCCESS";
export const CREATE_USER_WALLET_FAILURE = "CREATE_USER_WALLET_FAILURE";

export const DELETE_USER_WALLET_REQUEST = "DELETE_USER_WALLET_REQUEST";
export const DELETE_USER_WALLET_STARTED = "DELETE_USER_WALLET_STARTED";
export const DELETE_USER_WALLET_SUCCESS = "DELETE_USER_WALLET_SUCCESS";
export const DELETE_USER_WALLET_FAILURE = "DELETE_USER_WALLET_FAILURE";

export const GET_USER_FINANCE_REQUEST = "GET_USER_FINANCE_REQUEST";
export const GET_USER_FINANCE_STARTED = "GET_USER_FINANCE_STARTED";
export const GET_USER_FINANCE_SUCCESS = "GET_USER_FINANCE_SUCCESS";
export const GET_USER_FINANCE_FAILURE = "GET_USER_FINANCE_FAILURE";

export const GET_USER_YOUTUBE_CHANNELS_REQUEST =
  "GET_USER_YOUTUBE_CHANNELS_REQUEST";
export const GET_USER_YOUTUBE_CHANNELS_STARTED =
  "GET_USER_YOUTUBE_CHANNELS_STARTED";
export const GET_USER_YOUTUBE_CHANNELS_SUCCESS =
  "GET_USER_YOUTUBE_CHANNELS_SUCCESS";
export const GET_USER_YOUTUBE_CHANNELS_FAILURE =
  "GET_USER_YOUTUBE_CHANNELS_FAILURE";

export const EDIT_YOUTUBE_CHANNEL_INFO_REQUEST =
  "EDIT_YOUTUBE_CHANNEL_INFO_REQUEST";
export const EDIT_YOUTUBE_CHANNEL_INFO_STARTED =
  "EDIT_YOUTUBE_CHANNEL_INFO_STARTED";
export const EDIT_YOUTUBE_CHANNEL_INFO_SUCCESS =
  "EDIT_YOUTUBE_CHANNEL_INFO_SUCCESS";
export const EDIT_YOUTUBE_CHANNEL_INFO_FAILURE =
  "EDIT_YOUTUBE_CHANNEL_INFO_FAILURE";

export const ADD_YOUTUBE_ARCHIVE_CONTRACT_REQUEST =
  "ADD_YOUTUBE_ARCHIVE_CONTRACT_REQUEST";
export const ADD_YOUTUBE_ARCHIVE_CONTRACT_STARTED =
  "ADD_YOUTUBE_ARCHIVE_CONTRACT_STARTED";
export const ADD_YOUTUBE_ARCHIVE_CONTRACT_SUCCESS =
  "ADD_YOUTUBE_ARCHIVE_CONTRACT_SUCCESS";
export const ADD_YOUTUBE_ARCHIVE_CONTRACT_FAILURE =
  "ADD_YOUTUBE_ARCHIVE_CONTRACT_FAILURE";

export const GET_YOUTUBE_ARCHIVE_CONTRACTS_REQUEST =
  "GET_YOUTUBE_ARCHIVE_CONTRACTS_REQUEST";
export const GET_YOUTUBE_ARCHIVE_CONTRACTS_STARTED =
  "GET_YOUTUBE_ARCHIVE_CONTRACTS_STARTED";
export const GET_YOUTUBE_ARCHIVE_CONTRACTS_SUCCESS =
  "GET_YOUTUBE_ARCHIVE_CONTRACTS_SUCCESS";
export const GET_YOUTUBE_ARCHIVE_CONTRACTS_FAILURE =
  "GET_YOUTUBE_ARCHIVE_CONTRACTS_FAILURE";

export const DELETE_YOUTUBE_ARCHIVE_CONTRACT_REQUEST =
  "DELETE_YOUTUBE_ARCHIVE_CONTRACT_REQUEST";
export const DELETE_YOUTUBE_ARCHIVE_CONTRACT_STARTED =
  "DELETE_YOUTUBE_ARCHIVE_CONTRACT_STARTED";
export const DELETE_YOUTUBE_ARCHIVE_CONTRACT_SUCCESS =
  "DELETE_YOUTUBE_ARCHIVE_CONTRACT_SUCCESS";
export const DELETE_YOUTUBE_ARCHIVE_CONTRACT_FAILURE =
  "DELETE_YOUTUBE_ARCHIVE_CONTRACT_FAILURE";

export const CREATE_YOUTUBE_CHANNEL_CONTRACT_REQUEST =
  "CREATE_YOUTUBE_CHANNEL_CONTRACT_REQUEST";
export const CREATE_YOUTUBE_CHANNEL_CONTRACT_STARTED =
  "CREATE_YOUTUBE_CHANNEL_CONTRACT_STARTED";
export const CREATE_YOUTUBE_CHANNEL_CONTRACT_SUCCESS =
  "CREATE_YOUTUBE_CHANNEL_CONTRACT_SUCCESS";
export const CREATE_YOUTUBE_CHANNEL_CONTRACT_FAILURE =
  "CREATE_YOUTUBE_CHANNEL_CONTRACT_FAILURE";

export const EDIT_YOUTUBE_CHANNEL_CONTRACT_REQUEST =
  "EDIT_YOUTUBE_CHANNEL_CONTRACT_REQUEST";
export const EDIT_YOUTUBE_CHANNEL_CONTRACT_STARTED =
  "EDIT_YOUTUBE_CHANNEL_CONTRACT_STARTED";
export const EDIT_YOUTUBE_CHANNEL_CONTRACT_SUCCESS =
  "EDIT_YOUTUBE_CHANNEL_CONTRACT_SUCCESS";
export const EDIT_YOUTUBE_CHANNEL_CONTRACT_FAILURE =
  "EDIT_YOUTUBE_CHANNEL_CONTRACT_FAILURE";

export const GET_YOUTUBE_CHANNEL_COMMENTS_REQUEST =
  "GET_YOUTUBE_CHANNEL_COMMENTS_REQUEST";
export const GET_YOUTUBE_CHANNEL_COMMENTS_STARTED =
  "GET_YOUTUBE_CHANNEL_COMMENTS_STARTED";
export const GET_YOUTUBE_CHANNEL_COMMENTS_SUCCESS =
  "GET_YOUTUBE_CHANNEL_COMMENTS_SUCCESS";
export const GET_YOUTUBE_CHANNEL_COMMENTS_FAILURE =
  "GET_YOUTUBE_CHANNEL_COMMENTS_FAILURE";

export const CREATE_YOUTUBE_CHANNEL_COMMENT_REQUEST =
  "CREATE_YOUTUBE_CHANNEL_COMMENT_REQUEST";
export const CREATE_YOUTUBE_CHANNEL_COMMENT_STARTED =
  "CREATE_YOUTUBE_CHANNEL_COMMENT_STARTED";
export const CREATE_YOUTUBE_CHANNEL_COMMENT_SUCCESS =
  "CREATE_YOUTUBE_CHANNEL_COMMENT_SUCCESS";
export const CREATE_YOUTUBE_CHANNEL_COMMENT_FAILURE =
  "CREATE_YOUTUBE_CHANNEL_COMMENT_FAILURE";

export const DELETE_YOUTUBE_CHANNEL_COMMENT_REQUEST =
  "DELETE_YOUTUBE_CHANNEL_COMMENT_REQUEST";
export const DELETE_YOUTUBE_CHANNEL_COMMENT_STARTED =
  "DELETE_YOUTUBE_CHANNEL_COMMENT_STARTED";
export const DELETE_YOUTUBE_CHANNEL_COMMENT_SUCCESS =
  "DELETE_YOUTUBE_CHANNEL_COMMENT_SUCCESS";
export const DELETE_YOUTUBE_CHANNEL_COMMENT_FAILURE =
  "DELETE_YOUTUBE_CHANNEL_COMMENT_FAILURE";

export const GET_USER_COMPANY_REQUEST = "GET_USER_COMPANY_REQUEST";
export const GET_USER_COMPANY_STARTED = "GET_USER_COMPANY_STARTED";
export const GET_USER_COMPANY_SUCCESS = "GET_USER_COMPANY_SUCCESS";
export const GET_USER_COMPANY_FAILURE = "GET_USER_COMPANY_FAILURE";

export const CHANGE_USER_COMPANY_STATUS_REQUEST =
  "CHANGE_USER_COMPANY_STATUS_REQUEST";
export const CHANGE_USER_COMPANY_STATUS_STARTED =
  "CHANGE_USER_COMPANY_STATUS_STARTED";
export const CHANGE_USER_COMPANY_STATUS_SUCCESS =
  "CHANGE_USER_COMPANY_STATUS_SUCCESS";
export const CHANGE_USER_COMPANY_STATUS_FAILURE =
  "CHANGE_USER_COMPANY_STATUS_FAILURE";

export const GET_USER_ADDRESS_REQUEST = "GET_USER_ADDRESS_REQUEST";
export const GET_USER_ADDRESS_STARTED = "GET_USER_ADDRESS_STARTED";
export const GET_USER_ADDRESS_SUCCESS = "GET_USER_ADDRESS_SUCCESS";
export const GET_USER_ADDRESS_FAILURE = "GET_USER_ADDRESS_FAILURE";

export const CHANGE_USER_ADDRESS_STATUS_REQUEST =
  "CHANGE_USER_ADDRESS_STATUS_REQUEST";
export const CHANGE_USER_ADDRESS_STATUS_STARTED =
  "CHANGE_USER_ADDRESS_STATUS_STARTED";
export const CHANGE_USER_ADDRESS_STATUS_SUCCESS =
  "CHANGE_USER_ADDRESS_STATUS_SUCCESS";
export const CHANGE_USER_ADDRESS_STATUS_FAILURE =
  "CHANGE_USER_ADDRESS_STATUS_FAILURE";

export const GET_USER_PLANS_REQUEST = "GET_USER_PLANS_REQUEST";
export const GET_USER_PLANS_STARTED = "GET_USER_PLANS_STARTED";
export const GET_USER_PLANS_SUCCESS = "GET_USER_PLANS_SUCCESS";
export const GET_USER_PLANS_FAILURE = "GET_USER_PLANS_FAILURE";

// export const CREATE_USER_PLAN_REQUEST = "CREATE_USER_PLAN_REQUEST";
// export const CREATE_USER_PLAN_STARTED = "CREATE_USER_PLAN_STARTED";
// export const CREATE_USER_PLAN_SUCCESS = "CREATE_USER_PLAN_SUCCESS";
// export const CREATE_USER_PLAN_FAILURE = "CREATE_USER_PLAN_FAILURE";

export const GET_USER_TRANSACTIONS_REQUEST = "GET_USER_TRANSACTIONS_REQUEST";
export const GET_USER_TRANSACTIONS_STARTED = "GET_USER_TRANSACTIONS_STARTED";
export const GET_USER_TRANSACTIONS_SUCCESS = "GET_USER_TRANSACTIONS_SUCCESS";
export const GET_USER_TRANSACTIONS_FAILURE = "GET_USER_TRANSACTIONS_FAILURE";

export const GET_USER_FAILED_TRANSACTIONS_REQUEST =
  "GET_USER_FAILED_TRANSACTIONS_REQUEST";
export const GET_USER_FAILED_TRANSACTIONS_STARTED =
  "GET_USER_FAILED_TRANSACTIONS_STARTED";
export const GET_USER_FAILED_TRANSACTIONS_SUCCESS =
  "GET_USER_FAILED_TRANSACTIONS_SUCCESS";
export const GET_USER_FAILED_TRANSACTIONS_FAILURE =
  "GET_USER_FAILED_TRANSACTIONS_FAILURE";

export const GET_USER_BANK_ACCOUNTS_REQUEST = "GET_USER_BANK_ACCOUNTS_REQUEST";
export const GET_USER_BANK_ACCOUNTS_STARTED = "GET_USER_BANK_ACCOUNTS_STARTED";
export const GET_USER_BANK_ACCOUNTS_SUCCESS = "GET_USER_BANK_ACCOUNTS_SUCCESS";
export const GET_USER_BANK_ACCOUNTS_FAILURE = "GET_USER_BANK_ACCOUNTS_FAILURE";

export const CHANGE_USER_BANK_ACCOUNT_STATUS_REQUEST =
  "CHANGE_USER_BANK_ACCOUNT_STATUS_REQUEST";
export const CHANGE_USER_BANK_ACCOUNT_STATUS_STARTED =
  "CHANGE_USER_BANK_ACCOUNT_STATUS_STARTED";
export const CHANGE_USER_BANK_ACCOUNT_STATUS_SUCCESS =
  "CHANGE_USER_BANK_ACCOUNT_STATUS_SUCCESS";
export const CHANGE_USER_BANK_ACCOUNT_STATUS_FAILURE =
  "CHANGE_USER_BANK_ACCOUNT_STATUS_FAILURE";

export const DELETE_USER_BANK_ACCOUNT_REQUEST =
  "DELETE_USER_BANK_ACCOUNT_REQUEST";
export const DELETE_USER_BANK_ACCOUNT_STARTED =
  "DELETE_USER_BANK_ACCOUNT_STARTED";
export const DELETE_USER_BANK_ACCOUNT_SUCCESS =
  "DELETE_USER_BANK_ACCOUNT_SUCCESS";
export const DELETE_USER_BANK_ACCOUNT_FAILURE =
  "DELETE_USER_BANK_ACCOUNT_FAILURE";

export const RESTORE_USER_BANK_ACCOUNT_REQUEST =
  "RESTORE_USER_BANK_ACCOUNT_REQUEST";
export const RESTORE_USER_BANK_ACCOUNT_STARTED =
  "RESTORE_USER_BANK_ACCOUNT_STARTED";
export const RESTORE_USER_BANK_ACCOUNT_SUCCESS =
  "RESTORE_USER_BANK_ACCOUNT_SUCCESS";
export const RESTORE_USER_BANK_ACCOUNT_FAILURE =
  "RESTORE_USER_BANK_ACCOUNT_FAILURE";

export const CREATE_USER_BANK_ACCOUNT_REQUEST =
  "CREATE_USER_BANK_ACCOUNT_REQUEST";
export const CREATE_USER_BANK_ACCOUNT_STARTED =
  "CREATE_USER_BANK_ACCOUNT_STARTED";
export const CREATE_USER_BANK_ACCOUNT_SUCCESS =
  "CREATE_USER_BANK_ACCOUNT_SUCCESS";
export const CREATE_USER_BANK_ACCOUNT_FAILURE =
  "CREATE_USER_BANK_ACCOUNT_FAILURE";

export const GET_USER_GATEWAYS_REQUEST = "GET_USER_GATEWAYS_REQUEST";
export const GET_USER_GATEWAYS_STARTED = "GET_USER_GATEWAYS_STARTED";
export const GET_USER_GATEWAYS_SUCCESS = "GET_USER_GATEWAYS_SUCCESS";
export const GET_USER_GATEWAYS_FAILURE = "GET_USER_GATEWAYS_FAILURE";

export const CHANGE_USER_GATEWAY_STATUS_REQUEST =
  "CHANGE_USER_GATEWAY_STATUS_REQUEST";
export const CHANGE_USER_GATEWAY_STATUS_STARTED =
  "CHANGE_USER_GATEWAY_STATUS_STARTED";
export const CHANGE_USER_GATEWAY_STATUS_SUCCESS =
  "CHANGE_USER_GATEWAY_STATUS_SUCCESS";
export const CHANGE_USER_GATEWAY_STATUS_FAILURE =
  "CHANGE_USER_GATEWAY_STATUS_FAILURE";

export const GET_USER_YEKPAY_LINKS_REQUEST = "GET_USER_YEKPAY_LINKS_REQUEST";
export const GET_USER_YEKPAY_LINKS_STARTED = "GET_USER_YEKPAY_LINKS_STARTED";
export const GET_USER_YEKPAY_LINKS_SUCCESS = "GET_USER_YEKPAY_LINKS_SUCCESS";
export const GET_USER_YEKPAY_LINKS_FAILURE = "GET_USER_YEKPAY_LINKS_FAILURE";

export const GET_PERSONAL_PAYLINKS_REQUEST = "GET_PERSONAL_PAYLINKS_REQUEST";
export const GET_PERSONAL_PAYLINKS_STARTED = "GET_PERSONAL_PAYLINKS_STARTED";
export const GET_PERSONAL_PAYLINKS_SUCCESS = "GET_PERSONAL_PAYLINKS_SUCCESS";
export const GET_PERSONAL_PAYLINKS_FAILURE = "GET_PERSONAL_PAYLINKS_FAILURE";

export const CHANGE_PERSONAL_PAYLINK_STATUS_REQUEST =
  "CHANGE_PERSONAL_PAYLINK_STATUS_REQUEST";
export const CHANGE_PERSONAL_PAYLINK_STATUS_STARTED =
  "CHANGE_PERSONAL_PAYLINK_STATUS_STARTED";
export const CHANGE_PERSONAL_PAYLINK_STATUS_SUCCESS =
  "CHANGE_PERSONAL_PAYLINK_STATUS_SUCCESS";
export const CHANGE_PERSONAL_PAYLINK_STATUS_FAILURE =
  "CHANGE_PERSONAL_PAYLINK_STATUS_FAILURE";

export const CHANGE_PRODUCT_PAYLINK_STATUS_REQUEST =
  "CHANGE_PRODUCT_PAYLINK_STATUS_REQUEST";
export const CHANGE_PRODUCT_PAYLINK_STATUS_STARTED =
  "CHANGE_PRODUCT_PAYLINK_STATUS_STARTED";
export const CHANGE_PRODUCT_PAYLINK_STATUS_SUCCESS =
  "CHANGE_PRODUCT_PAYLINK_STATUS_SUCCESS";
export const CHANGE_PRODUCT_PAYLINK_STATUS_FAILURE =
  "CHANGE_PRODUCT_PAYLINK_STATUS_FAILURE";

export const GET_PRODUCT_PAYLINKS_REQUEST = "GET_PRODUCT_PAYLINKS_REQUEST";
export const GET_PRODUCT_PAYLINKS_STARTED = "GET_PRODUCT_PAYLINKS_STARTED";
export const GET_PRODUCT_PAYLINKS_SUCCESS = "GET_PRODUCT_PAYLINKS_SUCCESS";
export const GET_PRODUCT_PAYLINKS_FAILURE = "GET_PRODUCT_PAYLINKS_FAILURE";

export const CHANGE_USER_YEKPAY_LINK_STATUS_REQUEST =
  "CHANGE_USER_YEKPAY_LINK_STATUS_REQUEST";
export const CHANGE_USER_YEKPAY_LINK_STATUS_STARTED =
  "CHANGE_USER_YEKPAY_LINK_STATUS_STARTED";
export const CHANGE_USER_YEKPAY_LINK_STATUS_SUCCESS =
  "CHANGE_USER_YEKPAY_LINK_STATUS_SUCCESS";
export const CHANGE_USER_YEKPAY_LINK_STATUS_FAILURE =
  "CHANGE_USER_YEKPAY_LINK_STATUS_FAILURE";

export const DELETE_USER_YEKPAY_LINK_REQUEST =
  "DELETE_USER_YEKPAY_LINK_REQUEST";
export const DELETE_USER_YEKPAY_LINK_STARTED =
  "DELETE_USER_YEKPAY_LINK_STARTED";
export const DELETE_USER_YEKPAY_LINK_SUCCESS =
  "DELETE_USER_YEKPAY_LINK_SUCCESS";
export const DELETE_USER_YEKPAY_LINK_FAILURE =
  "DELETE_USER_YEKPAY_LINK_FAILURE";

export const GET_USER_TICKETS_REQUEST = "GET_USER_TICKETS_REQUEST";
export const GET_USER_TICKETS_STARTED = "GET_USER_TICKETS_STARTED";
export const GET_USER_TICKETS_SUCCESS = "GET_USER_TICKETS_SUCCESS";
export const GET_USER_TICKETS_FAILURE = "GET_USER_TICKETS_FAILURE";

export const GET_USER_LOGS_REQUEST = "GET_USER_LOGS_REQUEST";
export const GET_USER_LOGS_STARTED = "GET_USER_LOGS_STARTED";
export const GET_USER_LOGS_SUCCESS = "GET_USER_LOGS_SUCCESS";
export const GET_USER_LOGS_FAILURE = "GET_USER_LOGS_FAILURE";

export const CREATE_PAYOUT_REQUEST = "CREATE_PAYOUT_REQUEST";
export const CREATE_PAYOUT_STARTED = "CREATE_PAYOUT_STARTED";
export const CREATE_PAYOUT_SUCCESS = "CREATE_PAYOUT_SUCCESS";
export const CREATE_PAYOUT_FAILURE = "CREATE_PAYOUT_FAILURE";

export const GET_USER_TEXT_MESSAGES_REQUEST = "GET_USER_TEXT_MESSAGES_REQUEST";
export const GET_USER_TEXT_MESSAGES_STARTED = "GET_USER_TEXT_MESSAGES_STARTED";
export const GET_USER_TEXT_MESSAGES_SUCCESS = "GET_USER_TEXT_MESSAGES_SUCCESS";
export const GET_USER_TEXT_MESSAGES_FAILURE = "GET_USER_TEXT_MESSAGES_FAILURE";

export const GET_USER_CHANGE_LOGS_REQUEST = "GET_USER_CHANGE_LOGS_REQUEST";
export const GET_USER_CHANGE_LOGS_STARTED = "GET_USER_CHANGE_LOGS_STARTED";
export const GET_USER_CHANGE_LOGS_SUCCESS = "GET_USER_CHANGE_LOGS_SUCCESS";
export const GET_USER_CHANGE_LOGS_FAILURE = "GET_USER_CHANGE_LOGS_FAILURE";

export const TOPUP_USER_REQUEST = "TOPUP_USER_REQUEST";
export const TOPUP_USER_STARTED = "TOPUP_USER_STARTED";
export const TOPUP_USER_SUCCESS = "TOPUP_USER_SUCCESS";
export const TOPUP_USER_FAILURE = "TOPUP_USER_FAILURE";

export const GET_USER_EXCHANGE_DETAIL_REQUEST =
  "GET_USER_EXCHANGE_DETAIL_REQUEST";
export const GET_USER_EXCHANGE_DETAIL_STARTED =
  "GET_USER_EXCHANGE_DETAIL_STARTED";
export const GET_USER_EXCHANGE_DETAIL_SUCCESS =
  "GET_USER_EXCHANGE_DETAIL_SUCCESS";
export const GET_USER_EXCHANGE_DETAIL_FAILURE =
  "GET_USER_EXCHANGE_DETAIL_FAILURE";

export const EXCHANGE_USER_CURRENCY_REQUEST = "EXCHANGE_USER_CURRENCY_REQUEST";
export const EXCHANGE_USER_CURRENCY_STARTED = "EXCHANGE_USER_CURRENCY_STARTED";
export const EXCHANGE_USER_CURRENCY_SUCCESS = "EXCHANGE_USER_CURRENCY_SUCCESS";
export const EXCHANGE_USER_CURRENCY_FAILURE = "EXCHANGE_USER_CURRENCY_FAILURE";
// ---------------------------------------- MODAL ----------------------------------------
export const OPEN_MODAL_REQUEST = "OPEN_MODAL_REQUEST";
export const OPEN_MODAL_SUCCESS = "OPEN_MODAL_SUCCESS";

export const CLOSE_MODAL_DEFAULT = "CLOSE_MODAL_DEFAULT";
export const CLOSE_MODAL_REQUEST = "CLOSE_MODAL_REQUEST";
export const CLOSE_MODAL_SUCCESS = "CLOSE_MODAL_SUCCESS";
