import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import {
  token,
  fromPrevMonth,
  to,
  fromPrevWeek,
  fromPrevSixMonth,
  fromAll,
  from,
} from "constants/constants";
import YPDatePicker from "components/datePicker";
import classNames from "classnames";
import Loading from "components/Loading";
import { useMemo } from "react";
import { useDispatch } from "react-redux";

const withTable = (
  WrappedComponent,
  {
    title,
    apiAddress,
    requestAction,
    extraComponentOnTop,
    hasDatePicker = true,
    fromDate = "week",
    enableWrapperClassname = false,
    pageSize = 20,
    hideLoadingSpinner = false,
  }
) => () => {
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = `Yekpay | ${title} - Admin Panel`;
  }, []);

  const getFromDate = {
    day: from,
    week: fromPrevWeek,
    month: fromPrevMonth,
    sixMonth: fromPrevSixMonth,
    all: fromAll,
  };

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [date, setDate] = useState({
    from: getFromDate[fromDate],
    to,
    isGregorian: false,
  });

  const options = {
    columnsButton: true,
    search: true,
    exportButton: true,
    grouping: false,
    filtering: true,
    exportAllData: true,
    selection: false,
    editable: false,
    paging: true,
    pageSize,
    addRowPosition: "first",
  };

  useEffect(() => {
    fetchData();
  }, [date.isGregorian]);

  const requestPayload = useMemo(() => {
    if (hasDatePicker) return { from: date.from, to: date.to };
    return {};
  }, [hasDatePicker, date]);

  const fetchData = async () => {
    if (requestAction) {
      dispatch(requestAction(requestPayload));
    } else {
      try {
        setLoading(true);
        const result = await axios.post(apiAddress, requestPayload, token());
        if (result) setData(result?.data?.data);

        setLoading(false);
      } catch (error) {
        console.log(error, "Error in fetching data");

        setLoading(false);
      }
    }
  };

  const reRender = useCallback(() => fetchData(), []);

  return (
    <div className={classNames({ "app-wrapper": enableWrapperClassname })}>
      {extraComponentOnTop && extraComponentOnTop({ reRender })}

      {hasDatePicker && (
        <div className="row ">
          <div className="col-12 ">
            <div className="jr-card my-3">
              <YPDatePicker
                date={date}
                onHandleDate={() => fetchData()}
                onChangeFrom={(from) => setDate({ ...date, from })}
                onChangeTo={(to) => setDate({ ...date, to })}
                onChangeSwitch={() =>
                  setDate({ ...date, isGregorian: !date.isGregorian })
                }
                loading={loading}
              />
            </div>
          </div>
        </div>
      )}

      {loading && !hideLoadingSpinner ? (
        <Loading />
      ) : (
        <WrappedComponent
          loading={loading}
          options={options}
          data={data}
          setData={setData}
          date={date}
          reRender={reRender}
        />
      )}
    </div>
  );
};

export default withTable;
