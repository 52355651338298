import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import axios from 'axios';
import { API_GET_EXPANEL_USER_TRANSACTIONS, fromPrevMonth, to, token } from 'constants/constants';
import { thousands_separators } from 'constants/usefulFunctions';
import Button from '@mui/material/Button';
import { withRouter, Route, Switch } from 'react-router-dom';

function Transactions({ user_id, match }) {
  const [date, setDate] = useState({
    from: fromPrevMonth,
    to,
    isGregorian: false
  });
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({});

  const options = {
    columnsButton: true,
    actionsColumnIndex: state.columns && state.columns.length,
    search: true,
    exportButton: true,

    filtering: true,
    exportAllData: true,
    selection: false,
    editable: false,
    exportName: 'Transactions',
    pageSize: 20
  };

  useEffect(() => {
    fetchData();
  }, [submit, date.isGregorian]);

  const fetchData = async () => {
    setLoading(true);
    await axios.post(API_GET_EXPANEL_USER_TRANSACTIONS, { user_id }, token()).then((res) => {
      let data = res.data.data;
      console.log(data, 'test');
      setState({
        data: data
      });
    });
    setLoading(false);
    setSubmit(false);
  };

  return (
    <>
      <div className="row">
        {/* <div className="col-lg-12 col-sm-12">
        <div className="jr-card">
          <YPDatePicker
            date={date}
            onHandleDate={() => setSubmit(true)}
            onChangeFrom={(from) => setDate({ ...date, from })}
            onChangeTo={(to) => setDate({ ...date, to })}
            onChangeSwitch={() =>
              setDate({ ...date, isGregorian: !date.isGregorian })
            }
          />
        </div>
      </div> */}
      </div>
      <div className="card">
        <MaterialTable
          options={options}
          isLoading={loading}
          columns={[
            {
              title: 'Date',
              field: 'created_at'
            },
            { title: 'Event', field: 'event' },
            {
              title: 'amount',
              type: 'numeric',
              field: 'amount',
              render: (rowData) =>
                rowData.type === 'Decrease' ? (
                  <span className="text-danger">{thousands_separators(rowData.amount)}</span>
                ) : (
                  <span className="text-success">{thousands_separators(rowData.amount)}</span>
                )
            },
            {
              title: 'currency',
              field: 'currency'
            },
            {
              title: 'Delivery Time',
              field: 'delivery_time'
            },
            {
              title: 'Transfer Status',
              field: 'transfer_status'
            },
            {
              title: 'Fee',
              field: 'transfer_fee'
            },
            {
              title: 'Converted Amount',
              field: 'converted_amount',
              render: (rowData) =>
                rowData.converted_amount && thousands_separators(rowData.converted_amount)
            },
            {
              title: 'Rate',
              field: 'rate',
              render: (rowData) => rowData.rate && thousands_separators(rowData.rate)
            },

            {
              title: 'description',
              field: 'description'
            },
            { title: 'Tracking Number ', field: 'tracking_code' },
            { title: 'Payment Refrence ', field: 'payment_reference' },
            {
              title: 'Transaction Receipt ',
              field: '',
              render: (rowData) => {
                if (rowData.event === 'Transfer')
                  return (
                    <a href={`${match.url}/${rowData.id}`}>
                      <span className="material-icons pointer">receipt</span>
                    </a>
                  );
              }
            }
          ]}
          data={state.data}
          title={
            <div className="d-flex align-items-center">
              <span style={{ fontSize: '20px' }}> Transactions</span>
              <Button
                color="primary"
                className="jr-btn jr-btn-sm bg-primary text-white ml-3"
                onClick={fetchData}
                type="submit">
                Update Table
              </Button>
            </div>
          }
        />
      </div>
    </>
  );
}

export default withRouter(Transactions);
