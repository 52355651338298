import {
  CHANGE_HOLD_TRANSACTION_STATUS_FAILURE,
  CHANGE_HOLD_TRANSACTION_STATUS_STARTED,
  CHANGE_HOLD_TRANSACTION_STATUS_SUCCESS,
  CHANGE_REFUND_REQUEST_STATUS_FAILURE,
  CHANGE_REFUND_REQUEST_STATUS_STARTED,
  CHANGE_REFUND_REQUEST_STATUS_SUCCESS,
  CONFIRM_VIP_PAYOUT_FAILURE,
  CONFIRM_VIP_PAYOUT_STARTED,
  CONFIRM_VIP_PAYOUT_SUCCESS,
  GET_ALL_EXCHANGES_FAILURE,
  GET_ALL_EXCHANGES_STARTED,
  GET_ALL_EXCHANGES_SUCCESS,
  GET_ALL_PAYOUTS_FAILURE,
  GET_ALL_PAYOUTS_STARTED,
  GET_ALL_PAYOUTS_SUCCESS,
  GET_ALL_TOPUPS_FAILURE,
  GET_ALL_TOPUPS_STARTED,
  GET_ALL_TOPUPS_SUCCESS,
  GET_ALL_TRANSACTIONS_FAILURE,
  GET_ALL_TRANSACTIONS_STARTED,
  GET_ALL_TRANSACTIONS_SUCCESS,
  GET_ALL_TRANSFERS_FAILURE,
  GET_ALL_TRANSFERS_STARTED,
  GET_ALL_TRANSFERS_SUCCESS,
  GET_BANKS_REPORT_FAILURE,
  GET_BANKS_REPORT_STARTED,
  GET_BANKS_REPORT_SUCCESS,
  GET_HOLD_TRANSACTIONS_DEFAULT,
  GET_HOLD_TRANSACTIONS_FAILURE,
  GET_HOLD_TRANSACTIONS_STARTED,
  GET_HOLD_TRANSACTIONS_SUCCESS,
  GET_PAYMENT_LINK_TRANSACTIONS_FAILURE,
  GET_PAYMENT_LINK_TRANSACTIONS_STARTED,
  GET_PAYMENT_LINK_TRANSACTIONS_SUCCESS,
  GET_PENDING_PAYOUTS_FAILURE,
  GET_PENDING_PAYOUTS_STARTED,
  GET_PENDING_PAYOUTS_SUCCESS,
  GET_PERSONAL_TRANSACTIONS_FAILURE,
  GET_PERSONAL_TRANSACTIONS_STARTED,
  GET_PERSONAL_TRANSACTIONS_SUCCESS,
  GET_REFUND_REQUESTS_FAILURE,
  GET_REFUND_REQUESTS_STARTED,
  GET_REFUND_REQUESTS_SUCCESS,
  GET_TRANSACTIONS_PAYOUT_PAY_FAILURE,
  GET_TRANSACTIONS_PAYOUT_PAY_STARTED,
  GET_TRANSACTIONS_PAYOUT_PAY_SUCCESS,
  GET_VIP_PENDING_PAYOUTS_FAILURE,
  GET_VIP_PENDING_PAYOUTS_STARTED,
  GET_VIP_PENDING_PAYOUTS_SUCCESS,
  GET_WEBSITE_GATEWAYS_FAILURE,
  GET_WEBSITE_GATEWAYS_STARTED,
  GET_WEBSITE_GATEWAYS_SUCCESS,
  GET_WIRE_PAYOUTS_FAILURE,
  GET_WIRE_PAYOUTS_STARTED,
  GET_WIRE_PAYOUTS_SUCCESS,
} from "redux/types";
// --------------------------------------------------------------------------------
const initialState = {
  rType: GET_HOLD_TRANSACTIONS_DEFAULT,
  holdTransactions: [],
  banksReport: [],
  error: "",
};
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_TRANSACTIONS_STARTED:
    case GET_HOLD_TRANSACTIONS_STARTED:
    case CHANGE_HOLD_TRANSACTION_STATUS_STARTED:
    case GET_ALL_PAYOUTS_STARTED:
    case GET_PENDING_PAYOUTS_STARTED:
    case GET_TRANSACTIONS_PAYOUT_PAY_STARTED:
    case GET_VIP_PENDING_PAYOUTS_STARTED:
    case CONFIRM_VIP_PAYOUT_STARTED:
    case GET_WIRE_PAYOUTS_STARTED:
    case GET_ALL_TOPUPS_STARTED:
    case GET_REFUND_REQUESTS_STARTED:
    case CHANGE_REFUND_REQUEST_STATUS_STARTED:
    case GET_WEBSITE_GATEWAYS_STARTED:
    case GET_PAYMENT_LINK_TRANSACTIONS_STARTED:
    case GET_PERSONAL_TRANSACTIONS_STARTED:
    case GET_ALL_EXCHANGES_STARTED:
    case GET_ALL_TRANSFERS_STARTED:
    case GET_BANKS_REPORT_STARTED:
      return {
        ...state,
        rType: action.type,
      };
    case GET_ALL_TRANSACTIONS_SUCCESS:
    case GET_HOLD_TRANSACTIONS_SUCCESS:
    case CHANGE_HOLD_TRANSACTION_STATUS_SUCCESS:
    case GET_ALL_PAYOUTS_SUCCESS:
    case GET_PENDING_PAYOUTS_SUCCESS:
    case GET_VIP_PENDING_PAYOUTS_SUCCESS:
    case GET_WIRE_PAYOUTS_SUCCESS:
    case GET_ALL_TOPUPS_SUCCESS:
    case GET_REFUND_REQUESTS_SUCCESS:
    case CHANGE_REFUND_REQUEST_STATUS_SUCCESS:
    case GET_WEBSITE_GATEWAYS_SUCCESS:
    case GET_PAYMENT_LINK_TRANSACTIONS_SUCCESS:
    case GET_PERSONAL_TRANSACTIONS_SUCCESS:
    case GET_ALL_EXCHANGES_SUCCESS:
    case GET_ALL_TRANSFERS_SUCCESS:
    case GET_BANKS_REPORT_SUCCESS:
      return {
        ...state,
        rType: action.type,
        ...action.payload,
        error: "",
      };
    case GET_TRANSACTIONS_PAYOUT_PAY_SUCCESS:
      let pendingPayouts = [...state.pendingPayouts];
      let selectedPayout = pendingPayouts.find(
        (payout) => payout.id === action.payload.id
      );
      selectedPayout.status = "Approved";
      selectedPayout.tracking_code = action.payload.tracking_code;

      return {
        ...state,
        rType: GET_TRANSACTIONS_PAYOUT_PAY_SUCCESS,
        pendingPayouts,
        error: "",
      };
    case CONFIRM_VIP_PAYOUT_SUCCESS:
      let vipPendingPayouts = [...state.vipPendingPayouts];
      let selectedVIPPayout = vipPendingPayouts.find(
        (payout) => payout.id === action.payload.id
      );
      selectedVIPPayout.status = "Approved";
      selectedVIPPayout.tracking_code = action.payload.tracking_code;

      return {
        ...state,
        rType: GET_TRANSACTIONS_PAYOUT_PAY_SUCCESS,
        vipPendingPayouts,
        error: "",
      };
    case GET_ALL_TRANSACTIONS_FAILURE:
    case GET_HOLD_TRANSACTIONS_FAILURE:
    case CHANGE_HOLD_TRANSACTION_STATUS_FAILURE:
    case GET_ALL_PAYOUTS_FAILURE:
    case GET_PENDING_PAYOUTS_FAILURE:
    case GET_VIP_PENDING_PAYOUTS_FAILURE:
    case CONFIRM_VIP_PAYOUT_FAILURE:
    case GET_TRANSACTIONS_PAYOUT_PAY_FAILURE:
    case GET_WIRE_PAYOUTS_FAILURE:
    case GET_ALL_TOPUPS_FAILURE:
    case GET_REFUND_REQUESTS_FAILURE:
    case CHANGE_REFUND_REQUEST_STATUS_FAILURE:
    case GET_WEBSITE_GATEWAYS_FAILURE:
    case GET_PAYMENT_LINK_TRANSACTIONS_FAILURE:
    case GET_PERSONAL_TRANSACTIONS_FAILURE:
    case GET_ALL_EXCHANGES_FAILURE:
    case GET_ALL_TRANSFERS_FAILURE:
    case GET_BANKS_REPORT_FAILURE:
      return {
        ...state,
        rType: action.type,
        ...action.payload,
      };
    default:
      return state;
  }
};
