import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const PaymentGatewayInvoices = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Route
        path={`${match.url}/stripe/fonetra`}
        component={asyncComponent(() => import("./routes/StripeFonetra"))}
      />
      <Route
        path={`${match.url}/stripe/alameeran`}
        component={asyncComponent(() => import("./routes/StripeAlAmeeran"))}
      />
    </Switch>
  </div>
);

export default PaymentGatewayInvoices;
