import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const Loading = () => {
  return (
    <div className="loading-container">
      <CircularProgress size={50} />
    </div>
  );
};

export default Loading;
