import React from "react";
import MaterialTable from "material-table";
import { thousands_separators } from "constants/usefulFunctions";

function YouTubeAffiliate({ data }) {
  const options = {
    columnsButton: true,
    search: true,
    exportButton: true,
    grouping: false,
    filtering: true,
    exportAllData: true,
    selection: false,
    editable: false,
    pageSize: 5,
    exportFileName: "All Transactions",
  };

  return (
    <div className="mt-5">
      <MaterialTable
        options={options}
        columns={[
          {
            title: "fromDate",
            field: "from_date",
          },
          {
            title: "toDate",
            field: "to_date",
          },
          {
            title: "channelName",
            field: "youtube_channel_id",
          },
          {
            title: "total_amount",

            field: "total_amount",
            render: (rowData) => thousands_separators(rowData.total_amount),
          },
          {
            title: "commissionPercentage",
            field: "affiliate_commission_percent",
          },
          {
            title: "chargedAmount",

            field: "affiliate_commission_amount",
            render: (rowData) =>
              thousands_separators(rowData.affiliate_commission_amount),
          },
          {
            title: "rate",
            field: "currency_rate_id",
            render: (rowData) => thousands_separators(rowData.currency_rate_id),
          },

          {
            title: "irr_amount",
            field: "affiliate_irr_amount",
            render: (rowData) =>
              thousands_separators(rowData.affiliate_irr_amount),
          },
          {
            title: "registerDate",
            field: "created_at",
          },
        ]}
        data={data}
        title={
          <div className="mt-3">
            <h2>Affiliates Transactions</h2>
          </div>
        }
      />
    </div>
  );
}

export default YouTubeAffiliate;
