import { put, takeLatest } from "redux-saga/effects";
import {
  addTicketCommentFailure,
  addTicketCommentStarted,
  addTicketCommentSuccess,
  changeTicketRoleFailure,
  changeTicketRoleStarted,
  changeTicketRoleSuccess,
  changeTicketStatusFailure,
  changeTicketStatusStarted,
  changeTicketStatusSuccess,
  createNewTicketFailure,
  createNewTicketStarted,
  createNewTicketSuccess,
  createReplyTicketFailure,
  createReplyTicketStarted,
  createReplyTicketSuccess,
  deleteTicketFailure,
  deleteTicketStarted,
  deleteTicketSuccess,
  getAllPendingBankAccountFailure,
  getAllPendingBankAccountStarted,
  getAllPendingBankAccountSuccess,
  getAllPendingBillingAddressFailure,
  getAllPendingBillingAddressStarted,
  getAllPendingBillingAddressSuccess,
  getAllPendingIdentityFailure,
  getAllPendingIdentityStarted,
  getAllPendingIdentitySuccess,
  getAllTicketsFailure,
  getAllTicketsStarted,
  getAllTicketsSuccess,
  getAllUsersFailure,
  getAllUsersStarted,
  getAllUsersSuccess,
  getAyandeBalanceFailure,
  getAyandeBalanceStarted,
  getAyandeBalanceSuccess,
  getBlockedUsersFailure,
  getBlockedUsersStarted,
  getBlockedUsersSuccess,
  getBusinessUsersFailure,
  getBusinessUsersStarted,
  getBusinessUsersSuccess,
  getDashboardInfoFailure,
  getDashboardInfoStarted,
  getDashboardInfoSuccess,
  getElasticBalanceFailure,
  getElasticBalanceStarted,
  getElasticBalanceSuccess,
  getFraudSuspectUsersFailure,
  getFraudSuspectUsersStarted,
  getFraudSuspectUsersSuccess,
  getKavenegarBalanceFailure,
  getKavenegarBalanceStarted,
  getKavenegarBalanceSuccess,
  getOpenTicketsFailure,
  getOpenTicketsStarted,
  getOpenTicketsSuccess,
  getParsianBalanceFailure,
  getParsianBalanceStarted,
  getParsianBalanceSuccess,
  getPasargadBalanceFailure,
  getPasargadBalanceStarted,
  getPasargadBalanceSuccess,
  getStripeInvoiceFailure,
  getStripeInvoiceStarted,
  getStripeInvoiceSuccess,
  getTicketDetailsFailure,
  getTicketDetailsRequest,
  getTicketDetailsStarted,
  getTicketDetailsSuccess,
} from "redux/actions/Main";
import { history } from "redux/store";
import {
  ADD_TICKET_COMMENT_REQUEST,
  CHANGE_TICKET_ROLE_REQUEST,
  CHANGE_TICKET_STATUS_REQUEST,
  CREATE_NEW_TICKET_REQUEST,
  CREATE_REPLY_TICKET_REQUEST,
  DELETE_TICKET_REQUEST,
  GET_ALL_PENDING_BANK_ACCOUNT_REQUEST,
  GET_ALL_PENDING_BILLING_ADDRESS_REQUEST,
  GET_ALL_PENDING_IDENTITY_REQUEST,
  GET_ALL_TICKETS_REQUEST,
  GET_ALL_USERS_REQUEST,
  GET_AYANDE_BALANCE_REQUEST,
  GET_BLOCKED_USERS_REQUEST,
  GET_BUSINESS_USERS_REQUEST,
  GET_DASHBOARD_INFO_REQUEST,
  GET_ELASTIC_BALANCE_REQUEST,
  GET_FRAUD_SUSPECT_USERS_REQUEST,
  GET_KAVENEGAR_BALANCE_REQUEST,
  GET_OPEN_TICKETS_REQUEST,
  GET_PARSIAN_BALANCE_REQUEST,
  GET_PASARGAD_BALANCE_REQUEST,
  GET_STRIPE_INVOICE_REQUEST,
  GET_TICKET_DETAILS_REQUEST,
} from "redux/types";
import {
  addTicketCommentAPI,
  changeTicketRoleAPI,
  changeTicketStatusAPI,
  createNewTicketAPI,
  createReplyTicketAPI,
  deleteTicketAPI,
  getAllPendingBankAccountAPI,
  getAllPendingBillingAddressAPI,
  getAllPendingIdentityAPI,
  getAllTicketsAPI,
  getAllUsersAPI,
  getAyandeBalanceAPI,
  getBlockedUsersAPI,
  getBusinessUsersAPI,
  getDashboardInfoAPI,
  getElasticBalanceAPI,
  getFraudSuspectUsersAPI,
  getKavenegarBalanceAPI,
  getOpenTicketsAPI,
  getParsianBalanceAPI,
  getPasargadBalanceAPI,
  getStripeInvoiceAPI,
  getTicketDetailsAPI,
} from "services/API/Main/index";
// --------------------------------------------------------------------------------
function* onGetDashboardInfo() {
  yield put(getDashboardInfoStarted());
  try {
    let response = yield getDashboardInfoAPI();
    if (response.status === "success") {
      let {
        user,
        gateway,
        link_count,
        user_count,
        gateway_count,
        personal_count,
      } = response.data;
      let data = {
        day: user.map((item) => item.day),
        user_count: user.map((item) => item.count),
        gateway_count: gateway.map((item) => item.count),
        link_count: link_count,
        summary_user: user_count,
        summary_user_month: user_count,
        summary_gateway: gateway_count,
        personal_count: personal_count,
      };
      yield put(getDashboardInfoSuccess({ dashboardData: data }));
    } else {
      getDashboardInfoFailure({ error: response.errorMessage });
    }
  } catch (error) {
    yield put(
      getDashboardInfoFailure({ dashboardData: {}, error: error.message })
    );
  }
}
// --------------------------------------------------------------------------------
function* onGetParsianBalance() {
  yield put(getParsianBalanceStarted());
  try {
    let response = yield getParsianBalanceAPI();
    if (response.status === "success") {
      yield put(getParsianBalanceSuccess({ parsianBalance: response.data }));
    } else {
      getParsianBalanceFailure({ error: response.errorMessage });
    }
  } catch (error) {
    yield put(
      getParsianBalanceFailure({ parsianBalance: "0", error: error.message })
    );
  }
}
// --------------------------------------------------------------------------------
function* onGetAyandeBalance() {
  yield put(getAyandeBalanceStarted());
  try {
    let response = yield getAyandeBalanceAPI();
    if (response.status === "success") {
      yield put(getAyandeBalanceSuccess({ ayandeBalance: response.data }));
    } else {
      getAyandeBalanceFailure({
        ayandeBalance: "0",
        error: response.errorMessage,
      });
    }
  } catch (error) {
    yield put(
      getAyandeBalanceFailure({ ayandeBalance: "0", error: error.message })
    );
  }
}
// --------------------------------------------------------------------------------
function* onGetPasargadBalance() {
  yield put(getPasargadBalanceStarted());
  try {
    let response = yield getPasargadBalanceAPI();
    if (response.status === "success") {
      yield put(getPasargadBalanceSuccess({ pasargadBalance: response.data }));
    } else {
      getPasargadBalanceFailure({
        pasargadBalance: "0",
        error: response.errorMessage,
      });
    }
  } catch (error) {
    yield put(
      getPasargadBalanceFailure({ pasargadBalance: "0", error: error.message })
    );
  }
}
// --------------------------------------------------------------------------------
function* onGetKavenegarBalance() {
  yield put(getKavenegarBalanceStarted());
  try {
    let response = yield getKavenegarBalanceAPI();
    if (response.status === "success") {
      yield put(
        getKavenegarBalanceSuccess({ kavenegarBalance: response.data })
      );
    } else {
      // yield put(getUserProfileFailure("Token Incorrect!"));
    }
  } catch (error) {
    yield put(
      getKavenegarBalanceFailure({
        kavenegarBalance: "0",
        error: error.message,
      })
    );
  }
}
// --------------------------------------------------------------------------------
function* onGetElasticBalance() {
  yield put(getElasticBalanceStarted());
  try {
    let response = yield getElasticBalanceAPI();
    if (response.status === "success") {
      yield put(getElasticBalanceSuccess({ elasticBalance: response.data }));
    } else {
      // yield put(getUserProfileFailure("Token Incorrect!"));
    }
  } catch (error) {
    yield put(
      getElasticBalanceFailure({ elasticBalance: "0", error: error.message })
    );
  }
}
// --------------------------------------------------------------------------------
function* onGetAllPendingIdentity() {
  yield put(getAllPendingIdentityStarted());
  try {
    let response = yield getAllPendingIdentityAPI();
    if (response.status === "success") {
      yield put(
        getAllPendingIdentitySuccess({ pendingIdentity: response.data })
      );
    } else {
      // yield put(getUserProfileFailure("Token Incorrect!"));
    }
  } catch (error) {
    yield put(
      getAllPendingIdentityFailure({
        pendingIdentity: [],
        error: error.message,
      })
    );
  }
}
// --------------------------------------------------------------------------------
function* onGetAllPendingBankAccount() {
  yield put(getAllPendingBankAccountStarted());
  try {
    let response = yield getAllPendingBankAccountAPI();
    if (response.status === "success") {
      yield put(
        getAllPendingBankAccountSuccess({ pendingBankAccount: response.data })
      );
    } else {
      // yield put(getUserProfileFailure("Token Incorrect!"));
    }
  } catch (error) {
    yield put(
      getAllPendingBankAccountFailure({
        pendingBankAccount: [],
        error: error.message,
      })
    );
  }
}
// --------------------------------------------------------------------------------
function* onGetAllPendingBillingAddress() {
  yield put(getAllPendingBillingAddressStarted());
  try {
    let response = yield getAllPendingBillingAddressAPI();
    if (response.status === "success") {
      yield put(
        getAllPendingBillingAddressSuccess({
          pendingBillingAddress: response.data,
        })
      );
    } else {
      // yield put(getUserProfileFailure("Token Incorrect!"));
    }
  } catch (error) {
    yield put(
      getAllPendingBillingAddressFailure({
        pendingBillingAddress: [],
        error: error.message,
      })
    );
  }
}
// --------------------------------------------------------------------------------
function* onGetFraudSuspectUsers() {
  yield put(getFraudSuspectUsersStarted());
  try {
    let response = yield getFraudSuspectUsersAPI();
    if (response.status === "success") {
      yield put(
        getFraudSuspectUsersSuccess({
          fraudSuspectUsers: response.data,
        })
      );
    } else {
      // yield put(getUserProfileFailure("Token Incorrect!"));
    }
  } catch (error) {
    yield put(
      getFraudSuspectUsersFailure({
        fraudSuspectUsers: [],
        error: error.message,
      })
    );
  }
}
// --------------------------------------------------------------------------------
function* onGetBlockedUsers() {
  yield put(getBlockedUsersStarted());
  try {
    let response = yield getBlockedUsersAPI();
    if (response.status === "success") {
      yield put(
        getBlockedUsersSuccess({
          blockedUsers: response.data,
        })
      );
    } else {
      // yield put(getUserProfileFailure("Token Incorrect!"));
    }
  } catch (error) {
    yield put(
      getBlockedUsersFailure({
        blockedUsers: [],
        error: error.message,
      })
    );
  }
}
// --------------------------------------------------------------------------------
function* onGetAllUsers({ payload }) {
  yield put(getAllUsersStarted());
  try {
    let response = yield getAllUsersAPI(payload);
    if (response.status === "success") {
      yield put(getAllUsersSuccess({ users: response.data }));
    } else {
      // yield put(getUserProfileFailure("Token Incorrect!"));
    }
  } catch (error) {
    yield put(getAllUsersFailure({ users: [], error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onGetBusinessUsers() {
  yield put(getBusinessUsersStarted());
  try {
    let response = yield getBusinessUsersAPI();
    if (response.status === "success") {
      yield put(
        getBusinessUsersSuccess({
          businessUsers: response.data,
        })
      );
    } else {
      // yield put(getUserProfileFailure("Token Incorrect!"));
    }
  } catch (error) {
    yield put(
      getBusinessUsersFailure({
        businessUsers: [],
        error: error.message,
      })
    );
  }
}
// --------------------------------------------------------------------------------
function* onGetAllTickets({ payload }) {
  yield put(getAllTicketsStarted());
  try {
    let response = yield getAllTicketsAPI(payload);
    if (response.status === "success") {
      yield put(getAllTicketsSuccess({ tickets: response.data }));
    } else {
      // yield put(getUserProfileFailure("Token Incorrect!"));
    }
  } catch (error) {
    yield put(getAllTicketsFailure({ tickets: [], error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onGetOpenTickets({ payload }) {
  yield put(getOpenTicketsStarted());
  try {
    let response = yield getOpenTicketsAPI(payload);
    if (response.status === "success") {
      yield put(getOpenTicketsSuccess({ openTickets: response.data }));
    } else {
      // yield put(getUserProfileFailure("Token Incorrect!"));
    }
  } catch (error) {
    yield put(getOpenTicketsFailure({ openTickets: [], error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onCreateNewTicket({ payload }) {
  yield put(createNewTicketStarted());
  try {
    let response = yield createNewTicketAPI(payload);
    if (response.status === "success") {
      yield put(createNewTicketSuccess());
      history.push(`/app/tickets/ticket-page/${response.data.id}`);
    } else {
      // yield put(getUserProfileFailure("Token Incorrect!"));
    }
  } catch (error) {
    yield put(createNewTicketFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onGetTicketDetails({ payload }) {
  yield put(getTicketDetailsStarted());
  try {
    let response = yield getTicketDetailsAPI(payload);
    if (response.status === "success") {
      yield put(getTicketDetailsSuccess({ ticketDetails: response.data }));
    } else {
      // yield put(getUserProfileFailure("Token Incorrect!"));
    }
  } catch (error) {
    yield put(
      getTicketDetailsFailure({ ticketDetails: [], error: error.message })
    );
  }
}
// --------------------------------------------------------------------------------
function* onChangeTicketStatus({ payload }) {
  yield put(changeTicketStatusStarted());
  try {
    let response = yield changeTicketStatusAPI(payload);
    if (response.status === "success") {
      let ticket_id = payload.ticket_id;
      yield put(changeTicketStatusSuccess());
      yield put(getTicketDetailsRequest({ ticket_id }));
    } else {
      // yield put(ChangeUserPrStatusilure("Token Incorrect!"));
    }
  } catch (error) {
    yield put(changeTicketStatusFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onCreateReplyTicket({ payload }) {
  yield put(createReplyTicketStarted());
  try {
    let response = yield createReplyTicketAPI(payload);
    if (response.status === "success") {
      let ticket_id = payload.get("ticket_id");
      yield put(createReplyTicketSuccess());
      yield put(getTicketDetailsRequest({ ticket_id }));
    } else {
      // yield put(CreateReplyUserPrilure("Token Incorrect!"));
    }
  } catch (error) {
    yield put(createReplyTicketFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onChangeTicketRole({ payload }) {
  yield put(changeTicketRoleStarted());
  try {
    let response = yield changeTicketRoleAPI(payload);
    if (response.status === "success") {
      yield put(changeTicketRoleSuccess());
    } else {
      // yield put(CreateReplyUserPrilure("Token Incorrect!"));
    }
  } catch (error) {
    yield put(changeTicketRoleFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onDeleteTicket({ payload }) {
  yield put(deleteTicketStarted());
  try {
    let response = yield deleteTicketAPI(payload.selectedTicket);
    if (response.status === "success") {
      yield put(deleteTicketSuccess());
      yield put(getTicketDetailsRequest(payload.primaryTicket));
    } else {
      // yield put(DeleteUserPrilure("Token Incorrect!"));
    }
  } catch (error) {
    yield put(deleteTicketFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onAddTicketComment({ payload }) {
  yield put(addTicketCommentStarted());
  try {
    let response = yield addTicketCommentAPI(payload);
    if (response.status === "success") {
      yield put(addTicketCommentSuccess());
    } else {
      // yield put(AddUserPrCommentilure("Token Incorrect!"));
    }
  } catch (error) {
    yield put(addTicketCommentFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onGetStripeInvoice({ payload }) {
  yield put(getStripeInvoiceStarted());
  try {
    let response = yield getStripeInvoiceAPI(payload.data);
    if (response.status === "success") {
      payload.response(response.data);
      yield put(getStripeInvoiceSuccess());
    } else {
      // yield put(AddUserPrCommentilure("Token Incorrect!"));
    }
  } catch (error) {
    yield put(getStripeInvoiceFailure({ error: error.message }));
  }
}
// ================================================================================
function* mainSaga() {
  yield takeLatest(GET_DASHBOARD_INFO_REQUEST, onGetDashboardInfo);
  yield takeLatest(GET_PARSIAN_BALANCE_REQUEST, onGetParsianBalance);
  yield takeLatest(GET_AYANDE_BALANCE_REQUEST, onGetAyandeBalance);
  yield takeLatest(GET_PASARGAD_BALANCE_REQUEST, onGetPasargadBalance);
  yield takeLatest(GET_KAVENEGAR_BALANCE_REQUEST, onGetKavenegarBalance);
  yield takeLatest(GET_ELASTIC_BALANCE_REQUEST, onGetElasticBalance);
  yield takeLatest(GET_ALL_PENDING_IDENTITY_REQUEST, onGetAllPendingIdentity);
  yield takeLatest(
    GET_ALL_PENDING_BANK_ACCOUNT_REQUEST,
    onGetAllPendingBankAccount
  );
  yield takeLatest(
    GET_ALL_PENDING_BILLING_ADDRESS_REQUEST,
    onGetAllPendingBillingAddress
  );
  yield takeLatest(GET_FRAUD_SUSPECT_USERS_REQUEST, onGetFraudSuspectUsers);
  yield takeLatest(GET_BLOCKED_USERS_REQUEST, onGetBlockedUsers);
  yield takeLatest(GET_ALL_USERS_REQUEST, onGetAllUsers);
  yield takeLatest(GET_BUSINESS_USERS_REQUEST, onGetBusinessUsers);
  yield takeLatest(GET_ALL_TICKETS_REQUEST, onGetAllTickets);
  yield takeLatest(GET_OPEN_TICKETS_REQUEST, onGetOpenTickets);
  yield takeLatest(CREATE_NEW_TICKET_REQUEST, onCreateNewTicket);
  yield takeLatest(GET_TICKET_DETAILS_REQUEST, onGetTicketDetails);
  yield takeLatest(CHANGE_TICKET_STATUS_REQUEST, onChangeTicketStatus);
  yield takeLatest(CREATE_REPLY_TICKET_REQUEST, onCreateReplyTicket);
  yield takeLatest(CHANGE_TICKET_ROLE_REQUEST, onChangeTicketRole);
  yield takeLatest(DELETE_TICKET_REQUEST, onDeleteTicket);
  yield takeLatest(ADD_TICKET_COMMENT_REQUEST, onAddTicketComment);
  yield takeLatest(GET_STRIPE_INVOICE_REQUEST, onGetStripeInvoice);
}

export default mainSaga;
