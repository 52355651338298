import React, { useEffect } from "react";
import { boot } from "constants/constants";
import {
  NavHeader,
  NavMenu,
  NavWithSubmenu,
} from "containers/SideNav/components";
import { useDispatch, useSelector } from "react-redux";
import { getOpenTicketsRequest } from "redux/actions/Main";

const Main = () => {
  const dispatch = useDispatch();
  const { openTickets } = useSelector((state) => state.mainReducer);

  useEffect(() => {
    getOpenTicket();
  }, []);

  const getOpenTicket = () => {
    dispatch(getOpenTicketsRequest());
  };

  return (
    <>
      <NavHeader title="sidebar.main" />

      <NavMenu
        title="sidebar.dashboard"
        icon="zmdi zmdi-view-dashboard zmdi-hc-fw"
        url="/app/dashboard"
      />

      <NavWithSubmenu
        title="sidebar.users"
        icon="zmdi zmdi-accounts zmdi-hc-fw"
        subMenuList={[
          {
            title: "sidebar.users.PendingItems",
            url: "/app/users/pending-items",
            badge: boot?.count_pending_users,
          },
          {
            title: "sidebar.users.CardRequests",
            url: "/app/users/card-requests",
          },
          {
            title: "sidebar.users.FraudSuspectUsers",
            url: "/app/users/fraud-suspect-users",
          },
          {
            title: "sidebar.users.BlockedUsers",
            url: "/app/users/blocked-users",
          },
          { title: "sidebar.users.AllUsers", url: "/app/users/all-users" },
          {
            title: "sidebar.users.businessUsers",
            url: "/app/users/business-users",
          },
          { title: "sidebar.users.emails", url: "/app/users/emails" },
          { title: "sidebar.users.itsaaz", url: "/app/users/itsaaz" },
          { title: "sidebar.users.reports", url: "/app/users/reports" },
        ]}
      />

      <NavWithSubmenu
        title="sidebar.tickets"
        icon="zmdi zmdi-comments zmdi-hc-fw"
        subMenuList={[
          {
            title: "sidebar.tickets.newTicket",
            url: "/app/tickets/new-ticket",
          },
          {
            title: "sidebar.tickets.openTickets",
            url: "/app/tickets/open-tickets",
            badge: openTickets.length,
          },
          {
            title: "sidebar.tickets.allTickets",
            url: "/app/tickets/all-tickets",
          },
        ]}
      />
    </>
  );
};

export default Main;
