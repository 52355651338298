import React from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    width: ({ fullWidth }) => (fullWidth ? '100%' : 300),
    // margin: 10
  }
});

const AppDropDown = ({
  label,
  value,
  onChange,
  variant = 'outlined',
  fullWidth = false,
  readOnly = false,
  shrink = true,
  helperText,
  error = false,
  margin = 'normal',
  color = 'primary',
  disabled,
  items = [],
  itemSchema = { label: 'label', value: 'value' },
  ...otherProps
}) => {
  const classes = useStyles({ fullWidth });

  return (
    <TextField
      select
      label={label}
      className={classes.root}
      value={value}
      onChange={onChange}
      disabled={disabled}
      variant={variant}
      helperText={helperText}
      error={error}
      margin={margin}
      fullWidth={fullWidth}
      InputLabelProps={{
        shrink,
        readOnly
      }}
      {...otherProps}>
      {items.map((item) => (
        <MenuItem
          key={item[itemSchema.value]}
          value={item[itemSchema.value]}
          className={item?.className}>
          {item[itemSchema.label]}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default AppDropDown;
