import React from "react";
import Avatar from "@mui/material/Avatar";
import { Currencies, API_GET_ALL_GATEWAY } from "constants/constants";
import {
  createDateLookup,
  getUser,
  getUserEmail,
  renderCountryName,
  renderStatus,
} from "constants/usefulFunctions";
import DefaultAvatar from "assets/images/default/company-logo.png";
import withTable from "HOC/withTable/withTable";
import MainTable from "components/MainTable";
import useAPI from "services/hook";
import APIs from "services/API/PaymentGateways";

const AllGateways = ({ loading, data, setData }) => {
  const columns = [
    {
      title: "User",
      field: "fullname",
      editable: "never",
      render: (rowData) => getUser(rowData.user),
    },
    {
      title: "Email",
      field: "user.email",
      editable: "never",
      render: (rowData) => getUserEmail(rowData.user),
    },
    {
      title: "Plan",
      field: "plan.plan.name_en",
      editable: "never",
      lookup: {
        Personal: "Personal",
        Business: "Business",
        "Business plus": "Business plus",
      },
    },
    {
      title: "Website",
      field: "website_domain",
      editable: "never",
      render: (rowData) => {
        const avatar = rowData.website_logo
          ? rowData.website_logo
          : DefaultAvatar;

        return (
          <>
            <li className="media">
              <div className="user-avatar">
                <Avatar
                  className="border"
                  alt={rowData.website_name}
                  src={avatar}
                />
              </div>
              <div className="media-body">
                <div className="d-flex justify-content-between align-items-center">
                  <span className="jr-link">
                    <p>
                      <a
                        target="_blank"
                        href={
                          rowData.website_domain?.toLowerCase().slice(0, 3) ===
                          "htt"
                            ? rowData.website_domain
                            : "https://" + rowData.website_domain
                        }
                        className="mb-0 jr-fs-lg"
                      >
                        {rowData.website_domain?.toLowerCase().slice(0, 3) ===
                        "htt"
                          ? rowData.website_domain
                          : "https://" + rowData.website_domain}
                      </a>
                    </p>
                  </span>
                </div>
                <p className="sub-heading">
                  <a
                    href={`/app/gateway/${rowData.id}`}
                    className="mb-0 jr-fs-lg text-muted"
                  >
                    {rowData.website_name}
                  </a>
                </p>
              </div>
            </li>
          </>
        );
      },
    },
    {
      title: "Agent",
      field: "agent",
      editable: "never",
      render: (rowData) => (
        <span>
          {`${rowData?.agent_first_name} ${rowData?.agent_last_name}`}
          <br />
          {`${rowData.agent_email} ${rowData.agent_mobile}`}
        </span>
      ),
    },
    { title: "Type", field: "type", editable: "never" },
    {
      title: "Country",
      field: "country",
      editable: "never",
      render: ({ country_id }) => renderCountryName(country_id),
    },
    {
      title: "Currency",
      field: "currency_id",
      render: (rowData) => Currencies[rowData.currency_id],
      lookup: Currencies,
      editable: "never",
    },
    createDateLookup(),
    {
      title: "System Description",
      field: "last_status.system_description",
      editable: "never",
    },
    {
      title: "Admin Description",
      field: "last_status.admin_description",
    },
    {
      title: "Stage",
      field: "last_status.stage",
      lookup: {
        Submitted: "Submitted",
        Declined: "Declined",
        "Implementing By Customer": "Implementing By Customer",
        "Implementing By Yekpay": "Implementing By Yekpay",
        "Requesting Docs": "Requesting Docs",
        "Invalid Documents": "Invalid Documents",
        Contract: "Contract",
        Finalized: "Finalized",
        "Cancel By Customer": "Cancel By Customer",
        "Not Accessible": "Not Accessible",
      },
    },
    {
      title: "Status",
      field: "last_status.status",
      render: (rowData) => renderStatus(rowData.last_status.status),
    },
  ];

  const customOptions = {
    actionsColumnIndex: columns.length,
  };

  const { request } = useAPI(APIs.apiChangeGateway, true);
  const updateRow = async (
    user_id,
    id,
    status,
    stage,
    description,
    updatedData
  ) => {
    const result = await request({
      user_id,
      id,
      status,
      stage,
      description,
    });
    if (result) setData([...updatedData]);
  };

  return (
    <MainTable
      customOptions={customOptions}
      loading={loading}
      columns={columns}
      data={data}
      isLocalization
      title="All Gateways"
      onRowUpdate={(newData, oldData) =>
        new Promise((resolve) => {
          setTimeout(() => {
            const updatedData = [...data];
            updatedData[data?.indexOf(oldData)] = newData;
            updateRow(
              oldData.user_id,
              oldData.id,
              newData.last_status.status,
              newData.last_status.stage,
              newData.last_status.admin_description,
              updatedData
            );
            resolve();
          }, 600);
        })
      }
    />
  );
};

export default withTable(AllGateways, {
  title: "All Gateways",
  apiAddress: API_GET_ALL_GATEWAY,
  fromDate: "all",
});
