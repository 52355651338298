import AppButton from "components/AppButton";
import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
const MainModal = ({
  isOpen,
  handleToggle,
  mainContent,
  footer,
  title,
  hasRotation,
}) => {
  const [rotation, setRotation] = useState(0);
  const [scaleX, setScaleX] = useState(1);

  const rotate = (dir) => {
    if (dir === "right") return setRotation(rotation - 90);
    return setRotation(rotation + 90);
  };

  return (
    <Modal isOpen={isOpen} toggle={handleToggle}>
      <ModalHeader toggle={handleToggle}>{title}</ModalHeader>
      <ModalBody>
        <div
          style={{
            transform: `rotate(${rotation}deg) scaleX(${scaleX})`,
          }}
        >
          {mainContent}
        </div>
        {hasRotation && (
          <div className="d-flex justify-content-center mt-3">
            <AppButton
              onClick={() => rotate("left")}
              text={<i className="zmdi zmdi-rotate-cw"></i>}
            />
            <AppButton
              customClassName="mx-2"
              onClick={() => rotate("right")}
              text={<i className="zmdi  zmdi-rotate-ccw"></i>}
            />
            <AppButton
              customClassName="mx-2"
              onClick={() => setScaleX(scaleX === 1 ? -1 : 1)}
              text={<i className="zmdi zmdi-flip"></i>}
            />
          </div>
        )}
      </ModalBody>
      <ModalFooter>{footer}</ModalFooter>
    </Modal>
  );
};

export default MainModal;
