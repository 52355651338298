import axiosMiddleware from "..";
// --------------------------------------------------------------------------------
export const loginAdminAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "login",
    body: data,
  });
export const verifyAdminAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "verify",
    body: data,
  });
