import { capitalizeFirstLetter } from "constants/usefulFunctions";
import MaterialTable from "material-table";
import React, { useCallback } from "react";

const MainTable = ({
  loading,
  columns,
  data = [],
  title,
  customOptions,
  isLocalization = true,
  onRowUpdate,
  onRowDelete,
  onRowAdd,
  actions,
  detailPanel,
}) => {
  const options = {
    columnsButton: true,
    search: true,
    exportButton: true,
    grouping: false,
    filtering: true,
    exportAllData: true,
    selection: false,
    editable: false,
    paging: true,
    pageSize: 10,
    addRowPosition: "first",
    ...customOptions,
  };

  const localization = {
    header: {
      actions: "",
    },
  };

  const columnsGenerator = useCallback(() => {
    if (data) {
      const columns = [];
      Object.keys(data[0]).map((item) => {
        const splited = item.replaceAll("_", " ").split(" ");
        const capitilizedArr = splited?.map((word) =>
          capitalizeFirstLetter(word)
        );
        const title = capitilizedArr.join(" ");

        columns.push({ title, field: item });
      });
      return columns;
    }
  }, [data]);

  return (
    <MaterialTable
      actions={actions}
      isLoading={loading}
      title={title}
      options={options}
      columns={columns ?? columnsGenerator()}
      data={data}
      localization={isLocalization && localization}
      editable={{ onRowUpdate, onRowDelete, onRowAdd }}
      detailPanel={detailPanel}
    />
  );
};

export default MainTable;
