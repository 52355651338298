import {
  GET_CHART_ACTIONS_FAILURE,
  GET_CHART_ACTIONS_STARTED,
  GET_CHART_ACTIONS_SUCCESS,
} from "redux/types";
// --------------------------------------------------------------------------------
const initialState = {
  // rType: GET_HOLD_TRANSACTIONS_DEFAULT,
  charts: [],
  error: "",
};
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CHART_ACTIONS_STARTED:
      return {
        ...state,
        rType: action.type,
      };
    case GET_CHART_ACTIONS_SUCCESS:
      return {
        ...state,
        rType: GET_CHART_ACTIONS_SUCCESS,
        charts: action.payload.charts,
        error: "",
      };
    case GET_CHART_ACTIONS_FAILURE:
      return {
        ...state,
        rType: action.type,
        ...action.payload,
      };
    default:
      return state;
  }
};
