import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const Youtube = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Route
        path={`${match.url}/YoutubeChannels`}
        component={asyncComponent(() =>
          import("./routes/AllChannels/YoutubeChannels")
        )}
      />

      <Route
        path={`${match.url}/arzisho-consultant-form`}
        component={asyncComponent(() =>
          import("./routes/ArzishoConsultantForm")
        )}
      />
      <Route
        path={`${match.url}/arzisho-form`}
        component={asyncComponent(() =>
          import("./routes/ArzishoForm/ArzishoForm")
        )}
      />

      <Route
        path={`${match.url}/payouts`}
        component={asyncComponent(() =>
          import("./routes/YoutubePayouts/YoutubePayouts")
        )}
      />
      <Route
        path={`${match.url}/yektuber-signup`}
        component={asyncComponent(() => import("./routes/YektuberSignup"))}
      />
      <Route
        path={`${match.url}/counseling-list`}
        component={asyncComponent(() =>
          import("./routes/WebsiteForms/CounselingList")
        )}
      />
      <Route
        path={`${match.url}/checking-list`}
        component={asyncComponent(() =>
          import("./routes/WebsiteForms/CheckingList")
        )}
      />
      <Route
        path={`${match.url}/contact-list`}
        component={asyncComponent(() =>
          import("./routes/WebsiteForms/ContactList")
        )}
      />
      <Route
        path={`${match.url}/partner-list`}
        component={asyncComponent(() =>
          import("./routes/WebsiteForms/PartnerList")
        )}
      />
      <Route
        path={`${match.url}/check-channels`}
        component={asyncComponent(() => import("./routes/CheckChannels"))}
      />
    </Switch>
  </div>
);

export default Youtube;
