import React from "react";
import { getAttachment, renderStatus } from "constants/usefulFunctions";
import MainTable from "components/MainTable";
import moment from "moment-jalaali";
import DatePicker from "react-datepicker2";
import { editYoutubeChannelContractRequest } from "redux/actions/UserProfile";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";

function YoutubeContracts({ data, user_id }) {
  const dispatch = useDispatch();

  const updateRow = async (newData, oldData) => {
    const formData = new FormData();

    formData.append("id", newData.id);
    formData.append(
      "contract_date",
      moment(newData.contract_date).format("YYYY/MM/DD")
    );
    formData.append(
      "expire_date",
      moment(newData.expire_date).format("YYYY/MM/DD")
    );
    formData.append("description", newData.description);
    formData.append("status", newData.status);
    if (oldData.admin_contract_file !== newData.admin_contract_file)
      formData.append("admin_contract_file", newData.admin_contract_file);
    if (oldData.final_contract_file !== newData.final_contract_file)
      formData.append("final_contract_file", newData.final_contract_file);

    dispatch(editYoutubeChannelContractRequest({ formData, userId: user_id }));
  };

  const columns = [
    {
      title: "Admin Contract File",
      field: "admin_contract_file",
      render: ({ admin_contract_file }) => getAttachment(admin_contract_file),
      editComponent: (props) => (
        <>
          <Button
            className="bg-light jr-btn jr-btn-sm text-dark my-2 "
            component="label"
          >
            <i className="zmdi zmdi-file-plus  mr-2 jr-fs-xl d-inline-flex align-middle" />
            Browse
            <input
              type="file"
              onChange={(e) => {
                props.onChange(e.target.files[0]);
              }}
              style={{ display: "none" }}
            />
          </Button>
          {props?.value?.name && (
            <div className="mt-2">
              File Name:{" "}
              <div className="text-success mr-2">{props?.value?.name}</div>
              File Size:{" "}
              <div className="text-success">
                {Math.trunc(props?.value?.size / 1024)}KB
              </div>
            </div>
          )}
        </>
      ),
      cellStyle: {
        maxWidth: 100,
      },
    },
    {
      title: "User Contract File",
      field: "user_contract_file",
      render: ({ user_contract_file }) => getAttachment(user_contract_file),
      editable: "never",
    },
    {
      title: "Final Contract File",
      field: "final_contract_file",
      render: ({ final_contract_file }) => getAttachment(final_contract_file),
      editComponent: (props) => (
        <>
          <Button
            className="bg-light jr-btn jr-btn-sm text-dark my-2 "
            component="label"
          >
            <i className="zmdi zmdi-file-plus  mr-2 jr-fs-xl d-inline-flex align-middle" />
            Browse
            <input
              type="file"
              onChange={(e) => props.onChange(e.target.files[0])}
              style={{ display: "none" }}
            />
          </Button>
          {props?.value?.name && (
            <div className="mt-2">
              File Name:{" "}
              <div className="text-success mr-2">{props?.value?.name}</div>
              File Size:{" "}
              <div className="text-success">
                {Math.trunc(props?.value?.size / 1024)}KB
              </div>
            </div>
          )}
        </>
      ),
      cellStyle: {
        maxWidth: 100,
      },
    },
    {
      title: "Contract Date",
      field: "contract_date",
      editComponent: (props) => (
        <DatePicker
          onChange={(date) => {
            props.onChange(date);
          }}
          isGregorian={true}
          value={moment(props.value)}
          timePicker={false}
          className="float-left text-center "
        />
      ),
    },
    {
      title: "Expire Date",
      field: "expire_date",
      editComponent: (props) => (
        <DatePicker
          onChange={(date) => {
            props.onChange(date);
          }}
          isGregorian={true}
          value={moment(props.value)}
          timePicker={false}
          className="float-left text-center "
        />
      ),
    },
    {
      title: "Status",
      field: "status",
      initialEditValue: "Uploading",
      lookup: {
        Approved: "Approved",
        Declined: "Declined",
        Pending: "Pending",
        Uploading: "Uploading",
      },
      render: ({ status }) => renderStatus(status),
    },
    {
      title: "Description",
      field: "description",
    },
  ];

  const options = {
    actionsColumnIndex: columns.length,
  };

  return (
    <div className="card">
      <MainTable
        customOptions={options}
        isLocalization
        //   loading={loading}
        columns={columns}
        data={data?.contracts}
        title="Youtube Contracts"
        onRowUpdate={(newData, oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              updateRow(newData, oldData);
              resolve();
            }, 500);
          })
        }
      />
    </div>
  );
}

export default YoutubeContracts;
