import React from "react";
import Avatar from "@mui/material/Avatar";
import DefaultUser from "assets/images/default/default-user.png";
import YouTubeIcon from "@mui/icons-material/YouTube";
import Chip from "@mui/material/Chip";
import Logo from "../../../../../assets/images/logo.svg";

const ProfileHeader = ({ basicInfo }) => {
  return (
    <div className="jr-profile-banner">
      <div className="jr-profile-container">
        <div className="jr-profile-banner-top">
          <div className="jr-profile-banner-top-left">
            <div className="jr-profile-banner-avatar">
              <Avatar
                style={{ backgroundColor: "#fff" }}
                className="size-90"
                alt="..."
                src={
                  basicInfo?.personal_gateway_picture
                    ? basicInfo?.personal_gateway_picture
                    : DefaultUser
                }
              />
            </div>
            <div className="jr-profile-banner-avatar-info">
              <h3 className="mb-2 jr-mb-sm-3 jr-fs-xl">
                {basicInfo?.username}
              </h3>
              <p className="mb-1 text-grey ">Personal Gateway</p>
            </div>
            <div className="jr-profile-banner-avatar ml-3">
              <Avatar
                style={{ backgroundColor: "#fff" }}
                className="size-90"
                alt="..."
                src={
                  basicInfo?.profile_picture
                    ? basicInfo?.profile_picture
                    : DefaultUser
                }
              />
            </div>
            <div className="jr-profile-banner-avatar-info">
              <h2 className="mb-2 jr-mb-sm-3 jr-fs-xxl jr-font-weight-light">
                {basicInfo?.first_name} {basicInfo?.last_name}
              </h2>
              <p className="mb-1 text-grey ">Profile Name</p>
            </div>
          </div>
          <div className="jr-profile-banner-top-right">
            {basicInfo.new_dashboard ? (
              <img width={30} src={Logo} className="mb-2" />
            ) : null}
            <ul className="jr-follower-list">
              <li>
                <span className="jr-follower-title jr-fs-lg jr-font-weight-medium">
                  {basicInfo?.user_plan && basicInfo?.user_plan.plan.name_en}
                </span>
                <span className="jr-follower-title jr-fs-lg jr-font-weight-medium">
                  {basicInfo?.user_plan && basicInfo?.user_plan.end_date}
                </span>
                {basicInfo?.promoter_email && (
                  <a
                    target="_blank"
                    href={`/app/profile/user/${basicInfo?.promoter_id}/Summary`}
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <span className="jr-fs-sm d-block pointer mt-3">
                      Promoter Email{": "}
                      {basicInfo?.promoter_email}
                    </span>
                  </a>
                )}
                <div>Payout Commission: {basicInfo.commission}%</div>
                {basicInfo?.yektuber ? (
                  <span className="jr-fs-sm d-block mt-3">
                    <Chip label={<YouTubeIcon />} color="error" />
                  </span>
                ) : null}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ProfileHeader);
