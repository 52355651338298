import React from "react";
import GatewayInfo from "./GatewayInfo";
import StatusLoading from "app/routes/UserProfile/components/StatusLoading";

const GatewayWebsite = ({ gatewayInfo }) => {
  return (
    <div className="jr-card jr-full-card">
      <div className="card-header">
        <h4 className="card-title mb-0">Gateway Website</h4>
      </div>
      <div style={{ padding: 8 * 3 }}>
        {gatewayInfo.id ? (
          <GatewayInfo gatewayInfo={gatewayInfo} />
        ) : (
          <StatusLoading />
        )}
      </div>
    </div>
  );
};

export default GatewayWebsite;
