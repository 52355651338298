import React from "react";
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { closeModalRequest } from "redux/actions/Modal";

const MessageDilaog = () => {
  const dispatch = useDispatch();
  const {
    showDialog,
    description,
    title,
    status = "success",
    onConfirm,
    loading,
  } = useSelector(({ modalReducer }) => {
    return {
      showDialog: modalReducer.showDialog,
      description: modalReducer?.description,
      title: modalReducer?.title,
      status: modalReducer?.status,
      onConfirm: modalReducer?.onConfirm,
    };
  }, shallowEqual);

  return (
    <SweetAlert
      show={showDialog}
      type={status}
      title={title}
      onConfirm={
        status === "warning" ? onConfirm : () => dispatch(closeModalRequest())
      }
      confirmBtnBsStyle={
        status === "success"
          ? "success"
          : status === "warning"
          ? "primary"
          : "danger"
      }
      showCancel={status === "warning"}
      onCancel={() => dispatch(closeModalRequest())}
      disabled={loading}
    >
      {description}
    </SweetAlert>
  );
};

export default MessageDilaog;
