import React from "react";
import { getUser } from "../../../../constants/usefulFunctions";

const TransactionInfo = ({ transaction }) => {
  var PaymentGatewayItem = ({ label, value }) => ({
    render: function() {
      return (
        <div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
          <span className="mb-0 text-light jr-fs-sm pr-2">{label}: </span>
          <p className="mb-0">{value}</p>
        </div>
      );
    },
  });
  return (
    <div className="app-wrapper pb-0">
      <div className="jr-card jr-full-card">
        <div className="card-header">
          <h4 className="card-title mb-0">Transaction</h4>
        </div>
        <div
          style={{ padding: 24 }}
          className="media align-items-center flex-nowrap jr-pro-contact-list"
        >
          <div className="media-body row">
            <div className="col-lg-4 col-md-6 col-12">
              <PaymentGatewayItem
                label="User"
                value={getUser(transaction.user)}
              />
              <PaymentGatewayItem
                label="Email"
                value={transaction.user.email}
              />
              <PaymentGatewayItem
                label="Mobile"
                value={transaction.user.mobile}
              />
              <PaymentGatewayItem label="Status" value={transaction.status} />
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <PaymentGatewayItem
                label="Event"
                value={transaction.event.name_en}
              />
              <PaymentGatewayItem label="Amount" value={transaction.amount} />
              <PaymentGatewayItem
                label="Currency"
                value={transaction.currency.code}
              />
              <PaymentGatewayItem label="Balance" value={transaction.balance} />
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <PaymentGatewayItem label="Vat" value={transaction.vat} />
              <PaymentGatewayItem label="Type" value={transaction.type} />
              <PaymentGatewayItem
                label="Tracking Code"
                value={transaction.tracking_code}
              />
              <PaymentGatewayItem
                label="Created At"
                value={transaction.created_at}
              />
              <PaymentGatewayItem
                label="Updated At"
                value={transaction.updated_at}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionInfo;
