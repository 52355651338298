import React, { useState, useEffect } from "react";
import ProfileHeader from "./ProfileHeader";
import Summary from "./Summary/index";
import Itsaaz from "./Itsaaz";
import UserLog from "./UserLog/index";
import Transactions from "./Transactions/index";
import UserStatus from "./UserStatus/index";
import BankAccounts from "./BankAccounts/index";
import Gateways from "./Gateways/index";
import YekpayLinks from "./YekpayLinks/index";
import Tickets from "./Tickets";
import UserBalance from "./UserBalance";
import Report from "./Report";
import TextMessage from "./TextMessage";
import UserPlans from "./UserPlans";
import AdminComments from "./AdminComments";
import Logs from "./Logs";
import Auxiliary from "util/Auxiliary";
import Company from "./Company";
import Billing from "./Billing";
import TransactionsFailed from "./TransactionsFailed";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import HelpIcon from "@mui/icons-material/Help";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BusinessIcon from "@mui/icons-material/Business";
import Room from "@mui/icons-material/Room";
import ClearAll from "@mui/icons-material/ClearAll";
import SwapHoriz from "@mui/icons-material/SwapHoriz";
import AccountBalance from "@mui/icons-material/AccountBalance";
import Launch from "@mui/icons-material/Launch";
import Message from "@mui/icons-material/Message";
import TrendingUp from "@mui/icons-material/TrendingUp";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import AssignmentLate from "@mui/icons-material/AssignmentLate";
import Feedback from "@mui/icons-material/Feedback";
import RequestPayout from "./RequestPayout";
import { useHistory, useParams } from "react-router-dom";
import YouTubeIcon from "@mui/icons-material/YouTube";
import AssessmentIcon from "@mui/icons-material/Assessment";
import Youtube from "./Youtube";
import TaskAltRoundedIcon from "@mui/icons-material/TaskAltRounded";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfileRequest } from "redux/actions/UserProfile";
import Loading from "components/Loading";
import {
  EDIT_YOUTUBE_CHANNEL_CONTRACT_STARTED,
  EDIT_YOUTUBE_CHANNEL_INFO_STARTED,
  GET_USER_YOUTUBE_CHANNELS_STARTED,
} from "redux/types";
import Exchange from "./Exchnage/index";
import Topup from "./Topup";
import UId from "./UId/index";
import LinkIcon from "@mui/icons-material/Link";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PersonalPaylinks from "./PersonalPaylinks/index";
import ProductPaylinks from "./ProductPaylinks/index";
// --------------------------------------------------------------------------------
const TabContainer = ({ children }) => {
  return <div className="pt-3">{children}</div>;
};

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const Profile = () => {
  const { id: user_id, section } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const currentPath = `/app/profile/user/${user_id}`;

  const {
    rType,
    userData: {
      commission,
      basic: user,
      youtube: showYoutube,
      itsaz: verifyByItsaz,
      uid: verifyByUId,
    },
  } = useSelector((state) => state.userProfileReducer);
  const loading =
    rType === EDIT_YOUTUBE_CHANNEL_INFO_STARTED ||
    rType === EDIT_YOUTUBE_CHANNEL_CONTRACT_STARTED ||
    rType === GET_USER_YOUTUBE_CHANNELS_STARTED;
  const activeTab = {
    Summary: 0,
    Itsaaz: 1,
    UId: 2,
    Status: 3,
    Balance: 4,
    Report: 5,
    Youtube: 6,
    Company: 7,
    Billing: 8,
    Plans: 9,
    Transactions: 10,
    BankAccount: 11,
    Gateway: 12,
    PersonalPaylinks: 13,
    ProductPaylinks: 14,
    YekpayLinks: 15,
    Tickets: 16,
    Logs: 17,
    RequestPayout: 18,
    Topup: 19,
    Exchange: 20,
    TextMessage: 21,
    UserLog: 22,
  };

  useEffect(() => {
    if (user_id) fetchUserData({ user_id });
  }, []);

  const fetchUserData = async () => {
    dispatch(getUserProfileRequest({ user_id }));
  };

  return (
    <Auxiliary>
      {loading && <Loading />}
      <ProfileHeader basicInfo={{ ...user, commission }} />
      <div className="jr-profile-content">
        <div className="w-100">
          <AppBar position="static" color="inherit">
            <Tabs
              value={activeTab[section]}
              variant="scrollable"
              scrollButtons="auto"
              indicatorColor="primary"
              allowScrollButtonsMobile={true}
              textColor="primary"
            >
              <Tab
                label="Summary"
                icon={<PersonPinIcon />}
                onClick={() => history.push(`${currentPath}/Summary`)}
              />
              <Tab
                label="Itsaaz"
                icon={
                  <>
                    <PersonPinIcon
                      style={{
                        margin: "5px",
                      }}
                    />
                    {verifyByItsaz && (
                      <TaskAltRoundedIcon
                        style={{
                          position: "absolute",
                          right: "18px",
                          top: "10px",
                          color: "#5cb85c",
                        }}
                        fontSize="medium"
                      />
                    )}
                  </>
                }
                onClick={() => history.push(`${currentPath}/Itsaaz`)}
              />
              <Tab
                label="UId"
                icon={
                  <>
                    <PersonPinIcon
                      style={{
                        margin: "5px",
                      }}
                    />
                    {verifyByUId && (
                      <TaskAltRoundedIcon
                        style={{
                          position: "absolute",
                          right: "18px",
                          top: "10px",
                          color: "#5cb85c",
                        }}
                        fontSize="medium"
                      />
                    )}
                  </>
                }
                onClick={() => history.push(`${currentPath}/UId`)}
              />
              <Tab
                label="Status"
                icon={<HelpIcon />}
                onClick={() => history.push(`${currentPath}/Status`)}
              />
              <Tab
                label="Balance"
                icon={<AttachMoneyIcon />}
                onClick={() => history.push(`${currentPath}/Balance`)}
              />
              <Tab
                label="Report"
                icon={<AssessmentIcon />}
                onClick={() => history.push(`${currentPath}/Report`)}
              />
              <Tab
                hidden={!showYoutube}
                label="Youtube"
                icon={<YouTubeIcon />}
                onClick={() => history.push(`${currentPath}/Youtube`)}
              />
              <Tab
                label="Company"
                icon={<BusinessIcon />}
                onClick={() => history.push(`${currentPath}/Company`)}
              />
              <Tab
                label="Billing"
                icon={<Room />}
                onClick={() => history.push(`${currentPath}/Billing`)}
              />
              <Tab
                label="Plans"
                icon={<ClearAll />}
                onClick={() => history.push(`${currentPath}/Plans`)}
              />
              <Tab
                label="Transactions"
                icon={<SwapHoriz />}
                onClick={() => history.push(`${currentPath}/Transactions`)}
              />
              <Tab
                label="Bank Account"
                icon={<AccountBalance />}
                onClick={() => history.push(`${currentPath}/BankAccount`)}
              />
              <Tab
                label="Gateway"
                icon={<Launch />}
                onClick={() => history.push(`${currentPath}/Gateway`)}
              />
              <Tab
                label="Personal Paylinks"
                icon={<LinkIcon />}
                onClick={() => history.push(`${currentPath}/PersonalPaylinks`)}
              />
              <Tab
                label="Product Paylinks"
                icon={<ShoppingCartIcon />}
                onClick={() => history.push(`${currentPath}/ProductPaylinks`)}
              />
              <Tab
                label="Yekpay Links"
                icon={<AssignmentLate />}
                onClick={() => history.push(`${currentPath}/YekpayLinks`)}
              />
              <Tab
                label="Tickets"
                icon={<Message />}
                onClick={() =>
                  history.push({
                    pathname: `${currentPath}/Tickets`,
                    state: { email: user?.email },
                  })
                }
              />
              <Tab
                label="Logs"
                icon={<Feedback />}
                onClick={() => history.push(`${currentPath}/Logs`)}
              />
              <Tab
                label="Request Payout"
                icon={<TrendingUp />}
                onClick={() => history.push(`${currentPath}/RequestPayout`)}
              />
              <Tab
                label="Topup"
                icon={<AccountBalanceWalletIcon />}
                onClick={() => history.push(`${currentPath}/Topup`)}
              />
              <Tab
                label="Exchange"
                icon={<CurrencyExchangeIcon />}
                onClick={() => history.push(`${currentPath}/Exchange`)}
              />
              <Tab
                label="Text Message"
                icon={<Message />}
                onClick={() =>
                  history.push({
                    pathname: `${currentPath}/TextMessage`,
                    state: { mobile: user?.mobile },
                  })
                }
              />
              <Tab
                label="User Log"
                icon={<PersonPinIcon />}
                onClick={() => history.push(`${currentPath}/UserLog`)}
              />
            </Tabs>
          </AppBar>
          {section === "Summary" && (
            <TabContainer>
              <Summary user={user} />
            </TabContainer>
          )}

          {section === "Itsaaz" && (
            <TabContainer>
              <Itsaaz user_id={user_id} />
            </TabContainer>
          )}
          {section === "UId" && (
            <TabContainer>
              <UId user_id={user_id} />
            </TabContainer>
          )}
          {section === "Status" && (
            <TabContainer>
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                  <UserStatus basicInfo={user} />
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                  <AdminComments user_id={user_id} basicInfo={user} />
                </div>
              </div>
            </TabContainer>
          )}
          {section === "Balance" && (
            <TabContainer>
              <UserBalance
                basicInfo={{ ...user, commission }}
                user_id={user_id}
              />
            </TabContainer>
          )}
          {section === "Report" && (
            <TabContainer>
              <Report user_id={user_id} />
            </TabContainer>
          )}
          {section === "Youtube" && (
            <TabContainer>
              <Youtube user_id={user_id} basicInfo={user} />
            </TabContainer>
          )}
          {section === "Company" && (
            <TabContainer>
              <Company user_id={user_id} />
            </TabContainer>
          )}
          {section === "Billing" && (
            <TabContainer>
              <Billing user_id={user_id} />
            </TabContainer>
          )}
          {section === "Plans" && (
            <TabContainer>
              <UserPlans user_id={user_id} />
            </TabContainer>
          )}
          {section === "Transactions" && (
            <TabContainer>
              <Transactions user_id={user_id} />
              <TransactionsFailed user_id={user_id} />
            </TabContainer>
          )}
          {section === "BankAccount" && (
            <TabContainer>
              <BankAccounts user_id={user_id} />
            </TabContainer>
          )}
          {section === "Gateway" && (
            <TabContainer>
              <Gateways user_id={user_id} />
            </TabContainer>
          )}
          {section === "YekpayLinks" && (
            <TabContainer>
              <YekpayLinks user_id={user_id} />
            </TabContainer>
          )}
          {section === "PersonalPaylinks" && (
            <TabContainer>
              <PersonalPaylinks user_id={user_id} />
            </TabContainer>
          )}
          {section === "ProductPaylinks" && (
            <TabContainer>
              <ProductPaylinks user_id={user_id} />
            </TabContainer>
          )}
          {section === "Tickets" && (
            <TabContainer>
              <Tickets user_id={user_id} />
            </TabContainer>
          )}
          {section === "Logs" && (
            <TabContainer>
              <Logs user_id={user_id} />
            </TabContainer>
          )}
          {section === "RequestPayout" && (
            <TabContainer>
              <RequestPayout user_id={user_id} />
            </TabContainer>
          )}
          {section === "Topup" && (
            <TabContainer>
              <Topup user_id={user_id} />
            </TabContainer>
          )}
          {section === "Exchange" && (
            <TabContainer>
              <Exchange user_id={user_id} />
            </TabContainer>
          )}
          {section === "TextMessage" && (
            <TabContainer>
              <TextMessage user_id={user_id} />
            </TabContainer>
          )}
          {section === "UserLog" && (
            <TabContainer>
              <UserLog user_id={user_id} />
            </TabContainer>
          )}
        </div>
      </div>
    </Auxiliary>
  );
};

export default Profile;
