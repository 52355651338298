import React, { useState, useEffect } from "react";
import RequestInfo from "./RequestInfo";
import { useSelector } from "react-redux";

const RequestPayout = ({ user_id }) => {
  const {
    userData: { basic: user },
  } = useSelector((state) => state.userProfileReducer);

  return (
    <div className="jr-card jr-full-card">
      <div className="card-header">
        <h4 className="card-title mb-0">Request Payout</h4>
      </div>
      <div style={{ padding: 24 }}>
        <RequestInfo basicInfo={user} user_id={user_id} />
      </div>
    </div>
  );
};

export default RequestPayout;
