import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import { API_GET_TEXT_MESSAGES_USER, token } from "constants/constants";
import { createDateLookup, updateDateLookup } from "constants/usefulFunctions";
import { withRouter } from "react-router-dom";
import AppButton from "components/AppButton";
import { useDispatch, useSelector } from "react-redux";
import { GET_USER_TEXT_MESSAGES_STARTED } from "redux/types";
import { getUserTextMessagesRequest } from "redux/actions/UserProfile";

function TextMessage() {
  const dispatch = useDispatch();
  const {
    rType,
    userData: {
      basic: { id: user_id, mobile: user_mobile },
    },
    userTextMessages,
  } = useSelector((state) => state.userProfileReducer);
  const loading = rType === GET_USER_TEXT_MESSAGES_STARTED;
  const options = {
    columnsButton: true,
    search: true,
    exportButton: true,
    grouping: false,
    filtering: true,
    exportAllData: true,
    selection: false,
    editable: false,
  };

  const columns = [
    { title: "Return Status", field: "return_status" },
    { title: "Return Message", field: "return_message" },
    { title: "Message ID", field: "message_id" },
    { title: "Message", field: "message" },
    { title: "Status Text", field: "status_text" },
    { title: "Sender", field: "sender" },
    { title: "Receptor", field: "receptor" },
    { title: "Send Date", field: "send_date" },
    { title: "Cost", field: "cost" },
    { title: "Delivery Status", field: "delivery_status" },
    createDateLookup(),
    updateDateLookup(),
  ];

  useEffect(() => {
    dispatch(getUserTextMessagesRequest({ user_id }));
  }, []);

  return (
    <div className="card">
      <AppButton
        onClick={() =>
          window.open(
            `/app/text-messages/new?mobile=${user_mobile}&&user_id=${user_id}`
          )
        }
        text="Send New Message"
        size="large"
        customClassName="m-3"
      />
      <MaterialTable
        options={options}
        isLoading={loading}
        columns={columns}
        data={userTextMessages}
        title="Text Message"
      />
    </div>
  );
}

export default withRouter(TextMessage);
