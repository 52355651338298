import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const Transactions = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Redirect
        exact
        from={`${match.url}/`}
        to={`${match.url}/PendingTransactions`}
      />
      <Route
        path={`${match.url}/hold-transactions`}
        component={asyncComponent(() => import("./routes/HoldTransactions"))}
      />
      <Route
        path={`${match.url}/payouts`}
        component={asyncComponent(() => import("./routes/Payouts"))}
      />
      <Route
        path={`${match.url}/pending-payouts`}
        component={asyncComponent(() => import("./routes/PendingPayouts"))}
      />
      <Route
        path={`${match.url}/vip-pending-payouts`}
        component={asyncComponent(() => import("./routes/VIPPendingPayouts"))}
      />
      <Route
        path={`${match.url}/WirePayouts`}
        component={asyncComponent(() => import("./routes/WirePayouts"))}
      />
      <Route
        path={`${match.url}/topup`}
        component={asyncComponent(() => import("./routes/Topup"))}
      />
      <Route
        path={`${match.url}/refund-requests`}
        component={asyncComponent(() => import("./routes/RefundRequests"))}
      />
      <Route
        path={`${match.url}/all-transactions`}
        component={asyncComponent(() => import("./routes/AllTransactions"))}
      />
      <Route
        path={`${match.url}/website-gateway`}
        component={asyncComponent(() => import("./routes/WebsiteGateway"))}
      />
      <Route
        path={`${match.url}/yekpay-link`}
        component={asyncComponent(() => import("./routes/YekpayLink"))}
      />
      <Route
        path={`${match.url}/personal-gateway`}
        component={asyncComponent(() => import("./routes/PersonalGateway"))}
      />
      <Route
        path={`${match.url}/exchange`}
        component={asyncComponent(() => import("./routes/Exchange"))}
      />
      <Route
        path={`${match.url}/transfer`}
        component={asyncComponent(() => import("./routes/Transfer"))}
      />
    </Switch>
  </div>
);

export default Transactions;
