import React from 'react';
import Button from '@mui/material/Button';
import { Link, withRouter } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="app-footer">
      <span className="d-inline-block">Copyright Yekpay &copy; 2020</span>
      <Link className="jr-list-link" to="/app/faq">
        <span className="jr-list-text">FAQ</span>
        <i className="zmdi zmdi-help zmdi-hc-fw zmdi-hc-lg" />
      </Link>
    </footer>
  );
};
export default Footer;
