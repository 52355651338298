import React, { useState, useEffect } from "react";
import {
  API_GET_EXPANEL_USER_TRANSACTIONS_RECEIPT,
  token,
} from "../../../../../../constants/constants";
import axios from "axios";
import { thousands_separators } from "../../../../../../constants/usefulFunctions";
import { withRouter } from "react-router-dom";
import ReactToPDF from "../../../../../../components/ReactToPdf";
import "./Receipt.stlye.scss";
import StatusLoading from "../StatusLoading";

const TransactionReceipt = ({ match }) => {
  useEffect(() => {
    fetchData();
  }, []);
  const transaction_id = match.params.id;

  const [transfer, setTransfer] = useState({});
  const [profile, setProfile] = useState({});
  const [recipient, setRecipient] = useState({});
  const [quote, setQuote] = useState({});
  const [fee, setFee] = useState();

  const [loading, setloading] = useState(true);

  const fetchData = async () => {
    await axios
      .post(
        API_GET_EXPANEL_USER_TRANSACTIONS_RECEIPT,
        { transaction_id },
        token()
      )
      .then((res) => {
        let data = res.data.data;

        setTransfer(data.transfer);
        setProfile(data.profile);
        setRecipient(data.recipient);
        setQuote(data.quote);
        setFee(data.payment_fee);
        setloading(false);
      });
  };

  const mapOtherDetails = (details) => {
    let items = [];
    for (const property in details) {
      items.push({ ...items, label: property, value: details[property] });
    }
    return (
      <>
        {items.map((item) => {
          if (
            item.label === "accountNumber" ||
            item.label === "IBAN" ||
            item.label === "bankCode" ||
            item.label === "bsbCode" ||
            item.label === "ifscCode" ||
            item.label === "transitNumber" ||
            item.label === "sortCode"
          )
            return <div className="my-1">{item.value}</div>;
        })}
      </>
    );
  };

  if (loading)
    return (
      <div className="mt-5">
        <StatusLoading />
      </div>
    );
  return (
    <div className="d-flex justify-content-center flex-column align-items-center px-3 ">
      <div className="col-lg-8 col-md-10">
        <ReactToPDF text="Download Receipt">
          <div className="reciept-transfer">
            <div className="reciept-tranfer-header">Payment Confirmation</div>
            <div className="reciept-titles text-success">Executed</div>

            <div className="reciept-titles ">{transfer?.updated_at}</div>

            <div className="reciept-titles mt-4">Sender</div>

            <div className="px-4">
              <div className="bold">{profile?.name}</div>
              <div style={{ width: "150px" }}>{profile?.busineess_address}</div>
            </div>

            <div className="reciept-titles mt-4 ">Payment Overview</div>

            <div className="px-4 receipt-overview">
              <div className="overview-title">
                Amount paid by {profile?.name}
              </div>
              <div>
                {thousands_separators(parseFloat(quote?.yekpay_source_amount))}{" "}
                {transfer?.source_currency}
                <div className="overview-title">Fee</div>
                <div>
                  {thousands_separators(parseFloat(fee))}{" "}
                  {transfer?.source_currency}
                </div>
                <div className="overview-title">
                  Converted and sent to {recipient?.accountHolderName}
                </div>
                <div>
                  {thousands_separators(parseFloat(transfer?.target_value))}{" "}
                  {transfer?.target_currency}
                </div>
              </div>
            </div>

            <div className="reciept-titles mt-4">Recipient </div>
            <div className=" px-4">
              <div className="bold">{recipient?.accountHolderName}</div>
              {mapOtherDetails(recipient)}
            </div>

            <div className="reciept-titles mt-4 ">Description </div>

            <div className=" px-4">
              <div>{transfer?.details_reference}</div>
            </div>

            <div className="reciept-titles mt-4">Peyment Reference Number</div>
            <div className="px-4">
              <div>#{transfer?.transfer_id}</div>
            </div>
          </div>
        </ReactToPDF>
      </div>
    </div>
  );
};

export default withRouter(TransactionReceipt);
