import {
  GET_ALL_TEXT_MESSAGES_FAILURE,
  GET_ALL_TEXT_MESSAGES_REQUEST,
  GET_ALL_TEXT_MESSAGES_STARTED,
  GET_ALL_TEXT_MESSAGES_SUCCESS,
  SEND_SINGLE_SMS_FAILURE,
  SEND_SINGLE_SMS_REQUEST,
  SEND_SINGLE_SMS_STARTED,
  SEND_SINGLE_SMS_SUCCESS,
} from "redux/types";
// --------------------------------------------------------------------------------
export const sendSingleSmsRequest = (data) => ({
  type: SEND_SINGLE_SMS_REQUEST,
  payload: data,
});

export const sendSingleSmsStarted = () => ({
  type: SEND_SINGLE_SMS_STARTED,
});

export const sendSingleSmsSuccess = () => ({
  type: SEND_SINGLE_SMS_SUCCESS,
});

export const sendSingleSmsFailure = (data) => ({
  type: SEND_SINGLE_SMS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getAllTextMessagesRequest = (data) => ({
  type: GET_ALL_TEXT_MESSAGES_REQUEST,
  payload: data,
});

export const getAllTextMessagesStarted = () => ({
  type: GET_ALL_TEXT_MESSAGES_STARTED,
});

export const getAllTextMessagesSuccess = (data) => ({
  type: GET_ALL_TEXT_MESSAGES_SUCCESS,
  payload: data,
});

export const getAllTextMessagesFailure = (data) => ({
  type: GET_ALL_TEXT_MESSAGES_FAILURE,
  payload: data,
});
