import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import NumberFormat from 'react-number-format';
import TextField from '@mui/material/TextField';

const useStyles = makeStyles({
  root: {
    width: ({ fullWidth }) => (fullWidth ? '100%' : 300),
    margin: 10
  }
});

const AppNumberInput = ({
  label,
  value,
  onChange,
  variant = 'outlined',
  fullWidth = false,
  readOnly = false,
  shrink = true,
  helperText,
  error = false,
  multiline = false,
  rows = 5,
  margin = 'normal',
  color = 'primary',
  dir = 'rtl',
  disabled,
  thousandSeparator = true,
  ...otherProps
}) => {
  const classes = useStyles({ fullWidth });

  return (
    <TextField
      label={label}
      className={classes.root}
      value={value}
      onChange={onChange}
      disabled={disabled}
      variant={variant}
      helperText={helperText}
      error={error}
      dir={dir}
      margin={margin}
      fullWidth={fullWidth}
      color={color}
      InputLabelProps={{
        shrink,
        readOnly
      }}
      InputProps={{
        inputComponent: NumberFormatCustom
      }}
      {...otherProps}
    />
  );
};

const NumberFormatCustom = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
            name: props.name
          }
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
});
export default AppNumberInput;
