import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import TopUpChannel from "./TopUpChannel";
import YouTubeTransactions from "./YouTubeTransactions";
import YoutubePayoutTransactions from "./YoutubePayoutTransactions";
import PayoutChannel from "./PayoutChannel";
import Button from "@mui/material/Button";
import { from, calculateNextYear } from "constants/constants";
import DatePicker from "react-datepicker2";
import CircularProgress from "@mui/material/CircularProgress";
import { Grid } from "@mui/material";
import moment from "moment-jalaali";
import YouTubeAffiliate from "./YoutubeAffiliate";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import {
  addYoutubeArchiveContractRequest,
  createYoutubeChannelContractRequest,
  editYoutubeChannelInfoRequest,
} from "redux/actions/UserProfile";
import {
  EDIT_YOUTUBE_CHANNEL_CONTRACT_SUCCESS,
  GET_USER_YOUTUBE_CHANNELS_SUCCESS,
} from "redux/types";
import YoutubeContracts from "./YoutubeContracts";
import YoutubeComment from "./YoutubeComment";
import {
  Card,
  CardContent,
  CardHeader,
} from "../../../../../../node_modules/@mui/material/index";
import YoutubeArchiveContracts from "./YoutubeArchiveContracts";

function YoutubeDetails({ data, user_id, reRender, basicInfo }) {
  const dispatch = useDispatch();
  const { rType } = useSelector((state) => state.userProfileReducer);
  const [name, setName] = useState(data.channel_name);
  const [address, setAddress] = useState(data.channel_link);
  const [content, setcontent] = useState(data.channel_content);
  const [status, setStatus] = useState(data.status);
  const [description, setDecription] = useState("");
  const [contractDate, setContractDate] = useState(
    moment(data.contract?.contract_date)
  );
  const [expireDate, setExpireDate] = useState(
    moment(data.contract?.expire_date)
  );
  const [contractFile, setContractFile] = useState(
    data.contract?.contract_file
  );

  const [uploadContract, setUploadContract] = useState(false);

  useEffect(() => {
    switch (rType) {
      case EDIT_YOUTUBE_CHANNEL_CONTRACT_SUCCESS:
        setUploadContract(false);
        break;
      case GET_USER_YOUTUBE_CHANNELS_SUCCESS:
        setContractFile(data.contract?.contract_file);
        setName(data.channel_name);
        setcontent(data.channel_content);
        setAddress(data.channel_link);
        setStatus(data.status);
        break;
      default:
        break;
    }
  }, [rType]);

  const handleEdit = () => {
    const formData = new FormData();

    formData.append("channel_id", data.id);
    formData.append("channel_name", name);
    formData.append("channel_link", address);
    formData.append("channel_content", content);
    formData.append("status", status);

    dispatch(editYoutubeChannelInfoRequest({ formData, userId: user_id }));
  };

  const handleStatus = (event) => {
    setStatus(event.target.value);
  };
  const AddContractFile = () => {
    const formData = new FormData();

    formData.append("channel_id", data.id);
    formData.append("admin_contract_file", contractFile);
    formData.append("contract_date", moment(contractDate).format("YYYY/MM/DD"));
    formData.append("expire_date", moment(expireDate).format("YYYY/MM/DD"));

    dispatch(
      createYoutubeChannelContractRequest({ formData, userId: user_id })
    );
  };

  const AddArchiveContractFile = () => {
    const formData = new FormData();

    formData.append("channel_id", data.id);
    formData.append("uploaded_contract_file", contractFile);
    formData.append("contract_date", moment(contractDate).format("YYYY/MM/DD"));
    formData.append("expire_date", moment(expireDate).format("YYYY/MM/DD"));
    formData.append("description", description);

    dispatch(addYoutubeArchiveContractRequest({ formData, userId: user_id }));
  };

  return (
    <>
      <Grid container spacing={1} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Youtube Channel Informations" />
            <CardContent>
              <Grid item container spacing={2} xs={8}>
                <Grid item xs={6.5}>
                  <TextField
                    label="Channel Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="my-2"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6.5}>
                  <TextField
                    label="Channel Content"
                    value={content}
                    onChange={(e) => setcontent(e.target.value)}
                    className="my-2 "
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6.5}>
                  <TextField
                    label="Channel Address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    className="my-2"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6.5}>
                  <TextField
                    select
                    fullWidth
                    label="Status"
                    value={status}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleStatus}
                    margin="normal"
                    variant="outlined"
                  >
                    <MenuItem key="Approved" value="Approved">
                      <span className="text-success">Approved</span>
                    </MenuItem>
                    <MenuItem key="Pending" value="Pending">
                      <span className="text-warning">Pending</span>
                    </MenuItem>
                    <MenuItem key="Declined" value="Declined">
                      <span className="text-danger">Declined</span>
                    </MenuItem>
                    <MenuItem key="Canceled" value="Canceled">
                      <span className="text-danger">Canceled</span>
                    </MenuItem>
                  </TextField>
                </Grid>
              </Grid>
              <Button
                color="primary"
                className="jr-btn jr-btn-sm bg-primary align-self-center text-white px-3 mt-3"
                onClick={handleEdit}
                type="submit"
                style={{ width: "140px" }}
              >
                Edit
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Youtube Channel's Contract" />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <span className="float-left mx-1 ">Contract Date:</span>
                  <DatePicker
                    onChange={(date) => {
                      setContractDate(date);
                      let expire = calculateNextYear(date);
                      setExpireDate(expire);
                    }}
                    isGregorian={true}
                    value={from}
                    timePicker={false}
                    className="float-left text-center "
                  />
                </Grid>
                <Grid item xs={12}>
                  <span className="float-left mx-1 ">Expire Date:</span>
                  <DatePicker
                    onChange={(date) => setExpireDate(date)}
                    isGregorian={true}
                    value={expireDate}
                    timePicker={false}
                    className="float-left text-center "
                  />
                </Grid>
                <Grid item xs={12}>
                  <span className=" mx-1 ">Contract File:</span>
                  <Button
                    className="bg-light jr-btn jr-btn-sm text-dark my-2 "
                    component="label"
                  >
                    <i className="zmdi zmdi-file-plus  mr-2 jr-fs-xl d-inline-flex align-middle" />
                    Browse
                    <input
                      type="file"
                      onChange={(e) => {
                        setContractFile(e.target.files[0]);
                        setUploadContract(true);
                      }}
                      style={{ display: "none" }}
                    />
                  </Button>
                  {contractFile && !uploadContract && (
                    <a
                      className="m-3"
                      target="_blank"
                      href={"https://adminapi.yekpay.com/" + contractFile}
                    >
                      <i
                        style={{ fontSize: "20px" }}
                        className="zmdi zmdi-attachment zmdi-hc-fw zmdi-hc-lg text-primary"
                      />
                    </a>
                  )}
                  {uploadContract && (
                    <div className="mt-2">
                      File Name:{" "}
                      <span className="text-success mr-2">
                        {contractFile?.name}
                      </span>
                      File Size:{" "}
                      <span className="text-success">
                        {Math.trunc(contractFile?.size / 1024)}KB
                      </span>
                    </div>
                  )}
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Description"
                    type="textarea"
                    value={description}
                    onChange={(e) => setDecription(e.target.value)}
                    fullWidth
                    multiline
                    rows={4}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    color="primary"
                    className="jr-btn jr-btn-sm bg-primary text-white px-3 mt-3 align-self-center"
                    onClick={AddArchiveContractFile}
                    type="submit"
                    style={{ width: "140px" }}
                  >
                    Add Contract File
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <YoutubeArchiveContracts user_id={user_id} data={data} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <div className="w-100 d-flex align-items-scretch flex-wrap">
        <div className="col-md-6 h-100 px-1">
          <TopUpChannel
            user_id={user_id}
            channel_id={data.id}
            reRender={reRender}
          />
        </div>
        <div className="col-md-6 h-100 px-1">
          <PayoutChannel
            user_id={user_id}
            channel_id={data.id}
            reRender={reRender}
            balance={data.balance}
          />
        </div>
      </div>

      <div>
        <YoutubeComment user_id={user_id} basicInfo={basicInfo} data={data} />
      </div>
      <div className="jr-card w-100  p-2">
        <div className=" d-flex  flex-column align-items-start flex-wrap col-md-6 p-3 ">
          <h3 className="  align-self-center mb-3">
            Youtube Channel's Contract
          </h3>
          <div className="my-3 d-flex align-items-center   ">
            <span className="float-left mx-1 ">Contract Date:</span>
            <DatePicker
              onChange={(date) => {
                setContractDate(date);
                let expire = calculateNextYear(date);
                setExpireDate(expire);
              }}
              isGregorian={true}
              value={from}
              timePicker={false}
              className="float-left text-center "
            />
          </div>
          <div className="my-3 d-flex align-items-center">
            <span className="float-left mx-1 ">Expire Date:</span>
            <DatePicker
              onChange={(date) => setExpireDate(date)}
              isGregorian={true}
              value={expireDate}
              timePicker={false}
              className="float-left text-center "
            />
          </div>

          <Button
            className="bg-light jr-btn jr-btn-sm text-dark my-2 "
            component="label"
          >
            <i className="zmdi zmdi-file-plus  mr-2 jr-fs-xl d-inline-flex align-middle" />
            Browse
            <input
              type="file"
              onChange={(e) => {
                setContractFile(e.target.files[0]);
                setUploadContract(true);
              }}
              style={{ display: "none" }}
            />
          </Button>
          {contractFile && !uploadContract && (
            <a
              className="m-3"
              target="_blank"
              href={"https://adminapi.yekpay.com/" + contractFile}
            >
              <i
                style={{ fontSize: "20px" }}
                className="zmdi zmdi-attachment zmdi-hc-fw zmdi-hc-lg text-primary"
              />
            </a>
          )}
          {uploadContract && (
            <div className="mt-2">
              File Name:{" "}
              <span className="text-success mr-2">{contractFile?.name}</span>
              File Size:{" "}
              <span className="text-success">
                {Math.trunc(contractFile?.size / 1024)}KB
              </span>
            </div>
          )}

          <Button
            color="primary"
            className="jr-btn jr-btn-sm bg-primary text-white px-3 mt-3 align-self-center"
            onClick={AddContractFile}
            type="submit"
            style={{ width: "140px" }}
          >
            Add Contract File
          </Button>
        </div>
        <YoutubeContracts user_id={user_id} data={data} />
      </div>
      <YouTubeTransactions user_id={user_id} data={data} />
      <YoutubePayoutTransactions user_id={user_id} data={data} />
      {data.user_id === 1040093 && (
        <YouTubeAffiliate user_id={user_id} data={data?.affiliate} />
      )}
    </>
  );
}

export default YoutubeDetails;
