import {
  CLOSE_MODAL_DEFAULT,
  CLOSE_MODAL_REQUEST,
  CLOSE_MODAL_SUCCESS,
  OPEN_MODAL_SUCCESS,
} from "redux/types";
// --------------------------------------------------------------------------------
const initialState = {
  rType: CLOSE_MODAL_DEFAULT,
  showDialog: false,
  title: "",
  description: "",
  status: "primary",
  onConfirm: () => {},
  error: "",
};
export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODAL_SUCCESS:
      let { title, description, status, onConfirm } = action.payload;
      return {
        ...state,
        rType: OPEN_MODAL_SUCCESS,
        showDialog: true,
        title,
        description,
        status,
        onConfirm,
        error: "",
      };
    case CLOSE_MODAL_SUCCESS:
      return {
        ...state,
        rType: CLOSE_MODAL_REQUEST,
        showDialog: false,
        title: "",
        description: "",
        status: "primary",
        onConfirm: () => {},
        error: "",
      };
    default:
      return state;
  }
};
