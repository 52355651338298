import React from 'react';
import ReactToPdf from 'react-to-pdf';
import Button from '@mui/material/Button';

const ReactToPDF = ({ children, text }) => {
  return (
    <ReactToPdf>
      {({ toPdf, targetRef }) => (
        <>
          <div ref={targetRef}>{children}</div>
          <div className="d-flex justify-content-center w-100">
            <Button className="bg-primary text-white" onClick={toPdf}>
              {text}
            </Button>
          </div>
        </>
      )}
    </ReactToPdf>
  );
};

export default ReactToPDF;
