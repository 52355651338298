import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";
import UserProfile from "./UserProfile";

const ExchangePanel = ({ match }) => {
  return (
    <div className="app-wrapper ">
      <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/all-users`} />
        <Route
          path={`${match.url}/all-users`}
          component={asyncComponent(() => import("./Users"))}
        />
        <Route path={`${match.url}/profile`} component={UserProfile} />
      </Switch>
    </div>
  );
};

export default ExchangePanel;
