import React, { useEffect } from "react";
import { getAttachment, renderStatus } from "constants/usefulFunctions";
import MainTable from "components/MainTable";
import moment from "moment-jalaali";
import DatePicker from "react-datepicker2";
import {
  deleteYoutubeArchiveContractRequest,
  editYoutubeChannelContractRequest,
  getYoutubeArchiveContractsRequest,
} from "redux/actions/UserProfile";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";

function YoutubeArchiveContracts({ data, user_id }) {
  const dispatch = useDispatch();
  const { archiveContracts } = useSelector((state) => state.userProfileReducer);

  const updateRow = async (newData) => {
    console.log(newData);
    dispatch(
      deleteYoutubeArchiveContractRequest({
        contract_id: newData.id,
        channel_id: data.id,
      })
    );
  };

  useEffect(() => {
    console.log(data);
    dispatch(getYoutubeArchiveContractsRequest({ channelId: data.id }));
  }, []);

  const columns = [
    {
      title: "Uploaded Contract File",
      field: "Uploaded_contract_file",
      render: ({ uploaded_contract_file }) =>
        getAttachment(uploaded_contract_file),
      editComponent: (props) => (
        <>
          <Button
            className="bg-light jr-btn jr-btn-sm text-dark my-2 "
            component="label"
          >
            <i className="zmdi zmdi-file-plus  mr-2 jr-fs-xl d-inline-flex align-middle" />
            Browse
            <input
              type="file"
              onChange={(e) => {
                props.onChange(e.target.files[0]);
              }}
              style={{ display: "none" }}
            />
          </Button>
          {props?.value?.name && (
            <div className="mt-2">
              File Name:{" "}
              <div className="text-success mr-2">{props?.value?.name}</div>
              File Size:{" "}
              <div className="text-success">
                {Math.trunc(props?.value?.size / 1024)}KB
              </div>
            </div>
          )}
        </>
      ),
      cellStyle: {
        maxWidth: 100,
      },
    },
    {
      title: "Contract Date",
      field: "contract_date",
      editComponent: (props) => (
        <DatePicker
          onChange={(date) => {
            props.onChange(date);
          }}
          isGregorian={true}
          value={moment(props.value)}
          timePicker={false}
          className="float-left text-center "
        />
      ),
    },
    {
      title: "Expire Date",
      field: "expire_date",
      editComponent: (props) => (
        <DatePicker
          onChange={(date) => {
            props.onChange(date);
          }}
          isGregorian={true}
          value={moment(props.value)}
          timePicker={false}
          className="float-left text-center "
        />
      ),
    },
    {
      title: "Description",
      field: "description",
    },
  ];

  const options = {
    actionsColumnIndex: columns.length,
  };

  return (
    <div className="card">
      <MainTable
        customOptions={options}
        isLocalization
        //   loading={loading}
        columns={columns}
        data={archiveContracts}
        title="Youtube Contracts"
        onRowDelete={(newData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              updateRow(newData);
              resolve();
            }, 500);
          })
        }
      />
    </div>
  );
}

export default YoutubeArchiveContracts;
