import React from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const AppButton = ({
  disabled,
  text,
  onClick,
  loading,
  color = 'primary',
  size = 'meduim',
  variant = 'contained',
  status,
  customClassName,
  ...props
}) => (
  <Button
    variant={variant}
    disabled={disabled}
    color={color}
    className={`app-button ${size} bg-${status} ${customClassName}`}
    onClick={onClick}
    {...props}>
    {loading ? <CircularProgress color="inherit" size={14} /> : text}
  </Button>
);

export default AppButton;
