import React, { useState } from "react";
import TextField from "@mui/material/TextField";

const CompanyInfo = ({ company }) => {
  const [values, setValues] = useState({ company });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  return (
    <div className="row">
      <h3 className="p-3">Company Info</h3>
      <div className="d-flex flex-wrap justify-content-start p-5 ">
        <div className="col-6 mt-1">
          <TextField
            label="Name"
            value={company.name}
            onChange={handleChange("name")}
            className="w-100"
          />
        </div>

        <div className="col-6 mt-1">
          <TextField
            className="w-100"
            label={"busineess_address "}
            value={company.busineess_address}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className="col-6 mt-1">
          <TextField
            className="w-100"
            label="date_of_birth"
            value={company.date_of_birth}
            onChange={handleChange("date_of_birth")}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className="col-6 mt-1">
          <TextField
            className="w-100"
            label="phone"
            value={company.phone}
            onChange={handleChange("phone")}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>

        <div className="col-6 mt-1">
          <TextField
            className="w-100"
            label="Created at"
            value={company.created_at}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default CompanyInfo;
