import React, { useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import { Language, Currencies, First_Status2 } from 'constants/constants';
import {
  createDateLookup,
  getYekpayLinkStatus,
  thousands_separators
} from 'constants/usefulFunctions';
import DefaultAvatar from 'assets/images/default/company-logo.png';
import MainTable from 'components/MainTable';
import { useDispatch, useSelector } from 'react-redux';
import { changeUserYekpayLinkStatusRequest, deleteUserYekpayLinkRequest, getUserYekpayLinksRequest } from 'redux/actions/UserProfile';
import { CHANGE_USER_YEKPAY_LINK_STATUS_STARTED, DELETE_USER_YEKPAY_LINK_STARTED, GET_USER_YEKPAY_LINKS_STARTED } from 'redux/types';

const YekpayLink = ({ user_id }) => {
  const dispatch = useDispatch();
  const { rType, userYekpayLinks } = useSelector((state) => state.userProfileReducer);
  const loading =
    rType === GET_USER_YEKPAY_LINKS_STARTED ||
    rType === CHANGE_USER_YEKPAY_LINK_STATUS_STARTED ||
    rType === DELETE_USER_YEKPAY_LINK_STARTED;  

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    dispatch(getUserYekpayLinksRequest({ user_id }));
  };

  const columns = [
    {
      title: 'Name',
      field: 'name',
      editable: 'never',
      render: (rowData) => (
        <>
          <div className="jr-profile-banner-top-left">
            <div className="jr-profile-banner-avatar">
              <Avatar className="size-50 border" alt={rowData.name} src={rowData.website_logo} />
            </div>
            <div className="jr-profile-banner-avatar-info">
              <p className="mb-0 jr-fs-lg">
                <a target="_blank" href={rowData.link}>
                  {rowData.name}
                </a>
              </p>
            </div>
          </div>
        </>
      )
    },
    {
      title: 'Amount',
      field: 'amount',
      type: 'numeric',
      editable: 'never',
      render: (rowData) =>
        rowData.amount
          ? thousands_separators(
              rowData.currency_id === 3 ? Math.trunc(rowData.amount) : rowData.amount
            )
          : 0
    },
    {
      title: 'Currency',
      field: 'currency_id',
      editable: 'never',
      render: (rowData) => Currencies[rowData.currency_id]
    },
    { title: 'Description', field: 'description', editable: 'never' },
    createDateLookup(),
    { title: 'Deleted at', field: 'deleted_at', editable: 'never' },
    {
      title: 'Language',
      field: 'lang',
      editable: 'never',
      render: (rowData) => Language[rowData.lang]
    },
    {
      title: 'Status',
      field: 'status',
      lookup: First_Status2,
      render: (rowData) => getYekpayLinkStatus(rowData.status)
    }
  ];

  const options = {
    actionsColumnIndex: columns.length
  };

  const updateRow = async (id, status) => {
    dispatch(changeUserYekpayLinkStatusRequest({ user_id, id, status }));
  };

  const deleteRow = async (user_id, id) => {
    dispatch(deleteUserYekpayLinkRequest({ user_id, id }));
  };

  return (
    <div className="card">
      <MainTable
        customOptions={options}
        isLocalization
        loading={loading}
        columns={columns}
        data={userYekpayLinks}
        title="Yekpay Links"
        onRowDelete={(oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              deleteRow(oldData.user_id, oldData.id);
              resolve();
            }, 600);
          })
        }
        onRowUpdate={(newData, oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              updateRow(oldData.id, newData.status);
              resolve();
            }, 600);
          })
        }
      />
    </div>
  );
};

export default YekpayLink;
