import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import { Currencies, First_Status3 } from "constants/constants";
import { createDateLookup, getPaylinkStatus } from "constants/usefulFunctions";
import MainTable from "components/MainTable";
import { useDispatch, useSelector } from "react-redux";
import {
  changePersonalPaylinkStatusRequest,
  deleteUserYekpayLinkRequest,
  getPersonalPaylinksRequest,
} from "redux/actions/UserProfile";
import {
  CHANGE_PERSONAL_PAYLINK_STATUS_STARTED,
  CHANGE_USER_YEKPAY_LINK_STATUS_STARTED,
  DELETE_USER_YEKPAY_LINK_STARTED,
  GET_USER_YEKPAY_LINKS_STARTED,
} from "redux/types";
import { Link } from "@mui/material/index";
import { Clear, Done } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material/index";

const PersonalPaylinks = ({ user_id }) => {
  const dispatch = useDispatch();
  const { rType, userYekpayLinks, personalPaylinks } = useSelector(
    (state) => state.userProfileReducer
  );
  const [open, setOpen] = useState(false);
  const [desc, setDesc] = useState("");
  const loading =
    rType === GET_USER_YEKPAY_LINKS_STARTED ||
    rType === CHANGE_USER_YEKPAY_LINK_STATUS_STARTED ||
    rType === DELETE_USER_YEKPAY_LINK_STARTED;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    dispatch(getPersonalPaylinksRequest({ user_id }));
  };

  const columns = [
    {
      title: "Link Image",
      field: "image",
      editable: "never",
      render: (rowData) => (
        <Avatar
          className="size-50 border"
          alt={rowData.name}
          src={rowData.image}
        />
      ),
      editable: "never",
    },
    {
      title: "Name",
      field: "title",
      render: (rowData) => (
        <Link href={rowData.link} target="_blank">
          {rowData.title}
        </Link>
      ),
    },
    {
      title: "Amount",
      field: "default_amount",
      editable: "never",
    },
    {
      title: "Min Amount",
      field: "min_amount",
      editable: "never",
    },
    {
      title: "Max Amount",
      field: "max_amount",
      editable: "never",
    },
    {
      title: "Currency",
      field: "currency_id",
      editable: "never",
      render: (rowData) => Currencies[rowData.currency_id],
    },
    { title: "Description", field: "description", editable: "never" },
    createDateLookup(),
    { title: "Deleted at", field: "deleted_at", editable: "never" },
    {
      title: "Expire Days",
      field: "expire_days",
      editable: "never",
    },
    {
      title: "Expire Count",
      field: "expire_count",
      editable: "never",
    },
    {
      title: "Contact Show",
      field: "contact_show",
      editable: "never",
      render: (rowData) =>
        rowData.contact_show ? (
          <Done className="text-green" />
        ) : (
          <Clear className="text-red" />
        ),
    },
    {
      title: "Business Description",
      field: "business_description",
      editable: "never",
    },
    {
      title: "Admin Description",
      field: "admin_description",
      editable: "never",
    },
    {
      title: "Status",
      field: "status",
      lookup: First_Status3,
      render: (rowData) => getPaylinkStatus(rowData.status),
    },
  ];

  const options = {
    actionsColumnIndex: columns.length,
  };

  const updateRow = async (id, status) => {
    switch (status) {
      case "Approved":
      case "Pending":
        dispatch(
          changePersonalPaylinkStatusRequest({
            user_id,
            id,
            status,
            admin_description: null,
          })
        );
        break;
      default:
        setOpen({ id, status });
        break;
    }
  };

  const deleteRow = async (user_id, id) => {
    dispatch(deleteUserYekpayLinkRequest({ user_id, id }));
  };

  const renderDescription = () => {
    return (
      <Dialog open={open}>
        <DialogTitle>Description</DialogTitle>
        <DialogContent>
          <TextField
            label="Description"
            fullWidth
            rows={5}
            multiline
            onChange={(e) => setDesc(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            fullWidth
            onClick={() =>
              dispatch(
                changePersonalPaylinkStatusRequest({
                  user_id,
                  id: open.id,
                  status: open.status,
                  admin_description: desc,
                  setOpen,
                })
              )
            }
            disabled={rType === CHANGE_PERSONAL_PAYLINK_STATUS_STARTED}
          >
            Submit
          </Button>
          <Button variant="outlined" fullWidth onClick={() => setOpen(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <div className="card">
      <MainTable
        customOptions={options}
        isLocalization
        loading={loading}
        columns={columns}
        data={personalPaylinks}
        title="Personal Paylinks"
        // onRowDelete={(oldData) =>
        //   new Promise((resolve) => {
        //     setTimeout(() => {
        //       deleteRow(oldData.user_id, oldData.id);
        //       resolve();
        //     }, 600);
        //   })
        // }
        onRowUpdate={(newData, oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              updateRow(oldData.id, newData.status);
              resolve();
            }, 600);
          })
        }
      />
      {renderDescription()}
    </div>
  );
};

export default PersonalPaylinks;
