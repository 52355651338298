import React from "react";
import TextField from "@mui/material/TextField";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
  root: {
    width: ({ fullWidth }) => (fullWidth ? "100%" : 300),
    margin: 10,
  },
});

const AppTextInput = ({
  label,
  value,
  onChange,
  variant = "outlined",
  fullWidth = false,
  readOnly = false,
  shrink = true,
  helperText,
  error = false,
  multiline = false,
  rows = 5,
  margin = "normal",
  color = "primary",
  dir = "rtl",
  disabled,
  type = "text",
  ...otherProps
}) => {
  const classes = useStyles({ fullWidth });

  return (
    <TextField
      label={label}
      className={classes.root}
      value={value}
      onChange={onChange}
      disabled={disabled}
      variant={variant}
      helperText={helperText}
      error={error}
      dir={dir}
      multiline={multiline}
      margin={margin}
      fullWidth={fullWidth}
      color={color}
      type={type}
      rows={rows}
      InputLabelProps={{
        shrink,
        readOnly,
      }}
      {...otherProps}
    />
  );
};

export default AppTextInput;
