import React, { useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getAyandeBalanceRequest,
  getAyandeBalanceSuccess,
  getParsianBalanceRequest,
  getParsianBalanceSuccess,
  getPasargadBalanceRequest,
  getPasargadBalanceSuccess,
} from "redux/actions/Main";
import {
  GET_AYANDE_BALANCE_STARTED,
  GET_BANKS_REPORT_STARTED,
  GET_PARSIAN_BALANCE_STARTED,
  GET_PASARGAD_BALANCE_STARTED,
} from "redux/types";
import DataCard from "components/DataCard";

import MuiTable from "components/MuiTable";
import withTable from "HOC/withTable/withTable";
import { API_GET_PASARGAD_REPORT } from "constants/constants";
import {
  FormControl,
 
  Grid,
  OutlinedInput,
 
} from "@mui/material";
import AppDropDown from "components/AppDropDown/index";
import DatePicker from "react-datepicker2";
import { InputLabel } from "../../../../../../../node_modules/@mui/material/index";
import { getBanksReportRequest } from "redux/actions/Financial";
import AppButton from "components/AppButton/index";
import moment from "../../../../../../../node_modules/moment/moment";

const Banks = ({ data }) => {
  const dispatch = useDispatch();

  const [bank, setBank] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const ParsianBalance = async () => {
    dispatch(getParsianBalanceRequest());
  };

  const AyandeBalance = async () => {
    dispatch(getAyandeBalanceRequest());
  };

  const PasargadBalance = async () => {
    dispatch(getPasargadBalanceRequest());
  };

  useEffect(() => {
    return () => {
      dispatch(getPasargadBalanceSuccess({ pasargadBalance: 0 }));
      dispatch(getAyandeBalanceSuccess({ ayandeBalance: 0 }));
      dispatch(getParsianBalanceSuccess({ parsianBalance: 0 }));
    };
  }, []);

  const {
    rType,
    parsianBalance,
    ayandeBalance,
    pasargadBalance,
    banksReport,
    financialRType,
  } = useSelector(
    (state) => ({
      rType: state.mainReducer?.rType,
      parsianBalance: state.mainReducer?.parsianBalance,
      ayandeBalance: state.mainReducer?.ayandeBalance,
      pasargadBalance: state.mainReducer?.pasargadBalance,
      banksReport: state.financialReducer?.banksReport,
      financialRType: state.financialReducer?.rType,
    }),
    shallowEqual
  );
  const dataMetrics = useMemo(
    () => [
      {
        title: parsianBalance + " IRR",
        subTitle: "Parsian Bank",
        background: "primary",
        loading: rType === GET_PARSIAN_BALANCE_STARTED,
        onClick: ParsianBalance,
      },
      {
        title: ayandeBalance + " IRR",
        subTitle: "Ayandeh Bank ",
        background: "warning",
        loading: rType === GET_AYANDE_BALANCE_STARTED,
        onClick: AyandeBalance,
      },
      {
        title: pasargadBalance + " IRR",
        subTitle: "Pasargad Bank",
        background: "success",
        loading: rType === GET_PASARGAD_BALANCE_STARTED,
        onClick: PasargadBalance,
      },
    ],
    [ayandeBalance, parsianBalance, pasargadBalance, rType]
  );

  const columns = [
    {
      field: "fullDate",
      headerName: "Date",
      renderCell: ({ fullDate }) =>
        `${fullDate.slice(0, 4)}/${fullDate.slice(4, 6)}/${fullDate.slice(
          6,
          8
        )}`,
    },
    {
      field: "ibanInformationMap",
      headerName: "Destination Account",
      renderCell: ({ ibanInformationMap }) =>
        ibanInformationMap.destinationIban,
    },
    {
      field: "name",
      headerName: "Name",
      renderCell: ({ ibanInformationMap }) => ibanInformationMap.name,
    },
    {
      field: "type",
      headerName: "Type",
      renderCell: ({ operationCode }) => operationCode.message,
    },
  ];

  return (
    <>
      <div className="jr-card d-flex flex-row">
        {dataMetrics.map((data) => (
          <DataCard key={data.subTitle} {...data} />
        ))}
      </div>
      <MuiTable
        // isLoading={loading}
        columns={columns}
        rows={banksReport}
        title="Statement"
        header={
          <Grid container spacing={3} sx={{ my: 3, alignItems: "center" }}>
            <Grid item>
              <AppDropDown
                label="Banks"
                // value={values.paymentType}
                name="paymentType"
                onChange={(e) => setBank(e.target.value)}
                items={[
                  { label: "پارسیان", value: "Parsian" },
                  { label: "آینده", value: "Ayandeh" },
                  { label: "پاسارگاد", value: "Pasargad" },
                ]}
                // error={error?.paymentType}
              />
            </Grid>
            <Grid item>
              <FormControl margin="normal">
                <InputLabel variant="outlined" shrink>
                  From
                </InputLabel>
                <OutlinedInput
                  // value={values.textmask}
                  onChange={(e) => setFrom(moment(e).format("YYYY-MM-DD"))}
                  // name="textmask"
                  // id="formatted-text-mask-input"
                  inputComponent={DatePicker}
                  variant="outlined"
                  label="From"
                  notched
                  margin="normal"
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl margin="normal">
                <InputLabel variant="outlined" shrink>
                  To
                </InputLabel>
                <OutlinedInput
                  // value={values.textmask}
                  onChange={(e) => setTo(moment(e).format("YYYY-MM-DD"))}
                  // name="textmask"
                  // id="formatted-text-mask-input"
                  inputComponent={DatePicker}
                  variant="outlined"
                  label="To"
                  notched
                />
              </FormControl>
            </Grid>
            <Grid item>
              <AppButton
                text="Search"
                loading={financialRType === GET_BANKS_REPORT_STARTED}
                onClick={() =>
                  dispatch(getBanksReportRequest({ bank, to, from }))
                }
              />
            </Grid>
          </Grid>
        }
      />
    </>
  );
};

export default withTable(Banks, {
  title: "Banks Report",
  apiAddress: API_GET_PASARGAD_REPORT,
  hasDatePicker: false,
});
