import React from "react";

const ChartCard = ({ chartProperties, children }) => {
  const { prize, title, styleName, desc, bgColor, percent } = chartProperties;
  return (
    <div className="col-lg-3 col-md-6 col-sm-6">
      <div className=" jr-card jr-card-full">
        <div className={`jr-fillchart bg-${bgColor} jr-overlay-fillchart`}>
          <div className="card-title mb-3">{title}</div>
          {children}
          <div className="jr-fillchart-content">
            <div style={{ fontSize: "18px" }} className=" mb-4 ">
              {title}
            </div>
            <h2 className="mb-2 jr-fs-xl jr-font-weight-medium">{prize}</h2>
            {percent > 0}
            <p className="mb-0 jr-fs-sm">
              <span
                className={`jr-font-weight-medium jr-fs-md jr-chart-${styleName}`}
              >
                {percent}
                {percent > 0 ? (
                  <i className="zmdi zmdi-caret-down jr-fs-sm d-none" />
                ) : null}
              </span>
              {desc}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChartCard;
