import React from "react";
import { withRouter, Route, Switch } from "react-router-dom";
import TransactionReceipt from "./TransactionReceipt";
import Transactions from "./Transactions";

function index({ match, user_id }) {
  return (
    <>
      <Switch>
        <Route
          exact
          path={`${match.url}/:id`}
          component={() => <TransactionReceipt />}
        />
        <Route
          path={`${match.url}`}
          component={() => <Transactions user_id={user_id} />}
        />
      </Switch>
    </>
  );
}

export default withRouter(index);
