import {
  GET_USER_PROFILE_FAILURE,
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_STARTED,
  GET_USER_COMMENTS_REQUEST,
  GET_USER_COMMENTS_STARTED,
  GET_USER_COMMENTS_SUCCESS,
  GET_USER_COMMENTS_FAILURE,
  DELETE_USER_COMMENT_REQUEST,
  CREATE_USER_COMMENT_REQUEST,
  DELETE_USER_COMMENT_STARTED,
  DELETE_USER_COMMENT_SUCCESS,
  DELETE_USER_COMMENT_FAILURE,
  CREATE_USER_COMMENT_FAILURE,
  CREATE_USER_COMMENT_SUCCESS,
  CREATE_USER_COMMENT_STARTED,
  CHANGE_USER_STATUS_REQUEST,
  CHANGE_USER_STATUS_STARTED,
  CHANGE_USER_STATUS_SUCCESS,
  CHANGE_USER_STATUS_FAILURE,
  GET_USER_WALLETS_REQUEST,
  GET_USER_WALLETS_STARTED,
  GET_USER_WALLETS_SUCCESS,
  GET_USER_WALLETS_FAILURE,
  CREATE_USER_WALLET_FAILURE,
  CREATE_USER_WALLET_SUCCESS,
  CREATE_USER_WALLET_STARTED,
  CREATE_USER_WALLET_REQUEST,
  DELETE_USER_WALLET_REQUEST,
  DELETE_USER_WALLET_STARTED,
  DELETE_USER_WALLET_SUCCESS,
  DELETE_USER_WALLET_FAILURE,
  GET_USER_FINANCE_REQUEST,
  GET_USER_FINANCE_STARTED,
  GET_USER_FINANCE_SUCCESS,
  GET_USER_FINANCE_FAILURE,
  GET_USER_ADDRESS_REQUEST,
  GET_USER_ADDRESS_STARTED,
  GET_USER_ADDRESS_SUCCESS,
  GET_USER_ADDRESS_FAILURE,
  CHANGE_USER_ADDRESS_STATUS_REQUEST,
  CHANGE_USER_ADDRESS_STATUS_STARTED,
  CHANGE_USER_ADDRESS_STATUS_SUCCESS,
  CHANGE_USER_ADDRESS_STATUS_FAILURE,
  GET_USER_PLANS_REQUEST,
  GET_USER_PLANS_STARTED,
  GET_USER_PLANS_SUCCESS,
  GET_USER_PLANS_FAILURE,
  CREATE_USER_PLAN_REQUEST,
  CREATE_USER_PLAN_STARTED,
  CREATE_USER_PLAN_SUCCESS,
  CREATE_USER_PLAN_FAILURE,
  GET_USER_TRANSACTIONS_REQUEST,
  GET_USER_TRANSACTIONS_STARTED,
  GET_USER_TRANSACTIONS_SUCCESS,
  GET_USER_TRANSACTIONS_FAILURE,
  GET_USER_FAILED_TRANSACTIONS_REQUEST,
  GET_USER_FAILED_TRANSACTIONS_STARTED,
  GET_USER_FAILED_TRANSACTIONS_SUCCESS,
  GET_USER_FAILED_TRANSACTIONS_FAILURE,
  GET_USER_ITSAAZ_REQUEST,
  GET_USER_ITSAAZ_STARTED,
  GET_USER_ITSAAZ_SUCCESS,
  GET_USER_ITSAAZ_FAILURE,
  GET_USER_COMPANY_REQUEST,
  GET_USER_COMPANY_STARTED,
  GET_USER_COMPANY_SUCCESS,
  GET_USER_COMPANY_FAILURE,
  GET_USER_BANK_ACCOUNTS_REQUEST,
  GET_USER_BANK_ACCOUNTS_STARTED,
  GET_USER_BANK_ACCOUNTS_SUCCESS,
  GET_USER_BANK_ACCOUNTS_FAILURE,
  GET_USER_GATEWAYS_REQUEST,
  GET_USER_GATEWAYS_STARTED,
  GET_USER_GATEWAYS_SUCCESS,
  GET_USER_GATEWAYS_FAILURE,
  CHANGE_USER_GATEWAY_STATUS_REQUEST,
  CHANGE_USER_GATEWAY_STATUS_STARTED,
  CHANGE_USER_GATEWAY_STATUS_SUCCESS,
  CHANGE_USER_GATEWAY_STATUS_FAILURE,
  GET_USER_YEKPAY_LINKS_REQUEST,
  GET_USER_YEKPAY_LINKS_STARTED,
  GET_USER_YEKPAY_LINKS_SUCCESS,
  GET_USER_YEKPAY_LINKS_FAILURE,
  CHANGE_USER_YEKPAY_LINK_STATUS_REQUEST,
  CHANGE_USER_YEKPAY_LINK_STATUS_STARTED,
  CHANGE_USER_YEKPAY_LINK_STATUS_SUCCESS,
  CHANGE_USER_YEKPAY_LINK_STATUS_FAILURE,
  DELETE_USER_YEKPAY_LINK_REQUEST,
  DELETE_USER_YEKPAY_LINK_STARTED,
  DELETE_USER_YEKPAY_LINK_SUCCESS,
  DELETE_USER_YEKPAY_LINK_FAILURE,
  GET_USER_TICKETS_REQUEST,
  GET_USER_TICKETS_STARTED,
  GET_USER_TICKETS_SUCCESS,
  GET_USER_TICKETS_FAILURE,
  CHANGE_USER_BANK_ACCOUNT_STATUS_REQUEST,
  CHANGE_USER_BANK_ACCOUNT_STATUS_STARTED,
  CHANGE_USER_BANK_ACCOUNT_STATUS_SUCCESS,
  CHANGE_USER_BANK_ACCOUNT_STATUS_FAILURE,
  DELETE_USER_BANK_ACCOUNT_REQUEST,
  DELETE_USER_BANK_ACCOUNT_STARTED,
  DELETE_USER_BANK_ACCOUNT_SUCCESS,
  DELETE_USER_BANK_ACCOUNT_FAILURE,
  CHANGE_USER_COMPANY_STATUS_REQUEST,
  CHANGE_USER_COMPANY_STATUS_STARTED,
  CHANGE_USER_COMPANY_STATUS_SUCCESS,
  CHANGE_USER_COMPANY_STATUS_FAILURE,
  RESTORE_USER_BANK_ACCOUNT_REQUEST,
  RESTORE_USER_BANK_ACCOUNT_STARTED,
  RESTORE_USER_BANK_ACCOUNT_SUCCESS,
  RESTORE_USER_BANK_ACCOUNT_FAILURE,
  GET_USER_LOGS_REQUEST,
  GET_USER_LOGS_STARTED,
  GET_USER_LOGS_SUCCESS,
  GET_USER_LOGS_FAILURE,
  CREATE_PAYOUT_REQUEST,
  CREATE_PAYOUT_STARTED,
  CREATE_PAYOUT_SUCCESS,
  CREATE_PAYOUT_FAILURE,
  GET_USER_TEXT_MESSAGES_REQUEST,
  GET_USER_TEXT_MESSAGES_STARTED,
  GET_USER_TEXT_MESSAGES_SUCCESS,
  GET_USER_TEXT_MESSAGES_FAILURE,
  GET_USER_CHANGE_LOGS_REQUEST,
  GET_USER_CHANGE_LOGS_STARTED,
  GET_USER_CHANGE_LOGS_SUCCESS,
  GET_USER_CHANGE_LOGS_FAILURE,
  GET_USER_YOUTUBE_CHANNELS_REQUEST,
  GET_USER_YOUTUBE_CHANNELS_STARTED,
  GET_USER_YOUTUBE_CHANNELS_SUCCESS,
  GET_USER_YOUTUBE_CHANNELS_FAILURE,
  EDIT_YOUTUBE_CHANNEL_INFO_FAILURE,
  EDIT_YOUTUBE_CHANNEL_INFO_SUCCESS,
  EDIT_YOUTUBE_CHANNEL_INFO_STARTED,
  EDIT_YOUTUBE_CHANNEL_INFO_REQUEST,
  EDIT_YOUTUBE_CHANNEL_CONTRACT_REQUEST,
  EDIT_YOUTUBE_CHANNEL_CONTRACT_STARTED,
  EDIT_YOUTUBE_CHANNEL_CONTRACT_SUCCESS,
  EDIT_YOUTUBE_CHANNEL_CONTRACT_FAILURE,
  EDIT_USER_PROFILE_REQUEST,
  EDIT_USER_PROFILE_STARTED,
  EDIT_USER_PROFILE_SUCCESS,
  EDIT_USER_PROFILE_FAILURE,
  CLEAR_USER_PROFILE_REQUEST,
  CLEAR_USER_PROFILE_SUCCESS,
  CREATE_USER_BANK_ACCOUNT_REQUEST,
  CREATE_USER_BANK_ACCOUNT_STARTED,
  CREATE_USER_BANK_ACCOUNT_SUCCESS,
  CREATE_USER_BANK_ACCOUNT_FAILURE,
  CREATE_YOUTUBE_CHANNEL_CONTRACT_REQUEST,
  CREATE_YOUTUBE_CHANNEL_CONTRACT_STARTED,
  CREATE_YOUTUBE_CHANNEL_CONTRACT_SUCCESS,
  CREATE_YOUTUBE_CHANNEL_CONTRACT_FAILURE,
  TOPUP_USER_REQUEST,
  TOPUP_USER_STARTED,
  TOPUP_USER_SUCCESS,
  TOPUP_USER_FAILURE,
  GET_USER_EXCHANGE_DETAIL_REQUEST,
  GET_USER_EXCHANGE_DETAIL_STARTED,
  GET_USER_EXCHANGE_DETAIL_SUCCESS,
  GET_USER_EXCHANGE_DETAIL_FAILURE,
  EXCHANGE_USER_CURRENCY_REQUEST,
  EXCHANGE_USER_CURRENCY_STARTED,
  EXCHANGE_USER_CURRENCY_SUCCESS,
  EXCHANGE_USER_CURRENCY_FAILURE,
  GET_YOUTUBE_CHANNEL_COMMENTS_REQUEST,
  GET_YOUTUBE_CHANNEL_COMMENTS_STARTED,
  GET_YOUTUBE_CHANNEL_COMMENTS_SUCCESS,
  GET_YOUTUBE_CHANNEL_COMMENTS_FAILURE,
  CREATE_YOUTUBE_CHANNEL_COMMENT_REQUEST,
  CREATE_YOUTUBE_CHANNEL_COMMENT_STARTED,
  CREATE_YOUTUBE_CHANNEL_COMMENT_SUCCESS,
  CREATE_YOUTUBE_CHANNEL_COMMENT_FAILURE,
  DELETE_YOUTUBE_CHANNEL_COMMENT_REQUEST,
  DELETE_YOUTUBE_CHANNEL_COMMENT_STARTED,
  DELETE_YOUTUBE_CHANNEL_COMMENT_SUCCESS,
  DELETE_YOUTUBE_CHANNEL_COMMENT_FAILURE,
  CHANGE_BUSINESS_CATEGORY_REQUEST,
  CHANGE_BUSINESS_CATEGORY_STARTED,
  CHANGE_BUSINESS_CATEGORY_SUCCESS,
  CHANGE_BUSINESS_CATEGORY_FAILURE,
  ADD_YOUTUBE_ARCHIVE_CONTRACT_REQUEST,
  ADD_YOUTUBE_ARCHIVE_CONTRACT_STARTED,
  ADD_YOUTUBE_ARCHIVE_CONTRACT_SUCCESS,
  ADD_YOUTUBE_ARCHIVE_CONTRACT_FAILURE,
  GET_YOUTUBE_ARCHIVE_CONTRACTS_REQUEST,
  GET_YOUTUBE_ARCHIVE_CONTRACTS_STARTED,
  GET_YOUTUBE_ARCHIVE_CONTRACTS_SUCCESS,
  GET_YOUTUBE_ARCHIVE_CONTRACTS_FAILURE,
  DELETE_YOUTUBE_ARCHIVE_CONTRACT_REQUEST,
  DELETE_YOUTUBE_ARCHIVE_CONTRACT_STARTED,
  DELETE_YOUTUBE_ARCHIVE_CONTRACT_SUCCESS,
  DELETE_YOUTUBE_ARCHIVE_CONTRACT_FAILURE,
  GET_USER_UID_REQUEST,
  GET_USER_UID_STARTED,
  GET_USER_UID_SUCCESS,
  GET_USER_UID_FAILURE,
  GET_PERSONAL_PAYLINKS_REQUEST,
  GET_PERSONAL_PAYLINKS_STARTED,
  GET_PERSONAL_PAYLINKS_SUCCESS,
  GET_PERSONAL_PAYLINKS_FAILURE,
  GET_PRODUCT_PAYLINKS_REQUEST,
  GET_PRODUCT_PAYLINKS_STARTED,
  GET_PRODUCT_PAYLINKS_SUCCESS,
  GET_PRODUCT_PAYLINKS_FAILURE,
  CHANGE_PERSONAL_PAYLINK_STATUS_REQUEST,
  CHANGE_PERSONAL_PAYLINK_STATUS_STARTED,
  CHANGE_PERSONAL_PAYLINK_STATUS_SUCCESS,
  CHANGE_PERSONAL_PAYLINK_STATUS_FAILURE,
  CHANGE_PRODUCT_PAYLINK_STATUS_REQUEST,
  CHANGE_PRODUCT_PAYLINK_STATUS_STARTED,
  CHANGE_PRODUCT_PAYLINK_STATUS_SUCCESS,
  CHANGE_PRODUCT_PAYLINK_STATUS_FAILURE,
} from "../types";
// --------------------------------------------------------------------------------
export const getUserProfileRequest = (data) => ({
  type: GET_USER_PROFILE_REQUEST,
  payload: data,
});

export const getUserProfileStarted = () => ({
  type: GET_USER_PROFILE_STARTED,
});

export const getUserProfileSuccess = (data) => ({
  type: GET_USER_PROFILE_SUCCESS,
  payload: data,
});

export const getUserProfileFailure = (data) => ({
  type: GET_USER_PROFILE_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const clearUserProfileRequest = () => ({
  type: CLEAR_USER_PROFILE_REQUEST,
});

export const clearUserProfileSuccess = (data) => ({
  type: CLEAR_USER_PROFILE_SUCCESS,
  payload: data,
});
// --------------------------------------------------------------------------------
export const editUserProfileRequest = (data) => ({
  type: EDIT_USER_PROFILE_REQUEST,
  payload: data,
});

export const editUserProfileStarted = () => ({
  type: EDIT_USER_PROFILE_STARTED,
});

export const editUserProfileSuccess = (data) => ({
  type: EDIT_USER_PROFILE_SUCCESS,
  payload: data,
});

export const editUserProfileFailure = (data) => ({
  type: EDIT_USER_PROFILE_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getUserItsaazRequest = (data) => ({
  type: GET_USER_ITSAAZ_REQUEST,
  payload: data,
});

export const getUserItsaazStarted = () => ({
  type: GET_USER_ITSAAZ_STARTED,
});

export const getUserItsaazSuccess = (data) => ({
  type: GET_USER_ITSAAZ_SUCCESS,
  payload: data,
});

export const getUserItsaazFailure = (data) => ({
  type: GET_USER_ITSAAZ_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getUserUIdRequest = (data) => ({
  type: GET_USER_UID_REQUEST,
  payload: data,
});

export const getUserUIdStarted = () => ({
  type: GET_USER_UID_STARTED,
});

export const getUserUIdSuccess = (data) => ({
  type: GET_USER_UID_SUCCESS,
  payload: data,
});

export const getUserUIdFailure = (data) => ({
  type: GET_USER_UID_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const changeUserStatusRequest = (data) => ({
  type: CHANGE_USER_STATUS_REQUEST,
  payload: data,
});

export const changeUserStatusStarted = () => ({
  type: CHANGE_USER_STATUS_STARTED,
});

export const changeUserStatusSuccess = () => ({
  type: CHANGE_USER_STATUS_SUCCESS,
});

export const changeUserStatusFailure = (data) => ({
  type: CHANGE_USER_STATUS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const changeBusinessCategoryRequest = (data) => ({
  type: CHANGE_BUSINESS_CATEGORY_REQUEST,
  payload: data,
});

export const changeBusinessCategoryStarted = () => ({
  type: CHANGE_BUSINESS_CATEGORY_STARTED,
});

export const changeBusinessCategorySuccess = () => ({
  type: CHANGE_BUSINESS_CATEGORY_SUCCESS,
});

export const changeBusinessCategoryFailure = (data) => ({
  type: CHANGE_BUSINESS_CATEGORY_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getUserCommentsRequest = (data) => ({
  type: GET_USER_COMMENTS_REQUEST,
  payload: data,
});

export const getUserCommentsStarted = () => ({
  type: GET_USER_COMMENTS_STARTED,
});

export const getUserCommentsSuccess = (data) => ({
  type: GET_USER_COMMENTS_SUCCESS,
  payload: data,
});

export const getUserCommentsFailure = (data) => ({
  type: GET_USER_COMMENTS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const createUserCommentRequest = (data) => ({
  type: CREATE_USER_COMMENT_REQUEST,
  payload: data,
});

export const createUserCommentStarted = () => ({
  type: CREATE_USER_COMMENT_STARTED,
});

export const createUserCommentSuccess = () => ({
  type: CREATE_USER_COMMENT_SUCCESS,
});

export const createUserCommentFailure = (data) => ({
  type: CREATE_USER_COMMENT_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const deleteUserCommentRequest = (data) => ({
  type: DELETE_USER_COMMENT_REQUEST,
  payload: data,
});

export const deleteUserCommentStarted = () => ({
  type: DELETE_USER_COMMENT_STARTED,
});

export const deleteUserCommentSuccess = () => ({
  type: DELETE_USER_COMMENT_SUCCESS,
});

export const deleteUserCommentFailure = (data) => ({
  type: DELETE_USER_COMMENT_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getUserWalletsRequest = (data) => ({
  type: GET_USER_WALLETS_REQUEST,
  payload: data,
});

export const getUserWalletsStarted = () => ({
  type: GET_USER_WALLETS_STARTED,
});

export const getUserWalletsSuccess = (data) => ({
  type: GET_USER_WALLETS_SUCCESS,
  payload: data,
});

export const getUserWalletsFailure = (data) => ({
  type: GET_USER_WALLETS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const createUserWalletRequest = (data) => ({
  type: CREATE_USER_WALLET_REQUEST,
  payload: data,
});

export const createUserWalletStarted = () => ({
  type: CREATE_USER_WALLET_STARTED,
});

export const createUserWalletSuccess = () => ({
  type: CREATE_USER_WALLET_SUCCESS,
});

export const createUserWalletFailure = (data) => ({
  type: CREATE_USER_WALLET_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const deleteUserWalletRequest = (data) => ({
  type: DELETE_USER_WALLET_REQUEST,
  payload: data,
});

export const deleteUserWalletStarted = () => ({
  type: DELETE_USER_WALLET_STARTED,
});

export const deleteUserWalletSuccess = () => ({
  type: DELETE_USER_WALLET_SUCCESS,
});

export const deleteUserWalletFailure = (data) => ({
  type: DELETE_USER_WALLET_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getUserFinanceRequest = (data) => ({
  type: GET_USER_FINANCE_REQUEST,
  payload: data,
});

export const getUserFinanceStarted = () => ({
  type: GET_USER_FINANCE_STARTED,
});

export const getUserFinanceSuccess = (data) => ({
  type: GET_USER_FINANCE_SUCCESS,
  payload: data,
});

export const getUserFinanceFailure = (data) => ({
  type: GET_USER_FINANCE_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getUserYoutubeChannelsRequest = (data) => ({
  type: GET_USER_YOUTUBE_CHANNELS_REQUEST,
  payload: data,
});

export const getUserYoutubeChannelsStarted = () => ({
  type: GET_USER_YOUTUBE_CHANNELS_STARTED,
});

export const getUserYoutubeChannelsSuccess = (data) => ({
  type: GET_USER_YOUTUBE_CHANNELS_SUCCESS,
  payload: data,
});

export const getUserYoutubeChannelsFailure = (data) => ({
  type: GET_USER_YOUTUBE_CHANNELS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const editYoutubeChannelInfoRequest = (data) => ({
  type: EDIT_YOUTUBE_CHANNEL_INFO_REQUEST,
  payload: data,
});

export const editYoutubeChannelInfoStarted = () => ({
  type: EDIT_YOUTUBE_CHANNEL_INFO_STARTED,
});

export const editYoutubeChannelInfoSuccess = () => ({
  type: EDIT_YOUTUBE_CHANNEL_INFO_SUCCESS,
});

export const editYoutubeChannelInfoFailure = (data) => ({
  type: EDIT_YOUTUBE_CHANNEL_INFO_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const addYoutubeArchiveContractRequest = (data) => ({
  type: ADD_YOUTUBE_ARCHIVE_CONTRACT_REQUEST,
  payload: data,
});

export const addYoutubeArchiveContractStarted = () => ({
  type: ADD_YOUTUBE_ARCHIVE_CONTRACT_STARTED,
});

export const addYoutubeArchiveContractSuccess = () => ({
  type: ADD_YOUTUBE_ARCHIVE_CONTRACT_SUCCESS,
});

export const addYoutubeArchiveContractFailure = (data) => ({
  type: ADD_YOUTUBE_ARCHIVE_CONTRACT_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getYoutubeArchiveContractsRequest = (data) => ({
  type: GET_YOUTUBE_ARCHIVE_CONTRACTS_REQUEST,
  payload: data,
});

export const getYoutubeArchiveContractsStarted = () => ({
  type: GET_YOUTUBE_ARCHIVE_CONTRACTS_STARTED,
});

export const getYoutubeArchiveContractsSuccess = (data) => ({
  type: GET_YOUTUBE_ARCHIVE_CONTRACTS_SUCCESS,
  payload: data,
});

export const getYoutubeArchiveContractsFailure = (data) => ({
  type: GET_YOUTUBE_ARCHIVE_CONTRACTS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const deleteYoutubeArchiveContractRequest = (data) => ({
  type: DELETE_YOUTUBE_ARCHIVE_CONTRACT_REQUEST,
  payload: data,
});

export const deleteYoutubeArchiveContractStarted = () => ({
  type: DELETE_YOUTUBE_ARCHIVE_CONTRACT_STARTED,
});

export const deleteYoutubeArchiveContractSuccess = (data) => ({
  type: DELETE_YOUTUBE_ARCHIVE_CONTRACT_SUCCESS,
  payload: data,
});

export const deleteYoutubeArchiveContractFailure = (data) => ({
  type: DELETE_YOUTUBE_ARCHIVE_CONTRACT_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const createYoutubeChannelContractRequest = (data) => ({
  type: CREATE_YOUTUBE_CHANNEL_CONTRACT_REQUEST,
  payload: data,
});

export const createYoutubeChannelContractStarted = () => ({
  type: CREATE_YOUTUBE_CHANNEL_CONTRACT_STARTED,
});

export const createYoutubeChannelContractSuccess = () => ({
  type: CREATE_YOUTUBE_CHANNEL_CONTRACT_SUCCESS,
});

export const createYoutubeChannelContractFailure = (data) => ({
  type: CREATE_YOUTUBE_CHANNEL_CONTRACT_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const editYoutubeChannelContractRequest = (data) => ({
  type: EDIT_YOUTUBE_CHANNEL_CONTRACT_REQUEST,
  payload: data,
});

export const editYoutubeChannelContractStarted = () => ({
  type: EDIT_YOUTUBE_CHANNEL_CONTRACT_STARTED,
});

export const editYoutubeChannelContractSuccess = () => ({
  type: EDIT_YOUTUBE_CHANNEL_CONTRACT_SUCCESS,
});

export const editYoutubeChannelContractFailure = (data) => ({
  type: EDIT_YOUTUBE_CHANNEL_CONTRACT_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getYoutubeChannelCommentsRequest = (data) => ({
  type: GET_YOUTUBE_CHANNEL_COMMENTS_REQUEST,
  payload: data,
});

export const getYoutubeChannelCommentsStarted = () => ({
  type: GET_YOUTUBE_CHANNEL_COMMENTS_STARTED,
});

export const getYoutubeChannelCommentsSuccess = (data) => ({
  type: GET_YOUTUBE_CHANNEL_COMMENTS_SUCCESS,
  payload: data,
});

export const getYoutubeChannelCommentsFailure = (data) => ({
  type: GET_YOUTUBE_CHANNEL_COMMENTS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const createYoutubeChannelCommentRequest = (data) => ({
  type: CREATE_YOUTUBE_CHANNEL_COMMENT_REQUEST,
  payload: data,
});

export const createYoutubeChannelCommentStarted = () => ({
  type: CREATE_YOUTUBE_CHANNEL_COMMENT_STARTED,
});

export const createYoutubeChannelCommentSuccess = () => ({
  type: CREATE_YOUTUBE_CHANNEL_COMMENT_SUCCESS,
});

export const createYoutubeChannelCommentFailure = (data) => ({
  type: CREATE_YOUTUBE_CHANNEL_COMMENT_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const deleteYoutubeChannelCommentRequest = (data) => ({
  type: DELETE_YOUTUBE_CHANNEL_COMMENT_REQUEST,
  payload: data,
});

export const deleteYoutubeChannelCommentStarted = () => ({
  type: DELETE_YOUTUBE_CHANNEL_COMMENT_STARTED,
});

export const deleteYoutubeChannelCommentSuccess = () => ({
  type: DELETE_YOUTUBE_CHANNEL_COMMENT_SUCCESS,
});

export const deleteYoutubeChannelCommentFailure = (data) => ({
  type: DELETE_YOUTUBE_CHANNEL_COMMENT_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getUserCompanyRequest = (data) => ({
  type: GET_USER_COMPANY_REQUEST,
  payload: data,
});

export const getUserCompanyStarted = () => ({
  type: GET_USER_COMPANY_STARTED,
});

export const getUserCompanySuccess = (data) => ({
  type: GET_USER_COMPANY_SUCCESS,
  payload: data,
});

export const getUserCompanyFailure = (data) => ({
  type: GET_USER_COMPANY_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const changeUserCompanyStatusRequest = (data) => ({
  type: CHANGE_USER_COMPANY_STATUS_REQUEST,
  payload: data,
});

export const changeUserCompanyStatusStarted = () => ({
  type: CHANGE_USER_COMPANY_STATUS_STARTED,
});

export const changeUserCompanyStatusSuccess = () => ({
  type: CHANGE_USER_COMPANY_STATUS_SUCCESS,
});

export const changeUserCompanyStatusFailure = (data) => ({
  type: CHANGE_USER_COMPANY_STATUS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getUserAddressRequest = (data) => ({
  type: GET_USER_ADDRESS_REQUEST,
  payload: data,
});

export const getUserAddressStarted = () => ({
  type: GET_USER_ADDRESS_STARTED,
});

export const getUserAddressSuccess = (data) => ({
  type: GET_USER_ADDRESS_SUCCESS,
  payload: data,
});

export const getUserAddressFailure = (data) => ({
  type: GET_USER_ADDRESS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const changeUserAddressStatusRequest = (data) => ({
  type: CHANGE_USER_ADDRESS_STATUS_REQUEST,
  payload: data,
});

export const changeUserAddressStatusStarted = () => ({
  type: CHANGE_USER_ADDRESS_STATUS_STARTED,
});

export const changeUserAddressStatusSuccess = () => ({
  type: CHANGE_USER_ADDRESS_STATUS_SUCCESS,
});

export const changeUserAddressStatusFailure = (data) => ({
  type: CHANGE_USER_ADDRESS_STATUS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getUserPlansRequest = (data) => ({
  type: GET_USER_PLANS_REQUEST,
  payload: data,
});

export const getUserPlansStarted = () => ({
  type: GET_USER_PLANS_STARTED,
});

export const getUserPlansSuccess = (data) => ({
  type: GET_USER_PLANS_SUCCESS,
  payload: data,
});

export const getUserPlansFailure = (data) => ({
  type: GET_USER_PLANS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
// export const createUserPlanRequest = (data) => ({
//   type: CREATE_USER_PLAN_REQUEST,
//   payload: data,
// });

// export const createUserPlanStarted = () => ({
//   type: CREATE_USER_PLAN_STARTED,
// });

// export const createUserPlanSuccess = () => ({
//   type: CREATE_USER_PLAN_SUCCESS,
// });

// export const createUserPlanFailure = (data) => ({
//   type: CREATE_USER_PLAN_FAILURE,
//   payload: data,
// });
// --------------------------------------------------------------------------------
export const getUserTransactionsRequest = (data) => ({
  type: GET_USER_TRANSACTIONS_REQUEST,
  payload: data,
});

export const getUserTransactionsStarted = () => ({
  type: GET_USER_TRANSACTIONS_STARTED,
});

export const getUserTransactionsSuccess = (data) => ({
  type: GET_USER_TRANSACTIONS_SUCCESS,
  payload: data,
});

export const getUserTransactionsFailure = (data) => ({
  type: GET_USER_TRANSACTIONS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getUserFailedTransactionsRequest = (data) => ({
  type: GET_USER_FAILED_TRANSACTIONS_REQUEST,
  payload: data,
});

export const getUserFailedTransactionsStarted = () => ({
  type: GET_USER_FAILED_TRANSACTIONS_STARTED,
});

export const getUserFailedTransactionsSuccess = (data) => ({
  type: GET_USER_FAILED_TRANSACTIONS_SUCCESS,
  payload: data,
});

export const getUserFailedTransactionsFailure = (data) => ({
  type: GET_USER_FAILED_TRANSACTIONS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getUserBankAccountsRequest = (data) => ({
  type: GET_USER_BANK_ACCOUNTS_REQUEST,
  payload: data,
});

export const getUserBankAccountsStarted = () => ({
  type: GET_USER_BANK_ACCOUNTS_STARTED,
});

export const getUserBankAccountsSuccess = (data) => ({
  type: GET_USER_BANK_ACCOUNTS_SUCCESS,
  payload: data,
});

export const getUserBankAccountsFailure = (data) => ({
  type: GET_USER_BANK_ACCOUNTS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const changeUserBankAccountStatusRequest = (data) => ({
  type: CHANGE_USER_BANK_ACCOUNT_STATUS_REQUEST,
  payload: data,
});

export const changeUserBankAccountStatusStarted = () => ({
  type: CHANGE_USER_BANK_ACCOUNT_STATUS_STARTED,
});

export const changeUserBankAccountStatusSuccess = () => ({
  type: CHANGE_USER_BANK_ACCOUNT_STATUS_SUCCESS,
});

export const changeUserBankAccountStatusFailure = (data) => ({
  type: CHANGE_USER_BANK_ACCOUNT_STATUS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const deleteUserBankAccountRequest = (data) => ({
  type: DELETE_USER_BANK_ACCOUNT_REQUEST,
  payload: data,
});

export const deleteUserBankAccountStarted = () => ({
  type: DELETE_USER_BANK_ACCOUNT_STARTED,
});

export const deleteUserBankAccountSuccess = () => ({
  type: DELETE_USER_BANK_ACCOUNT_SUCCESS,
});

export const deleteUserBankAccountFailure = (data) => ({
  type: DELETE_USER_BANK_ACCOUNT_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const restoreUserBankAccountRequest = (data) => ({
  type: RESTORE_USER_BANK_ACCOUNT_REQUEST,
  payload: data,
});

export const restoreUserBankAccountStarted = () => ({
  type: RESTORE_USER_BANK_ACCOUNT_STARTED,
});

export const restoreUserBankAccountSuccess = () => ({
  type: RESTORE_USER_BANK_ACCOUNT_SUCCESS,
});

export const restoreUserBankAccountFailure = (data) => ({
  type: RESTORE_USER_BANK_ACCOUNT_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const createUserBankAccountRequest = (data) => ({
  type: CREATE_USER_BANK_ACCOUNT_REQUEST,
  payload: data,
});

export const createUserBankAccountStarted = () => ({
  type: CREATE_USER_BANK_ACCOUNT_STARTED,
});

export const createUserBankAccountSuccess = () => ({
  type: CREATE_USER_BANK_ACCOUNT_SUCCESS,
});

export const createUserBankAccountFailure = (data) => ({
  type: CREATE_USER_BANK_ACCOUNT_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getUserGatewaysRequest = (data) => ({
  type: GET_USER_GATEWAYS_REQUEST,
  payload: data,
});

export const getUserGatewaysStarted = () => ({
  type: GET_USER_GATEWAYS_STARTED,
});

export const getUserGatewaysSuccess = (data) => ({
  type: GET_USER_GATEWAYS_SUCCESS,
  payload: data,
});

export const getUserGatewaysFailure = (data) => ({
  type: GET_USER_GATEWAYS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const changeUserGatewayStatusRequest = (data) => ({
  type: CHANGE_USER_GATEWAY_STATUS_REQUEST,
  payload: data,
});

export const changeUserGatewayStatusStarted = () => ({
  type: CHANGE_USER_GATEWAY_STATUS_STARTED,
});

export const changeUserGatewayStatusSuccess = () => ({
  type: CHANGE_USER_GATEWAY_STATUS_SUCCESS,
});

export const changeUserGatewayStatusFailure = (data) => ({
  type: CHANGE_USER_GATEWAY_STATUS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getUserYekpayLinksRequest = (data) => ({
  type: GET_USER_YEKPAY_LINKS_REQUEST,
  payload: data,
});

export const getUserYekpayLinksStarted = () => ({
  type: GET_USER_YEKPAY_LINKS_STARTED,
});

export const getUserYekpayLinksSuccess = (data) => ({
  type: GET_USER_YEKPAY_LINKS_SUCCESS,
  payload: data,
});

export const getUserYekpayLinksFailure = (data) => ({
  type: GET_USER_YEKPAY_LINKS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getPersonalPaylinksRequest = (data) => ({
  type: GET_PERSONAL_PAYLINKS_REQUEST,
  payload: data,
});

export const getPersonalPaylinksStarted = () => ({
  type: GET_PERSONAL_PAYLINKS_STARTED,
});

export const getPersonalPaylinksSuccess = (data) => ({
  type: GET_PERSONAL_PAYLINKS_SUCCESS,
  payload: data,
});

export const getPersonalPaylinksFailure = (data) => ({
  type: GET_PERSONAL_PAYLINKS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const changePersonalPaylinkStatusRequest = (data) => ({
  type: CHANGE_PERSONAL_PAYLINK_STATUS_REQUEST,
  payload: data,
});

export const changePersonalPaylinkStatusStarted = () => ({
  type: CHANGE_PERSONAL_PAYLINK_STATUS_STARTED,
});

export const changePersonalPaylinkStatusSuccess = (data) => ({
  type: CHANGE_PERSONAL_PAYLINK_STATUS_SUCCESS,
  payload: data,
});

export const changePersonalPaylinkStatusFailure = (data) => ({
  type: CHANGE_PERSONAL_PAYLINK_STATUS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const changeProductPaylinkStatusRequest = (data) => ({
  type: CHANGE_PRODUCT_PAYLINK_STATUS_REQUEST,
  payload: data,
});

export const changeProductPaylinkStatusStarted = () => ({
  type: CHANGE_PRODUCT_PAYLINK_STATUS_STARTED,
});

export const changeProductPaylinkStatusSuccess = (data) => ({
  type: CHANGE_PRODUCT_PAYLINK_STATUS_SUCCESS,
  payload: data,
});

export const changeProductPaylinkStatusFailure = (data) => ({
  type: CHANGE_PRODUCT_PAYLINK_STATUS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getProductPaylinksRequest = (data) => ({
  type: GET_PRODUCT_PAYLINKS_REQUEST,
  payload: data,
});

export const getProductPaylinksStarted = () => ({
  type: GET_PRODUCT_PAYLINKS_STARTED,
});

export const getProductPaylinksSuccess = (data) => ({
  type: GET_PRODUCT_PAYLINKS_SUCCESS,
  payload: data,
});

export const getProductPaylinksFailure = (data) => ({
  type: GET_PRODUCT_PAYLINKS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const changeUserYekpayLinkStatusRequest = (data) => ({
  type: CHANGE_USER_YEKPAY_LINK_STATUS_REQUEST,
  payload: data,
});

export const changeUserYekpayLinkStatusStarted = () => ({
  type: CHANGE_USER_YEKPAY_LINK_STATUS_STARTED,
});

export const changeUserYekpayLinkStatusSuccess = () => ({
  type: CHANGE_USER_YEKPAY_LINK_STATUS_SUCCESS,
});

export const changeUserYekpayLinkStatusFailure = (data) => ({
  type: CHANGE_USER_YEKPAY_LINK_STATUS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const deleteUserYekpayLinkRequest = (data) => ({
  type: DELETE_USER_YEKPAY_LINK_REQUEST,
  payload: data,
});

export const deleteUserYekpayLinkStarted = () => ({
  type: DELETE_USER_YEKPAY_LINK_STARTED,
});

export const deleteUserYekpayLinkSuccess = () => ({
  type: DELETE_USER_YEKPAY_LINK_SUCCESS,
});

export const deleteUserYekpayLinkFailure = (data) => ({
  type: DELETE_USER_YEKPAY_LINK_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getUserTicketsRequest = (data) => ({
  type: GET_USER_TICKETS_REQUEST,
  payload: data,
});

export const getUserTicketsStarted = () => ({
  type: GET_USER_TICKETS_STARTED,
});

export const getUserTicketsSuccess = (data) => ({
  type: GET_USER_TICKETS_SUCCESS,
  payload: data,
});

export const getUserTicketsFailure = (data) => ({
  type: GET_USER_TICKETS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getUserLogsRequest = (data) => ({
  type: GET_USER_LOGS_REQUEST,
  payload: data,
});

export const getUserLogsStarted = () => ({
  type: GET_USER_LOGS_STARTED,
});

export const getUserLogsSuccess = (data) => ({
  type: GET_USER_LOGS_SUCCESS,
  payload: data,
});

export const getUserLogsFailure = (data) => ({
  type: GET_USER_LOGS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const createPayoutRequest = (data) => ({
  type: CREATE_PAYOUT_REQUEST,
  payload: data,
});

export const createPayoutStarted = () => ({
  type: CREATE_PAYOUT_STARTED,
});

export const createPayoutSuccess = (data) => ({
  type: CREATE_PAYOUT_SUCCESS,
  payload: data,
});

export const createPayoutFailure = (data) => ({
  type: CREATE_PAYOUT_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getUserTextMessagesRequest = (data) => ({
  type: GET_USER_TEXT_MESSAGES_REQUEST,
  payload: data,
});

export const getUserTextMessagesStarted = () => ({
  type: GET_USER_TEXT_MESSAGES_STARTED,
});

export const getUserTextMessagesSuccess = (data) => ({
  type: GET_USER_TEXT_MESSAGES_SUCCESS,
  payload: data,
});

export const getUserTextMessagesFailure = (data) => ({
  type: GET_USER_TEXT_MESSAGES_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getUserChangeLogsRequest = (data) => ({
  type: GET_USER_CHANGE_LOGS_REQUEST,
  payload: data,
});

export const getUserChangeLogsStarted = () => ({
  type: GET_USER_CHANGE_LOGS_STARTED,
});

export const getUserChangeLogsSuccess = (data) => ({
  type: GET_USER_CHANGE_LOGS_SUCCESS,
  payload: data,
});

export const getUserChangeLogsFailure = (data) => ({
  type: GET_USER_CHANGE_LOGS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const topupUserRequest = (data) => ({
  type: TOPUP_USER_REQUEST,
  payload: data,
});

export const topupUserStarted = () => ({
  type: TOPUP_USER_STARTED,
});

export const topupUserSuccess = (data) => ({
  type: TOPUP_USER_SUCCESS,
  payload: data,
});

export const topupUserFailure = (data) => ({
  type: TOPUP_USER_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getUserExchangeDetailRequest = (data) => ({
  type: GET_USER_EXCHANGE_DETAIL_REQUEST,
  payload: data,
});

export const getUserExchangeDetailStarted = () => ({
  type: GET_USER_EXCHANGE_DETAIL_STARTED,
});

export const getUserExchangeDetailSuccess = (data) => ({
  type: GET_USER_EXCHANGE_DETAIL_SUCCESS,
  payload: data,
});

export const getUserExchangeDetailFailure = (data) => ({
  type: GET_USER_EXCHANGE_DETAIL_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const exchangeUserCurrencyRequest = (data) => ({
  type: EXCHANGE_USER_CURRENCY_REQUEST,
  payload: data,
});

export const exchangeUserCurrencyStarted = () => ({
  type: EXCHANGE_USER_CURRENCY_STARTED,
});

export const exchangeUserCurrencySuccess = (data) => ({
  type: EXCHANGE_USER_CURRENCY_SUCCESS,
  payload: data,
});

export const exchangeUserCurrencyFailure = (data) => ({
  type: EXCHANGE_USER_CURRENCY_FAILURE,
  payload: data,
});
