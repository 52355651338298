import React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import DefaultUser from "assets/images/default/default-user.png";

const ProfileHeader = ({ user }) => {
  return (
    <div className="jr-profile-banner">
      <div className="jr-profile-container">
        <h3 className="mb-3 jr-mb-sm-3 jr-fs-xl text-center">
          {user?.full_name}
        </h3>
        <div className="jr-profile-banner-top">
          <div className="jr-profile-banner-top-left">
            <div className="jr-profile-banner-avatar-info"></div>
            <div className="jr-profile-banner-avatar ml-3">
              <Avatar
                style={{ backgroundColor: "#fff" }}
                className="size-90"
                alt="..."
                src={
                  user?.profile_picture ? user?.profile_picture : DefaultUser
                }
              />
            </div>
            <div className="jr-profile-banner-avatar-info">
              <p className="mb-1 text-grey ">Profile Name</p>
              <p className="mb-2 jr-mb-sm-3 jr-fs-xxl jr-font-weight-light my-1">
                {user?.first_name} {user?.last_name}
              </p>
            </div>
          </div>
          <div className="jr-profile-banner-top-right">
            <ul className="jr-follower-list">
              <li>
                Email:
                <span className="jr-follower-title jr-fs-lg jr-font-weight-medium my-1">
                  {user?.email}
                </span>
                <span className="jr-fs-sm">Account ID: {user?.account_id}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileHeader;
