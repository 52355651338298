import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import React from 'react';

function Shaparak({
  shaparak_contract_date,
  shaparak_contract_dateChange,
  shaparak_service_date,
  shaparak_service_dateChange,
  shaparak_contract_number,
  shaparak_contract_numberChange,
  shaparak_corporation_code,
  shaparak_corporation_codeChange,
  shaparak_identifier_code,
  shaparak_identifier_codeChange,
  shaparak_terminal_date,
  shaparak_terminal_dateChange,
  shaparak_address_callback,
  shaparak_address_callbackChange,
  shaparak_port_callback,
  shaparak_port_callbackChange,
  shaparak_tracking_number,
  shaparak_tracking_numberChange
}) {
  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '65%'
      }
    }
  }));
  const classes = useStyles();
  return (
    <div>
      <div className="row">
        <div className="jr-card p-0 col-xl-12 col-lg-12 col-md-12 col-12 ">
          <div className="jr-card-header card-img-top mb-0 p-4 bg-grey lighten-4">
            <h3 className="card-heading">Shaparak</h3>
          </div>
          <div className="card-body text-center">
            <ul className="contact-list list-unstyled">
              <div className="row">
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="Contract Date"
                    value={shaparak_contract_date}
                    InputLabelProps={{ shrink: true }}
                    onChange={shaparak_contract_dateChange}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="Service Date"
                    value={shaparak_service_date}
                    InputLabelProps={{ shrink: true }}
                    onChange={shaparak_service_dateChange}
                    margin="normal"
                    placeholder="0000/00/00"
                    variant="outlined"></TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="Contract Number"
                    value={shaparak_contract_number}
                    InputLabelProps={{ shrink: true }}
                    onChange={shaparak_contract_numberChange}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="Corporation code"
                    value={shaparak_corporation_code}
                    InputLabelProps={{ shrink: true }}
                    onChange={shaparak_corporation_codeChange}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="Identifier Code"
                    value={shaparak_identifier_code}
                    InputLabelProps={{ shrink: true }}
                    onChange={shaparak_identifier_codeChange}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="Terminal Date"
                    value={shaparak_terminal_date}
                    InputLabelProps={{ shrink: true }}
                    onChange={shaparak_terminal_dateChange}
                    margin="normal"
                    placeholder="0000/00/00"
                    variant="outlined"></TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="Address Callback"
                    value={shaparak_address_callback}
                    InputLabelProps={{ shrink: true }}
                    onChange={shaparak_address_callbackChange}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="Port Callback"
                    value={shaparak_port_callback}
                    InputLabelProps={{ shrink: true }}
                    onChange={shaparak_port_callbackChange}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="Tracking Number"
                    value={shaparak_tracking_number}
                    InputLabelProps={{ shrink: true }}
                    onChange={shaparak_tracking_numberChange}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Shaparak;
