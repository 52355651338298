import { token, API_GET_ACCEPTOR_SHOW } from "constants/constants";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ShaparakHaghighi from "./component/ShaparakHaghighi";
import StatusLoading from "../../../../UserProfile/components/StatusLoading";

function AcceptorShaparak() {
  const urlObject = new URL(window.location);
  const parts = urlObject.pathname.split("/");
  const acceptor_id = parts[parts.length - 1];

  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
    async function fetchData() {
      await axios
        .post(API_GET_ACCEPTOR_SHOW, { id: acceptor_id }, token())
        .then((res) => {
          let data = res.data.data;
          setState(data);
        });
      setLoading(true);
    }
    fetchData();
  }, []);

  return (
    <>
      {state.id ? <ShaparakHaghighi acceptorInfo={state} /> : <StatusLoading />}
    </>
  );
}

export default AcceptorShaparak;
