import React from "react";
import { Route, Switch } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import Header from "components/Header";
import Sidebar from "containers/SideNav/index";
import Footer from "components/Footer";
import Tour from "components/Tour";
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from "constants/ActionTypes";
import { isIOS, isMobile } from "react-device-detect";
import asyncComponent from "../util/asyncComponent";
import TopNav from "components/TopNav";
import Transactions from "./routes/Financial/Transactions";
import Cryptocurrency from "./routes/Cryptocurrency";
import Payout from "./routes/Payout";
import IPGTransactions from "./routes/Financial/IPGTransactions";
import Gateways from "./routes/PaymentGateways";
import Users from "./routes/Main/Users";
import Tickets from "./routes/Main/Tickets";
import FinancialReports from "./routes/Reports/FinancialReports";
import Profile from "./routes/UserProfile";
import Gateway from "./routes/GatewayUser";
import General from "./routes/Settings/General";
import System from "./routes/Settings/System";
import AdminProfile from "./routes/Settings/Profile";
import SupportReports from "./routes/Reports/Tickets";
import GatewayReports from "./routes/Reports/GatewayReports";
import Support from "./routes/Support";
import FAQ from "./routes/FAQ";
import Transaction from "./routes/Transaction";
import TransactionOffline from "./routes/TransactionOffline";
import PaymentFacilitator from "./routes/Financial/PaymentFacilitator";
import AcceptorEdit from "./routes/Financial/PaymentFacilitator/routes/AcceptorForm/AcceptorEdit";
import AcceptorShaparak from "./routes/Financial/PaymentFacilitator/routes/AcceptorShaparak";
import YekpayWallet from "./routes/Financial/YekpayWallet";
import ExchangePanel from "./routes/ExchangePanel/index";
import TextMessages from "./routes/TextMessages";
import Youtube from "./routes/Youtube";
import MessageDialog from "components/Dialogs";
import Tools from "./routes/Tools/index";
import PaymentGatewayInvoices from "./routes/Financial/PaymentGatewayInvoices/index";
import CRM from "./routes/CRM/index";

const App = () => {
  const currentUrl = "/app";

  const { drawerType, navigationStyle, horizontalNavPosition } = useSelector(
    ({ settings }) => {
      return {
        drawerType: settings.drawerType,
        navigationStyle: settings.navigationStyle,
        horizontalNavPosition: settings.horizontalNavPosition,
      };
    },
    shallowEqual
  );

  const drawerStyle = drawerType.includes(FIXED_DRAWER)
    ? "fixed-drawer"
    : drawerType.includes(COLLAPSED_DRAWER)
    ? "collapsible-drawer"
    : "mini-drawer";

  //set default height and overflow for iOS mobile Safari 10+ support.
  if (isIOS && isMobile) {
    document.body.classList.add("ios-mobile-view-height");
  } else if (document.body.classList.contains("ios-mobile-view-height")) {
    document.body.classList.remove("ios-mobile-view-height");
  }

  return (
    <div className={`app-container ${drawerStyle}`}>
      <Tour />

      <Sidebar />
      <div className="app-main-container">
        <div
          className={`app-header ${
            navigationStyle === HORIZONTAL_NAVIGATION
              ? "app-header-horizontal"
              : ""
          }`}
        >
          {navigationStyle === HORIZONTAL_NAVIGATION &&
            horizontalNavPosition === ABOVE_THE_HEADER && (
              <TopNav styleName="app-top-header" />
            )}
          <Header />
          {navigationStyle === HORIZONTAL_NAVIGATION &&
            horizontalNavPosition === BELOW_THE_HEADER && <TopNav />}
        </div>

        <main className="app-main-content-wrapper">
          <div className="app-main-content">
            <MessageDialog />

            <Switch>
              {/* Dashboard */}
              <Route
                path={`${currentUrl}/dashboard`}
                component={asyncComponent(() =>
                  import("./routes/Main/Dashboard")
                )}
              />

              {/* Users */}
              <Route path={`${currentUrl}/users`} component={Users} />

              {/* Tickets */}
              <Route path={`${currentUrl}/tickets`} component={Tickets} />

              {/* Text Messages */}
              <Route
                path={`${currentUrl}/text-messages`}
                component={TextMessages}
              />
              {/* Support */}
              <Route path={`${currentUrl}/support`} component={Support} />

              {/* Transactions */}
              <Route
                path={`${currentUrl}/transactions`}
                component={Transactions}
              />

              {/*IPG Transactions */}
              <Route
                path={`${currentUrl}/ipg-transactions`}
                component={IPGTransactions}
              />

              {/*Yekpay WAllet */}
              <Route
                path={`${currentUrl}/yekpay-wallet`}
                component={YekpayWallet}
              />

              {/*Cryptocurrency */}
              <Route
                path={`${currentUrl}/cryptocurrency`}
                component={Cryptocurrency}
              />

              {/* Gateways */}
              <Route path={`${currentUrl}/gateways`} component={Gateways} />
              {/* CRM */}
              <Route path={`${currentUrl}/crm`} component={CRM} />
              {/* Youtube */}
              <Route path={`${currentUrl}/youtube`} component={Youtube} />

              {/* System */}
              <Route path={`${currentUrl}/system`} component={System} />

              {/* General */}
              <Route path={`${currentUrl}/general`} component={General} />

              {/* Profile */}
              <Route path={`${currentUrl}/profile`} component={Profile} />

              {/* Gateway */}
              <Route path={`${currentUrl}/gateway/:id`} component={Gateway} />

              {/* TransactionWise */}
              <Route
                path={`${currentUrl}/transaction-wise`}
                component={asyncComponent(() =>
                  import("./routes/Financial/TransferWise")
                )}
              />

              {/* Transaction */}
              <Route
                path={`${currentUrl}/transaction/:id`}
                component={Transaction}
              />

              {/* payment Facilitator */}
              <Route
                path={`${currentUrl}/payment-facilitator`}
                component={PaymentFacilitator}
              />
              <Route
                path={`${currentUrl}/invoices`}
                component={PaymentGatewayInvoices}
              />

              {/*/!* AcceptorEdit *!/*/}
              <Route
                path={`${currentUrl}/acceptor-edit`}
                component={AcceptorEdit}
              />

              {/* AcceptorShaparak */}
              <Route
                path={`${currentUrl}/acceptor-shaparak/haghighi`}
                component={AcceptorShaparak}
              />

              {/* TransactionOffline */}
              <Route
                path={`${currentUrl}/transactionOffline/:id`}
                component={TransactionOffline}
              />

              {/* Pending Payout */}
              <Route
                path={`${currentUrl}/transaction-payout/:id`}
                component={Payout}
              />

              {/* User Reports */}
              <Route
                path={`${currentUrl}/reports`}
                component={SupportReports}
              />

              <Route path={`${currentUrl}/tools`} component={Tools} />

              {/* Gateway Reports */}
              <Route
                path={`${currentUrl}/report/gateway`}
                component={GatewayReports}
              />

              {/*Admin Profile */}
              <Route
                path={`${currentUrl}/admin-profile`}
                component={AdminProfile}
              />

              {/*FAQ */}
              <Route path={`${currentUrl}/faq`} component={FAQ} />

              {/* Financial Reports */}
              <Route
                path={`${currentUrl}/financial`}
                component={FinancialReports}
              />
              {/*${currentUrl} Exchange Panel */}

              <Route
                path={`${currentUrl}/exchange-panel`}
                component={ExchangePanel}
              />
              <Route
                component={asyncComponent(() => import("components/Error404"))}
              />
            </Switch>
          </div>
          <Footer />
        </main>
      </div>
    </div>
  );
};

export default App;
