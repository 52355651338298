import axios from "axios";
import { store } from "redux/store";
import { BaseURL } from "constants/constants";
import { logoutAdminRequest } from "redux/actions/Auth";
// --------------------------------------------------------------------------------
const axiosMiddleware = async ({
  method,
  path,
  body,
  baseAPIAddress = BaseURL,
  config,
}) => {
  let accessToken = store.getState().authReducer.authToken;
  let theAxios = axios.create({
    baseURL: baseAPIAddress,
    // timeout: 10000,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...config,
    },
  });
  let request = null;
  switch (method) {
    case "get":
      request = theAxios.get(path);
      break;
    case "post":
      request = theAxios.post(path, body);
      break;
    case "put":
      request = theAxios.put(path, body);
      break;
    case "patch":
      request = theAxios.patch(path, body);
      break;
    case "delete":
      request = theAxios.delete(path);
      break;
  }
  return request
    ?.then((response) => {
      console.warn("middleware response", { path, response });
      if (
        "code" in response.data &&
        response.data.code &&
        response.data.code === 401
      ) {
        store.dispatch(logoutAdminRequest());
        localStorage.removeItem("boot");
      }
      return response.data;
    })
    .catch(async (error) => {
      console.warn("middleware error", { path, error });
      if (
        "response" in error &&
        error.response &&
        "data" in error.response &&
        error.response.data &&
        "data" in error.response.data &&
        error.response.data.data
      ) {
        let errorMessage = "";
        if (typeof error.response.data.data === "string") {
          errorMessage = error.response.data.data;
        } else {
          for (let prop in error.response.data.data) {
            errorMessage +=
              prop + ": " + error.response.data.data[prop] + " - ";
          }
        }
        return { ...error.response.data, errorMessage };
      }
      return Promise.reject(error);
    });
};

export default axiosMiddleware;
