import React, { useState, useEffect } from 'react';
import {
  fromPrevMonth,
  to,
  token,
  API_PAYOUT_YOUTUBE,
  API_GET_BANK_ACCOUNT
} from 'constants/constants';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import NumberFormat from 'react-number-format';
import { thousands_separators } from 'constants/usefulFunctions';
import MenuItem from '@mui/material/MenuItem';
import SweetAlert from 'react-bootstrap-sweetalert';
import { getUserYoutubeChannelsRequest } from 'redux/actions/UserProfile';
import { useDispatch } from 'react-redux';

function PayoutChannel({ user_id, balance }) {
  const dispatch = useDispatch();
  
  const [amount, setAmount] = useState('');
  const [banks, setBanks] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    getBankAccountList();
  }, []);

  const getBankAccountList = () => {
    axios
      .post(API_GET_BANK_ACCOUNT, { user_id }, token())
      .then((res) => {
        setLoading(false);
        setBanks(res.data.data);
      })
      .catch((error) => {
        console.log(error, 'Error');
        setLoading(false);
      });
  };
  const handleSubmit = () => {
    setShowAlert(false);
    if (amount) {
      setLoading(true);

      axios
        .post(
          API_PAYOUT_YOUTUBE,
          {
            user_id,
            amount,
            bank_account_id: selectedAccount
          },
          token()
        )
        .then(() => {
          dispatch(getUserYoutubeChannelsRequest({ user_id }));
          setAmount('');

          setLoading(false);
        })
        .catch((error) => {
          console.log('error', error);
          setLoading(false);
        });
    }
  };

  return (
    <div className=" card jr-card p-3  w-100 h-100 ">
      <h3 className=" col-12 text-center mb-5">
        Payout (Balance: {thousands_separators(balance)} IRR)
      </h3>
      <div className="d-flex  flex-wrap justufy-content-between align-items-center  w-100">
        <div className="my-2 d-flex align-items-center  mx-auto col-md-4  col-sm-12 ">
          <TextField
            select
            label="Account"
            className="w-100"
            value={selectedAccount}
            onChange={(e) => setSelectedAccount(e.target.value)}
            InputLabelProps={{
              shrink: true
            }}>
            {banks.map(
              (bank) =>
                bank.status === 'Approved' && (
                  <MenuItem key={bank.id} value={bank.id}>
                    {bank.iban}
                  </MenuItem>
                )
            )}
          </TextField>
        </div>
        <div className="my-2 d-flex align-items-center  mx-auto  col-md-4  col-sm-12">
          <span className="mr-3 ml-0 float-left" style={{ margin: 'auto' }}>
            Amount
          </span>
          <NumberFormat
            className="MuiInputBase-input MuiOutlinedInput-input p-1"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            thousandSeparator={true}
            style={{ borderBottom: '1px solid black' }}
            suffix={' IRR'}
          />
        </div>
      </div>
      <div className="d-flex justify-content-center mt-3 col-12">
        <Button
          color="primary"
          className="jr-btn jr-btn-sm bg-primary text-white px-3 mt-3"
          onClick={() => setShowAlert(true)}
          type="submit"
          style={{ width: '140px' }}>
          {loading ? <CircularProgress color="inherit" size={15} /> : 'Submit'}
        </Button>
      </div>
      <SweetAlert
        show={showAlert}
        warning
        title={'Are you sure?'}
        confirmBtnText={'Yes'}
        cancelBtnText={'No'}
        onConfirm={() => handleSubmit()}
        onCancel={() => setShowAlert(false)}
        cancelBtnBsStyle="danger"
        confirmBtnBsStyle="success"
        showCancel
        focusCancelBtn></SweetAlert>
    </div>
  );
}

export default PayoutChannel;
