import React, { useEffect, useState } from "react";
import axios from "axios";
import { token, API_GET_TRANSACTION_SHOW, from, to } from "constants/constants";
import TransactionInfo from "./TransactionInfo";
import StatusLoading from "app/routes/UserProfile/components/StatusLoading";
import TopupAdditional from "./TopupAdditional";

const Transaction = () => {
  // Getting last argument from URL as user_id
  const urlObject = new URL(window.location);
  const parts1 = urlObject.pathname.split("/");
  const id = parts1[parts1.length - 1];

  const [transaction, setTransaction] = useState({});

  useEffect(() => {
    async function fetchData() {
      await axios
        .post(API_GET_TRANSACTION_SHOW, { id }, token())
        .then((res) => res.data.data)
        .then((res) => {
          document.title = "Transaction | " + res.id + " " + res.event_id;
          setTransaction(res);
        });
    }
    fetchData();
  }, []);
  return (
    <>
      {transaction.id ? (
        <TransactionInfo transaction={transaction} />
      ) : (
        <StatusLoading />
      )}
      {transaction.id && transaction.event_id === 1 && (
        <TopupAdditional transaction={transaction} />
      )}
    </>
  );
};

export default Transaction;
