import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AllGateways from "./routes/AllGateways";
import PendingGateways from "./routes/PendingGateways";

const Gateways = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/all-gateways`} />
      <Route
        path={`${match.url}/all-gateways`}
        component={() => <AllGateways />}
      />
      <Route
        path={`${match.url}/pending-gateways`}
        component={() => <PendingGateways />}
      />
    </Switch>
  </div>
);

export default Gateways;
