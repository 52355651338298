import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const CRM = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Route
        path={`${match.url}/sale-report`}
        component={asyncComponent(() => import("./routes/Reports/SaleReport"))}
      />
      <Route
        path={`${match.url}/support-report`}
        component={asyncComponent(() =>
          import("./routes/Reports/SupportReport")
        )}
      />
    </Switch>
  </div>
);

export default CRM;
