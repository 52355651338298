import axiosMiddleware from "services/API";
// --------------------------------------------------------------------------------
export const getAllTransactionsAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "transaction/all",
    body: data,
  });
export const getHoldTransactionsAPI = () =>
  axiosMiddleware({
    method: "post",
    path: "transaction/hold",
  });
export const changeHoldTransactionStatusAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "transaction/hold/change-status",
    body: data,
  });
export const getAllPayoutsAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "payout/all",
    body: data,
  });
export const getPendingPayoutsAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "payout/pending",
    body: data,
  });
export const getTransactionsPayoutPayAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "payout/confirm-irr",
    body: data,
  });
export const getVIPPendingPayoutsAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "payout/pending-vip",
    body: data,
  });
export const confirmVIPPayoutAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "payout/confirm-irr-vip",
    body: data,
  });
export const getWirePayoutsAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "payout/offline",
    body: data,
  });
export const getAllTopupsAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "topup/all",
    body: data,
  });
export const getRefundRequestsAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "transaction/refund",
    body: data,
  });
export const changeRefundRequestStatusAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "transaction/refund/change-status",
    body: data,
  });
export const getWebsiteGatewaysAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "gateway-transactions/all",
    body: data,
  });
export const getPaymentLinkTransactionsAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "gateway-transactions/all",
    body: data,
  });
export const getPersonalTransactionsAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "personal-gateway-transactions/all",
    body: data,
  });
export const getAllExchangesAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "exchanges/all",
    body: data,
  });
export const getAllTransfersAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "transfers/all",
    body: data,
  });
export const getBanksReportAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "pasargad-report",
    body: data,
  });
