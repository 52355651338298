import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const IPGTransactions = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/Ziraat`} />
      <Route
        path={`${match.url}/ziraat`}
        component={asyncComponent(() => import("./routes/Ziraat"))}
      />
      <Route
        path={`${match.url}/parsian`}
        component={asyncComponent(() => import("./routes/Parsian"))}
      />
      <Route
        path={`${match.url}/vakif`}
        component={asyncComponent(() => import("./routes/Vakif"))}
      />
      <Route
        path={`${match.url}/iyzico`}
        component={asyncComponent(() => import("./routes/Iyzico"))}
      />
      <Route
        path={`${match.url}/esnek`}
        component={asyncComponent(() => import("./routes/Esnek"))}
      />
      <Route
        path={`${match.url}/paybyme`}
        component={asyncComponent(() => import("./routes/Paybyme"))}
      />
      <Route
        path={`${match.url}/sipay`}
        component={asyncComponent(() => import("./routes/SIpay"))}
      />
      <Route
        path={`${match.url}/wire`}
        component={asyncComponent(() => import("./routes/WireCard"))}
      />
      <Route
        path={`${match.url}/moka`}
        component={asyncComponent(() => import("./routes/Moka"))}
      />
      <Route
        path={`${match.url}/irankish`}
        component={asyncComponent(() => import("./routes/Irankish"))}
      />
      <Route
        path={`${match.url}/stripe`}
        component={asyncComponent(() => import("./routes/Stripe"))}
      />
      <Route
        path={`${match.url}/akbank`}
        component={asyncComponent(() => import("./routes/Akbank"))}
      />
    </Switch>
  </div>
);

export default IPGTransactions;
