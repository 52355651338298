import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import {
  API_DELETE_GATEWAY_IP_ADDRESS,
  API_GET_GATEWAY_IP_ADDRESS,
  API_CREATE_GATEWAY_IP_ADDRESS,
  token,
  boot,
} from "constants/constants";
import { NotificationContainer } from "react-notifications";
import SweetAlert from "react-bootstrap-sweetalert";
import { createDateLookup, updateDateLookup } from "constants/usefulFunctions";

function GatewayIPs({ gateway_id }) {
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState({ success: false, error: false });
  const [add, setAdd] = useState(false);
  const [state, setState] = useState({});
  const options = {
    columnsButton: true,
    actionsColumnIndex: state.columns && state.columns.length,
    search: true,
    exportButton: true,
    grouping: false,
    filtering: false,
    exportAllData: true,
    selection: false,
    editable: true,
    addRowPosition: "first",
  };
  const localization = {
    header: {
      actions: "",
    },
  };
  useEffect(() => {
    async function fetchData() {
      await axios
        .post(API_GET_GATEWAY_IP_ADDRESS, { gateway_id }, token())
        .then((res) => {
          let data = res.data.data;
          setState({
            columns: [
              { title: "IP", field: "ip_address" },
              {
                title: "Status",
                field: "status",
                lookup: { Active: "Active", Inactive: "Inactive" },
                editable: "never",
              },
              createDateLookup(),
              updateDateLookup(),
              { title: "Deleted at", field: "deleted_at", editable: "never" },
            ],
            data: data,
          });
        });
      setLoading(false);
    }
    fetchData();
  }, [add]);

  function deleteRow(id, data) {
    return axios
      .post(API_DELETE_GATEWAY_IP_ADDRESS, { gateway_id, id }, token())
      .then((response) => {
        if (response.status === 200 && response.data.status === "success") {
          setState({ ...state, data });
          setValues({ ...values, success: true });
        } else {
          setValues({ ...values, error: true });
        }
      })
      .catch((error) => {
        let failedValue = "";
        if (typeof error.response.data.data === "string") {
          failedValue = error.response.data.data;
        } else {
          for (var prop in error.response.data.data) {
            failedValue += prop + ": " + error.response.data.data[prop] + " - ";
          }
        }
        setValues({ ...values, error: true, code: failedValue });
      });
  }
  function addRow(ip_address, data) {
    return axios
      .post(API_CREATE_GATEWAY_IP_ADDRESS, { ip_address, gateway_id }, token())
      .then((response) => {
        if (response.status === 200 && response.data.status === "success") {
          setState({ ...state, data });
          setValues({ ...values, success: true });
        } else {
          setValues({ ...values, error: true });
        }
      })
      .catch((error) => {
        let failedValue = "";
        if (typeof error.response.data.data === "string") {
          failedValue = error.response.data.data;
        } else {
          for (var prop in error.response.data.data) {
            failedValue += prop + ": " + error.response.data.data[prop] + " - ";
          }
        }
        setValues({ ...values, error: true, code: failedValue });
      });
  }

  function onConfirm() {
    setValues({ success: false, error: false });
  }
  return (
    <div className="card mt-5">
      <MaterialTable
        options={options}
        localization={localization}
        isLoading={loading}
        columns={state.columns}
        data={state.data}
        title="Gateway IPs"
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                resolve();
                const data = [...state.data];
                data.push(newData);
                addRow(newData.ip_address, data);
                setAdd(true);
              }, 600);
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                resolve();
                const data = [...state.data];
                data.splice(data.indexOf(oldData), 1);
                deleteRow(oldData.id, oldData.gateway_id, data);
                setState({ ...state, data });
              }, 600);
            }),
        }}
      />
      <NotificationContainer />
      <SweetAlert
        show={values.success}
        success
        title={"Success"}
        confirmBtnText={"Close"}
        onConfirm={onConfirm}
      >
        Successfully Changed!
      </SweetAlert>

      <SweetAlert
        show={values.error}
        error
        title={"Error"}
        confirmBtnText={"Close"}
        onConfirm={onConfirm}
      >
        Error! {values.code ? values.code : ""}
      </SweetAlert>
    </div>
  );
}

export default GatewayIPs;
