import React from 'react';
import { boot } from 'constants/constants';
import { NavHeader, NavMenu } from 'containers/SideNav/components';

const PeymentGateWays = () => (
  <>
    <NavHeader title="sidebar.peymentGateways" />
    <NavMenu
      title="sidebar.gateways.allGateways"
      icon="zmdi zmdi-view-web"
      url="/app/gateways/all-gateways"
    />
    <NavMenu
      title="sidebar.users.PendingItems"
      icon="zmdi zmdi-pause"
      url="/app/gateways/pending-gateways"
      badge={boot && boot.count_pending_gateways}
    />
  </>
);

export default PeymentGateWays;
