import React from "react";
const TopupAdditional = ({ transaction }) => {
  let additional =JSON.parse(transaction.additional.gateway_response)
  const object = transaction.additional;

  var PaymentGatewayItem = ({ label, value }) => ({
    render: function () {
      return <div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
        <span className="mb-0 text-light jr-fs-sm pr-2">{label}: </span>
        <p className="mb-0">{value}</p>
      </div>;
    }
  });

  return (
    <>
      <div className="app-wrapper pt-0 pb-0">
        <div className="jr-card jr-full-card">
          <div className="card-header">
            <h4 className="card-title mb-0">Topup</h4>
          </div>
          <div style={{ padding: 24 }} className="media align-items-center flex-nowrap jr-pro-contact-list">
            <div className="media-body row">
              <div className="col-lg-4 col-md-6 col-12">

                <PaymentGatewayItem label="Payment Gateway" value={object.payment_gateway_id} />
                <PaymentGatewayItem label="Description" value={object.description} />
                <PaymentGatewayItem label="Admin Description" value={object.admin_description} />
                <PaymentGatewayItem label="Source" value={object.source} />
                <PaymentGatewayItem label="Type" value={object.type} />

              </div>
              <div className="col-lg-4 col-md-6 col-12">
                
                <PaymentGatewayItem label="Image" value={object.image} />
                <PaymentGatewayItem label="Signature" value={object.signature} />
                <PaymentGatewayItem label="Language" value={object.lang} />
                <PaymentGatewayItem label="IP Address" value={object.ip_address} />
                <PaymentGatewayItem label="IP Location" value={object.ip_location} />

              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <PaymentGatewayItem label="Authority" value={object.authority} />
                <PaymentGatewayItem label="Tracking Code" value={object.tracking_code} />
                <PaymentGatewayItem label="Status" value={object.status} />
                <PaymentGatewayItem label="Created At" value={object.created_at} />
                <PaymentGatewayItem label="Updated At" value={object.updated_at} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="app-wrapper pt-0 ">
        <div className="jr-card jr-full-card">
          <div className="card-header">
            <h4 className="card-title mb-0">Payment Gateway Response</h4>
          </div>
          <div style={{ padding: 24 }} className="media align-items-center flex-nowrap jr-pro-contact-list">
            <div className="media-body">
              <div className="col-lg-12 col-12">

                {additional && Object.keys(additional).map((e, i) =>
                  <PaymentGatewayItem key={i} label={e} value={additional[e]} />
                )}
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TopupAdditional
