import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import React from 'react';

function AcceptorTechnician({
  acceptor_technical_name,
  acceptor_technical_nameChange,
  acceptor_technical_family,
  acceptor_technical_familyChange,
  acceptor_technical_phone,
  acceptor_technical_phoneChange,
  acceptor_technical_email,
  acceptor_technical_emailChange
}) {
  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '65%'
      }
    }
  }));
  const classes = useStyles();
  return (
    <div>
      <div className="row">
        <div className="jr-card p-0 col-xl-12 col-lg-12 col-md-12 col-12 ">
          <div className="jr-card-header card-img-top mb-0 p-4 bg-grey lighten-4">
            <h3 className="card-heading">Technician Info Acceptor </h3>
          </div>
          <div className="card-body text-center">
            <ul className="contact-list list-unstyled">
              <div className="row">
                <div className=" col-xl-3 col-lg-3 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="IPG Technical Name (Persian)"
                    value={acceptor_technical_name}
                    InputLabelProps={{ shrink: true }}
                    onChange={acceptor_technical_nameChange}
                    margin="normal"
                    variant="outlined"
                    inputProps={{ dir: 'auto' }}></TextField>
                </div>
                <div className=" col-xl-3 col-lg-3 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="IPG Technical Family (Persian)"
                    value={acceptor_technical_family}
                    InputLabelProps={{ shrink: true }}
                    onChange={acceptor_technical_familyChange}
                    margin="normal"
                    variant="outlined"
                    inputProps={{ dir: 'auto' }}></TextField>
                </div>
                <div className=" col-xl-3 col-lg-3 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="IPG Technical Phone"
                    value={acceptor_technical_phone}
                    InputLabelProps={{ shrink: true }}
                    onChange={acceptor_technical_phoneChange}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
                <div className=" col-xl-3 col-lg-3 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="IPG Technical Email"
                    value={acceptor_technical_email}
                    InputLabelProps={{ shrink: true }}
                    onChange={acceptor_technical_emailChange}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AcceptorTechnician;
