import { API_GET_ALL_SETTING, token } from 'constants/constants';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import CustomScrollbars from 'util/CustomScrollbars';
import CircularProgress from '@mui/material/CircularProgress';
import FirstAssignee from './components/FirstAssignee';
import IrrPayout from './components/IrrPayout';
import ExchangeStatus from './components/ExchangeStatus';

const TicketPage = () => {
  const [state, setState] = useState();
  const [ref, setRef] = useState(false);

  useEffect(() => {
    async function fetchData() {
      await axios.post(API_GET_ALL_SETTING, {}, token()).then((res) => {
        const data = res.data.data;
        setState(data);
        setRef(false);
      });
    }

    fetchData();
  }, [ref]);

  function refresh() {
    setRef(true);
  }

  return (
    <div className="jr-main-content p-3">
      <div className="row">
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
          <CustomScrollbars className="jr-wall-scroll scrollbar">
            <div
              style={{
                position: 'absolute',
                top: '0px',
                left: ' 0px',
                right: '0px',
                bottom: '0px',
                overflow: 'scroll',
                overflowX: 'hidden',
                marginRight: '0px',
                marginBottom: '0px'
              }}>
              {state ? (
                <FirstAssignee state={state} refresh={refresh} />
              ) : (
                <div className="text-center">
                  <CircularProgress color="inherit" size={30} />
                </div>
              )}
              {state ? <IrrPayout state={state} refresh={refresh} /> : ''}
              {state ? <ExchangeStatus state={state} refresh={refresh} /> : ''}
            </div>
          </CustomScrollbars>
        </div>
        <div
          style={{ borderLeft: '2px solid #3f51b5' }}
          className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
          <CustomScrollbars className="jr-wall-scroll scrollbar">
            <div className="jr-wall-postlist"></div>
          </CustomScrollbars>
        </div>
      </div>
    </div>
  );
};

export default TicketPage;
