import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import { token, API_GET_GATEWAY_REPORT, boot } from "constants/constants";
import {
  getIdentityStatus2,
  thousands_separators,
  getUser,
  getUserEmail,
  updateDateLookup,
  createDateLookup,
} from "constants/usefulFunctions";

function GatewayCurrency() {
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({});

  const options = {
    columnsButton: true,
    actionsColumnIndex: state.columns && state.columns.length,
    search: true,
    exportButton: true,
    grouping: false,
    filtering: true,
    exportAllData: true,
    selection: false,
    editable: false,
    paging: true,
    pageSize: 10,
    sorting: true,
  };

  const urlObject = new URL(window.location);
  const parts = urlObject.pathname.split("/");
  const currency_code = parts[parts.length - 1];

  function lastActivity(data) {
    if (data === 0) {
      data = <span className="">Today</span>;
    }
    if (data === 1) {
      data = <span className="">Yesterday</span>;
    }
    if (data === 2) {
      data = <span className="">2 days ago</span>;
    }
    if (data > 2) {
      data = <span className="">{data} days ago</span>;
    }
    return data;
  }

  useEffect(() => {
    const currency_id =
      boot && boot.currencies
        ? boot.currencies.find((o) => o.code === currency_code).id
        : 2;

    async function fetchData() {
      await axios
        .post(API_GET_GATEWAY_REPORT, { currency_id }, token())
        .then((res) => {
          let data = res.data.data;
          var new_array = data.map((item) => {
            item.fullname = item.user.first_name + " " + item.user.last_name;
            return item;
          });
          setState(new_array);
          setState({
            columns: [
              {
                title: "User",
                field: "fullname",
                render: (rowData) => getUser(rowData.user),
              },
              {
                title: "Email",
                field: "user.email",
                render: (rowData) => getUserEmail(rowData.user),
              },
              {
                title: "Plan",
                field: "plan",
                lookup: {
                  Personal: "Personal",
                  Business: "Business",
                  "Business plus": "Business plus",
                },
              },
              { title: "Website Domain", field: "website_domain" },
              {
                title: "Status",
                field: "status",
                render: (rowData) => getIdentityStatus2(rowData.status),
                lookup: {
                  Suspend: "Suspend",
                  Approved: "Approved",
                  Declined: "Declined",
                  Trial: "Trial",
                },
              },
              {
                title: "Transaction Count",
                type: "numeric",
                field: "total_count",
                render: (rowData) =>
                  thousands_separators(
                    currency_code === "IRR"
                      ? Math.trunc(rowData.total_count)
                      : rowData.total_count
                  ),
              },
              {
                title: "Transaction Amount",
                type: "numeric",
                field: "total_amount",
                render: (rowData) =>
                  thousands_separators(
                    currency_code === "IRR"
                      ? Math.trunc(rowData.total_amount)
                      : rowData.total_amount
                  ),
              },
              {
                title: "Average",
                field: "average",
                render: (rowData) =>
                  thousands_separators(
                    currency_code === "IRR"
                      ? Math.trunc(rowData.average)
                      : rowData.average
                  ),
              },
              { title: "Last Transaction", field: "last_transaction_time" },
              {
                title: "Last Activity",
                field: "last_activity_time",
                render: (rowData) => lastActivity(rowData.last_activity_time),
              },
              {
                title: "Last Week",
                field: "last_week",
                type: "numeric",
                render: (rowData) =>
                  thousands_separators(
                    currency_code === "IRR"
                      ? Math.trunc(rowData.last_week)
                      : rowData.last_week
                  ),
              },
              {
                title: "Last Month",
                field: "last_month",
                type: "numeric",
                render: (rowData) =>
                  thousands_separators(
                    currency_code === "IRR"
                      ? Math.trunc(rowData.last_month)
                      : rowData.last_month
                  ),
              },
              {
                title: "Last Six Month",
                field: "last_six_month",
                type: "numeric",
                render: (rowData) =>
                  thousands_separators(
                    currency_code === "IRR"
                      ? Math.trunc(rowData.last_six_month)
                      : rowData.last_six_month
                  ),
              },
              createDateLookup(),
              updateDateLookup(),
            ],
            data: data,
          });
        });
      setLoading(false);
    }

    fetchData();
  }, []);

  return (
    <div className="card">
      <MaterialTable
        isLoading={loading}
        options={options}
        columns={state.columns}
        data={state.data}
        title={"Gateway Report: " + currency_code + " Currency"}
      />
    </div>
  );
}

export default GatewayCurrency;
