import {
  GET_ALL_TEXT_MESSAGES_DEFAULT,
  GET_ALL_TEXT_MESSAGES_FAILURE,
  GET_ALL_TEXT_MESSAGES_STARTED,
  GET_ALL_TEXT_MESSAGES_SUCCESS,
  SEND_SINGLE_SMS_FAILURE,
  SEND_SINGLE_SMS_STARTED,
  SEND_SINGLE_SMS_SUCCESS,
} from "redux/types";
// --------------------------------------------------------------------------------
const initialState = {
  rType: GET_ALL_TEXT_MESSAGES_DEFAULT,
  textMessages: [],
  error: "",
};
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_TEXT_MESSAGES_STARTED:
    case SEND_SINGLE_SMS_STARTED:
      return {
        ...state,
        rType: action.type,
      };
    case GET_ALL_TEXT_MESSAGES_SUCCESS:
    case SEND_SINGLE_SMS_SUCCESS:
      return {
        ...state,
        rType: action.type,
        ...action.payload,
        error: "",
      };
    case GET_ALL_TEXT_MESSAGES_FAILURE:
    case SEND_SINGLE_SMS_FAILURE:
      return {
        ...state,
        rType: action.type,
        ...action.payload,
      };
    default:
      return state;
  }
};
