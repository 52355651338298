import { put, takeLatest } from "redux-saga/effects";
import {
  getAllTextMessagesFailure,
  getAllTextMessagesStarted,
  getAllTextMessagesSuccess,
  sendSingleSmsFailure,
  sendSingleSmsStarted,
  sendSingleSmsSuccess,
} from "redux/actions";
import {
  GET_ALL_TEXT_MESSAGES_REQUEST,
  SEND_SINGLE_SMS_REQUEST,
} from "redux/types";
import { getAllTextMessagesAPI, sendSingleSmsAPI } from "services/API/Support";
// --------------------------------------------------------------------------------
function* onSendSingleSms({ payload }) {
  yield put(sendSingleSmsStarted());
  try {
    let response = yield sendSingleSmsAPI(payload);
    if (response.status === "success") {
      yield put(sendSingleSmsSuccess());
    } else {
      // yield put(getUserProfileFailure("Token Incorrect!"));
    }
  } catch (error) {
    yield put(sendSingleSmsFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onGetAllTextMessages({ payload }) {
  yield put(getAllTextMessagesStarted());
  try {
    let response = yield getAllTextMessagesAPI(payload);
    if (response.status === "success") {
      yield put(getAllTextMessagesSuccess({ textMessages: response.data }));
    } else {
      // yield put(getUserProfileFailure("Token Incorrect!"));
    }
  } catch (error) {
    yield put(
      getAllTextMessagesFailure({ textMessages: [], error: error.message })
    );
  }
}
// ================================================================================
function* supportSaga() {
  yield takeLatest(SEND_SINGLE_SMS_REQUEST, onSendSingleSms);
  yield takeLatest(GET_ALL_TEXT_MESSAGES_REQUEST, onGetAllTextMessages);
}

export default supportSaga;
