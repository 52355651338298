import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import {
  Currencies,
  boot,
  Main_Stage,
  forth_Status,
} from "constants/constants";
import {
  createDateLookup,
  getIdentityStatus2,
} from "constants/usefulFunctions";
import MainTable from "components/MainTable";
import { useDispatch, useSelector } from "react-redux";
import {
  changeUserGatewayStatusRequest,
  getUserGatewaysRequest,
} from "redux/actions/UserProfile";
import {
  CHANGE_USER_GATEWAY_STATUS_STARTED,
  GET_USER_GATEWAYS_STARTED,
} from "redux/types";

const Gateways = ({ user_id }) => {
  const dispatch = useDispatch();
  const { rType, userGateways } = useSelector(
    (state) => state.userProfileReducer
  );
  const loading =
    rType === GET_USER_GATEWAYS_STARTED ||
    rType === CHANGE_USER_GATEWAY_STATUS_STARTED;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    dispatch(getUserGatewaysRequest({ user_id }));
  };

  const columns = [
    {
      title: "WebSite",
      field: "website",
      editable: "never",
      render: (rowData) => (
        <>
          <div className="jr-profile-banner-top-left">
            <div className="jr-profile-banner-avatar">
              <Avatar
                className="size-50 border"
                alt={rowData.website_name}
                src={rowData.website_logo}
              />
            </div>
            <div className="jr-profile-banner-avatar-info">
              <p>
                <a
                  target="_blanck"
                  href={
                    rowData.website_domain.slice(0, 3) === "htt"
                      ? rowData.website_domain
                      : "http://" + rowData.website_domain
                  }
                  className="mb-0 jr-fs-lg"
                >
                  {rowData.website_domain.slice(0, 3) === "htt"
                    ? rowData.website_domain
                    : "https://" + rowData.website_domain}
                </a>
              </p>
              <a
                href={`/app/gateway/${rowData.id}?userId=${user_id}`}
                className="mb-0 jr-fs-lg"
              >
                {rowData.website_name}
              </a>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Agent",
      field: "agent",
      editable: "never",
      render: (rowData) => (
        <span>
          {rowData.agent}
          <br />
          {rowData.agent_contact}
        </span>
      ),
    },
    { title: "Type", field: "type", editable: "never" },
    {
      title: "Country",
      field: "country_id",
      editable: "never",
      render: (rowData) =>
        rowData.country_id && boot.countries
          ? boot?.countries?.find((o) => o.id === rowData.country_id).name_en
          : "-",
    },
    {
      title: "Merchant ID",
      field: "merchant_code",
      editable: "never",
    },
    {
      title: "Currency",
      field: "currency_id",
      editable: "never",
      render: (rowData) => Currencies[rowData.currency_id],
    },
    createDateLookup(),

    { title: "Stage", field: "last_status.stage", lookup: Main_Stage },
    {
      title: "Status",
      field: "last_status.status",
      lookup: forth_Status,
      render: (rowData) => getIdentityStatus2(rowData.last_status.status),
    },
    { title: "Description", field: "last_status.admin_description" },
  ];
  const options = {
    actionsColumnIndex: columns.length,
  };

  const updateRow = async (user_id, id, status, stage, description) => {
    dispatch(
      changeUserGatewayStatusRequest({
        user_id,
        id,
        status,
        description,
        stage,
      })
    );
  };

  return (
    <div className="card">
      <MainTable
        customOptions={options}
        loading={loading}
        columns={columns}
        data={userGateways}
        title="Gateways"
        onRowUpdate={(newData, oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              updateRow(
                oldData.user_id,
                oldData.id,
                newData.last_status.status,
                newData.last_status.stage,
                newData.last_status.admin_description
              );
            }, 600);
            resolve();
          })
        }
      />
    </div>
  );
};

export default Gateways;
