import {
  CHANGE_PERSONAL_PAYLINK_STATUS_FAILURE,
  CHANGE_PERSONAL_PAYLINK_STATUS_STARTED,
  CHANGE_PERSONAL_PAYLINK_STATUS_SUCCESS,
  CHANGE_PRODUCT_PAYLINK_STATUS_FAILURE,
  CHANGE_PRODUCT_PAYLINK_STATUS_STARTED,
  CHANGE_PRODUCT_PAYLINK_STATUS_SUCCESS,
  CHANGE_USER_ADDRESS_STATUS_FAILURE,
  CHANGE_USER_ADDRESS_STATUS_STARTED,
  CHANGE_USER_ADDRESS_STATUS_SUCCESS,
  CHANGE_USER_BANK_ACCOUNT_STATUS_FAILURE,
  CHANGE_USER_BANK_ACCOUNT_STATUS_STARTED,
  CHANGE_USER_BANK_ACCOUNT_STATUS_SUCCESS,
  CHANGE_USER_COMPANY_STATUS_FAILURE,
  CHANGE_USER_COMPANY_STATUS_STARTED,
  CHANGE_USER_COMPANY_STATUS_SUCCESS,
  CHANGE_USER_GATEWAY_STATUS_FAILURE,
  CHANGE_USER_GATEWAY_STATUS_STARTED,
  CHANGE_USER_GATEWAY_STATUS_SUCCESS,
  CHANGE_USER_STATUS_FAILURE,
  CHANGE_USER_STATUS_STARTED,
  CHANGE_USER_STATUS_SUCCESS,
  CHANGE_USER_YEKPAY_LINK_STATUS_FAILURE,
  CHANGE_USER_YEKPAY_LINK_STATUS_STARTED,
  CHANGE_USER_YEKPAY_LINK_STATUS_SUCCESS,
  CLEAR_USER_PROFILE_SUCCESS,
  CREATE_PAYOUT_FAILURE,
  CREATE_PAYOUT_STARTED,
  CREATE_PAYOUT_SUCCESS,
  CREATE_USER_BANK_ACCOUNT_FAILURE,
  CREATE_USER_BANK_ACCOUNT_STARTED,
  CREATE_USER_BANK_ACCOUNT_SUCCESS,
  CREATE_USER_COMMENT_FAILURE,
  CREATE_USER_COMMENT_STARTED,
  CREATE_USER_COMMENT_SUCCESS,
  CREATE_USER_WALLET_FAILURE,
  CREATE_USER_WALLET_STARTED,
  CREATE_USER_WALLET_SUCCESS,
  DELETE_USER_BANK_ACCOUNT_FAILURE,
  DELETE_USER_BANK_ACCOUNT_STARTED,
  DELETE_USER_BANK_ACCOUNT_SUCCESS,
  DELETE_USER_COMMENT_FAILURE,
  DELETE_USER_COMMENT_STARTED,
  DELETE_USER_COMMENT_SUCCESS,
  DELETE_USER_WALLET_FAILURE,
  DELETE_USER_WALLET_STARTED,
  DELETE_USER_WALLET_SUCCESS,
  DELETE_USER_YEKPAY_LINK_FAILURE,
  DELETE_USER_YEKPAY_LINK_STARTED,
  DELETE_USER_YEKPAY_LINK_SUCCESS,
  EDIT_USER_PROFILE_FAILURE,
  EDIT_USER_PROFILE_STARTED,
  EDIT_USER_PROFILE_SUCCESS,
  EDIT_YOUTUBE_CHANNEL_CONTRACT_FAILURE,
  EDIT_YOUTUBE_CHANNEL_CONTRACT_STARTED,
  EDIT_YOUTUBE_CHANNEL_CONTRACT_SUCCESS,
  EDIT_YOUTUBE_CHANNEL_INFO_FAILURE,
  EDIT_YOUTUBE_CHANNEL_INFO_STARTED,
  EDIT_YOUTUBE_CHANNEL_INFO_SUCCESS,
  EXCHANGE_USER_CURRENCY_FAILURE,
  EXCHANGE_USER_CURRENCY_STARTED,
  EXCHANGE_USER_CURRENCY_SUCCESS,
  GET_PERSONAL_PAYLINKS_FAILURE,
  GET_PERSONAL_PAYLINKS_STARTED,
  GET_PERSONAL_PAYLINKS_SUCCESS,
  GET_PRODUCT_PAYLINKS_FAILURE,
  GET_PRODUCT_PAYLINKS_STARTED,
  GET_PRODUCT_PAYLINKS_SUCCESS,
  GET_USER_ADDRESS_FAILURE,
  GET_USER_ADDRESS_STARTED,
  GET_USER_ADDRESS_SUCCESS,
  GET_USER_BANK_ACCOUNTS_FAILURE,
  GET_USER_BANK_ACCOUNTS_STARTED,
  GET_USER_BANK_ACCOUNTS_SUCCESS,
  GET_USER_CHANGE_LOGS_FAILURE,
  GET_USER_CHANGE_LOGS_STARTED,
  GET_USER_CHANGE_LOGS_SUCCESS,
  GET_USER_COMMENTS_FAILURE,
  GET_USER_COMMENTS_STARTED,
  GET_USER_COMMENTS_SUCCESS,
  GET_USER_COMPANY_FAILURE,
  GET_USER_COMPANY_STARTED,
  GET_USER_COMPANY_SUCCESS,
  GET_USER_EXCHANGE_DETAIL_FAILURE,
  GET_USER_EXCHANGE_DETAIL_STARTED,
  GET_USER_EXCHANGE_DETAIL_SUCCESS,
  GET_USER_FAILED_TRANSACTIONS_FAILURE,
  GET_USER_FAILED_TRANSACTIONS_STARTED,
  GET_USER_FAILED_TRANSACTIONS_SUCCESS,
  GET_USER_FINANCE_FAILURE,
  GET_USER_FINANCE_STARTED,
  GET_USER_FINANCE_SUCCESS,
  GET_USER_GATEWAYS_FAILURE,
  GET_USER_GATEWAYS_STARTED,
  GET_USER_GATEWAYS_SUCCESS,
  GET_USER_ITSAAZ_FAILURE,
  GET_USER_ITSAAZ_STARTED,
  GET_USER_ITSAAZ_SUCCESS,
  GET_USER_LOGS_FAILURE,
  GET_USER_LOGS_STARTED,
  GET_USER_LOGS_SUCCESS,
  GET_USER_PLANS_FAILURE,
  GET_USER_PLANS_STARTED,
  GET_USER_PLANS_SUCCESS,
  GET_USER_PROFILE_DEFAULT,
  GET_USER_PROFILE_FAILURE,
  GET_USER_PROFILE_STARTED,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_TEXT_MESSAGES_FAILURE,
  GET_USER_TEXT_MESSAGES_STARTED,
  GET_USER_TEXT_MESSAGES_SUCCESS,
  GET_USER_TICKETS_FAILURE,
  GET_USER_TICKETS_STARTED,
  GET_USER_TICKETS_SUCCESS,
  GET_USER_TRANSACTIONS_FAILURE,
  GET_USER_TRANSACTIONS_STARTED,
  GET_USER_TRANSACTIONS_SUCCESS,
  GET_USER_UID_FAILURE,
  GET_USER_UID_STARTED,
  GET_USER_UID_SUCCESS,
  GET_USER_WALLETS_FAILURE,
  GET_USER_WALLETS_STARTED,
  GET_USER_WALLETS_SUCCESS,
  GET_USER_YEKPAY_LINKS_FAILURE,
  GET_USER_YEKPAY_LINKS_STARTED,
  GET_USER_YEKPAY_LINKS_SUCCESS,
  GET_USER_YOUTUBE_CHANNELS_FAILURE,
  GET_USER_YOUTUBE_CHANNELS_STARTED,
  GET_USER_YOUTUBE_CHANNELS_SUCCESS,
  GET_YOUTUBE_ARCHIVE_CONTRACTS_FAILURE,
  GET_YOUTUBE_ARCHIVE_CONTRACTS_STARTED,
  GET_YOUTUBE_ARCHIVE_CONTRACTS_SUCCESS,
  RESTORE_USER_BANK_ACCOUNT_FAILURE,
  RESTORE_USER_BANK_ACCOUNT_STARTED,
  RESTORE_USER_BANK_ACCOUNT_SUCCESS,
  TOPUP_USER_FAILURE,
  TOPUP_USER_STARTED,
  TOPUP_USER_SUCCESS,
} from "redux/types";
// --------------------------------------------------------------------------------
const initialState = {
  rType: GET_USER_PROFILE_DEFAULT,
  userData: {},
  itsaazData: [],
  uIdData: [],
  userComments: [],
  userWallets: [],
  userFinance: [],
  userYoutubeChannels: [],
  userCompany: [],
  userAddress: [],
  userPlans: [],
  userTransactions: [],
  userFaildTransactions: [],
  userBankAccounts: [],
  userTextMessages: [],
  userChangeLogs: [],
  archiveContracts: [],
  personalPaylinks: [],
  productPaylinks: [],
  error: "",
};
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_PROFILE_STARTED:
    case EDIT_USER_PROFILE_STARTED:
    case GET_USER_ITSAAZ_STARTED:
    case GET_USER_UID_STARTED:
    case CHANGE_USER_STATUS_STARTED:
    case GET_USER_COMMENTS_STARTED:
    case CREATE_USER_COMMENT_STARTED:
    case DELETE_USER_COMMENT_STARTED:
    case GET_USER_WALLETS_STARTED:
    case CREATE_USER_WALLET_STARTED:
    case DELETE_USER_WALLET_STARTED:
    case GET_USER_FINANCE_STARTED:
    case GET_USER_YOUTUBE_CHANNELS_STARTED:
    case EDIT_YOUTUBE_CHANNEL_INFO_STARTED:
    case EDIT_YOUTUBE_CHANNEL_CONTRACT_STARTED:
    case GET_USER_COMPANY_STARTED:
    case CHANGE_USER_COMPANY_STATUS_STARTED:
    case GET_USER_ADDRESS_STARTED:
    case CHANGE_USER_ADDRESS_STATUS_STARTED:
    case GET_USER_PLANS_STARTED:
    // case CREATE_USER_PLAN_STARTED:
    case GET_USER_TRANSACTIONS_STARTED:
    case GET_USER_FAILED_TRANSACTIONS_STARTED:
    case GET_USER_BANK_ACCOUNTS_STARTED:
    case CHANGE_USER_BANK_ACCOUNT_STATUS_STARTED:
    case DELETE_USER_BANK_ACCOUNT_STARTED:
    case RESTORE_USER_BANK_ACCOUNT_STARTED:
    case CREATE_USER_BANK_ACCOUNT_STARTED:
    case GET_USER_GATEWAYS_STARTED:
    case CHANGE_USER_GATEWAY_STATUS_STARTED:
    case GET_PERSONAL_PAYLINKS_STARTED:
    case GET_PRODUCT_PAYLINKS_STARTED:
    case CHANGE_PERSONAL_PAYLINK_STATUS_STARTED:
    case CHANGE_PRODUCT_PAYLINK_STATUS_STARTED:
    case GET_USER_YEKPAY_LINKS_STARTED:
    case CHANGE_USER_YEKPAY_LINK_STATUS_STARTED:
    case DELETE_USER_YEKPAY_LINK_STARTED:
    case GET_USER_TICKETS_STARTED:
    case GET_USER_LOGS_STARTED:
    case CREATE_PAYOUT_STARTED:
    case GET_USER_TEXT_MESSAGES_STARTED:
    case GET_USER_CHANGE_LOGS_STARTED:
    case TOPUP_USER_STARTED:
    case GET_USER_EXCHANGE_DETAIL_STARTED:
    case EXCHANGE_USER_CURRENCY_STARTED:
    case GET_YOUTUBE_ARCHIVE_CONTRACTS_STARTED:
      return {
        ...state,
        rType: action.type,
      };
    case GET_USER_PROFILE_SUCCESS:
    case CLEAR_USER_PROFILE_SUCCESS:
    case EDIT_USER_PROFILE_SUCCESS:
    case GET_USER_ITSAAZ_SUCCESS:
    case GET_USER_UID_SUCCESS:
    case CHANGE_USER_STATUS_SUCCESS:
    case GET_USER_COMMENTS_SUCCESS:
    case CREATE_USER_COMMENT_SUCCESS:
    case DELETE_USER_COMMENT_SUCCESS:
    case GET_USER_WALLETS_SUCCESS:
    case CREATE_USER_WALLET_SUCCESS:
    case DELETE_USER_WALLET_SUCCESS:
    case GET_USER_FINANCE_SUCCESS:
    case GET_USER_YOUTUBE_CHANNELS_SUCCESS:
    case EDIT_YOUTUBE_CHANNEL_INFO_SUCCESS:
    case EDIT_YOUTUBE_CHANNEL_CONTRACT_SUCCESS:
    case GET_USER_COMPANY_SUCCESS:
    case CHANGE_USER_COMPANY_STATUS_SUCCESS:
    case GET_USER_ADDRESS_SUCCESS:
    case CHANGE_USER_ADDRESS_STATUS_SUCCESS:
    case GET_USER_PLANS_SUCCESS:
    // case CREATE_USER_PLAN_SUCCESS:
    case GET_USER_TRANSACTIONS_SUCCESS:
    case GET_USER_FAILED_TRANSACTIONS_SUCCESS:
    case GET_USER_BANK_ACCOUNTS_SUCCESS:
    case CHANGE_USER_BANK_ACCOUNT_STATUS_SUCCESS:
    case DELETE_USER_BANK_ACCOUNT_SUCCESS:
    case RESTORE_USER_BANK_ACCOUNT_SUCCESS:
    case CREATE_USER_BANK_ACCOUNT_SUCCESS:
    case GET_USER_GATEWAYS_SUCCESS:
    case CHANGE_USER_GATEWAY_STATUS_SUCCESS:
    case GET_PERSONAL_PAYLINKS_SUCCESS:
    case GET_PRODUCT_PAYLINKS_SUCCESS:
    case CHANGE_PERSONAL_PAYLINK_STATUS_SUCCESS:
    case CHANGE_PRODUCT_PAYLINK_STATUS_SUCCESS:
    case GET_USER_YEKPAY_LINKS_SUCCESS:
    case CHANGE_USER_YEKPAY_LINK_STATUS_SUCCESS:
    case DELETE_USER_YEKPAY_LINK_SUCCESS:
    case GET_USER_TICKETS_SUCCESS:
    case GET_USER_LOGS_SUCCESS:
    case CREATE_PAYOUT_SUCCESS:
    case GET_USER_TEXT_MESSAGES_SUCCESS:
    case GET_USER_CHANGE_LOGS_SUCCESS:
    case TOPUP_USER_SUCCESS:
    case GET_USER_EXCHANGE_DETAIL_SUCCESS:
    case EXCHANGE_USER_CURRENCY_SUCCESS:
    case GET_YOUTUBE_ARCHIVE_CONTRACTS_SUCCESS:
      return {
        ...state,
        rType: action.type,
        ...action.payload,
        error: "",
      };
    case GET_USER_PROFILE_FAILURE:
    case EDIT_USER_PROFILE_FAILURE:
    case GET_USER_ITSAAZ_FAILURE:
    case GET_USER_UID_FAILURE:
    case CHANGE_USER_STATUS_FAILURE:
    case GET_USER_COMMENTS_FAILURE:
    case CREATE_USER_COMMENT_FAILURE:
    case DELETE_USER_COMMENT_FAILURE:
    case GET_USER_WALLETS_FAILURE:
    case CREATE_USER_WALLET_FAILURE:
    case DELETE_USER_WALLET_FAILURE:
    case GET_USER_FINANCE_FAILURE:
    case GET_USER_YOUTUBE_CHANNELS_FAILURE:
    case EDIT_YOUTUBE_CHANNEL_INFO_FAILURE:
    case EDIT_YOUTUBE_CHANNEL_CONTRACT_FAILURE:
    case GET_USER_COMPANY_FAILURE:
    case CHANGE_USER_COMPANY_STATUS_FAILURE:
    case GET_USER_ADDRESS_FAILURE:
    case CHANGE_USER_ADDRESS_STATUS_FAILURE:
    case GET_USER_PLANS_FAILURE:
    // case CREATE_USER_PLAN_FAILURE:
    case GET_USER_TRANSACTIONS_FAILURE:
    case GET_USER_FAILED_TRANSACTIONS_FAILURE:
    case GET_USER_BANK_ACCOUNTS_FAILURE:
    case CHANGE_USER_BANK_ACCOUNT_STATUS_FAILURE:
    case DELETE_USER_BANK_ACCOUNT_FAILURE:
    case RESTORE_USER_BANK_ACCOUNT_FAILURE:
    case CREATE_USER_BANK_ACCOUNT_FAILURE:
    case GET_USER_GATEWAYS_FAILURE:
    case CHANGE_USER_GATEWAY_STATUS_FAILURE:
    case GET_PERSONAL_PAYLINKS_FAILURE:
    case GET_PRODUCT_PAYLINKS_FAILURE:
    case CHANGE_PERSONAL_PAYLINK_STATUS_FAILURE:
    case CHANGE_PRODUCT_PAYLINK_STATUS_FAILURE:
    case GET_USER_YEKPAY_LINKS_FAILURE:
    case CHANGE_USER_YEKPAY_LINK_STATUS_FAILURE:
    case DELETE_USER_YEKPAY_LINK_FAILURE:
    case GET_USER_TICKETS_FAILURE:
    case GET_USER_LOGS_FAILURE:
    case CREATE_PAYOUT_FAILURE:
    case GET_USER_TEXT_MESSAGES_FAILURE:
    case GET_USER_CHANGE_LOGS_FAILURE:
    case TOPUP_USER_FAILURE:
    case GET_USER_EXCHANGE_DETAIL_FAILURE:
    case EXCHANGE_USER_CURRENCY_FAILURE:
    case GET_YOUTUBE_ARCHIVE_CONTRACTS_FAILURE:
      return {
        ...state,
        rType: action.type,
        ...action.payload,
      };
    default:
      return state;
  }
};
