import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import {
  token,
  API_GET_ERRORS,
  API_Update_ERRORS,
  API_CREATE_ERRORS,
  API_DELETE_ERRORS,
} from "constants/constants";

function ErrorCodes() {
  useEffect(() => {
    document.title = "Yekpay | FAQ - Admin Panel";
  });

  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({});

  const options = {
    columnsButton: false,
    actionsColumnIndex: state.columns && state.columns.length,
    search: true,
    exportButton: true,
    grouping: false,
    filtering: false,
    exportAllData: true,
    selection: false,
    editable: true,
    pageSize: 10,
  };
  const localization = {
    header: {
      actions: "",
    },
  };
  useEffect(() => {
    async function fetchData() {
      await axios.post(API_GET_ERRORS, {}, token()).then((res) => {
        let data = res.data.data;
        setState({
          columns: [
            { title: "Code", field: "code" },
            { title: "Persian", field: "fa" },
          ],
          data,
        });
      });
      setLoading(false);
    }

    fetchData();
  }, []);

  function updateRow(id, code, en, fa, tr) {
    axios.post(API_Update_ERRORS, { id, code, en, fa, tr }, token());
  }

  function addRow(code, en, fa, tr) {
    axios.post(API_CREATE_ERRORS, { code, en, fa, tr }, token());
  }

  function deleteRow(id) {
    axios.post(API_DELETE_ERRORS, { id }, token());
  }

  return (
    <div className="app-wrapper">
      <MaterialTable
        options={options}
        localization={localization}
        isLoading={loading}
        columns={state.columns}
        data={state.data}
        title="FAQ"
        detailPanel={(rowData) => {
          return (
            <div
              style={{
                fontSize: 24,
                textAlign: "center",
                color: "white",
                backgroundColor: "#43A047",
              }}
            >
              {rowData.fa}
            </div>
          );
        }}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                resolve();
                const data = [...state.data];
                data.push(newData);
                addRow(newData.code, newData.en, newData.fa, newData.tr);
                setState({ ...state, data });
              }, 600);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                resolve();
                const data = [...state.data];
                data[data.indexOf(oldData)] = newData;
                updateRow(
                  oldData.id,
                  newData.code,
                  newData.en,
                  newData.fa,
                  newData.tr
                );
                setState({ ...state, data });
              }, 600);
            }),

          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                resolve();
                const data = [...state.data];
                data.splice(data.indexOf(oldData), 1);
                deleteRow(oldData.id);
                setState({ ...state, data });
              }, 600);
            }),
        }}
      />
    </div>
  );
}

export default ErrorCodes;
