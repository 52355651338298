import React from 'react';
import Switch from '@mui/material/Switch';

const AppSwitch = (props) => {
  return (
    <div className="app-switch col-lg-6">
      <span>{props.title}</span>
      <Switch
        classes={{
          checked: 'text-primary'
        }}
        checked={props.checked}
        onChange={props.onChange}
      />
    </div>
  );
};

export default AppSwitch;
