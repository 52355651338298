import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Profile from "./components";

const UserProfile = ({ match, location }) => {
  const pathArr = location.pathname.split("/");
  const id = pathArr[pathArr.length - 1];

  return (
    <div>
      <Switch>
        <Route
          path={`${match.url}/user/:id/:section`}
          component={() => <Profile />}
        />
        <Route path={`${match.url}/user/:id`}>
          <Redirect to={`${match.url}/user/${id}/Transactions`} />
        </Route>
      </Switch>
    </div>
  );
};

export default UserProfile;
