import React from "react";
import {
  NavHeader,
  NavMenu,
  NavWithSubmenu,
} from "containers/SideNav/components";
import { logOut } from "constants/usefulFunctions";
import { useSelector } from "react-redux";

const Settings = () => {
  const { adminId, adminRoleId } = useSelector((state) => state.authReducer);

  return (
    <>
      <NavHeader title="sidebar.settings" />

      <NavMenu
        title="sidebar.general"
        icon="zmdi zmdi-wrench zmdi-hc-fw"
        url="/app/general"
      />

      <NavWithSubmenu
        title="sidebar.system"
        icon="zmdi zmdi-settings zmdi-hc-fw"
        subMenuList={[
          { title: "sidebar.system.Admins", url: "/app/system/admins" },
          {
            title: "sidebar.system.Permissions",
            url: "/app/system/permissions",
          },
          {
            title: "sidebar.system.ErrorCodes",
            url: "/app/system/error-codes",
          },
          {
            title: "sidebar.system.ExchangeRate",
            url: "/app/system/exchange-rate",
          },
          {
            title: "sidebar.system.ExchangeTransactions",
            url: "/app/system/exchange-transactions",
          },
          {
            title: "sidebar.system.RollcallManagement",
            url: "/app/system/rollcall-management",
            showMenu: adminRoleId === "1",
          },
        ]}
      />

      <NavWithSubmenu
        title="sidebar.profile"
        icon="zmdi zmdi-account zmdi-hc-fw"
        subMenuList={[
          {
            title: "sidebar.profile.rollcall",
            url: "/app/admin-profile/rollcall",
          },
          {
            title: "sidebar.profile.change.password",
            url: "/app/admin-profile/change-password",
          },
          {
            title: "sidebar.profile.logout",
            url: "/app/admin-profile/logout",
            onClick: logOut,
          },
        ]}
      />
    </>
  );
};

export default Settings;
