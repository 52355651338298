import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import Button from "@mui/material/Button";
import { downloadBase64 } from "constants/usefulFunctions";

function AttachmentFile({
  customer_file_contract,
  customer_file_contractChange,
  customer_file_commitment,
  customer_file_commitmentChange,
  customer_file_melli_card,
  customer_file_melli_cardChange,
  customer_file_newsletter,
  customer_file_newsletterChange,
  customer_file_statute,
  customer_file_statuteChange,
  logo_file,
  logo_fileChange,
}) {
  const useStyles = makeStyles((theme) => ({
    root: {
      "& > *": {
        margin: theme.spacing(1),
        width: "65%",
      },
    },
  }));
  const classes = useStyles();
  return (
    <div>
      <div className="row">
        <div className="jr-card p-0 col-xl-12 col-lg-12 col-md-12 col-12 ">
          <div className="jr-card-header card-img-top mb-0 p-4 bg-grey lighten-4">
            <h3 className="card-heading">File Attachment</h3>
          </div>
          <div className="card-body ">
            <ul className="contact-list list-unstyled">
              <div className="row">
                <div className=" col-xl-12 col-lg-12 col-md-12 col-12 ">
                  <div className="row">
                    <div className=" col-xl-3 col-lg-2 col-md-6 col-12 ">
                      <label>Internet acceptors contract form: </label>
                    </div>
                    <div className=" col-xl-2 col-lg-2 col-md-6 col-12 ">
                      <Button
                        variant="contained"
                        component="label"
                        className="bg-light jr-btn jr-btn-sm text-dark"
                      >
                        <i className="zmdi zmdi-file-plus mr-2 jr-fs-xl d-inline-flex align-middle" />
                        Choose File
                        <input
                          multiple
                          type="file"
                          onChange={customer_file_contractChange}
                          style={{ display: "none" }}
                        />
                      </Button>
                    </div>
                    <div className=" col-xl-7 col-lg-6 col-md-12 col-12 ">
                      {customer_file_contract && (
                        <>
                          <a
                            role="button"
                            className="ml-2"
                            target="_blank"
                            onClick={() =>
                              downloadBase64(customer_file_contract)
                            }
                          >
                            <i className="zmdi zmdi-attachment zmdi-hc-fw zmdi-hc-lg text-primary" />
                          </a>
                          <lable>
                            File Name:{" "}
                            <span className="text-success mr-2">
                              {customer_file_contract.name}
                            </span>
                            File Size:{" "}
                            <span className="text-success">
                              {Math.trunc(customer_file_contract.size / 1024)}KB
                            </span>
                          </lable>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className=" col-xl-12 col-lg-12 col-md-12 col-12 mt-3">
                  <div className="row">
                    <div className=" col-xl-3 col-lg-2 col-md-6 col-12 ">
                      <label>Internet acceptance commitment form: </label>
                    </div>
                    <div className=" col-xl-2 col-lg-2 col-md-6 col-12 ">
                      <Button
                        variant="contained"
                        component="label"
                        className="bg-light jr-btn jr-btn-sm text-dark"
                      >
                        <i className="zmdi zmdi-file-plus mr-2 jr-fs-xl d-inline-flex align-middle" />
                        Choose File
                        <input
                          multiple
                          type="file"
                          onChange={customer_file_commitmentChange}
                          style={{ display: "none" }}
                        />
                      </Button>
                    </div>
                    <div className=" col-xl-7 col-lg-6 col-md-12 col-12 ">
                      {customer_file_commitment && (
                        <>
                          <a
                            role="button"
                            className="ml-2"
                            target="_blank"
                            onClick={() =>
                              downloadBase64(customer_file_commitment)
                            }
                          >
                            <i className="zmdi zmdi-attachment zmdi-hc-fw zmdi-hc-lg text-primary" />
                          </a>
                          <lable>
                            File Name:{" "}
                            <span className="text-success mr-2">
                              {customer_file_commitment.name}
                            </span>
                            File Size:{" "}
                            <span className="text-success">
                              {Math.trunc(customer_file_commitment.size / 1024)}
                              KB
                            </span>
                          </lable>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className=" col-xl-12 col-lg-12 col-md-12 col-12 mt-3">
                  <div className="row">
                    <div className=" col-xl-3 col-lg-2 col-md-6 col-12 ">
                      <label>National card image and identity card: </label>
                    </div>
                    <div className=" col-xl-2 col-lg-2 col-md-6 col-12 ">
                      <Button
                        variant="contained"
                        component="label"
                        className="bg-light jr-btn jr-btn-sm text-dark"
                      >
                        <i className="zmdi zmdi-file-plus mr-2 jr-fs-xl d-inline-flex align-middle" />
                        Choose File
                        <input
                          multiple
                          type="file"
                          onChange={customer_file_melli_cardChange}
                          style={{ display: "none" }}
                        />
                      </Button>
                    </div>
                    <div className=" col-xl-7 col-lg-6 col-md-12 col-12 ">
                      {customer_file_melli_card && (
                        <>
                          <a
                            role="button"
                            className="ml-2"
                            target="_blank"
                            onClick={() =>
                              downloadBase64(customer_file_melli_card)
                            }
                          >
                            <i className="zmdi zmdi-attachment zmdi-hc-fw zmdi-hc-lg text-primary" />
                          </a>
                          <lable>
                            File Name:{" "}
                            <span className="text-success mr-2">
                              {customer_file_melli_card.name}
                            </span>
                            File Size:{" "}
                            <span className="text-success">
                              {Math.trunc(customer_file_melli_card.size / 1024)}
                              KB
                            </span>
                          </lable>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className=" col-xl-12 col-lg-12 col-md-12 col-12 mt-3">
                  <div className="row">
                    <div className=" col-xl-3 col-lg-2 col-md-6 col-12 ">
                      <label>
                        The official newspaper announces the latest changes:{" "}
                      </label>
                    </div>
                    <div className=" col-xl-2 col-lg-2 col-md-6 col-12 ">
                      <Button
                        variant="contained"
                        component="label"
                        className="bg-light jr-btn jr-btn-sm text-dark"
                      >
                        <i className="zmdi zmdi-file-plus mr-2 jr-fs-xl d-inline-flex align-middle" />
                        Choose File
                        <input
                          multiple
                          type="file"
                          onChange={customer_file_newsletterChange}
                          style={{ display: "none" }}
                        />
                      </Button>
                    </div>
                    <div className=" col-xl-7 col-lg-6 col-md-12 col-12 ">
                      {customer_file_newsletter && (
                        <>
                          <a
                            role="button"
                            className="ml-2"
                            target="_blank"
                            onClick={() =>
                              downloadBase64(customer_file_newsletter)
                            }
                          >
                            <i className="zmdi zmdi-attachment zmdi-hc-fw zmdi-hc-lg text-primary" />
                          </a>
                          <lable>
                            File Name:{" "}
                            <span className="text-success mr-2">
                              {customer_file_newsletter.name}
                            </span>
                            File Size:{" "}
                            <span className="text-success">
                              {Math.trunc(customer_file_newsletter.size / 1024)}
                              KB
                            </span>
                          </lable>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className=" col-xl-12 col-lg-12 col-md-12 col-12 mt-3">
                  <div className="row">
                    <div className=" col-xl-3 col-lg-2 col-md-6 col-12 ">
                      <label>
                        Establishment announcement or company articles of
                        association:{" "}
                      </label>
                    </div>
                    <div className=" col-xl-2 col-lg-2 col-md-6 col-12 ">
                      <Button
                        variant="contained"
                        component="label"
                        className="bg-light jr-btn jr-btn-sm text-dark"
                      >
                        <i className="zmdi zmdi-file-plus mr-2 jr-fs-xl d-inline-flex align-middle" />
                        Choose File
                        <input
                          multiple
                          type="file"
                          onChange={customer_file_statuteChange}
                          style={{ display: "none" }}
                        />
                      </Button>
                    </div>
                    <div className=" col-xl-7 col-lg-6 col-md-12 col-12 ">
                      {customer_file_statute && (
                        <>
                          <a
                            role="button"
                            className="ml-2"
                            target="_blank"
                            onClick={() =>
                              downloadBase64(customer_file_statute)
                            }
                          >
                            <i className="zmdi zmdi-attachment zmdi-hc-fw zmdi-hc-lg text-primary" />
                          </a>
                          <lable>
                            File Name:{" "}
                            <span className="text-success mr-2">
                              {customer_file_statute.name}
                            </span>
                            File Size:{" "}
                            <span className="text-success">
                              {Math.trunc(customer_file_statute.size / 1024)}KB
                            </span>
                          </lable>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className=" col-xl-12 col-lg-12 col-md-12 col-12 mt-3">
                  <div className="row">
                    <div className=" col-xl-3 col-lg-2 col-md-6 col-12 ">
                      <label>Logo File: </label>
                    </div>
                    <div className=" col-xl-2 col-lg-2 col-md-6 col-12 ">
                      <Button
                        variant="contained"
                        component="label"
                        className="bg-light jr-btn jr-btn-sm text-dark"
                      >
                        <i className="zmdi zmdi-file-plus mr-2 jr-fs-xl d-inline-flex align-middle" />
                        Choose File
                        <input
                          multiple
                          type="file"
                          onChange={logo_fileChange}
                          style={{ display: "none" }}
                        />
                      </Button>
                    </div>
                    <div className=" col-xl-7 col-lg-6 col-md-12 col-12 ">
                      {logo_file && (
                        <>
                          <a
                            role="button"
                            className="ml-2"
                            target="_blank"
                            onClick={() => downloadBase64(logo_file)}
                          >
                            <i className="zmdi zmdi-attachment zmdi-hc-fw zmdi-hc-lg text-primary" />
                          </a>
                          <lable>
                            File Name:{" "}
                            <span className="text-success mr-2">
                              {logo_file.name}
                            </span>
                            File Size:{" "}
                            <span className="text-success">
                              {Math.trunc(logo_file.size / 1024)}KB
                            </span>
                          </lable>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AttachmentFile;
