import {
  ADD_TICKET_COMMENT_FAILURE,
  ADD_TICKET_COMMENT_STARTED,
  ADD_TICKET_COMMENT_SUCCESS,
  CHANGE_TICKET_ROLE_FAILURE,
  CHANGE_TICKET_ROLE_STARTED,
  CHANGE_TICKET_ROLE_SUCCESS,
  CHANGE_TICKET_STATUS_FAILURE,
  CHANGE_TICKET_STATUS_STARTED,
  CHANGE_TICKET_STATUS_SUCCESS,
  CREATE_NEW_TICKET_FAILURE,
  CREATE_NEW_TICKET_STARTED,
  CREATE_NEW_TICKET_SUCCESS,
  CREATE_REPLY_TICKET_FAILURE,
  CREATE_REPLY_TICKET_STARTED,
  CREATE_REPLY_TICKET_SUCCESS,
  DELETE_TICKET_FAILURE,
  DELETE_TICKET_STARTED,
  DELETE_TICKET_SUCCESS,
  GET_ALL_PENDING_BANK_ACCOUNT_FAILURE,
  GET_ALL_PENDING_BANK_ACCOUNT_STARTED,
  GET_ALL_PENDING_BANK_ACCOUNT_SUCCESS,
  GET_ALL_PENDING_BILLING_ADDRESS_FAILURE,
  GET_ALL_PENDING_BILLING_ADDRESS_STARTED,
  GET_ALL_PENDING_BILLING_ADDRESS_SUCCESS,
  GET_ALL_PENDING_IDENTITY_FAILURE,
  GET_ALL_PENDING_IDENTITY_STARTED,
  GET_ALL_PENDING_IDENTITY_SUCCESS,
  GET_ALL_TICKETS_FAILURE,
  GET_ALL_TICKETS_STARTED,
  GET_ALL_TICKETS_SUCCESS,
  GET_ALL_USERS_FAILURE,
  GET_ALL_USERS_STARTED,
  GET_ALL_USERS_SUCCESS,
  GET_AYANDE_BALANCE_FAILURE,
  GET_AYANDE_BALANCE_STARTED,
  GET_AYANDE_BALANCE_SUCCESS,
  GET_BLOCKED_USERS_FAILURE,
  GET_BLOCKED_USERS_STARTED,
  GET_BLOCKED_USERS_SUCCESS,
  GET_BUSINESS_USERS_FAILURE,
  GET_BUSINESS_USERS_STARTED,
  GET_BUSINESS_USERS_SUCCESS,
  GET_DASHBOARD_INFO_DEFAULT,
  GET_DASHBOARD_INFO_FAILURE,
  GET_DASHBOARD_INFO_STARTED,
  GET_DASHBOARD_INFO_SUCCESS,
  GET_ELASTIC_BALANCE_FAILURE,
  GET_ELASTIC_BALANCE_STARTED,
  GET_ELASTIC_BALANCE_SUCCESS,
  GET_FRAUD_SUSPECT_USERS_STARTED,
  GET_FRAUD_SUSPECT_USERS_SUCCESS,
  GET_KAVENEGAR_BALANCE_FAILURE,
  GET_KAVENEGAR_BALANCE_STARTED,
  GET_KAVENEGAR_BALANCE_SUCCESS,
  GET_OPEN_TICKETS_FAILURE,
  GET_OPEN_TICKETS_STARTED,
  GET_OPEN_TICKETS_SUCCESS,
  GET_PARSIAN_BALANCE_FAILURE,
  GET_PARSIAN_BALANCE_STARTED,
  GET_PARSIAN_BALANCE_SUCCESS,
  GET_PASARGAD_BALANCE_FAILURE,
  GET_PASARGAD_BALANCE_STARTED,
  GET_PASARGAD_BALANCE_SUCCESS,
  GET_STRIPE_INVOICE_FAILURE,
  GET_STRIPE_INVOICE_STARTED,
  GET_STRIPE_INVOICE_SUCCESS,
  GET_TICKET_DETAILS_FAILURE,
  GET_TICKET_DETAILS_STARTED,
  GET_TICKET_DETAILS_SUCCESS,
} from "redux/types";
// --------------------------------------------------------------------------------
const initialState = {
  rType: GET_DASHBOARD_INFO_DEFAULT,
  dashboardData: {},
  parsianBalance: "0",
  pasargadBalance: "0",
  ayandeBalance: "0",
  kavenegarBalance: "0",
  elasticBalance: "0",
  pendingIdentity: [],
  pendingBankAccount: [],
  pendingBillingAddress: [],
  fraudSuspectUsers: [],
  blockedUsers: [],
  users: {},
  businessUsers: [],
  tickets: [],
  openTickets: [],
  ticketDetails: [],
  error: "",
};
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_INFO_STARTED:
    case GET_PARSIAN_BALANCE_STARTED:
    case GET_PASARGAD_BALANCE_STARTED:
    case GET_AYANDE_BALANCE_STARTED:
    case GET_KAVENEGAR_BALANCE_STARTED:
    case GET_ELASTIC_BALANCE_STARTED:
    case GET_ALL_PENDING_IDENTITY_STARTED:
    case GET_ALL_PENDING_BANK_ACCOUNT_STARTED:
    case GET_ALL_PENDING_BILLING_ADDRESS_STARTED:
    case GET_FRAUD_SUSPECT_USERS_STARTED:
    case GET_BLOCKED_USERS_STARTED:
    case GET_ALL_USERS_STARTED:
    case GET_BUSINESS_USERS_STARTED:
    case GET_ALL_TICKETS_STARTED:
    case GET_OPEN_TICKETS_STARTED:
    case CREATE_NEW_TICKET_STARTED:
    case GET_TICKET_DETAILS_STARTED:
    case CHANGE_TICKET_STATUS_STARTED:
    case CREATE_REPLY_TICKET_STARTED:
    case CHANGE_TICKET_ROLE_STARTED:
    case DELETE_TICKET_STARTED:
    case ADD_TICKET_COMMENT_STARTED:
    case GET_STRIPE_INVOICE_STARTED:
      return {
        ...state,
        rType: action.type,
      };
    case GET_DASHBOARD_INFO_SUCCESS:
    case GET_PARSIAN_BALANCE_SUCCESS:
    case GET_PASARGAD_BALANCE_SUCCESS:
    case GET_AYANDE_BALANCE_SUCCESS:
    case GET_KAVENEGAR_BALANCE_SUCCESS:
    case GET_ELASTIC_BALANCE_SUCCESS:
    case GET_ALL_PENDING_IDENTITY_SUCCESS:
    case GET_ALL_PENDING_BANK_ACCOUNT_SUCCESS:
    case GET_ALL_PENDING_BILLING_ADDRESS_SUCCESS:
    case GET_FRAUD_SUSPECT_USERS_SUCCESS:
    case GET_BLOCKED_USERS_SUCCESS:
    case GET_ALL_USERS_SUCCESS:
    case GET_BUSINESS_USERS_SUCCESS:
    case GET_ALL_TICKETS_SUCCESS:
    case GET_OPEN_TICKETS_SUCCESS:
    case CREATE_NEW_TICKET_SUCCESS:
    case GET_TICKET_DETAILS_SUCCESS:
    case CHANGE_TICKET_STATUS_SUCCESS:
    case CREATE_REPLY_TICKET_SUCCESS:
    case CHANGE_TICKET_ROLE_SUCCESS:
    case DELETE_TICKET_SUCCESS:
    case ADD_TICKET_COMMENT_SUCCESS:
    case GET_STRIPE_INVOICE_SUCCESS:
      return {
        ...state,
        rType: action.type,
        ...action.payload,
        error: "",
      };
    case GET_DASHBOARD_INFO_FAILURE:
    case GET_PARSIAN_BALANCE_FAILURE:
    case GET_PASARGAD_BALANCE_FAILURE:
    case GET_AYANDE_BALANCE_FAILURE:
    case GET_KAVENEGAR_BALANCE_FAILURE:
    case GET_ELASTIC_BALANCE_FAILURE:
    case GET_ALL_PENDING_IDENTITY_FAILURE:
    case GET_ALL_PENDING_BANK_ACCOUNT_FAILURE:
    case GET_ALL_PENDING_BILLING_ADDRESS_FAILURE:
    case GET_FRAUD_SUSPECT_USERS_SUCCESS:
    case GET_BLOCKED_USERS_FAILURE:
    case GET_ALL_USERS_FAILURE:
    case GET_BUSINESS_USERS_FAILURE:
    case GET_ALL_TICKETS_FAILURE:
    case GET_OPEN_TICKETS_FAILURE:
    case CREATE_NEW_TICKET_FAILURE:
    case GET_TICKET_DETAILS_FAILURE:
    case CHANGE_TICKET_STATUS_FAILURE:
    case CREATE_REPLY_TICKET_FAILURE:
    case CHANGE_TICKET_ROLE_FAILURE:
    case DELETE_TICKET_FAILURE:
    case ADD_TICKET_COMMENT_FAILURE:
    case GET_STRIPE_INVOICE_FAILURE:
      return {
        ...state,
        rType: action.type,
        ...action.payload,
      };
    default:
      return state;
  }
};
