import React, { useState, useEffect, Fragment } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Toolbar,
  Typography,
  Tooltip,
  TextField,
  InputAdornment,
  IconButton,
  Menu,
  MenuItem,
  CircularProgress,
  Box,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { CsvBuilder } from "filefy";
import jsPDF from "jspdf";
import Row from "./TableRow";
// --------------------------------------------------------------------------------
const MuiTable = ({
  title,
  columns,
  rows,
  getDetailPanelContent,
  hideToolbar,
  hideTooltip,
  hideFooterPagination,
  header,
  loading,
}) => {
  const [data, setData] = useState(rows);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(false);

  useEffect(() => setData(rows), [rows]);

  useEffect(() => {
    if (searchText.length) {
      let obj = [];
      data.filter((item) => {
        for (const i in item) {
          if (item[i] && typeof item[i] === "string") {
            if (
              item[i].toLowerCase().includes(searchText.toLowerCase()) &&
              !obj.includes(item)
            ) {
              obj.push(item);
            }
          }
        }
        setFilteredData(obj);
      });
    } else {
      setFilteredData(data);
    }
  }, [searchText, data]);

  const handleOnSearchTextChange = (event) => {
    setSearchText(event.target.value);
  };

  const onKeyDown = (e) => {
    e.stopPropagation();
    // e.preventDefault()
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const exportTableCSV = () => {
    let filteredColumns = columns.filter((column) => "field" in column);

    let columnData = filteredColumns.map((c) => c.headerName);
    let rowData = filteredData.map((data) =>
      filteredColumns.map((column) => data[column.field])
    );

    let csvBuilder = new CsvBuilder(`${title}.csv`)
      .setDelimeter(",")
      .setColumns(columnData)
      .addRows(rowData)
      .exportFile();

    setAnchorEl(null);
  };

  const exportTablePDF = () => {
    let filteredColumns = columns.filter((column) => "field" in column);

    let columnData = filteredColumns.map((c) => c.headerName);
    let rowData = filteredData.map((row) =>
      filteredColumns.map((column) => row[column.field])
    );

    let doc = new jsPDF("l", "mm", [297, 210]);
    doc.autoTable({
      head: [columnData],
      body: rowData,
      theme: "grid",
    });

    doc.save(`${title}.pdf`);
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <Paper sx={{ position: "relative" }}>
        {loading && (
          <Box
            sx={{
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              left: 0,
              right: 0,
              bottom: 0,
              top: 0,
              zIndex: 1,
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {!hideToolbar && (
          <Fragment>
            <Toolbar>
              <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                {title}
              </Typography>
              {!hideTooltip && (
                <>
                  <Tooltip title="Filter list">
                    <TextField
                      placeholder="Filter..."
                      inputProps={{ "aria-label": "search" }}
                      className="mr-3"
                      variant={"standard"}
                      onChange={handleOnSearchTextChange}
                      value={searchText}
                      onKeyDown={onKeyDown}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Tooltip>
                  <IconButton
                    id="basic-button"
                    aria-controls={anchorEl ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={anchorEl ? "true" : undefined}
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                    variant="contained"
                    size="large"
                  >
                    <FileDownloadOutlinedIcon />
                  </IconButton>
                </>
              )}
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={exportTableCSV}>Export as CSV</MenuItem>
                <MenuItem onClick={exportTablePDF}>Export as PDF</MenuItem>
              </Menu>
            </Toolbar>
            {header && <Toolbar>{header}</Toolbar>}
          </Fragment>
        )}
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {columns.map((c) => (
                  <TableCell key={c.field} align="right">
                    {c.headerName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? filteredData.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : filteredData
              ).map((r) => (
                <Row
                  key={r.id}
                  columns={columns}
                  row={r}
                  getDetailPanelContent={getDetailPanelContent}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {!hideFooterPagination && (
          <TablePagination
            rowsPerPageOptions={[20, 50, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </Fragment>
  );
};

export default MuiTable;
