import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";
import Banks from "./routes/Banks";

const FinancialReports = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/Income`} />
      <Route
        path={`${match.url}/income`}
        component={asyncComponent(() => import("./routes/Income"))}
      />
      <Route
        path={`${match.url}/transactions-flow`}
        component={asyncComponent(() => import("./routes/TransactionsFlow"))}
      />
      <Route
        path={`${match.url}/debts-reports`}
        component={asyncComponent(() => import("./routes/DebtsReports"))}
      />
      <Route
        path={`${match.url}/exchange-rate`}
        component={asyncComponent(() => import("./routes/ExchangeRateDiagram"))}
      />
      <Route
        path={`${match.url}/transactions-bills`}
        component={asyncComponent(() => import("./routes/TransactionsBills"))}
      />
      <Route
        path={`${match.url}/transactions-bills2`}
        component={asyncComponent(() => import("./routes/TransactionsBills2"))}
      />
      <Route
        path={`${match.url}/users-balance`}
        component={asyncComponent(() => import("./routes/UsersBalance"))}
      />
      <Route
        path={`${match.url}/send-money`}
        component={asyncComponent(() => import("./routes/SendMoney"))}
      />
      <Route
        path={`${match.url}/total`}
        component={asyncComponent(() => import("./routes/Total"))}
      />
      <Route path={`${match.url}/banks`} component={Banks} />
    </Switch>
  </div>
);

export default FinancialReports;
