import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import SweetAlert from 'react-bootstrap-sweetalert';
import makeStyles from '@mui/styles/makeStyles';
import { NotificationContainer } from 'react-notifications';
import CircularProgress from '@mui/material/CircularProgress';
import WidgetHeader from 'components/WidgetHeader';
import { API_UPDATE_ALL_SETTING, boot, token } from 'constants/constants';
import axios from 'axios';

const FirstAssignee = ({ state, refresh }) => {
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [values, setValues] = useState({ success: false, error: false });
  const [status, setStatus] = useState('');

  useEffect(() => {
    state.map((item) => {
      return item.key === 'PAYOUT_SERVICE' ? setStatus(item.value) : '';
    });
  }, []);

  const handleChange = (prop) => {
    setStatus(prop.target.value);
  };

  function handleSave() {
    setLoadingStatus(true);
    axios
      .post(API_UPDATE_ALL_SETTING, { key: 'PAYOUT_SERVICE', value: status }, token())
      .then((response) => {
        if (response.status === 200 && response.data.status === 'success') {
          setValues({ ...values, success: true });
          refresh();
        } else {
          setValues({ ...values, error: true });
        }
        setLoadingStatus(false);
      })
      .catch((error) => {
        let failedValue = '';
        if (typeof error.response.data.data === 'string') {
          failedValue = error.response.data.data;
        } else {
          for (let prop in error.response.data.data) {
            failedValue += prop + ': ' + error.response.data.data[prop] + ' - ';
          }
        }
        setValues({ ...values, error: true, code: failedValue });
        setLoadingStatus(false);
      });
  }

  function onConfirm() {
    setValues({ ...values, success: false, error: false });
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '65%'
      }
    }
  }));
  const classes = useStyles();
  return (
    <div className="jr-entry-sec">
      <WidgetHeader title="IRR Payout Service" />
      <div className="jr-card p-0">
        <div className="card-body">
          <div className="row">
            <NotificationContainer />
            <SweetAlert
              show={values.success}
              success
              title={'Success'}
              confirmBtnText={'Close'}
              onConfirm={onConfirm}>
              Successfully Changed!
            </SweetAlert>
            <SweetAlert
              show={values.error}
              error
              title={'Error'}
              confirmBtnText={'Close'}
              onConfirm={onConfirm}>
              Error! {values.code ? values.code : ''}
            </SweetAlert>
            <div className="col-xl-12 col-lg-12 col-md-12 col-12 text-center">
              <div className={classes.root}>
                <TextField
                  select
                  fullWidth
                  label="Assignee"
                  value={status}
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange}
                  margin="normal"
                  variant="outlined">
                  <MenuItem key="Parsian" value="Parsian">
                    Parsian{' '}
                  </MenuItem>
                  <MenuItem key="Finnotech" value="Finnotech">
                    Finnotech{' '}
                  </MenuItem>
                  <MenuItem key="Sanbod" value="Sanbod">
                    Sanbod{' '}
                  </MenuItem>
                </TextField>
              </div>
              <div className="text-center mt-2">
                {
                  <Button
                    disabled={loadingStatus}
                    style={{ width: '100px' }}
                    className="jr-btn jr-btn-lg bg-primary text-white MuiButton-contained"
                    onClick={handleSave}>
                    {loadingStatus ? <CircularProgress color="inherit" size={15} /> : 'Save'}
                  </Button>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstAssignee;
