import React, { Fragment, useState } from "react";
import {
  Box,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// --------------------------------------------------------------------------------
export const Row = ({ columns, row, getDetailPanelContent }) => {
  const [open, setOpen] = useState(false);
  return (
    <Fragment>
      <TableRow hover>
        <TableCell>
          {row?.users?.length > 0 || row?.history?.length > 0 ? (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          ) : null}
        </TableCell>
        {columns.map((column) => (
          <TableCell key={column.field}>
            {column.renderCell ? column.renderCell(row) : row[column.field]}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {getDetailPanelContent ? getDetailPanelContent(row) : null}
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export default Row;
