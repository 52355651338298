import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { Currencies, Events } from "constants/constants";
import {
  thousands_separators,
  eventCode,
  createDateLookup,
} from "../../../../../constants/usefulFunctions";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { getUserFailedTransactionsRequest } from "redux/actions/UserProfile";
import { GET_USER_FAILED_TRANSACTIONS_STARTED } from "redux/types";

function TransactionsFailed({ user_id }) {
  const dispatch = useDispatch();
  const { rType, userFailedTransactions } = useSelector(
    (state) => state.userProfileReducer
  );
  const [state, setState] = useState({});
  const loading = rType === GET_USER_FAILED_TRANSACTIONS_STARTED;

  const options = {
    columnsButton: true,
    actionsColumnIndex: state.columns && state.columns.length,
    search: true,
    exportButton: true,
    grouping: false,
    filtering: true,
    exportAllData: true,
    selection: false,
    editable: false,
    exportName: "Transactions Failed",
  };

  const columns = [
    {
      title: "Code",
      field: "id",
      render: (rowData) => eventCode(rowData),
    },
    {
      title: "Event",
      field: "event_id",
      render: (rowData) => Events[rowData.event_id],
      searchable: true,
      lookup: Events,
    },
    {
      title: "Amount",
      field: "amount",
      render: (rowData) =>
        rowData.amount
          ? thousands_separators(
              rowData.currency_id === 3
                ? Math.trunc(rowData.amount)
                : rowData.amount
            )
          : 0,
    },

    {
      title: "Currency",
      field: "currency_id",
      render: (rowData) => Currencies[rowData.currency_id],
      lookup: Currencies,
    },
    {
      title: "Payer Fullname",
      field: "payer_last_name",
      editable: "never",
      render: (rowData) => (
        <>
          {rowData.payer_first_name
            ? rowData.payer_first_name + " " + rowData.payer_last_name
            : "-"}
        </>
      ),
    },
    {
      title: "Payer Contact",
      field: "payer_mobile",
      editable: "never",
      render: (rowData) => (
        <>
          <div>{rowData.payer_mobile ? rowData.payer_mobile : "-"}</div>
          <div>{rowData.payer_email}</div>
        </>
      ),
    },
    {
      title: "Payer Address",
      field: "payer_country",
      editable: "never",
      render: (rowData) => (
        <>
          <p>{rowData.payer_country ? rowData.payer_country : "-"}</p>
          <p>{rowData.payer_city}</p>
          <p>{rowData.payer_address}</p>
        </>
      ),
    },
    {
      title: "Description",
      field: "payer_description",
      editable: "never",
    },
    {
      title: "Masked CreditCard",
      field: "gateway_response",
      editable: "never",
      render: (rowData) =>
        rowData.gateway_response &&
        JSON.parse(rowData.gateway_response).maskedCreditCard,
    },
    {
      title: "ProcReturn Code",
      field: "gateway_response",
      editable: "never",
      render: (rowData) =>
        rowData.gateway_response &&
        JSON.parse(rowData.gateway_response).ProcReturnCode,
    },
    {
      title: "IP Address",
      field: "gateway_response",
      editable: "never",
      render: (rowData) =>
        rowData.gateway_response &&
        JSON.parse(rowData.gateway_response).clientIp,
    },
    createDateLookup(),
  ];

  useEffect(() => {
    dispatch(getUserFailedTransactionsRequest({ user_id }));
  }, []);

  return (
    <div className="card">
      <Accordion defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontSize: 18 }} className="font-weight-bold">
            Transactions Failed
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography gutterBottom noWrap>
            <MaterialTable
              options={options}
              isLoading={loading}
              columns={columns}
              data={userFailedTransactions}
              title={false}
            />
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default TransactionsFailed;
