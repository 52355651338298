import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const Transactions = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Redirect
        exact
        from={`${match.url}/`}
        to={`${match.url}/AcceptorsList`}
      />
      <Route
        path={`${match.url}/acceptors-list`}
        component={asyncComponent(() => import("./routes/AcceptorsList"))}
      />
      <Route
        path={`${match.url}/acceptor-form/add-acceptor`}
        component={asyncComponent(() =>
          import("./routes/AcceptorForm/AddAcceptor/index")
        )}
      />
      <Route
        path={`${match.url}/bank-identity`}
        component={asyncComponent(() => import("./routes/BankIdentity"))}
      />
      <Route
        path={`${match.url}/corporation-code`}
        component={asyncComponent(() => import("./routes/CorporationCode"))}
      />
      <Route
        path={`${match.url}/supplementary-code`}
        component={asyncComponent(() => import("./routes/SupplementaryCode"))}
      />
      <Route
        path={`${match.url}/ribopay-request`}
        component={asyncComponent(() => import("./routes/RibopayRequest"))}
      />
      <Route
        path={`${match.url}/acceptor-edit/:id`}
        component={asyncComponent(() => import("./routes/AcceptorForm"))}
      />
      <Route
        path={`${match.url}/acceptor-shaparak/:id`}
        component={asyncComponent(() => import("./routes/AcceptorShaparak"))}
      />
      <Route
        path={`${match.url}/payment-facilitator-transactions`}
        component={asyncComponent(() =>
          import("./routes/PaymentFacilitatorTransactions")
        )}
      />
      <Route
        path={`${match.url}/daily-batches`}
        component={asyncComponent(() => import("./routes/DailyBatches"))}
      />
    </Switch>
  </div>
);

export default Transactions;
