import { put, takeLatest } from "redux-saga/effects";
import { closeModalSuccess, openModalSuccess } from "redux/actions/Modal";
import { CLOSE_MODAL_REQUEST, OPEN_MODAL_REQUEST } from "redux/types";
// --------------------------------------------------------------------------------
function* onOpenModal({ payload }) {
  yield put(openModalSuccess(payload));
}
// --------------------------------------------------------------------------------
function* onCloseModal() {
  yield put(closeModalSuccess());
}
// ================================================================================
function* modalSaga() {
  yield takeLatest(OPEN_MODAL_REQUEST, onOpenModal);
  yield takeLatest(CLOSE_MODAL_REQUEST, onCloseModal);
}

export default modalSaga;
