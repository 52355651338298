import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { Currencies } from "constants/constants";
import { NotificationContainer } from "react-notifications";
import SweetAlert from "react-bootstrap-sweetalert";
import makeStyles from "@mui/styles/makeStyles";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  exchangeUserCurrencyRequest,
  getUserBankAccountsRequest,
  getUserExchangeDetailRequest,
  getUserWalletsRequest,
} from "redux/actions/UserProfile";
import {
  EXCHANGE_USER_CURRENCY_FAILURE,
  EXCHANGE_USER_CURRENCY_STARTED,
  EXCHANGE_USER_CURRENCY_SUCCESS,
  GET_USER_EXCHANGE_DETAIL_STARTED,
  GET_USER_EXCHANGE_DETAIL_SUCCESS,
} from "redux/types";
import AppNumberInput from "components/AppNumberInput/index";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";

const Exchange = (user) => {
  const dispatch = useDispatch();
  const { rType, userBankAccounts, userWallets, userExchangeDetail, error } =
    useSelector((state) => state.userProfileReducer);
  const user_id = user.user_id;
  const loading =
    rType === GET_USER_EXCHANGE_DETAIL_STARTED ||
    rType === EXCHANGE_USER_CURRENCY_STARTED;

  useEffect(() => {
    dispatch(getUserBankAccountsRequest({ user_id }));
  }, []);

  useEffect(() => {
    dispatch(getUserWalletsRequest({ user_id }));
  }, []);

  useEffect(() => {
    if (rType === EXCHANGE_USER_CURRENCY_SUCCESS)
      setValues({ ...values, success: true });
    else if (rType === EXCHANGE_USER_CURRENCY_FAILURE)
      setValues({ ...values, error: true });
    else if (rType === GET_USER_EXCHANGE_DETAIL_SUCCESS)
      setAlert({ open: true });
  }, [rType]);

  const [values, setValues] = useState({
    amount: 0,
    from_currency_id: 3,
    to_currency_id: 2,
    description: "",
    account: 0,
    success: false,
    error: false,
    created_at: moment().format("YYYY-MM-DD HH:mm:ss"),
  });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const [alert, setAlert] = useState({ open: false });
  const handleRequestClose = () => {
    setAlert({ open: false });
  };

  const handleSaveUser = () => {
    dispatch(
      exchangeUserCurrencyRequest({
        amount: values.amount,
        from_currency_id: values.from_currency_id,
        to_currency_id: values.to_currency_id,
        user_id,
      })
    );
    handleRequestClose();
  };

  let ibanBank =
    userBankAccounts &&
    userBankAccounts.find((item) => item.id === values.account);

  function onConfirm() {
    setValues({ ...values, success: false, error: false });
  }

  function onConfirm() {
    setValues({ ...values, success: false, error: false });
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      "& > *": {
        margin: theme.spacing(1),
        width: "65%",
      },
    },
  }));
  const classes = useStyles();
  return (
    <div className="jr-card jr-full-card">
      <div className="card-header">
        <h4 className="card-title mb-0">Exchange</h4>
      </div>
      <div style={{ padding: 24 }}>
        <div className="row">
          <>
            <NotificationContainer />
            <SweetAlert
              show={values.success}
              success
              title={"Success"}
              confirmBtnText={"Close"}
              onConfirm={onConfirm}
            >
              Successfully Changed!
            </SweetAlert>
            <SweetAlert
              show={values.error}
              error
              title={"Error"}
              confirmBtnText={"Close"}
              onConfirm={onConfirm}
            >
              Error! {values.code ? values.code : ""}
            </SweetAlert>
          </>

          <div className="col-xl-6 col-lg-12 col-md-12 col-12">
            <div className={classes.root}>
              <AppNumberInput
                label="Amount"
                value={values.amount}
                onChange={handleChange("amount")}
                dir="ltr"
              />
            </div>
            <div className={classes.root}>
              <TextField
                select
                label="Source Currency"
                value={values.from_currency_id}
                onChange={handleChange("from_currency_id")}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                {userWallets &&
                  userWallets.map((option) => (
                    <MenuItem
                      key={option.currency_id}
                      value={option.currency_id}
                    >
                      {Currencies[option.currency_id]}
                    </MenuItem>
                  ))}
              </TextField>
            </div>
            <div className={classes.root}>
              <TextField
                select
                label="Target Currency"
                value={values.to_currency_id}
                onChange={handleChange("to_currency_id")}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                {userWallets &&
                  userWallets.map((option) => (
                    <MenuItem
                      key={option.currency_id}
                      value={option.currency_id}
                    >
                      {Currencies[option.currency_id]}
                    </MenuItem>
                  ))}
              </TextField>
            </div>

            <div className="d-flex flex-row align-items-center mt-2">
              {/*{ <Button disabled={loading} style={{width: "100px"}} color="primary" className=" jr-btn jr-btn-lg bg-primary text-white MuiButton-contained" onClick={handleSaveUser}>{loading ? <CircularProgress color={"#fff"} size={14} />: "Save"}</Button>}*/}
              <div>
                <Button
                  style={{ width: "100px" }}
                  variant="contained"
                  className="jr-btn jr-btn-lg bg-primary text-white MuiButton-contained"
                  onClick={() =>
                    dispatch(
                      getUserExchangeDetailRequest({
                        amount: values.amount,
                        from_currency_id: values.from_currency_id,
                        to_currency_id: values.to_currency_id,
                        user_id,
                      })
                    )
                  }
                >
                  {loading ? (
                    <CircularProgress color="inherit" size={14} />
                  ) : (
                    "Save"
                  )}
                </Button>
                <div style={{ minWidth: "500px" }}>
                  <Dialog open={alert.open} onClose={handleRequestClose}>
                    <DialogTitle>{"Verify Exchange"}</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        <div className="text-center text-black mb-3">
                          <div>
                            {userExchangeDetail?.from_amount}{" "}
                            {userExchangeDetail?.from_currency}
                          </div>
                          <CurrencyExchangeIcon sx={{ m: 1 }} color="primary" />
                          <div>
                            {userExchangeDetail?.to_amount}{" "}
                            {userExchangeDetail?.to_currency}
                          </div>
                        </div>
                        <div className="text-center">Commission</div>
                        <h2
                          style={{ color: "#03B585" }}
                          className="text-center"
                        >
                          {userExchangeDetail?.commission}
                        </h2>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleRequestClose} color="secondary">
                        Cancel
                      </Button>

                      <Button
                        disabled={loading}
                        color="primary"
                        onClick={handleSaveUser}
                      >
                        Agree
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Exchange;
