import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import IdCard from "assets/images/default/id-card.png";
import { Done, Clear } from "@mui/icons-material";

import { NotificationContainer } from "react-notifications";
import AppSwitch from "components/AppSwitch";
import AppDropDown from "components/AppDropDown";
import AppPieChart from "components/AppPieChart";
import AppTextInput from "components/AppTextInput";
import AppButton from "components/AppButton";
import MainModal from "components/MainModal";
import { useDispatch, useSelector } from "react-redux";
import {
  changeBusinessCategoryRequest,
  changeUserStatusRequest,
  editUserProfileRequest,
} from "redux/actions/UserProfile";
import {
  CHANGE_USER_STATUS_FAILURE,
  CHANGE_USER_STATUS_STARTED,
  CHANGE_USER_STATUS_SUCCESS,
  EDIT_USER_PROFILE_FAILURE,
  EDIT_USER_PROFILE_STARTED,
  EDIT_USER_PROFILE_SUCCESS,
} from "redux/types";
import CheckIcon from "@mui/icons-material/Check";
import { IconButton, InputAdornment } from "@mui/material/index";
import EditIcon from "@mui/icons-material/Edit";

const UserInfo = (user) => {
  const dispatch = useDispatch();
  const { rType, userData } = useSelector((state) => state.userProfileReducer);
  const basicInfo = user.basicInfo;

  const [modal, setModal] = useState(false);
  const [editBusinessCategory, setEditBusinessCategory] = useState(false);
  const loadingUser = rType === EDIT_USER_PROFILE_STARTED;
  const loadingStatus = rType === CHANGE_USER_STATUS_STARTED;

  const businessCategories = userData.business_categories.map((category) => ({
    label: category.name_en,
    value: category.id,
  }));

  const toggle = () => setModal(!modal);
  const [values, setValues] = useState({
    first_name: basicInfo.first_name,
    last_name: basicInfo.last_name,
    username: basicInfo.username,
    mobile: basicInfo.mobile,
    national_code: basicInfo.national_code,
    phone: basicInfo.phone,
    parent_id: basicInfo.parent_id,
    identity_status: basicInfo.identity_status,
    identity_comment: basicInfo.identity_comment,
    otp: basicInfo.otp === "Active",
    renew: basicInfo.renew === "Active",
    sms_notification: basicInfo.sms_notification === "Active",
    email_notification: basicInfo.email_notification === "Active",
    business_category_id: basicInfo.business_category_id,
    success: false,
    error: false,
  });

  useEffect(() => {
    switch (rType) {
      case EDIT_USER_PROFILE_SUCCESS:
      case CHANGE_USER_STATUS_SUCCESS:
        setValues({ ...values, success: true });
        break;
      case EDIT_USER_PROFILE_FAILURE:
      case CHANGE_USER_STATUS_FAILURE:
        setValues({ ...values, error: true });
        break;
      default:
        break;
    }
  }, [rType]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleChange2 = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.checked });
  };

  function handleSave() {
    let formData = new FormData();

    formData.append("user_id", basicInfo.id);
    formData.append("status_field", "identity_status");
    formData.append("value", values.identity_status);
    formData.append("comment", values.identity_comment);

    dispatch(changeUserStatusRequest(formData));
  }

  const data = [
    { value: basicInfo?.strength },
    { value: 100 - basicInfo?.strength },
  ];

  function handleSaveUser() {
    dispatch(
      editUserProfileRequest({
        first_name: values.first_name,
        last_name: values.last_name,
        username: values.username,
        mobile: values.mobile,
        phone: values.phone,
        parent_id: values.parent_id,
        national_code: values.national_code,
        otp: values.otp,
        renew: values.renew,
        sms_notification: values.sms_notification,
        email_notification: values.email_notification,
        user_id: basicInfo.id,
      })
    );
  }

  function handleRestoreAccount() {}

  function onConfirm() {
    setValues({ ...values, success: false, error: false });
  }

  return (
    <div className="row">
      <NotificationContainer />
      <SweetAlert
        show={values.success}
        success
        title={"Success"}
        confirmBtnText={"Close"}
        onConfirm={onConfirm}
      >
        Successfully Changed!
      </SweetAlert>
      <SweetAlert
        show={values.error}
        error
        title={"Error"}
        confirmBtnText={"Close"}
        onConfirm={onConfirm}
      >
        Error! {values.code ? values.code : ""}
      </SweetAlert>

      <div className="col-lg-6">
        <AppTextInput
          label="First Name"
          value={values.first_name}
          onChange={handleChange("first_name")}
          disabled={basicInfo.deleted_at != null ? true : false}
          dir="ltr"
        />

        <AppTextInput
          label="last Name"
          value={values.last_name}
          onChange={handleChange("last_name")}
          disabled={basicInfo.deleted_at != null ? true : false}
          dir="ltr"
        />

        <AppTextInput
          label="User Name"
          value={values.username}
          onChange={handleChange("username")}
          disabled={basicInfo.deleted_at != null ? true : false}
          dir="ltr"
        />

        <AppTextInput
          label={"Email "}
          value={basicInfo.email}
          onChange={handleChange}
          dir="ltr"
          InputProps={{
            endAdornment:
              basicInfo.email_status === "Active" ? (
                <Done className="text-green" />
              ) : (
                <Clear className="text-red" />
              ),
          }}
          disabled={basicInfo.deleted_at != null ? true : false}
        />

        <AppTextInput
          label="Mobile"
          value={values.mobile}
          onChange={handleChange("mobile")}
          dir="ltr"
          InputProps={{
            endAdornment:
              basicInfo.mobile_status === "Active" ? (
                <Done className="text-green" />
              ) : (
                <Clear className="text-red" />
              ),
          }}
          disabled={basicInfo.deleted_at != null ? true : false}
        />

        <AppTextInput
          label="National Code"
          value={values.national_code}
          onChange={handleChange("national_code")}
          disabled={basicInfo.deleted_at != null ? true : false}
          dir="ltr"
        />

        <AppTextInput
          label="Phone"
          value={values.phone}
          onChange={handleChange("phone")}
          disabled={basicInfo.deleted_at != null ? true : false}
          dir="ltr"
        />

        <AppTextInput
          label="Parent Id"
          value={values.parent_id}
          onChange={handleChange("parent_id")}
          disabled={basicInfo.deleted_at != null ? true : false}
          dir="ltr"
        />

        <AppTextInput
          label="Created at"
          value={basicInfo.created_at}
          onChange={handleChange}
          disabled
          dir="ltr"
        />

        <AppTextInput
          label="Updated at"
          value={basicInfo.updated_at}
          onChange={handleChange}
          disabled
          dir="ltr"
        />

        <AppTextInput
          label="Deleted at"
          value={basicInfo.deleted_at}
          onChange={handleChange}
          disabled
          dir="ltr"
        />

        <div className="d-flex flex-wrap">
          <AppSwitch
            title="Auto Renew"
            checked={values.renew}
            onChange={handleChange2("renew")}
          />

          <AppSwitch
            title="OTP"
            checked={values.otp}
            onChange={handleChange2("otp")}
          />

          <AppSwitch
            title="SMS Notification"
            checked={values.sms_notification}
            onChange={handleChange2("sms_notification")}
          />

          <AppSwitch
            title="Email Notification"
            checked={values.email_notification}
            onChange={handleChange2("email_notification")}
          />
        </div>

        {basicInfo.deleted_at !== null ? (
          <>
            <span className="text-danger">
              Acount Deleted at {basicInfo.deleted_at}
            </span>
            <div className="d-flex flex-row align-items-center mt-2">
              <AppButton
                loading={loadingStatus}
                text={"Restore Account"}
                onClick={handleRestoreAccount}
              />
            </div>
          </>
        ) : (
          <div className="text-center">
            <AppButton
              loading={loadingStatus}
              text={"Save"}
              onClick={handleSaveUser}
              disabled={loadingUser}
              customClassName="mt-3"
            />
          </div>
        )}
      </div>

      <div className="col-lg-6 ">
        <div className={`mb-3 d-flex flex-column align-items-center`}>
          <AppDropDown
            label="Business Category"
            value={values.business_category_id}
            onChange={handleChange("business_category_id")}
            items={businessCategories}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  {editBusinessCategory ? (
                    <IconButton
                      onClick={() =>
                        dispatch(
                          changeBusinessCategoryRequest({
                            user_id: basicInfo.id,
                            business_category_id: values.business_category_id,
                            setEditBusinessCategory,
                          })
                        )
                      }
                    >
                      <CheckIcon color="primary" />
                    </IconButton>
                  ) : (
                    <IconButton onClick={() => setEditBusinessCategory(true)}>
                      <EditIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
            disabled={!editBusinessCategory}
          />
        </div>
        {basicInfo.identity_attachment &&
        basicInfo.identity_attachment.substring(
          basicInfo.identity_attachment.length - 4
        ) === ".pdf" ? (
          <iframe
            style={{ border: "1px solid #c4c4c4", borderRadius: "4px" }}
            src={basicInfo.identity_attachment}
            width="100%"
            height="400px"
          ></iframe>
        ) : (
          <div
            onClick={toggle}
            style={{
              width: "100%",
              height: "400px",
              borderRadius: "4px",
              backgroundImage: `url(${
                basicInfo.identity_attachment
                  ? basicInfo.identity_attachment
                  : IdCard
              })`,
              backgroundPosition: "center",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
        )}

        <div className={`mt-3 d-flex flex-column align-items-center`}>
          <AppDropDown
            label="Status"
            value={values.identity_status}
            onChange={handleChange("identity_status")}
            items={[
              {
                label: "Approved",
                value: "Approved",
                className: "text-success",
              },
              { label: "Pending", value: "Pending", className: "text-warning" },
              {
                label: "Declined",
                value: "Declined",
                className: "text-danger",
              },
            ]}
          />

          <AppTextInput
            label="Comment"
            multiline
            onChange={handleChange("identity_comment")}
            value={values.identity_comment}
          />

          <AppButton
            loading={loadingStatus}
            text={"Save"}
            onClick={handleSave}
            disabled={loadingStatus}
            customClassName="mt-3"
          />
        </div>
        <div className="py-3">
          <AppPieChart strength={basicInfo?.strength} data={data} />
        </div>
      </div>

      <MainModal
        mainContent={
          <img
            alt="Passport"
            style={{ width: "100%" }}
            src={
              basicInfo.identity_attachment
                ? basicInfo.identity_attachment
                : IdCard
            }
          />
        }
        title="Passport"
        handleToggle={toggle}
        isOpen={modal}
        hasRotation
        footer={<AppButton color="primary" text="Close" onClick={toggle} />}
      />
    </div>
  );
};

export default UserInfo;
