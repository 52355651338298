import React, { useState } from 'react';
import { fromPrevMonth, to, token, API_ADD_YOUTUBE_TRANSACTION } from 'constants/constants';
import DatePicker from 'react-datepicker2';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import SweetAlert from 'react-bootstrap-sweetalert';
import NumberFormat from 'react-number-format';

function TopUpChannel({ user_id, channel_id, reRender }) {
  const [amount, setAmount] = useState('');
  const [date, setDate] = useState({
    from: fromPrevMonth,
    to
  });
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setShowAlert(false);
    if (amount) {
      setLoading(true);
      axios
        .post(
          API_ADD_YOUTUBE_TRANSACTION,
          {
            user_id,
            channel_id,
            amount,
            from_date: date.from,
            to_date: date.to
          },
          token()
        )
        .then(() => {
          reRender();
          setAmount('');
          setDate({
            from: fromPrevMonth,
            to
          });
          setLoading(false);
        })
        .catch((error) => {
          console.log('error', error);
          setLoading(false);
        });
    }
  };

  return (
    <div className=" card jr-card p-3  w-100  ">
      <h3 className=" col-12 text-center mb-5 ">Charge Youtube Channel </h3>
      <div className="d-flex justify-content-around  flex-wrap ">
        <div className="my-3 d-flex align-items-center mx-auto ">
          <span className="mr-3 ml-0 float-left">From Date:</span>
          <DatePicker
            onChange={(from) => setDate({ ...date, from })}
            isGregorian={true}
            value={date.from}
            timePicker={false}
            className="float-left text-center "
          />
        </div>
        <div className="my-2 d-flex align-items-center mx-auto ">
          <span className="mr-3 ml-0 float-left">To Date:</span>
          <DatePicker
            onChange={(to) => setDate({ ...date, to })}
            isGregorian={true}
            value={date.to}
            timePicker={false}
            className="float-left text-center "
          />
        </div>
        <div className="my-2 d-flex align-items-center mx-auto  ">
          <span className="mr-3 ml-0 float-left">Amount</span>
          <NumberFormat
            className="MuiInputBase-input MuiOutlinedInput-input p-1"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            thousandSeparator={true}
            style={{ borderBottom: '1px solid black' }}
            suffix={' TRY'}
          />
        </div>
      </div>
      <div className="d-flex justify-content-center mt-4 col-12">
        <Button
          color="primary"
          className="jr-btn jr-btn-sm bg-primary text-white px-3 mt-3"
          onClick={() => setShowAlert(true)}
          type="submit"
          style={{ width: '140px' }}>
          {loading ? <CircularProgress color="inherit" size={15} /> : 'Submit'}
        </Button>
      </div>
      <SweetAlert
        show={showAlert}
        warning
        title={'Are you sure?'}
        confirmBtnText={'Yes'}
        cancelBtnText={'No'}
        onConfirm={() => handleSubmit()}
        onCancel={() => setShowAlert(false)}
        cancelBtnBsStyle="danger"
        confirmBtnBsStyle="success"
        showCancel
        focusCancelBtn></SweetAlert>
    </div>
  );
}

export default TopUpChannel;
