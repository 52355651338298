import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const YekpayWallet = ({ match }) => {
  return (
    <div className="app-wrapper">
      <Switch>
        <Redirect
          exact
          from={`${match.url}/`}
          to={`${match.url}/Transactions`}
        />
        <Route
          path={`${match.url}/transactions`}
          component={asyncComponent(() => import("./routes/Transactions"))}
        />
        <Route
          path={`${match.url}/wallet`}
          component={asyncComponent(() => import("./routes/Wallet"))}
        />
        <Route
          path={`${match.url}/payout`}
          component={asyncComponent(() => import("./routes/Payout"))}
        />
      </Switch>
    </div>
  );
};

export default YekpayWallet;
