import { put, takeLatest } from "redux-saga/effects";
import {
  loginAdminFailure,
  loginAdminStarted,
  loginAdminSuccess,
  logoutAdminSuccess,
  verifyAdminFailure,
  verifyAdminStarted,
  verifyAdminSuccess,
} from "redux/actions";
import { history } from "redux/store";
import {
  LOGIN_ADMIN_REQUEST,
  LOGOUT_ADMIN_REQUEST,
  VERIFY_ADMIN_REQUEST,
} from "redux/types";
import { loginAdminAPI, verifyAdminAPI } from "services/API/Auth";
// --------------------------------------------------------------------------------
function* onLoginAdmin({ payload }) {
  yield put(loginAdminStarted());
  try {
    let response = yield loginAdminAPI(payload);
    if (response.status === "success") {
      yield put(loginAdminSuccess());
    } else {
      yield put(loginAdminFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(loginAdminFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onVerifyAdmin({ payload }) {
  yield put(verifyAdminStarted());
  try {
    let response = yield verifyAdminAPI(payload);
    if (response.status === "success") {
      let {
        token,
        id,
        admin_role_id,
        first_name,
        last_name,
        department,
      } = response.data;
      yield put(
        verifyAdminSuccess({
          authToken: token,
          adminId: id,
          adminRoleId: admin_role_id,
          fullName: `${first_name} ${last_name}`,
          department: department.name_en,
        })
      );
      history.push("/app/dashboard");
    } else {
      yield put(verifyAdminFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(verifyAdminFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onLogoutAdmin() {
  yield put(
    logoutAdminSuccess({
      authToken: "",
      adminId: "",
      adminRoleId: "",
      fullName: "",
      department: "",
    })
  );
}
// ================================================================================
function* authSaga() {
  yield takeLatest(LOGIN_ADMIN_REQUEST, onLoginAdmin);
  yield takeLatest(VERIFY_ADMIN_REQUEST, onVerifyAdmin);
  yield takeLatest(LOGOUT_ADMIN_REQUEST, onLogoutAdmin);
}

export default authSaga;
