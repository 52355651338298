import React from "react";
import {
  NavHeader,
  NavWithSubmenu,
  NavMenu,
} from "containers/SideNav/components";

const Support = () => (
  <>
    <NavHeader title="sidebar.support" />

    <NavWithSubmenu
      icon="zmdi zmdi-comment-text"
      title="sidebar.sms"
      subMenuList={[
        { title: "sidebar.sms.new", url: "/app/support/sms/new" },
        { title: "sidebar.sms.newGroup", url: "/app/support/sms/new-group" },
        {
          title: "sidebar.sms.GroupList",
          url: "/app/support/sms/group-sms-list",
        },
        {
          title: "sidebar.sms.list",
          url: "/app/support/sms/list",
        },
      ]}
    />

    <NavMenu
      url="/app/support/all-announcement"
      title="sidebar.announcement.allAnnouncement"
      icon="zmdi zmdi-comment-text"
    />
  </>
);

export default Support;
