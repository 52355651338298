import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import { API_Edit_ACCEPTOR, token } from 'constants/constants';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { NotificationContainer } from 'react-notifications';
import AcceptorType from '../components/AcceptorType';
import AcceptorTechnician from '../components/AcceptorTechnician';
import AcceptorBilling from '../components/AcceptorBilling';
import AcceptorBankInfo from '../components/AcceptorBankInfo';
import CustomerInfo from '../components/CustomerInfo';
import Shaparak from '../components/Shaparak';
import IrankishResponse from '../components/IrankishResponse';
import AttachmentFile from '../components/AttachmentFile';

function AcceptorEdit({ acceptorInfo }) {
  const urlObject = new URL(window.location);
  const parts = urlObject.pathname.split('/');
  const acceptor_id = parts[parts.length - 1];

  const [loadingStatus, setLoadingStatus] = useState(false);
  const [files, setFile] = useState({
    customer_file_contract: acceptorInfo.customer_file_contract,
    customer_file_commitment: acceptorInfo.customer_file_commitment,
    customer_file_melli_card: acceptorInfo.customer_file_melli_card,
    customer_file_newsletter: acceptorInfo.customer_file_newsletter,
    customer_file_statute: acceptorInfo.customer_file_statute,
    logo_file: acceptorInfo.logo_file
  });

  const [values, setValues] = useState({
    success: false,
    error: false,

    acceptor_type_id: acceptorInfo.acceptor_type_id,
    acceptor_qty: acceptorInfo.acceptor_qty,
    acceptor_enamad_verified: acceptorInfo.acceptor_enamad_verified,
    acceptor_enamad_category: acceptorInfo.acceptor_enamad_category,
    acceptor_enamad_type: acceptorInfo.acceptor_enamad_type,
    acceptor_enamad_issue_date: acceptorInfo.acceptor_enamad_issue_date,
    acceptor_enamad_expire_date: acceptorInfo.acceptor_enamad_expire_date,
    business_id: acceptorInfo.business_id,
    acceptor_identifier: acceptorInfo.acceptor_identifier,
    acceptor_website: acceptorInfo.acceptor_website,
    acceptor_ip_address: acceptorInfo.acceptor_ip_address,

    acceptor_technical_name: acceptorInfo.acceptor_technical_name,
    acceptor_technical_family: acceptorInfo.acceptor_technical_family,
    acceptor_technical_phone: acceptorInfo.acceptor_technical_phone,
    acceptor_technical_email: acceptorInfo.acceptor_technical_email,

    acceptor_merchant_name: acceptorInfo.acceptor_merchant_name,
    acceptor_zip_code: acceptorInfo.acceptor_zip_code,
    acceptor_phone: acceptorInfo.acceptor_phone,
    acceptor_mobile: acceptorInfo.acceptor_mobile,
    acceptor_email: acceptorInfo.acceptor_email,
    acceptor_latin_merchant_name: acceptorInfo.acceptor_latin_merchant_name,
    acceptor_province_id: acceptorInfo.acceptor_province_id,
    acceptor_city_id: acceptorInfo.acceptor_city_id,
    acceptor_area_id: acceptorInfo.acceptor_area_id,
    acceptor_ownership_type: acceptorInfo.acceptor_ownership_type,
    acceptor_address: acceptorInfo.acceptor_address,

    acceptor_bank_id: acceptorInfo.acceptor_bank_id,
    acceptor_account_no: acceptorInfo.acceptor_account_no,
    acceptor_iban_no: acceptorInfo.acceptor_iban_no,

    customer_name: acceptorInfo.customer_name,
    customer_family: acceptorInfo.customer_family,
    customer_latin_name: acceptorInfo.customer_latin_name,
    customer_latin_family: acceptorInfo.customer_latin_family,
    customer_father_name: acceptorInfo.customer_father_name,
    customer_latin_father_name: acceptorInfo.customer_latin_father_name,
    customer_national_code: acceptorInfo.customer_national_code,
    customer_birthdate: acceptorInfo.customer_birthdate,
    customer_gender: acceptorInfo.customer_gender,
    customer_country_id: acceptorInfo.customer_country_id,
    customer_province_id: acceptorInfo.customer_province_id,
    customer_city_id: acceptorInfo.customer_city_id,
    customer_register_number: acceptorInfo.customer_register_number,
    customer_licence_number: acceptorInfo.customer_licence_number,
    customer_legal_type: acceptorInfo.customer_legal_type,
    customer_foundation_date: acceptorInfo.customer_foundation_date,
    customer_company_name: acceptorInfo.customer_company_name,
    customer_latin_company_name: acceptorInfo.customer_latin_company_name,
    customer_vital_status: acceptorInfo.customer_vital_status,
    customer_commercial_code: acceptorInfo.customer_commercial_code,

    shaparak_contract_date: acceptorInfo.shaparak_contract_date,
    shaparak_service_date: acceptorInfo.shaparak_service_date,
    shaparak_contract_number: acceptorInfo.shaparak_contract_number,
    shaparak_corporation_code: acceptorInfo.shaparak_corporation_code,
    shaparak_identifier_code: acceptorInfo.shaparak_identifier_code,
    shaparak_terminal_date: acceptorInfo.shaparak_terminal_date,
    shaparak_address_callback: acceptorInfo.shaparak_address_callback,
    shaparak_port_callback: acceptorInfo.shaparak_port_callback,
    shaparak_tracking_number: acceptorInfo.shaparak_tracking_number,

    inquiry_terminal: acceptorInfo.inquiry_terminal,
    inquiry_sha1: acceptorInfo.inquiry_sha1,
    inquiry_acceptor: acceptorInfo.inquiry_acceptor,
    inquiry_acceptor_code: acceptorInfo.inquiry_acceptor_code,
    inquiry_passphrase: acceptorInfo.inquiry_passphrase,
    inquiry_public_key: acceptorInfo.inquiry_public_key,
    response_psp_tracking_code: acceptorInfo.response_psp_tracking_code,
    response_tracking_code: acceptorInfo.response_tracking_code,
    inquiry_description: acceptorInfo.inquiry_description,
    inquiry_status: acceptorInfo.inquiry_status
  });

  const onChangeFile = (prop) => (event) => {
    setFile({ ...files, [prop]: event.target.files[0] });
  };
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleChangeIban = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value.toUpperCase() });
  };

  function handleSave() {
    setLoadingStatus(true);

    const formData = new FormData();
    if (files.customer_file_contract != null)
      formData.append('customer_file_contract', files.customer_file_contract);
    if (files.customer_file_commitment != null)
      formData.append('customer_file_commitment', files.customer_file_commitment);
    if (files.customer_file_melli_card != null)
      formData.append('customer_file_melli_card', files.customer_file_melli_card);
    if (files.customer_file_newsletter != null)
      formData.append('customer_file_newsletter', files.customer_file_newsletter);
    if (files.customer_file_statute != null)
      formData.append('customer_file_statute', files.customer_file_statute);
    if (files.logo_file != null) formData.append('logo_file', files.logo_file);

    if (acceptor_id != null) formData.append('id', acceptor_id);
    if (values.acceptor_type_id != null)
      formData.append('acceptor_type_id', values.acceptor_type_id);
    if (values.acceptor_qty != null) formData.append('acceptor_qty', values.acceptor_qty);
    if (values.acceptor_enamad_verified != null)
      formData.append('acceptor_enamad_verified', values.acceptor_enamad_verified);
    if (values.acceptor_enamad_category != null)
      formData.append('acceptor_enamad_category', values.acceptor_enamad_category);
    if (values.acceptor_enamad_type != null)
      formData.append('acceptor_enamad_type', values.acceptor_enamad_type);
    if (values.acceptor_enamad_issue_date != null)
      formData.append('acceptor_enamad_issue_date', values.acceptor_enamad_issue_date);
    if (values.acceptor_enamad_expire_date != null)
      formData.append('acceptor_enamad_expire_date', values.acceptor_enamad_expire_date);
    if (values.business_id != null) formData.append('business_id', values.business_id);
    if (values.acceptor_identifier != null)
      formData.append('acceptor_identifier', values.acceptor_identifier);
    if (values.acceptor_website != null)
      formData.append('acceptor_website', values.acceptor_website);
    if (values.acceptor_ip_address != null)
      formData.append('acceptor_ip_address', values.acceptor_ip_address);

    if (values.acceptor_technical_name != null)
      formData.append('acceptor_technical_name', values.acceptor_technical_name);
    if (values.acceptor_technical_family != null)
      formData.append('acceptor_technical_family', values.acceptor_technical_family);
    if (values.acceptor_technical_phone != null)
      formData.append('acceptor_technical_phone', values.acceptor_technical_phone);
    if (values.acceptor_technical_email != null)
      formData.append('acceptor_technical_email', values.acceptor_technical_email);

    if (values.acceptor_merchant_name != null)
      formData.append('acceptor_merchant_name', values.acceptor_merchant_name);
    if (values.acceptor_zip_code != null)
      formData.append('acceptor_zip_code', values.acceptor_zip_code);
    if (values.acceptor_phone != null) formData.append('acceptor_phone', values.acceptor_phone);
    if (values.acceptor_mobile != null) formData.append('acceptor_mobile', values.acceptor_mobile);
    if (values.acceptor_email != null) formData.append('acceptor_email', values.acceptor_email);
    if (values.acceptor_latin_merchant_name != null)
      formData.append('acceptor_latin_merchant_name', values.acceptor_latin_merchant_name);
    if (values.acceptor_province_id != null)
      formData.append('acceptor_province_id', values.acceptor_province_id);
    if (values.acceptor_city_id != null)
      formData.append('acceptor_city_id', values.acceptor_city_id);
    if (values.acceptor_area_id != null)
      formData.append('acceptor_area_id', values.acceptor_area_id);
    if (values.acceptor_ownership_type != null)
      formData.append('acceptor_ownership_type', values.acceptor_ownership_type);
    if (values.acceptor_address != null)
      formData.append('acceptor_address', values.acceptor_address);

    if (values.acceptor_bank_id != null)
      formData.append('acceptor_bank_id', values.acceptor_bank_id);
    if (values.acceptor_account_no != null)
      formData.append('acceptor_account_no', values.acceptor_account_no);
    if (values.acceptor_iban_no != null)
      formData.append('acceptor_iban_no', values.acceptor_iban_no);

    if (values.customer_name != null) formData.append('customer_name', values.customer_name);
    if (values.customer_family != null) formData.append('customer_family', values.customer_family);
    if (values.customer_latin_name != null)
      formData.append('customer_latin_name', values.customer_latin_name);
    if (values.customer_latin_family != null)
      formData.append('customer_latin_family', values.customer_latin_family);
    if (values.customer_father_name != null)
      formData.append('customer_father_name', values.customer_father_name);
    if (values.customer_latin_father_name != null)
      formData.append('customer_latin_father_name', values.customer_latin_father_name);
    if (values.customer_national_code != null)
      formData.append('customer_national_code', values.customer_national_code);
    if (values.customer_birthdate != null)
      formData.append('customer_birthdate', values.customer_birthdate);
    if (values.customer_gender != null) formData.append('customer_gender', values.customer_gender);
    if (values.customer_country_id != null)
      formData.append('customer_country_id', values.customer_country_id);
    if (values.customer_province_id != null)
      formData.append('customer_province_id', values.customer_province_id);
    if (values.customer_city_id != null)
      formData.append('customer_city_id', values.customer_city_id);
    if (values.customer_register_number != null)
      formData.append('customer_register_number', values.customer_register_number);
    if (values.customer_licence_number != null)
      formData.append('customer_licence_number', values.customer_licence_number);
    if (values.customer_legal_type != null)
      formData.append('customer_legal_type', values.customer_legal_type);
    if (values.customer_foundation_date != null)
      formData.append('customer_foundation_date', values.customer_foundation_date);
    if (values.customer_company_name != null)
      formData.append('customer_company_name', values.customer_company_name);
    if (values.customer_latin_company_name != null)
      formData.append('customer_latin_company_name', values.customer_latin_company_name);
    if (values.customer_vital_status != null)
      formData.append('customer_vital_status', values.customer_vital_status);
    if (values.customer_commercial_code != null)
      formData.append('customer_commercial_code', values.customer_commercial_code);

    if (values.shaparak_contract_date != null)
      formData.append('shaparak_contract_date', values.shaparak_contract_date);
    if (values.shaparak_service_date != null)
      formData.append('shaparak_service_date', values.shaparak_service_date);
    if (values.shaparak_contract_number != null)
      formData.append('shaparak_contract_number', values.shaparak_contract_number);
    if (values.shaparak_corporation_code != null)
      formData.append('shaparak_corporation_code', values.shaparak_corporation_code);
    if (values.shaparak_identifier_code != null)
      formData.append('shaparak_identifier_code', values.shaparak_identifier_code);
    if (values.shaparak_terminal_date != null)
      formData.append('shaparak_terminal_date', values.shaparak_terminal_date);
    if (values.shaparak_address_callback != null)
      formData.append('shaparak_address_callback', values.shaparak_address_callback);
    if (values.shaparak_port_callback != null)
      formData.append('shaparak_port_callback', values.shaparak_port_callback);
    if (values.shaparak_tracking_number != null)
      formData.append('shaparak_tracking_number', values.shaparak_tracking_number);

    if (values.inquiry_terminal != null)
      formData.append('inquiry_terminal', values.inquiry_terminal);
    if (values.inquiry_sha1 != null) formData.append('inquiry_sha1', values.inquiry_sha1);
    if (values.inquiry_acceptor != null)
      formData.append('inquiry_acceptor', values.inquiry_acceptor);
    if (values.inquiry_acceptor_code != null)
      formData.append('inquiry_acceptor_code', values.inquiry_acceptor_code);
    if (values.inquiry_passphrase != null)
      formData.append('inquiry_passphrase', values.inquiry_passphrase);
    if (values.inquiry_public_key != null)
      formData.append('inquiry_public_key', values.inquiry_public_key);
    if (values.response_psp_tracking_code != null)
      formData.append('response_psp_tracking_code', values.response_psp_tracking_code);
    if (values.response_tracking_code != null)
      formData.append('response_tracking_code', values.response_tracking_code);
    if (values.inquiry_description != null)
      formData.append('inquiry_description', values.inquiry_description);
    if (values.inquiry_status != null) formData.append('inquiry_status', values.inquiry_status);

    axios
      .post(API_Edit_ACCEPTOR, formData, token())

      .then((response) => {
        if (response.status === 200 && response.data.status === 'success') {
          setValues({ ...values, success: true });
        } else {
          setValues({ ...values, error: true });
        }
        setLoadingStatus(false);
      })
      .catch((error) => {
        let failedValue = '';
        if (typeof error.response.data.data === 'string') {
          failedValue = error.response.data.data;
        } else {
          for (const prop in error.response.data.data) {
            failedValue += prop + ': ' + error.response.data.data[prop] + ' - ';
          }
        }
        setValues({ ...values, error: true, code: failedValue });
        setLoadingStatus(false);
      });
  }

  function onConfirm() {
    setValues({ ...values, success: false, error: false });
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '65%'
      }
    }
  }));
  const classes = useStyles();
  return (
    <>
      <AcceptorType
        acceptor_type_id={values.acceptor_type_id}
        acceptor_type_idChange={handleChange('acceptor_type_id')}
        acceptor_qty={values.acceptor_qty}
        acceptor_qtyChange={handleChange('acceptor_qty')}
        acceptor_enamad_verified={values.acceptor_enamad_verified}
        acceptor_enamad_verifiedChange={handleChange('acceptor_enamad_verified')}
        acceptor_enamad_category={values.acceptor_enamad_category}
        acceptor_enamad_categoryChange={handleChange('acceptor_enamad_category')}
        acceptor_enamad_issue_date={values.acceptor_enamad_issue_date}
        acceptor_enamad_issue_dateChange={handleChange('acceptor_enamad_issue_date')}
        acceptor_enamad_type={values.acceptor_enamad_type}
        acceptor_enamad_typeChange={handleChange('acceptor_enamad_type')}
        acceptor_enamad_expire_date={values.acceptor_enamad_expire_date}
        acceptor_enamad_expire_dateChange={handleChange('acceptor_enamad_expire_date')}
        business_id={values.business_id}
        business_idChange={handleChange('business_id')}
        acceptor_identifier={values.acceptor_identifier}
        acceptor_identifierChange={handleChange('acceptor_identifier')}
        acceptor_website={values.acceptor_website}
        acceptor_websiteChange={handleChange('acceptor_website')}
        acceptor_ip_address={values.acceptor_ip_address}
        acceptor_ip_addressChange={handleChange('acceptor_ip_address')}
      />
      <AcceptorTechnician
        acceptor_technical_name={values.acceptor_technical_name}
        acceptor_technical_nameChange={handleChange('acceptor_technical_name')}
        acceptor_technical_family={values.acceptor_technical_family}
        acceptor_technical_familyChange={handleChange('acceptor_technical_family')}
        acceptor_technical_phone={values.acceptor_technical_phone}
        acceptor_technical_phoneChange={handleChange('acceptor_technical_phone')}
        acceptor_technical_email={values.acceptor_technical_email}
        acceptor_technical_emailChange={handleChange('acceptor_technical_email')}
      />
      <AcceptorBilling
        acceptor_merchant_name={values.acceptor_merchant_name}
        acceptor_merchant_nameChange={handleChange('acceptor_merchant_name')}
        acceptor_zip_code={values.acceptor_zip_code}
        acceptor_zip_codeChange={handleChange('acceptor_zip_code')}
        acceptor_phone={values.acceptor_phone}
        acceptor_phoneChange={handleChange('acceptor_phone')}
        acceptor_mobile={values.acceptor_mobile}
        acceptor_mobileChange={handleChange('acceptor_mobile')}
        acceptor_email={values.acceptor_email}
        acceptor_emailChange={handleChange('acceptor_email')}
        acceptor_latin_merchant_name={values.acceptor_latin_merchant_name}
        acceptor_latin_merchant_nameChange={handleChange('acceptor_latin_merchant_name')}
        acceptor_province_id={values.acceptor_province_id}
        acceptor_province_idChange={handleChange('acceptor_province_id')}
        acceptor_city_id={values.acceptor_city_id}
        acceptor_city_idChange={handleChange('acceptor_city_id')}
        acceptor_area_id={values.acceptor_area_id}
        acceptor_area_idChange={handleChange('acceptor_area_id')}
        acceptor_ownership_type={values.acceptor_ownership_type}
        acceptor_ownership_typeChange={handleChange('acceptor_ownership_type')}
        acceptor_address={values.acceptor_address}
        acceptor_addressChange={handleChange('acceptor_address')}
      />
      <AcceptorBankInfo
        acceptor_bank_id={values.acceptor_bank_id}
        acceptor_bank_idChange={handleChange('acceptor_bank_id')}
        acceptor_account_no={values.acceptor_account_no}
        acceptor_account_noChange={handleChange('acceptor_account_no')}
        acceptor_iban_no={values.acceptor_iban_no}
        acceptor_iban_noChange={handleChangeIban('acceptor_iban_no')}
      />
      <CustomerInfo
        customer_name={values.customer_name}
        customer_nameChange={handleChange('customer_name')}
        customer_family={values.customer_family}
        customer_familyChange={handleChange('customer_family')}
        customer_latin_name={values.customer_latin_name}
        customer_latin_nameChange={handleChange('customer_latin_name')}
        customer_latin_family={values.customer_latin_family}
        customer_latin_familyChange={handleChange('customer_latin_family')}
        customer_father_name={values.customer_father_name}
        customer_father_nameChange={handleChange('customer_father_name')}
        customer_latin_father_name={values.customer_latin_father_name}
        customer_latin_father_nameChange={handleChange('customer_latin_father_name')}
        customer_national_code={values.customer_national_code}
        customer_national_codeChange={handleChange('customer_national_code')}
        customer_birthdate={values.customer_birthdate}
        customer_birthdateChange={handleChange('customer_birthdate')}
        customer_gender={values.customer_gender}
        customer_genderChange={handleChange('customer_gender')}
        customer_country_id={values.customer_country_id}
        customer_country_idChange={handleChange('customer_country_id')}
        customer_province_id={values.customer_province_id}
        customer_province_idChange={handleChange('customer_province_id')}
        customer_city_id={values.customer_city_id}
        customer_city_idChange={handleChange('customer_city_id')}
        customer_register_number={values.customer_register_number}
        customer_register_numberChange={handleChange('customer_register_number')}
        customer_licence_number={values.customer_licence_number}
        customer_licence_numberChange={handleChange('customer_licence_number')}
        customer_legal_type={values.customer_legal_type}
        customer_legal_typeChange={handleChange('customer_legal_type')}
        customer_foundation_date={values.customer_foundation_date}
        customer_foundation_dateChange={handleChange('customer_foundation_date')}
        customer_company_name={values.customer_company_name}
        customer_company_nameChange={handleChange('customer_company_name')}
        customer_latin_company_name={values.customer_latin_company_name}
        customer_latin_company_nameChange={handleChange('customer_latin_company_name')}
        customer_vital_status={values.customer_vital_status}
        customer_vital_statusChange={handleChange('customer_vital_status')}
        customer_commercial_code={values.customer_commercial_code}
        customer_commercial_codeChange={handleChange('customer_commercial_code')}
      />
      <Shaparak
        shaparak_contract_date={values.shaparak_contract_date}
        shaparak_contract_dateChange={handleChange('shaparak_contract_date')}
        shaparak_service_date={values.shaparak_service_date}
        shaparak_service_dateChange={handleChange('shaparak_service_date')}
        shaparak_contract_number={values.shaparak_contract_number}
        shaparak_contract_numberChange={handleChange('shaparak_contract_number')}
        shaparak_corporation_code={values.shaparak_corporation_code}
        shaparak_corporation_codeChange={handleChange('shaparak_corporation_code')}
        shaparak_identifier_code={values.shaparak_identifier_code}
        shaparak_identifier_codeChange={handleChange('shaparak_identifier_code')}
        shaparak_terminal_date={values.shaparak_terminal_date}
        shaparak_terminal_dateChange={handleChange('shaparak_terminal_date')}
        shaparak_address_callback={values.shaparak_address_callback}
        shaparak_address_callbackChange={handleChange('shaparak_address_callback')}
        shaparak_port_callback={values.shaparak_port_callback}
        shaparak_port_callbackChange={handleChange('shaparak_port_callback')}
        shaparak_tracking_number={values.shaparak_tracking_number}
        shaparak_tracking_numberChange={handleChange('shaparak_tracking_number')}
      />
      <IrankishResponse
        inquiry_terminal={values.inquiry_terminal}
        inquiry_terminalChange={handleChange('inquiry_terminal')}
        inquiry_sha1={values.inquiry_sha1}
        inquiry_sha1Change={handleChange('inquiry_sha1')}
        inquiry_acceptor={values.inquiry_acceptor}
        inquiry_acceptorChange={handleChange('inquiry_acceptor')}
        inquiry_acceptor_code={values.inquiry_acceptor_code}
        inquiry_acceptor_codeChange={handleChange('inquiry_acceptor_code')}
        inquiry_passphrase={values.inquiry_passphrase}
        inquiry_passphraseChange={handleChange('inquiry_passphrase')}
        inquiry_public_key={values.inquiry_public_key}
        inquiry_public_keyChange={handleChange('inquiry_public_key')}
        response_psp_tracking_code={values.response_psp_tracking_code}
        response_psp_tracking_codeChange={handleChange('response_psp_tracking_code')}
        response_tracking_code={values.response_tracking_code}
        response_tracking_codeChange={handleChange('response_tracking_code')}
        inquiry_description={values.inquiry_description}
        inquiry_descriptionChange={handleChange('inquiry_description')}
        inquiry_status={values.inquiry_status}
        inquiry_statusChange={handleChange('inquiry_status')}
      />
      <AttachmentFile
        customer_file_contract={files.customer_file_contract}
        customer_file_contractChange={onChangeFile('customer_file_contract')}
        customer_file_commitment={files.customer_file_commitment}
        customer_file_commitmentChange={onChangeFile('customer_file_commitment')}
        customer_file_melli_card={files.customer_file_melli_card}
        customer_file_melli_cardChange={onChangeFile('customer_file_melli_card')}
        customer_file_newsletter={files.customer_file_newsletter}
        customer_file_newsletterChange={onChangeFile('customer_file_newsletter')}
        customer_file_statute={files.customer_file_statute}
        customer_file_statuteChange={onChangeFile('customer_file_statute')}
        logo_file={files.logo_file}
        logo_fileChange={onChangeFile('logo_file')}
      />
      <div className="mt-4 text-center">
        {
          <Button
            style={{ width: '100px' }}
            color="primary"
            className=" jr-btn jr-btn-lg bg-primary text-white MuiButton-contained"
            onClick={handleSave}>
            {loadingStatus ? <CircularProgress color="inherit" size={14} /> : 'Submit'}
          </Button>
        }
      </div>
      <div className="jr-main-content mt-4">
        <NotificationContainer />
        <SweetAlert
          show={values.success}
          success
          title={'Success'}
          confirmBtnText={'Close'}
          onConfirm={() => window.location.replace('/app/payment-facilitator/acceptors-list')}>
          Successfully Changed!
        </SweetAlert>
        <SweetAlert
          show={values.error}
          error
          title={'Error'}
          confirmBtnText={'Close'}
          onConfirm={onConfirm}>
          Error! {values.code ? values.code : ''}
        </SweetAlert>
      </div>
    </>
  );
}

export default AcceptorEdit;
