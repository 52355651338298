import React from "react";
import { NavHeader, NavMenu } from "containers/SideNav/components";

const Tools = () => (
  <>
    <NavHeader title="sidebar.tools" />

    <NavMenu
      title="sidebar.tools.IBANInquiry"
      icon="zmdi zmdi-accounts-list zmdi-hc-fw"
      url="/app/tools/iban-inquiry/"
    />
    <NavMenu
      title="sidebar.system.CardInquiry"
      icon="zmdi zmdi-accounts-list zmdi-hc-fw"
      url="/app/tools/card-inquiry/"
    />
    <NavMenu
      title="sidebar.system.Shahkar"
      icon="zmdi zmdi-accounts-list zmdi-hc-fw"
      url="/app/tools/shahkar-inquiry/"
    />
    <NavMenu
      title="sidebar.system.PostalCode"
      icon="zmdi zmdi-accounts-list zmdi-hc-fw"
      url="/app/tools/postalcode-inquiry/"
    />
  </>
);

export default Tools;
