import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import { token, API_UPDATE_GATEWAY_DOCUMENT, boot } from "constants/constants";
import {
  createDateLookup,
  downloadBase64,
  getIdentityStatus,
  updateDateLookup,
} from "constants/usefulFunctions";
import { NotificationContainer } from "react-notifications";
import SweetAlert from "react-bootstrap-sweetalert";
import { useSelector } from "react-redux";

function GatewayDocuments({ gatewayInfo }) {
  const { adminId } = useSelector((state) => state.authReducer);
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState({ success: false, error: false });
  const [state, setState] = useState({});
  const options = {
    columnsButton: true,
    actionsColumnIndex: state.columns && state.columns.length,
    search: true,
    exportButton: true,
    grouping: false,
    filtering: false,
    exportAllData: true,
    selection: false,
    editable: true,
  };
  const localization = {
    header: {
      actions: "",
    },
  };

  useEffect(() => {
    async function fetchData() {
      setState({
        columns: [
          {
            title: "Attachment type",
            field: "attachment_type",
            editable: "never",
          },
          {
            title: "Admin",
            field: "admin_id",
            editable: "never",
            initialEditValue: Number(adminId),
            render: (rowData) =>
              boot.admins && rowData.admin_id
                ? boot.admins.find((o) => o.id === rowData.admin_id)
                    .first_name +
                  " " +
                  boot.admins.find((o) => o.id === rowData.admin_id).last_name
                : "-",
          },
          {
            title: "File",
            field: "referer_transaction.amount",
            editable: "never",
            render: (rowData) => (
              <a
                role="button"
                target="_blank"
                onClick={() => downloadBase64(rowData.file)}
              >
                File
              </a>
            ),
          },
          createDateLookup(),
          updateDateLookup(),
          { title: "Description", field: "description" },
          {
            title: "Status",
            field: "status",
            lookup: {
              Approved: "Approved",
              Pending: "Pending",
              Declined: "Declined",
            },
            render: (rowData) => getIdentityStatus(rowData.status),
          },
        ],
        data: gatewayInfo.gateway_documents,
      });

      setLoading(false);
    }

    fetchData();
  }, [gatewayInfo]);

  function updateRow(gateway_id, id, status, description, data) {
    return axios
      .post(
        API_UPDATE_GATEWAY_DOCUMENT,
        {
          gateway_id,
          gateway_document_id: id,
          status,
          description,
        },
        token()
      )

      .then((response) => {
        if (response.status === 200 && response.data.status === "success") {
          data = response.data.data;

          setState({ ...state, data });
          setValues({ ...values, success: true });
        } else {
          setValues({ ...values, error: true });
        }
      })
      .catch((error) => {
        let failedValue = "";
        if (typeof error.response.data.data === "string") {
          failedValue = error.response.data.data;
        } else {
          for (var prop in error.response.data.data) {
            failedValue += prop + ": " + error.response.data.data[prop] + " - ";
          }
        }
        setValues({ ...values, error: true, code: failedValue });
      });
  }

  function onConfirm() {
    setValues({ success: false, error: false });
  }

  return (
    <div>
      <MaterialTable
        options={options}
        localization={localization}
        isLoading={loading}
        columns={state.columns}
        data={state.data}
        title="Gateway Documents"
        editable={{
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                resolve();
                const data = [...state.data];
                data[data.indexOf(oldData)] = newData;
                updateRow(
                  oldData.gateway_id,
                  oldData.id,
                  newData.status,
                  newData.description,
                  data
                );
              }, 600);
            }),
        }}
      />
      <NotificationContainer />
      <SweetAlert
        show={values.success}
        success
        title={"Success"}
        confirmBtnText={"Close"}
        onConfirm={onConfirm}
      >
        Successfully Changed!
      </SweetAlert>

      <SweetAlert
        show={values.error}
        error
        title={"Error"}
        confirmBtnText={"Close"}
        onConfirm={onConfirm}
      >
        Error! {values.code ? values.code : ""}
      </SweetAlert>
    </div>
  );
}

export default GatewayDocuments;
