import React, { useEffect, useState } from "react";
import { boot } from "constants/constants";
import {
  createDateLookup,
  downloadBase64,
  getIdentityStatus,
  renderAdmin,
  updateDateLookup,
} from "../../../../../constants/usefulFunctions";
import Avatar from "@mui/material/Avatar";
import DefaultAvatar from "assets/images/default/company-logo.png";
import MainTable from "components/MainTable";
import { useDispatch, useSelector } from "react-redux";
import {
  changeUserCompanyStatusRequest,
  getUserCompanyRequest,
} from "redux/actions/UserProfile";
import {
  CHANGE_USER_COMPANY_STATUS_STARTED,
  GET_USER_COMPANY_STARTED,
} from "redux/types";
import MaterialTable from "material-table";
import { Box } from "@mui/material";

const Company = ({ user_id }) => {
  const dispatch = useDispatch();
  const { rType, userCompany } = useSelector(
    (state) => state.userProfileReducer
  );
  const loading =
    rType === GET_USER_COMPANY_STARTED ||
    rType === CHANGE_USER_COMPANY_STATUS_STARTED;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    dispatch(getUserCompanyRequest({ user_id }));
  };

  const updateRow = async (id, status, description) => {
    dispatch(
      changeUserCompanyStatusRequest({ user_id, id, status, description })
    );
  };

  const columns = [
    renderAdmin(),
    {
      title: "Country",
      field: "country_id",
      editable: "never",
      render: (rowData) =>
        boot?.countries && rowData.country_id
          ? boot?.countries?.find((o) => o.id === rowData.country_id).name_en
          : "-",
    },
    { title: "Email", field: "email", editable: "never" },
    {
      title: "Name",
      field: "name",
      editable: "never",
      render: (rowData) => (
        <>
          <div className="jr-profile-banner-top-left">
            <div className="jr-profile-banner-avatar">
              <Avatar
                className="size-50 border"
                alt={rowData.name}
                src={rowData.logo != null ? rowData.logo : DefaultAvatar}
              />
            </div>
            <div className="jr-profile-banner-avatar-info">
              <p className="mb-0">{rowData.name}</p>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Files",
      field: "last_changes",
      editable: "never",
      render: (rowData) => (
        <>
          {rowData.last_changes && (
            <>
              <a
                role="button"
                onClick={() => downloadBase64(rowData.last_changes)}
              >
                Last Changes
              </a>
              <br />
            </>
          )}
          {rowData.official_newspaper && (
            <>
              <a
                role="button"
                onClick={() => downloadBase64(rowData.official_newspaper)}
              >
                Official Newspaper
              </a>
              <br />
            </>
          )}
          {rowData.owner_identity && (
            <>
              <a
                role="button"
                onClick={() => downloadBase64(rowData.owner_identity)}
              >
                Owner Identity
              </a>
              <br />
            </>
          )}
          {rowData.ownership_document && (
            <>
              <a
                role="button"
                onClick={() => downloadBase64(rowData.ownership_document)}
              >
                Ownership Document
              </a>
              <br />
            </>
          )}
          {rowData.logo && (
            <>
              <a role="button" onClick={() => downloadBase64(rowData.logo)}>
                Logo
              </a>
            </>
          )}
        </>
      ),
    },
    createDateLookup(),
    updateDateLookup(),
    { title: "Description", field: "description" },
    {
      title: "Status",
      field: "status",
      lookup: {
        Approved: "Approved",
        Declined: "Declined",
        Pending: "Pending",
      },
      render: (rowData) => getIdentityStatus(rowData.status),
    },
  ];

  const columns2 = [
    {
      title: "First Name",
      field: "first_name",
    },
    {
      title: "Last Name",
      field: "last_name",
    },
    {
      title: "Mobile",
      field: "mobile",
    },
    {
      title: "National Id",
      field: "national_id",
    },
    {
      title: "Files",
      field: "attachment",
      render: (rowData) => (
        <a role="button" onClick={() => downloadBase64(rowData.attachment)}>
          Signature
        </a>
      ),
    },
  ];
  const options = {
    columnsButton: true,
    actionsColumnIndex: columns.length,
    pageSize: 5,
  };

  return (
    <div className="card">
      <MainTable
        customOptions={options}
        loading={loading}
        columns={columns}
        data={userCompany}
        title="Company"
        onRowUpdate={(newData, oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              updateRow(oldData.id, newData.status, newData.description);
              resolve();
            }, 600);
          })
        }
        detailPanel={(rowData) => (
          <Box sx={{ p: 2 }}>
            <MaterialTable
              columns={columns2}
              data={rowData.owners}
              title=""
              options={{
                search: false,
                toolbar: false,
                paging: false,
              }}
            />
          </Box>
        )}
      />
    </div>
  );
};

export default Company;
