import React, { useEffect, useMemo } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import "assets/vendors/style";
import defaultTheme from "./themes/defaultTheme";
import AppLocale from "../lngProvider";
import "styles/AppStyles.scss";
import MainApp from "../app";
import RTL from "util/RTL";
import asyncComponent from "util/asyncComponent";
import SignIn from "./SignIn";

const App = (props) => {
  const { location, locale, authToken, isDirectionRTL } = props;

  useEffect(() => {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
  }, []);

  if (location.pathname === "/") {
    return <Redirect to={"/signin"} />;
  }
  const applyTheme = createTheme(defaultTheme);

  if (isDirectionRTL) {
    applyTheme.direction = "rtl";
    document.body.classList.add("rtl");
  } else {
    document.body.classList.remove("rtl");
    applyTheme.direction = "ltr";
  }

  const currentAppLocale = AppLocale[locale.locale];

  return (
    <ThemeProvider theme={applyTheme}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <RTL>
          <div className="app-main">
            <Switch>
              <Route
                path={"/app"}
                render={(props) =>
                  authToken ? <MainApp {...props} /> : <Redirect to="/signin" />
                }
              />
              <Route
                path="/signin"
                render={() =>
                  authToken ? <Redirect to="/app/dashboard" /> : <SignIn />
                }
              />
              <Route
                component={asyncComponent(() => import("components/Error404"))}
              />
            </Switch>
          </div>
        </RTL>
      </IntlProvider>
    </ThemeProvider>
  );
};

const mapStateToProps = ({ settings, authReducer }) => {
  return {
    locale: settings.locale,
    isDirectionRTL: settings.isDirectionRTL,
    authToken: authReducer.authToken,
  };
};

export default connect(mapStateToProps)(App);
