import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import {
  API_GET_BANK_ALL,
  API_GET_CITY_ONE,
  API_GET_COUNTRY_ALL,
  API_GET_PROVINCE_ALL,
  API_GET_AREA,
  token
} from 'constants/constants';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

function CustomerInfo({
  customer_name,
  customer_nameChange,
  customer_family,
  customer_familyChange,
  customer_latin_name,
  customer_latin_nameChange,
  customer_latin_family,
  customer_latin_familyChange,
  customer_father_name,
  customer_father_nameChange,
  customer_latin_father_name,
  customer_latin_father_nameChange,
  customer_national_code,
  customer_national_codeChange,
  customer_birthdate,
  customer_birthdateChange,
  customer_gender,
  customer_genderChange,
  customer_country_id,
  customer_country_idChange,
  customer_province_id,
  customer_province_idChange,
  customer_city_id,
  customer_city_idChange,
  customer_register_number,
  customer_register_numberChange,
  customer_licence_number,
  customer_licence_numberChange,
  customer_legal_type,
  customer_legal_typeChange,
  customer_foundation_date,
  customer_foundation_dateChange,
  customer_company_name,
  customer_company_nameChange,
  customer_latin_company_name,
  customer_latin_company_nameChange,
  customer_vital_status,
  customer_vital_statusChange,
  customer_commercial_code,
  customer_commercial_codeChange
}) {
  const [loading, setLoading] = useState(false);
  const [province, setProvince] = useState([]);
  const [cityCustomer, setCityCustomer] = useState([]);
  const [country, setCountry] = useState([]);

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      await axios.post(API_GET_PROVINCE_ALL, {}, token()).then((res) => {
        const data = res.data.data;
        setProvince(data);
      });
      setLoading(false);
    }
    fetchData();
  }, []);

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      await axios
        .post(API_GET_CITY_ONE, { province_id: customer_province_id }, token())
        .then((res) => {
          const data = res.data.data;
          console.log(data, 'lll');
          setCityCustomer(data);
        });
      setLoading(false);
    }
    fetchData();
  }, [customer_province_id]);

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      await axios.post(API_GET_COUNTRY_ALL, {}, token()).then((res) => {
        const data = res.data.data;
        setCountry(data);
      });
      setLoading(false);
    }
    fetchData();
  }, []);

  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '65%'
      }
    }
  }));
  const classes = useStyles();
  return (
    <div>
      <div className="row">
        <div className="jr-card p-0 col-xl-12 col-lg-12 col-md-12 col-12 ">
          <div className="jr-card-header card-img-top mb-0 p-4 bg-grey lighten-4">
            <h3 className="card-heading">Customer Info</h3>
          </div>
          <div className="card-body text-center">
            <ul className="contact-list list-unstyled">
              <div className="row">
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="Name"
                    value={customer_name}
                    InputLabelProps={{ shrink: true }}
                    onChange={customer_nameChange}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="Family"
                    value={customer_family}
                    InputLabelProps={{ shrink: true }}
                    onChange={customer_familyChange}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="Name Latin"
                    value={customer_latin_name}
                    InputLabelProps={{ shrink: true }}
                    onChange={customer_latin_nameChange}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="Last Name Latin"
                    value={customer_latin_family}
                    InputLabelProps={{ shrink: true }}
                    onChange={customer_latin_familyChange}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="Father Name"
                    value={customer_father_name}
                    InputLabelProps={{ shrink: true }}
                    onChange={customer_father_nameChange}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="Father Name Latin"
                    value={customer_latin_father_name}
                    InputLabelProps={{ shrink: true }}
                    onChange={customer_latin_father_nameChange}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="National Code"
                    value={customer_national_code}
                    InputLabelProps={{ shrink: true }}
                    onChange={customer_national_codeChange}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    placeholder="0000/00/00"
                    label="Birthdate"
                    value={customer_birthdate}
                    InputLabelProps={{ shrink: true }}
                    onChange={customer_birthdateChange}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    select
                    fullWidth
                    label="Gender"
                    value={customer_gender}
                    InputLabelProps={{ shrink: true }}
                    onChange={customer_genderChange}
                    margin="normal"
                    variant="outlined">
                    <MenuItem key="Man" value="Man">
                      {' '}
                      <span>مرد</span>{' '}
                    </MenuItem>
                    <MenuItem key="Woman" value="Woman">
                      {' '}
                      <span>زن</span>{' '}
                    </MenuItem>
                  </TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    select
                    fullWidth
                    label="Country ID"
                    value={customer_country_id}
                    InputLabelProps={{ shrink: true }}
                    onChange={customer_country_idChange}
                    margin="normal"
                    variant="outlined">
                    {country &&
                      country.map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.country_id}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                  </TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    select
                    fullWidth
                    label="Province ID"
                    value={customer_province_id}
                    InputLabelProps={{ shrink: true }}
                    onChange={customer_province_idChange}
                    margin="normal"
                    variant="outlined">
                    {province &&
                      province.map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.province_id}>
                            {item.title}
                          </MenuItem>
                        );
                      })}
                  </TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    select
                    fullWidth
                    label="City ID"
                    value={customer_city_id}
                    InputLabelProps={{ shrink: true }}
                    onChange={customer_city_idChange}
                    margin="normal"
                    variant="outlined">
                    {cityCustomer &&
                      cityCustomer.map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.city_id}>
                            {item.title}
                          </MenuItem>
                        );
                      })}
                  </TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="Register Number"
                    value={customer_register_number}
                    InputLabelProps={{ shrink: true }}
                    onChange={customer_register_numberChange}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="Licence Number"
                    value={customer_licence_number}
                    InputLabelProps={{ shrink: true }}
                    onChange={customer_licence_numberChange}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="Foundation Date"
                    value={customer_foundation_date}
                    InputLabelProps={{ shrink: true }}
                    onChange={customer_foundation_dateChange}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="Company Name"
                    value={customer_company_name}
                    InputLabelProps={{ shrink: true }}
                    onChange={customer_company_nameChange}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="EnCompany Name"
                    value={customer_latin_company_name}
                    InputLabelProps={{ shrink: true }}
                    onChange={customer_latin_company_nameChange}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="Commercial Code"
                    value={customer_commercial_code}
                    InputLabelProps={{ shrink: true }}
                    onChange={customer_commercial_codeChange}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    select
                    fullWidth
                    label="Legal Type"
                    value={customer_legal_type}
                    InputLabelProps={{ shrink: true }}
                    onChange={customer_legal_typeChange}
                    margin="normal"
                    variant="outlined">
                    <MenuItem key="Haghighi" value="Haghighi">
                      {' '}
                      <span>حقیقی</span>{' '}
                    </MenuItem>
                    <MenuItem key="Hoghooghi" value="Hoghooghi">
                      {' '}
                      <span>حقوقی</span>{' '}
                    </MenuItem>
                  </TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    select
                    fullWidth
                    label="Vital Status"
                    value={customer_vital_status}
                    InputLabelProps={{ shrink: true }}
                    onChange={customer_vital_statusChange}
                    margin="normal"
                    variant="outlined">
                    <MenuItem key="InLife" value="InLife">
                      {' '}
                      <span>در قید حیات</span>{' '}
                    </MenuItem>
                    <MenuItem key="Deceased" value="Deceased">
                      {' '}
                      <span>فوت شده</span>{' '}
                    </MenuItem>
                  </TextField>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerInfo;
