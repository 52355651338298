import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { Currencies } from "constants/constants";
import { NotificationContainer } from "react-notifications";
import SweetAlert from "react-bootstrap-sweetalert";
import makeStyles from "@mui/styles/makeStyles";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { thousands_separators } from "../../../../../constants/usefulFunctions";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserBankAccountsRequest,
  getUserWalletsRequest,
  topupUserRequest,
} from "redux/actions/UserProfile";
import {
  TOPUP_USER_FAILURE,
  TOPUP_USER_STARTED,
  TOPUP_USER_SUCCESS,
} from "redux/types";
import AppNumberInput from "components/AppNumberInput/index";

const Topup = (user) => {
  const dispatch = useDispatch();
  const { rType, userBankAccounts, userWallets, error } = useSelector(
    (state) => state.userProfileReducer
  );
  const user_id = user.user_id;
  const loading = rType === TOPUP_USER_STARTED;

  useEffect(() => {
    dispatch(getUserBankAccountsRequest({ user_id }));
  }, []);

  useEffect(() => {
    dispatch(getUserWalletsRequest({ user_id }));
  }, []);

  useEffect(() => {
    if (rType === TOPUP_USER_SUCCESS) setValues({ ...values, success: true });
    else if (rType === TOPUP_USER_FAILURE)
      setValues({ ...values, error: true });
  }, [rType]);

  const [values, setValues] = useState({
    amount: 0,
    currency: 3,
    description: "",
    account: 0,
    success: false,
    error: false,
    created_at: moment().format("YYYY-MM-DD HH:mm:ss"),
    file: null,
    upload_contract: false,
  });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const [alert, setAlert] = useState({ open: false });
  const handleRequestClose = () => {
    setAlert({ open: false });
  };

  const handleSaveUser = () => {
    const formData = new FormData();

    formData.append("amount", values.amount);
    formData.append("currency_id", values.currency);
    formData.append("admin_description", values.description);
    formData.append("created_at", values.created_at);
    formData.append("file", values.file);
    formData.append("user_id", user_id);

    dispatch(topupUserRequest(formData));
    handleRequestClose();
  };

  let ibanBank =
    userBankAccounts &&
    userBankAccounts.find((item) => item.id === values.account);

  function onConfirm() {
    setValues({ ...values, success: false, error: false });
  }

  function onConfirm() {
    setValues({ ...values, success: false, error: false });
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      "& > *": {
        margin: theme.spacing(1),
        width: "65%",
      },
    },
  }));
  const classes = useStyles();
  return (
    <div className="jr-card jr-full-card">
      <div className="card-header">
        <h4 className="card-title mb-0">Topup</h4>
      </div>
      <div style={{ padding: 24 }}>
        <div className="row">
          <>
            <NotificationContainer />
            <SweetAlert
              show={values.success}
              success
              title={"Success"}
              confirmBtnText={"Close"}
              onConfirm={onConfirm}
            >
              Successfully Changed!
            </SweetAlert>
            <SweetAlert
              show={values.error}
              error
              title={"Error"}
              confirmBtnText={"Close"}
              onConfirm={onConfirm}
            >
              Error! {values.code ? values.code : ""}
            </SweetAlert>
          </>

          <div className="col-xl-6 col-lg-12 col-md-12 col-12">
            <div className={classes.root}>
              <AppNumberInput
                label="Amount"
                value={values.amount}
                onChange={handleChange("amount")}
                dir="ltr"
              />
            </div>
            <div className={classes.root}>
              <TextField
                select
                label="Currency"
                value={values.currency}
                onChange={handleChange("currency")}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                {userWallets &&
                  userWallets.map((option) => (
                    <MenuItem
                      key={option.currency_id}
                      value={option.currency_id}
                    >
                      {Currencies[option.currency_id]}
                    </MenuItem>
                  ))}
              </TextField>
            </div>
            <div className={classes.root}>
              <TextField
                multiline
                label={"Description"}
                value={values.description}
                onChange={handleChange("description")}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className={classes.root}>
              <TextField
                label="Created at"
                value={values.created_at}
                onChange={handleChange("created_at")}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <Button
              className="bg-light jr-btn jr-btn-sm text-dark my-2 "
              component="label"
            >
              <i className="zmdi zmdi-file-plus  mr-2 jr-fs-xl d-inline-flex align-middle" />
              Browse
              <input
                type="file"
                onChange={(e) => {
                  setValues({
                    ...values,
                    file: e.target.files[0],
                    upload_contract: true,
                  });
                }}
                style={{ display: "none" }}
              />
            </Button>
            {values.file && (
              <div className="mt-2">
                File Name:{" "}
                <span className="text-success mr-2">{values.file?.name}</span>
                File Size:{" "}
                <span className="text-success">
                  {Math.trunc(values.file?.size / 1024)}KB
                </span>
              </div>
            )}

            <div className="d-flex flex-row align-items-center mt-2">
              {/*{ <Button disabled={loading} style={{width: "100px"}} color="primary" className=" jr-btn jr-btn-lg bg-primary text-white MuiButton-contained" onClick={handleSaveUser}>{loading ? <CircularProgress color={"#fff"} size={14} />: "Save"}</Button>}*/}
              <div>
                <Button
                  style={{ width: "100px" }}
                  variant="contained"
                  className="jr-btn jr-btn-lg bg-primary text-white MuiButton-contained"
                  onClick={() => setAlert({ open: true })}
                >
                  {loading ? (
                    <CircularProgress color="inherit" size={14} />
                  ) : (
                    "Save"
                  )}
                </Button>
                <div style={{ minWidth: "500px" }}>
                  <Dialog open={alert.open} onClose={handleRequestClose}>
                    <DialogTitle>{"Verify Topup"}</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        <div className="text-center">Amount:</div>
                        <h2
                          style={{ color: "#03B585" }}
                          className="text-center"
                        >{`${thousands_separators(
                          Currencies[values.currency] === "IRR"
                            ? Math.trunc(values.amount)
                            : values.amount
                        )} ${Currencies[values.currency]} `}</h2>
                        <div>
                          Description:{" "}
                          <div className="text-black">{values.description}</div>
                        </div>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleRequestClose} color="secondary">
                        Cancel
                      </Button>

                      <Button
                        disabled={loading}
                        color="primary"
                        onClick={handleSaveUser}
                      >
                        Agree
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topup;
