import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import { API_GET_BANK_ALL, token } from 'constants/constants';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

function AcceptorBankInfo({
  acceptor_bank_id,
  acceptor_bank_idChange,
  acceptor_account_no,
  acceptor_account_noChange,
  acceptor_iban_no,
  acceptor_iban_noChange
}) {
  const [loading, setLoading] = useState(false);
  const [bank, setBank] = useState([]);

  useEffect(() => {
    setLoading(true);

    async function fetchData() {
      await axios.post(API_GET_BANK_ALL, {}, token()).then((res) => {
        const data = res.data.data;
        setBank(data);
      });
      setLoading(false);
    }

    fetchData();
  }, []);

  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '65%'
      }
    }
  }));
  const classes = useStyles();
  return (
    <div>
      <div className="row">
        <div className="jr-card p-0 col-xl-12 col-lg-12 col-md-12 col-12 ">
          <div className="jr-card-header card-img-top mb-0 p-4 bg-grey lighten-4">
            <h3 className="card-heading">Bank Info Acceptor</h3>
          </div>
          <div className="card-body text-center">
            <ul className="contact-list list-unstyled">
              <div className="row">
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    select
                    fullWidth
                    label="Identity Bank"
                    value={acceptor_bank_id}
                    InputLabelProps={{ shrink: true }}
                    onChange={acceptor_bank_idChange}
                    margin="normal"
                    variant="outlined">
                    {bank &&
                      bank.map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.bank_id}>
                            {item.title}
                          </MenuItem>
                        );
                      })}
                  </TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="Account acceptor"
                    value={acceptor_account_no}
                    InputLabelProps={{ shrink: true }}
                    onChange={acceptor_account_noChange}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="Acceptor IBAN"
                    value={acceptor_iban_no}
                    InputLabelProps={{ shrink: true }}
                    onChange={acceptor_iban_noChange}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AcceptorBankInfo;
