import React from "react";
import MaterialTable from "material-table";
import { boot } from "constants/constants";
import {
  createDateLookup,
  thousands_separators,
} from "constants/usefulFunctions";

function YoutubePayoutTransactions({ data }) {
  const options = {
    columnsButton: true,
    search: true,
    exportButton: true,
    grouping: false,
    filtering: true,
    exportAllData: true,
    selection: false,
    editable: false,
    pageSize: 5,
    exportFileName: "All Transactions",
  };
  return (
    <div className="mt-5">
      <MaterialTable
        options={options}
        columns={[
          {
            title: "Admin",
            field: "admin_id",
            render: (rowData) =>
              rowData?.admin_id
                ? boot.admins.find((o) => o.id === rowData.admin_id)
                    .first_name +
                  " " +
                  boot?.admins.find((o) => o.id === rowData.admin_id).last_name
                : "-",
          },
          {
            title: "amount",
            field: "amount",
            render: (rowData) => thousands_separators(rowData.amount),
          },
          {
            title: "bank_account_id",
            field: "bank_account_id",
          },

          {
            title: "tracking_code",
            field: "tracking_code",
          },
          {
            title: "reference_code",
            field: "reference_code",
          },
          {
            title: "attachment",
            field: "attachment",
          },

          {
            title: "service",
            field: "service",
          },
          {
            title: "Service Status",
            field: "service_status",
          },
          {
            title: "description",
            field: "description",
          },
          createDateLookup(),
        ]}
        data={data?.payouts}
        title={"Youtube Payout"}
      />
    </div>
  );
}

export default YoutubePayoutTransactions;
