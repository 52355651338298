import { put, takeLatest } from "redux-saga/effects";
import {
  getChartActionsFailure,
  getChartActionsStarted,
  getChartActionsSuccess,
  getChartFailure,
  getChartStarted,
  getChartSuccess,
} from "redux/actions/CRM";
import { GET_CHART_ACTIONS_REQUEST, GET_CHART_REQUEST } from "redux/types";
import { getChartActionsAPI, getChartAPI } from "services/API/CRM/index";
// --------------------------------------------------------------------------------
function* onGetChartActions(data) {
  yield put(getChartActionsStarted());
  try {
    let response = yield getChartActionsAPI({
      urlPrefix: data.payload.urlPrefix,
    });
    if (response.success) {
      yield put(getChartActionsSuccess({ charts: response.data }));
    } else {
      yield put(getChartActionsFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(getChartActionsFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onGetChart(data) {
  yield put(getChartStarted());
  try {
    let response = yield getChartAPI({
      urlPrefix: data.payload.urlPrefix,
      category: data.payload.category,
      method: data.payload.method,
      fromDate: data.payload.fromDate,
      toDate: data.payload.toDate,
      type: data.payload.type,
    });
    if (response.success) {
      yield put(getChartSuccess({ charts: response.data }));
      data.payload.onSuccess(response.data);
    } else {
      yield put(getChartFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(getChartFailure({ error: error.message }));
  }
}
// ================================================================================
function* crmSaga() {
  yield takeLatest(GET_CHART_ACTIONS_REQUEST, onGetChartActions);
  yield takeLatest(GET_CHART_REQUEST, onGetChart);
}

export default crmSaga;
