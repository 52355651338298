import { yektuberBaseAPIAddress } from "constants/constants";
import axiosMiddleware from "../index";
// --------------------------------------------------------------------------------
export const getAllYoutubeChannelsAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "youtube-channels/reports",
    body: data,
  });
export const createYoutubeChannelAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "user-youtube-channels/create",
    body: data,
  });
export const getArzishoConsultantFormAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "youtube/arzisho-consultant-form",
    body: data,
  });
export const getYoutubeArzishoFormAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "youtube/arzisho-form",
    body: data,
  });
export const getYoutubePayoutsAPI = () =>
  axiosMiddleware({
    method: "post",
    path: "youtube-channels/payouts",
  });
export const getYektuberSignupAPI = () =>
  axiosMiddleware({
    method: "post",
    path: "youtube/users",
  });
export const getYoutubeCounselingListAPI = () =>
  axiosMiddleware({
    method: "post",
    baseAPIAddress: yektuberBaseAPIAddress,
    path: "counseling-list",
  });
export const getYoutubeCheckingListAPI = () =>
  axiosMiddleware({
    method: "post",
    baseAPIAddress: yektuberBaseAPIAddress,
    path: "checking-list",
  });
export const getYoutubeContactListAPI = () =>
  axiosMiddleware({
    method: "post",
    baseAPIAddress: yektuberBaseAPIAddress,
    path: "contact-list",
  });
export const getYoutubePartnerListAPI = () =>
  axiosMiddleware({
    method: "post",
    baseAPIAddress: yektuberBaseAPIAddress,
    path: "partner-list",
  });
