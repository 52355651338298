import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const System = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/system-log`} />
      <Route
        path={`${match.url}/admins`}
        component={asyncComponent(() => import("./routes/Admins"))}
      />
      <Route
        path={`${match.url}/permissions`}
        component={asyncComponent(() => import("./routes/Permissions"))}
      />
      <Route
        path={`${match.url}/error-codes`}
        component={asyncComponent(() => import("./routes/ErrorCodes"))}
      />
      <Route
        path={`${match.url}/exchange-rate`}
        component={asyncComponent(() => import("./routes/ExchangeRate"))}
      />
      <Route
        path={`${match.url}/exchange-transactions`}
        component={asyncComponent(() =>
          import("./routes/ExchangeTransactions")
        )}
      />
      <Route
        path={`${match.url}/rollcall-management`}
        component={asyncComponent(() => import("./routes/RollcallManagement"))}
      />
    </Switch>
  </div>
);

export default System;
