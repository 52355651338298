import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import { API_GET_GATEWAY_HISTORY, token, boot } from "constants/constants";
import { createDateLookup, updateDateLookup } from "constants/usefulFunctions";
import { useSelector } from "react-redux";

function GatewayHistory({ gateway_id }) {
  const { adminId } = useSelector((state) => state.authReducer);
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({});
  const options = {
    columnsButton: true,
    actionsColumnIndex: state.columns && state.columns.length,
    search: true,
    exportButton: true,
    grouping: false,
    filtering: false,
    exportAllData: true,
    selection: false,
    editable: true,
    addRowPosition: "first",
  };
  const localization = {
    header: {
      actions: "",
    },
  };
  useEffect(() => {
    async function fetchData() {
      await axios
        .post(API_GET_GATEWAY_HISTORY, { gateway_id }, token())
        .then((res) => {
          let data = res.data.data;
          setState({
            columns: [
              {
                title: "Admin",
                field: "admin_id",
                editable: "never",
                initialEditValue: Number(adminId),
                render: (rowData) =>
                  boot.admins && rowData.admin_id
                    ? boot.admins.find((o) => o.id === rowData.admin_id)
                        .first_name +
                      " " +
                      boot.admins.find((o) => o.id === rowData.admin_id)
                        .last_name
                    : "-",
              },
              { title: "System Description", field: "system_description" },
              { title: "Admin Description", field: "admin_description" },
              { title: "Stage", field: "stage" },
              { title: "Status", field: "status" },
              createDateLookup(),
              updateDateLookup(),
            ],
            data: data,
          });
        });
      setLoading(false);
    }

    fetchData();
  }, []);
  return (
    <div className="mt-5">
      <MaterialTable
        options={options}
        localization={localization}
        isLoading={loading}
        columns={state.columns}
        data={state.data}
        title="Gateway History"
      />
    </div>
  );
}

export default GatewayHistory;
