import React from "react";
import Widget from "components/Widget/index";
import classNames from "classnames";
import CircularProgress from "@mui/material/CircularProgress";

const DataCard = (props) => {
  const {
    imageIcon,
    title,
    subTitle,
    background,
    loading,
    onClick,
    customClassName,
  } = props;
  return (
    <div
      onClick={onClick}
      className={classNames(customClassName ?? "col-lg-4 col-sm-6 pointer")}
    >
      <Widget styleName={`p-4 bg-${background} my-3`}>
        {loading ? (
          <div className=" d-flex justify-content-center">
            <CircularProgress color="inherit" size={30} />
          </div>
        ) : (
          <div className="media align-items-center flex-nowrap text-white m-0">
            {imageIcon && (
              <div className="mr-lg-4 mr-3">
                <img className="img-fluid" src={imageIcon} alt="..." />
              </div>
            )}
            <div className="media-body">
              <h1 className="jr-font-weight-bold mb-0">{title}</h1>
              <p className="text-white mb-0">{subTitle}</p>
            </div>
          </div>
        )}
      </Widget>
    </div>
  );
};

export default DataCard;
