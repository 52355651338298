import { put, takeLatest } from "redux-saga/effects";
import {
  changeHoldTransactionStatusFailure,
  changeHoldTransactionStatusStarted,
  changeHoldTransactionStatusSuccess,
  changeRefundRequestStatusFailure,
  changeRefundRequestStatusStarted,
  changeRefundRequestStatusSuccess,
  closeModalRequest,
  confirmVIPPayoutFailure,
  confirmVIPPayoutStarted,
  confirmVIPPayoutSuccess,
  getAllExchangesFailure,
  getAllExchangesStarted,
  getAllExchangesSuccess,
  getAllPayoutsFailure,
  getAllPayoutsStarted,
  getAllPayoutsSuccess,
  getAllTopupsFailure,
  getAllTopupsStarted,
  getAllTopupsSuccess,
  getAllTransactionsFailure,
  getAllTransactionsStarted,
  getAllTransactionsSuccess,
  getAllTransfersFailure,
  getAllTransfersStarted,
  getAllTransfersSuccess,
  getBanksReportFailure,
  getBanksReportStarted,
  getBanksReportSuccess,
  getHoldTransactionsFailure,
  getHoldTransactionsRequest,
  getHoldTransactionsStarted,
  getHoldTransactionsSuccess,
  getPaymentLinkTransactionsFailure,
  getPaymentLinkTransactionsStarted,
  getPaymentLinkTransactionsSuccess,
  getPendingPayoutsFailure,
  getPendingPayoutsStarted,
  getPendingPayoutsSuccess,
  getPersonalTransactionsFailure,
  getPersonalTransactionsStarted,
  getPersonalTransactionsSuccess,
  getRefundRequestsFailure,
  getRefundRequestsRequest,
  getRefundRequestsStarted,
  getRefundRequestsSuccess,
  getTransactionsPayoutPayFailure,
  getTransactionsPayoutPayStarted,
  getTransactionsPayoutPaySuccess,
  getVIPPendingPayoutsFailure,
  getVIPPendingPayoutsStarted,
  getVIPPendingPayoutsSuccess,
  getWebsiteGatewaysFailure,
  getWebsiteGatewaysStarted,
  getWebsiteGatewaysSuccess,
  getWirePayoutsFailure,
  getWirePayoutsStarted,
  getWirePayoutsSuccess,
  openModalRequest,
} from "redux/actions";
import {
  CHANGE_HOLD_TRANSACTION_STATUS_REQUEST,
  CHANGE_REFUND_REQUEST_STATUS_REQUEST,
  CONFIRM_VIP_PAYOUT_REQUEST,
  GET_ALL_EXCHANGES_REQUEST,
  GET_ALL_PAYOUTS_REQUEST,
  GET_ALL_TOPUPS_REQUEST,
  GET_ALL_TRANSACTIONS_REQUEST,
  GET_ALL_TRANSFERS_REQUEST,
  GET_BANKS_REPORT_REQUEST,
  GET_HOLD_TRANSACTIONS_REQUEST,
  GET_PAYMENT_LINK_TRANSACTIONS_REQUEST,
  GET_PENDING_PAYOUTS_REQUEST,
  GET_PERSONAL_TRANSACTIONS_REQUEST,
  GET_REFUND_REQUESTS_REQUEST,
  GET_TRANSACTIONS_PAYOUT_PAY_REQUEST,
  GET_VIP_PENDING_PAYOUTS_REQUEST,
  GET_WEBSITE_GATEWAYS_REQUEST,
  GET_WIRE_PAYOUTS_REQUEST,
} from "redux/types";
import {
  changeHoldTransactionStatusAPI,
  changeRefundRequestStatusAPI,
  confirmVIPPayoutAPI,
  getAllExchangesAPI,
  getAllPayoutsAPI,
  getAllTopupsAPI,
  getAllTransactionsAPI,
  getAllTransfersAPI,
  getBanksReportAPI,
  getHoldTransactionsAPI,
  getPaymentLinkTransactionsAPI,
  getPendingPayoutsAPI,
  getPersonalTransactionsAPI,
  getRefundRequestsAPI,
  getTransactionsPayoutPayAPI,
  getVIPPendingPayoutsAPI,
  getWebsiteGatewaysAPI,
  getWirePayoutsAPI,
} from "services/API/Financial";
// --------------------------------------------------------------------------------
function* onGetAllTransactions({ payload }) {
  yield put(getAllTransactionsStarted());
  try {
    let response = yield getAllTransactionsAPI(payload);
    if (response.status === "success") {
      yield put(getAllTransactionsSuccess({ transactions: response.data }));
    } else {
      yield put(
        getAllTransactionsFailure({
          transactions: [],
          error: response.errorMessage,
        })
      );
    }
  } catch (error) {
    yield put(
      getAllTransactionsFailure({ transactions: [], error: error.message })
    );
  }
}
// --------------------------------------------------------------------------------
function* onGetHoldTransactions() {
  yield put(getHoldTransactionsStarted());
  try {
    let response = yield getHoldTransactionsAPI();
    if (response.status === "success") {
      yield put(
        getHoldTransactionsSuccess({ holdTransactions: response.data })
      );
    } else {
      yield put(
        getHoldTransactionsFailure({
          holdTransactions: [],
          error: response.errorMessage,
        })
      );
    }
  } catch (error) {
    yield put(
      getHoldTransactionsFailure({ holdTransactions: [], error: error.message })
    );
  }
}
// --------------------------------------------------------------------------------
function* onChangeHoldTransactionStatus({ payload }) {
  yield put(changeHoldTransactionStatusStarted());
  try {
    let response = yield changeHoldTransactionStatusAPI(payload);
    if (response.status === "success") {
      yield put(changeHoldTransactionStatusSuccess());
      yield put(getHoldTransactionsRequest());
    } else {
      yield put(
        changeHoldTransactionStatusFailure({ error: response.errorMessage })
      );
    }
  } catch (error) {
    yield put(changeHoldTransactionStatusFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onGetAllPayouts({ payload }) {
  yield put(getAllPayoutsStarted());
  try {
    let response = yield getAllPayoutsAPI(payload);
    if (response.status === "success") {
      yield put(getAllPayoutsSuccess({ payouts: response.data }));
    } else {
      yield put(
        getAllPayoutsFailure({ payouts: [], error: response.errorMessage })
      );
    }
  } catch (error) {
    yield put(getAllPayoutsFailure({ payouts: [], error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onGetPendingPayouts({ payload }) {
  yield put(getPendingPayoutsStarted());
  try {
    let response = yield getPendingPayoutsAPI(payload);
    if (response.status === "success") {
      yield put(getPendingPayoutsSuccess({ pendingPayouts: response.data }));
    } else {
      yield put(
        getPendingPayoutsFailure({ payouts: [], error: response.errorMessage })
      );
    }
  } catch (error) {
    yield put(getPendingPayoutsFailure({ payouts: [], error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onGetTransactionsPayoutPay({ payload }) {
  yield put(getTransactionsPayoutPayStarted());
  try {
    let response = yield getTransactionsPayoutPayAPI(payload);
    if (response.status === "success") {
      yield put(
        getTransactionsPayoutPaySuccess({
          id: payload.id,
          tracking_code: response.data,
        })
      );
      yield put(
        openModalRequest({
          status: "success",
          description: (
            <>
              Tracking Code:{" "}
              <span className="text-dark font-weight-bold">
                {response.data}
              </span>
            </>
          ),
        })
      );
    } else {
      yield put(
        getTransactionsPayoutPayFailure({
          transactionsPayoutPay: [],
          error: response.errorMessage,
        })
      );
      yield put(
        openModalRequest({ status: "error", description: response.data })
      );
    }
  } catch (error) {
    yield put(
      getTransactionsPayoutPayFailure({
        transactionsPayoutPay: [],
        error: error.message,
      })
    );
    yield put(
      openModalRequest({ status: "error", description: error.message })
    );
  }
}
// --------------------------------------------------------------------------------
function* onGetVIPPendingPayouts({ payload }) {
  yield put(getVIPPendingPayoutsStarted());
  try {
    let response = yield getVIPPendingPayoutsAPI(payload);
    if (response.status === "success") {
      yield put(
        getVIPPendingPayoutsSuccess({ vipPendingPayouts: response.data })
      );
    } else {
      yield put(
        getVIPPendingPayoutsFailure({
          payouts: [],
          error: response.errorMessage,
        })
      );
    }
  } catch (error) {
    yield put(
      getVIPPendingPayoutsFailure({
        vipPendingPayouts: [],
        error: error.message,
      })
    );
  }
}
// --------------------------------------------------------------------------------
function* onConfirmVIPPayout({ payload }) {
  yield put(confirmVIPPayoutStarted());
  try {
    let response = yield confirmVIPPayoutAPI(payload);
    if (response.status === "success") {
      yield put(
        confirmVIPPayoutSuccess({
          id: payload.id,
          tracking_code: response.data,
        })
      );
      yield put(
        openModalRequest({
          status: "success",
          description: (
            <>
              Tracking Code:{" "}
              <span className="text-dark font-weight-bold">
                {response.data}
              </span>
            </>
          ),
        })
      );
    } else {
      yield put(
        confirmVIPPayoutFailure({
          transactionsPayoutPay: [],
          error: response.errorMessage,
        })
      );
      yield put(
        openModalRequest({ status: "error", description: response.data })
      );
    }
  } catch (error) {
    yield put(
      confirmVIPPayoutFailure({
        error: error.message,
      })
    );
    yield put(
      openModalRequest({ status: "error", description: error.message })
    );
  }
}
// --------------------------------------------------------------------------------
function* onGetWirePayouts({ payload }) {
  yield put(getWirePayoutsStarted());
  try {
    let response = yield getWirePayoutsAPI(payload);
    if (response.status === "success") {
      yield put(getWirePayoutsSuccess({ wirePayouts: response.data }));
    } else {
      yield put(
        getWirePayoutsFailure({ wirePayouts: [], error: response.errorMessage })
      );
    }
  } catch (error) {
    yield put(getWirePayoutsFailure({ wirePayouts: [], error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onGetAllTopups({ payload }) {
  yield put(getAllTopupsStarted());
  try {
    let response = yield getAllTopupsAPI(payload);
    if (response.status === "success") {
      yield put(getAllTopupsSuccess({ topups: response.data }));
    } else {
      yield put(
        getAllTopupsFailure({ topups: [], error: response.errorMessage })
      );
    }
  } catch (error) {
    yield put(getAllTopupsFailure({ topups: [], error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onGetRefundRequests() {
  yield put(getRefundRequestsStarted());
  try {
    let response = yield getRefundRequestsAPI();
    if (response.status === "success") {
      yield put(getRefundRequestsSuccess({ refundRequests: response.data }));
    } else {
      yield put(
        getRefundRequestsFailure({
          refundRequests: [],
          error: response.errorMessage,
        })
      );
    }
  } catch (error) {
    yield put(
      getRefundRequestsFailure({
        refundRequests: [],
        error: error.message,
      })
    );
  }
}
// --------------------------------------------------------------------------------
function* onChangeRefundRequestStatus({ payload }) {
  yield put(changeRefundRequestStatusStarted());
  try {
    let response = yield changeRefundRequestStatusAPI(payload);
    if (response.status === "success") {
      yield put(changeRefundRequestStatusSuccess());
      yield put(getRefundRequestsRequest());
    } else {
      yield put(
        changeRefundRequestStatusFailure({ error: response.errorMessage })
      );
    }
  } catch (error) {
    yield put(changeRefundRequestStatusFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onGetWebsiteGateways({ payload }) {
  yield put(getWebsiteGatewaysStarted());
  try {
    let response = yield getWebsiteGatewaysAPI(payload);
    if (response.status === "success") {
      yield put(getWebsiteGatewaysSuccess({ websiteGateways: response.data }));
    } else {
      yield put(
        getWebsiteGatewaysFailure({
          websiteGateways: [],
          error: response.errorMessage,
        })
      );
    }
  } catch (error) {
    yield put(
      getWebsiteGatewaysFailure({ websiteGateways: [], error: error.message })
    );
  }
}
// --------------------------------------------------------------------------------
function* onGetPaymentLinkTransactions({ payload }) {
  yield put(getPaymentLinkTransactionsStarted());
  try {
    let response = yield getPaymentLinkTransactionsAPI(payload);
    if (response.status === "success") {
      yield put(
        getPaymentLinkTransactionsSuccess({
          paymentLinkTransactions: response.data,
        })
      );
    } else {
      yield put(
        getPaymentLinkTransactionsFailure({
          paymentLinkTransactions: [],
          error: response.errorMessage,
        })
      );
    }
  } catch (error) {
    yield put(
      getPaymentLinkTransactionsFailure({
        paymentLinkTransactions: [],
        error: error.message,
      })
    );
  }
}
// --------------------------------------------------------------------------------
function* onGetPersonalTransactions({ payload }) {
  yield put(getPersonalTransactionsStarted());
  try {
    let response = yield getPersonalTransactionsAPI(payload);
    if (response.status === "success") {
      yield put(
        getPersonalTransactionsSuccess({
          personalTransactions: response.data,
        })
      );
    } else {
      yield put(
        getPersonalTransactionsFailure({
          personalTransactions: [],
          error: response.errorMessage,
        })
      );
    }
  } catch (error) {
    yield put(
      getPersonalTransactionsFailure({
        personalTransactions: [],
        error: error.message,
      })
    );
  }
}
// --------------------------------------------------------------------------------
function* onGetAllExchanges({ payload }) {
  yield put(getAllExchangesStarted());
  try {
    let response = yield getAllExchangesAPI(payload);
    if (response.status === "success") {
      yield put(getAllExchangesSuccess({ exchanges: response.data }));
    } else {
      yield put(
        getAllExchangesFailure({ exchanges: [], error: response.errorMessage })
      );
    }
  } catch (error) {
    yield put(getAllExchangesFailure({ exchanges: [], error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onGetAllTransfers({ payload }) {
  yield put(getAllTransfersStarted());
  try {
    let response = yield getAllTransfersAPI(payload);
    if (response.status === "success") {
      yield put(getAllTransfersSuccess({ transfers: response.data }));
    } else {
      yield put(
        getAllTransfersFailure({ transfers: [], error: response.errorMessage })
      );
    }
  } catch (error) {
    yield put(getAllTransfersFailure({ transfers: [], error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onGetBanksReport({ payload }) {
  yield put(getBanksReportStarted());
  try {
    let response = yield getBanksReportAPI(payload);
    if (response.status === "success") {
      yield put(getBanksReportSuccess({ banksReport: response.data }));
    } else {
      yield put(
        getBanksReportFailure({
          banksReport: [],
          error: response.errorMessage,
        })
      );
    }
  } catch (error) {
    yield put(getBanksReportFailure({ banksReport: [], error: error.message }));
  }
}
// ================================================================================
function* financialSaga() {
  yield takeLatest(GET_ALL_TRANSACTIONS_REQUEST, onGetAllTransactions);
  yield takeLatest(GET_HOLD_TRANSACTIONS_REQUEST, onGetHoldTransactions);
  yield takeLatest(
    CHANGE_HOLD_TRANSACTION_STATUS_REQUEST,
    onChangeHoldTransactionStatus
  );
  yield takeLatest(GET_ALL_PAYOUTS_REQUEST, onGetAllPayouts);
  yield takeLatest(GET_PENDING_PAYOUTS_REQUEST, onGetPendingPayouts);
  yield takeLatest(
    GET_TRANSACTIONS_PAYOUT_PAY_REQUEST,
    onGetTransactionsPayoutPay
  );
  yield takeLatest(GET_WIRE_PAYOUTS_REQUEST, onGetWirePayouts);
  yield takeLatest(GET_ALL_TOPUPS_REQUEST, onGetAllTopups);
  yield takeLatest(GET_REFUND_REQUESTS_REQUEST, onGetRefundRequests);
  yield takeLatest(
    CHANGE_REFUND_REQUEST_STATUS_REQUEST,
    onChangeRefundRequestStatus
  );
  yield takeLatest(GET_VIP_PENDING_PAYOUTS_REQUEST, onGetVIPPendingPayouts);
  yield takeLatest(CONFIRM_VIP_PAYOUT_REQUEST, onConfirmVIPPayout);
  yield takeLatest(GET_WEBSITE_GATEWAYS_REQUEST, onGetWebsiteGateways);
  yield takeLatest(
    GET_PAYMENT_LINK_TRANSACTIONS_REQUEST,
    onGetPaymentLinkTransactions
  );
  yield takeLatest(
    GET_PERSONAL_TRANSACTIONS_REQUEST,
    onGetPersonalTransactions
  );
  yield takeLatest(GET_ALL_EXCHANGES_REQUEST, onGetAllExchanges);
  yield takeLatest(GET_ALL_TRANSFERS_REQUEST, onGetAllTransfers);
  yield takeLatest(GET_BANKS_REPORT_REQUEST, onGetBanksReport);
}

export default financialSaga;
