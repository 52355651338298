import React, { useEffect, useState } from "react";
import { Flags, Currencies, boot } from "constants/constants";
import {
  createDateLookup,
  getToggleStatus,
  renderAmount,
  thousands_separators,
  updateDateLookup,
} from "constants/usefulFunctions";
import MainTable from "components/MainTable";
import { useDispatch, useSelector } from "react-redux";
import {
  createUserWalletRequest,
  deleteUserWalletRequest,
  getUserWalletsRequest,
} from "redux/actions/UserProfile";
import {
  CREATE_USER_WALLET_STARTED,
  DELETE_USER_WALLET_STARTED,
  GET_USER_WALLETS_STARTED,
} from "redux/types";
// --------------------------------------------------------------------------------
const UserBalance = ({ user_id, basicInfo }) => {
  const dispatch = useDispatch();
  const { rType, userWallets } = useSelector(
    (state) => state.userProfileReducer
  );
  const [data, setData] = useState([]);
  const [lookup, setLookup] = useState(null);
  const loading =
    rType === GET_USER_WALLETS_STARTED ||
    rType === CREATE_USER_WALLET_STARTED ||
    rType === DELETE_USER_WALLET_STARTED;

  useEffect(() => {
    if (boot.currencies && boot.currencies.length > 1) {
      const obj2 = boot.currencies.reduce((acc, cur) => {
        acc[cur.id] = cur.code;
        return acc;
      }, {});

      setLookup(obj2);
    }
  }, [boot.currencies]);

  useEffect(() => {
    if (lookup) fetchData();
  }, [lookup]);

  const fetchData = async () => {
    dispatch(getUserWalletsRequest({ user_id }));
  };

  const columns = [
    {
      title: "Currency",
      field: "currency_id",
      render: (rowData) => (
        <>
          <div className="jr-profile-banner-top-left">
            <div className="jr-profile-banner-avatar">
              <i
                className={`flag flag-24 flag-${Flags[rowData.currency_id]}`}
              />
            </div>
            <div
              className="jr-profile-banner-avatar-info"
              style={{ marginTop: -4, marginLeft: -10 }}
            >
              {Currencies[rowData.currency_id]}
            </div>
          </div>
        </>
      ),
      lookup: lookup,
    },
    {
      title: "Status",
      field: "status",
      lookup: { Active: "Active", Inactive: "Inactive" },
      initialEditValue: "Active",
      render: (rowData) => getToggleStatus(rowData.status),
    },
    {
      title: "Balance",
      field: "balance",
      editable: "never",
      render: (rowData) => renderAmount(rowData, "balance"),
    },
    {
      title: "Hold",
      field: "pending_balance",
      editable: "never",
      render: (rowData) => renderAmount(rowData, "pending_balance"),
    },
    createDateLookup(),
    updateDateLookup(),
    {
      title: "Available",
      field: "balance",
      editable: "never",
      render: (rowData) =>
        rowData.currency_id === 3
          ? thousands_separators(
              Math.trunc(
                Math.round(
                  ((rowData.balance * 100) / (100 + basicInfo.commission)) * 100
                ) / 100
              )
            )
          : thousands_separators(
              Math.round(
                ((rowData.balance * 100) / (100 + basicInfo.commission)) * 100
              ) / 100
            ),
    },
  ];

  const options = {
    actionsColumnIndex: columns.length,
  };

  const addRow = async (newData) => {
    dispatch(
      createUserWalletRequest({
        user_id,
        currency_id: newData.currency_id,
      })
    );
  };

  const deleteRow = async (currency_id) => {
    dispatch(
      deleteUserWalletRequest({
        user_id,
        currency_id,
      })
    );
  };

  return (
    <div className="card">
      <MainTable
        loading={loading}
        customOptions={options}
        columns={columns}
        data={userWallets}
        title="Balance"
        onRowAdd={(newData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              addRow(newData);
              resolve();
            }, 600);
          })
        }
        onRowDelete={(oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              deleteRow(oldData.currency_id);
              resolve();
            }, 600);
          })
        }
      />
    </div>
  );
};

export default UserBalance;
