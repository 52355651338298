import axiosMiddleware from "..";
// --------------------------------------------------------------------------------
export const getUserProfileAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "user",
    body: data,
  });
export const editUserProfileAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "user/edit",
    body: data,
  });
export const getUserItsaazAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "itsaz/get-user",
    body: data,
  });
export const getUserUIdAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "uid/get-user",
    body: data,
  });
export const changeUserStatusAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "user/change-status",
    body: data,
  });
export const changeBusinessCategoryAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "user/set-business-category",
    body: data,
  });
export const getUserCommentsAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "user-comments/user",
    body: data,
  });
export const createUserCommentAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "user-comments/create",
    body: data,
  });
export const deleteUserCommentAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "user-comments/delete",
    body: data,
  });
export const getUserWalletsAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "wallet/user",
    body: data,
  });
export const createUserWalletAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "wallet/create",
    body: data,
  });
export const deleteUserWalletAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "wallet/delete",
    body: data,
  });
export const getUserFinanceAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "user-finance",
    body: data,
  });
export const getUserYoutubeChannelsAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "user-youtube-channels/all",
    body: data,
  });
export const editYoutubeChannelInfoAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "user-youtube-channels/edit-information",
    body: data,
  });
export const createYoutubeChannelContractAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "user-youtube-channels/create-contract",
    body: data,
  });
export const addYoutubeArchiveContractAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "user-youtube-channels/add-archive-contract",
    body: data,
  });
export const getYoutubeArchiveContractsAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "user-youtube-channels/get-archive-contract",
    body: data,
  });
export const deleteYoutubeArchiveContractAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "user-youtube-channels/remove-archive-contract",
    body: data,
  });
export const editYoutubeChannelContractAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "user-youtube-channels/edit-contract",
    body: data,
  });
export const getYoutubeChannelCommentsAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "youtube-channel/get-comment",
    body: data,
  });
export const createYoutubeChannelCommentAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "youtube-channel/add-comment",
    body: data,
  });
export const deleteYoutubeChannelCommentAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "youtube-channel/remove-comment",
    body: data,
  });
export const getUserCompanyAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "user-company/user",
    body: data,
  });
export const changeUserCompanyStatusAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "user-company/change-status",
    body: data,
  });
export const getUserAddressAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "user-address/user",
    body: data,
  });
export const changeUserAddressStatusAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "user-address/change-status",
    body: data,
  });
export const getUserPlansAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "user-plan/user",
    body: data,
  });
// export const createUserPlanAPI = (data) =>
//   axiosMiddleware({
//     method: "post",
//     path: "user-plan/create",
//     body: data,
//   });
export const getUserTransactionsAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "transaction/user",
    body: data,
  });
export const getUserFailedTransactionsAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "transaction/user/failed",
    body: data,
  });
export const getUserBankAccountsAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "bank-account/user",
    body: data,
  });
export const changeUserBankAccountStatusAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "bank-account/change-status",
    body: data,
  });
export const deleteUserBankAccountAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "bank-account/delete",
    body: data,
  });
export const restoreUserBankAccountAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "bank-account/restore",
    body: data,
  });
export const createUserBankAccountAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "bank-account/create",
    body: data,
  });
export const getUserGatewaysAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "gateway/user",
    body: data,
  });
export const changeUserGatewayStatusAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "gateway/change-status",
    body: data,
  });
export const getUserYekpayLinksAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "yekpay-link/user",
    body: data,
  });
export const getPersonalPaylinksAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "personal-link/user",
    body: data,
  });
export const changePersonalPaylinkStatusAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "personal-link/change-status",
    body: data,
  });
export const getProductPaylinksAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "product-link/user",
    body: data,
  });
export const changeProductPaylinkStatusAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "product-link/change-status",
    body: data,
  });
export const changeUserYekpayLinkStatusAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "yekpay-link/change-status",
    body: data,
  });
export const deleteUserYekpayLinkAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "yekpay-link/delete",
    body: data,
  });
export const getUserTicketsAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "ticket/user",
    body: data,
  });
export const getUserLogsAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "user-log/user",
    body: data,
  });
export const createPayoutAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "payout/create",
    body: data,
  });
export const getUserTextMessagesAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "text-message/user",
    body: data,
  });
export const getUserChangeLogsAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "user-change/user",
    body: data,
  });
export const topupUserAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "topup/create",
    body: data,
  });
export const getUserExchangeDetailAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "exchange/show",
    body: data,
  });
export const exchangeUserCurrencyAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "exchange/create",
    body: data,
  });
