import React from 'react';
import IntlMessages from 'util/IntlMessages';
import { NavLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import { Badge } from 'reactstrap';

export const NavHeader = ({ title = '' }) => (
  <li className="nav-header">
    <IntlMessages id={title} />
  </li>
);

export const NavSubMenu = ({ item }) => (
  <ul className="sub-menu">
    <li>
      <NavLink className="prepend-icon" to={item?.url} onClick={item?.onClick}>
        <span className="nav-text">
          <IntlMessages id={item.title} />
        </span>
        {item?.badge && (
          <Badge color="secondary" className="badge-pill ">
            {item.badge}
          </Badge>
        )}
      </NavLink>
    </li>
  </ul>
);

export const NavWithSubmenu = ({
  icon = '',
  title = '',
  subMenuList = [{ title: '', url: '', showMenu: true, badge: null }]
}) => (
  <li className="menu collapse-box">
    <Button>
      <i className={icon} />
      <span className="nav-text">
        <IntlMessages id={title} />
      </span>
    </Button>

    {subMenuList.map((item) => {
      if (item.showMenu === false) return;
      return <NavSubMenu key={item?.title} item={item} />;
    })}
  </li>
);

export const NavMenu = ({ url = '', title = '', icon = '', badge = '' }) => (
  <li className="menu no-arrow">
    <NavLink className="prepend-icon" to={url}>
      <i className={icon} />
      <span className="nav-text">
        <IntlMessages id={title} />
      </span>
      {badge && (
        <Badge color="secondary" className="badge-pill ">
          {badge}
        </Badge>
      )}
    </NavLink>
  </li>
);
