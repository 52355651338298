import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import { API_GET_LOGS, token } from "constants/constants";
import { createDateLookup } from "constants/usefulFunctions";
import { useDispatch, useSelector } from "react-redux";
import { getUserLogsRequest } from "redux/actions/UserProfile";
import { GET_USER_LOGS_STARTED } from "redux/types";

function Logs({ user_id }) {
  const dispatch = useDispatch();
  const { rType, userLogs } = useSelector((state) => state.userProfileReducer);
  const [state, setState] = useState({});
  const loading = rType === GET_USER_LOGS_STARTED;
  const options = {
    columnsButton: true,
    search: true,
    exportButton: true,
    grouping: false,
    filtering: false,
    exportAllData: true,
    selection: false,
    editable: false,
  };
  const columns = [
    { title: "Type", field: "type" },
    { title: "Operator", field: "operator", hidden: true },
    { title: "IP", field: "ip_address" },
    { title: "Device", field: "device" },
    { title: "Device Type", field: "device_type" },
    { title: "Platform", field: "platform" },
    {
      title: "Platform Version",
      field: "platform_version",
      hidden: true,
    },
    { title: "Browser", field: "browser" },
    {
      title: "Browser Version",
      field: "browser_version",
      hidden: true,
    },
    createDateLookup(),
  ];

  useEffect(() => {
    dispatch(getUserLogsRequest({ user_id }));
  }, []);
  return (
    <div className="card">
      <MaterialTable
        options={options}
        isLoading={loading}
        columns={columns}
        data={userLogs}
        title="Logs"
      />
    </div>
  );
}

export default Logs;
