import React from "react";
import { boot } from "constants/constants";
import {
  NavHeader,
  NavMenu,
  NavWithSubmenu,
} from "containers/SideNav/components";
import { useSelector } from "react-redux";

const Financial = () => {
  const { adminId } = useSelector((state) => state.authReducer);
  return (
    <>
      <NavHeader title="sidebar.financial" />
      {/* Transactions  */}
      <NavWithSubmenu
        title="sidebar.transactions"
        icon="zmdi zmdi-swap zmdi-hc-fw"
        subMenuList={[
          {
            title: "sidebar.transactions.holdTransactions",
            url: "/app/transactions/hold-transactions",
            badge: boot?.count_hold_transactions,
          },
          {
            title: "sidebar.transactions.payouts",
            url: "/app/transactions/payouts",
          },
          {
            title: "sidebar.transactions.pendingPayouts",
            url: "/app/transactions/pending-payouts",
            badge: boot?.count_pending_payouts,
          },
          {
            title: "sidebar.transactions.vipPendingPayouts",
            url: "/app/transactions/vip-pending-payouts",
          },
          {
            title: "sidebar.transactions.WirePayouts",
            url: "/app/transactions/WirePayouts",
          },
          {
            title: "sidebar.transactions.topup",
            url: "/app/transactions/topup",
            badge: boot?.count_pending_topups,
          },
          {
            title: "sidebar.transactions.refundRequests",
            url: "/app/transactions/refund-requests",
            badge: boot?.count_pending_refunds,
          },
          {
            title: "sidebar.transactions.allTransactions",
            url: "/app/transactions/all-transactions",
          },
          {
            title: "sidebar.transactions.websiteGateway",
            url: "/app/transactions/website-gateway",
          },
          {
            title: "sidebar.transactions.yekpayLink",
            url: "/app/transactions/yekpay-link",
          },
          {
            title: "sidebar.transactions.personalGateway",
            url: "/app/transactions/personal-gateway",
          },
          {
            title: "sidebar.transactions.exchange",
            url: "/app/transactions/exchange",
          },
          {
            title: "sidebar.transactions.transfer",
            url: "/app/transactions/transfer",
          },
        ]}
      />

      {/*transfer wise*/}
      <NavMenu
        title="sidebar.transactions.transferWise"
        icon="zmdi zmdi-flare zmdi-hc-fw"
        url="/app/transaction-wise"
      />

      {/* IPG Transactions */}
      <NavWithSubmenu
        title="sidebar.ipg.transactions"
        icon="zmdi zmdi-swap-alt zmdi-hc-fw"
        subMenuList={[
          {
            title: "sidebar.ipg.transactions.akbank",
            url: "/app/ipg-transactions/akbank",
          },
          {
            title: "sidebar.ipg.transactions.ziraat",
            url: "/app/ipg-transactions/ziraat",
          },
          {
            title: "sidebar.ipg.transactions.pec",
            url: "/app/ipg-transactions/parsian",
          },
          {
            title: "sidebar.ipg.transactions.vakif",
            url: "/app/ipg-transactions/vakif",
          },
          {
            title: "sidebar.ipg.transactions.iyzico",
            url: "/app/ipg-transactions/iyzico",
          },
          {
            title: "sidebar.ipg.transactions.esnek",
            url: "/app/ipg-transactions/esnek",
          },
          {
            title: "sidebar.ipg.transactions.paybyme",
            url: "/app/ipg-transactions/paybyme",
          },
          {
            title: "sidebar.ipg.transactions.sipay",
            url: "/app/ipg-transactions/sipay",
          },
          {
            title: "sidebar.ipg.transactions.wire",
            url: "/app/ipg-transactions/wire",
          },
          {
            title: "sidebar.ipg.transactions.moka",
            url: "/app/ipg-transactions/moka",
          },
          {
            title: "sidebar.ipg.transactions.irankish",
            url: "/app/ipg-transactions/irankish",
          },
          {
            title: "sidebar.ipg.transactions.stripe",
            url: "/app/ipg-transactions/stripe",
          },
        ]}
      />

      {/* Yekpay Wallet */}
      {(adminId === 1 ||
        adminId === 2 ||
        adminId === 3 ||
        adminId === 7 ||
        adminId === 36 ||
        adminId === 37 ||
        adminId === 42 ||
        adminId === 43 ||
        adminId === 48 ||
        adminId === 46 ||
        adminId === 59) && (
        <NavWithSubmenu
          title="sidebar.yekpay.wallet"
          icon="zmdi zmdi-balance-wallet zmdi-hc-fw"
          subMenuList={[
            {
              title: "sidebar.yekpay.wallet.transactionns",
              url: "/app/yekpay-wallet/transactions",
            },
            {
              title: "sidebar.yekpay.wallet.wallet",
              url: "/app/yekpay-wallet/wallet",
            },
            {
              title: "sidebar.yekpay.wallet.payout",
              url: "/app/yekpay-wallet/payout",
            },
          ]}
        />
      )}

      {/* payment facilitator */}

      <NavWithSubmenu
        title="sidebar.payment.facilitator"
        icon="zmdi zmdi-turning-sign zmdi-hc-fw"
        subMenuList={[
          {
            title: "sidebar.payment.facilitator.corporation.code",
            url: "/app/payment-facilitator/corporation-code",
          },
          {
            title: "sidebar.payment.facilitator.supplementary.code",
            url: "/app/payment-facilitator/supplementary-code",
          },
          {
            title: "sidebar.payment.facilitator.bank.identity",
            url: "/app/payment-facilitator/bank-identity",
          },
          {
            title: "sidebar.payment.facilitator.add.acceptor",
            url: "/app/payment-facilitator/acceptor-form/add-acceptor",
          },
          {
            title: "sidebar.payment.facilitator.acceptors.list",
            url: "/app/payment-facilitator/acceptors-list",
          },
          {
            title: "sidebar.payment.facilitator.transactions",
            url: "/app/payment-facilitator/payment-facilitator-transactions",
          },
          {
            title: "sidebar.daily.batches",
            url: "/app/payment-facilitator/daily-batches",
          },
          {
            title: "sidebar.payment.facilitator.ribopay.request",
            url: "/app/payment-facilitator/ribopay-request",
          },
        ]}
      />
      {(adminId === 1 || adminId === 31 || adminId === 46) && (
        <NavWithSubmenu
          title="sidebar.payment.gateway.invoices"
          icon="zmdi zmdi-receipt zmdi-hc-fw "
          subMenuList={[
            {
              title: "sidebar.payment.gateway.invoices.fonetra",
              url: "/app/invoices/stripe/fonetra",
            },
            {
              title: "sidebar.payment.gateway.invoices.alAmeeran",
              url: "/app/invoices/stripe/alameeran",
            },
          ]}
        />
      )}
    </>
  );
};

export default Financial;
