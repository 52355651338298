import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import React from 'react';

function IrankishResponse({
  inquiry_terminal,
  inquiry_terminalChange,
  inquiry_sha1,
  inquiry_sha1Change,
  inquiry_acceptor,
  inquiry_acceptorChange,
  inquiry_acceptor_code,
  inquiry_acceptor_codeChange,
  inquiry_passphrase,
  inquiry_passphraseChange,
  inquiry_public_key,
  inquiry_public_keyChange,
  response_psp_tracking_code,
  response_psp_tracking_codeChange,
  response_tracking_code,
  response_tracking_codeChange,
  inquiry_description,
  inquiry_descriptionChange,
  inquiry_status,
  inquiry_statusChange
}) {
  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '65%'
      }
    }
  }));
  const classes = useStyles();
  return (
    <div>
      <div className="row">
        <div className="jr-card p-0 col-xl-12 col-lg-12 col-md-12 col-12 ">
          <div className="jr-card-header card-img-top mb-0 p-4 bg-grey lighten-4">
            <h3 className="card-heading">Irankish Response</h3>
          </div>
          <div className="card-body text-center">
            <ul className="contact-list list-unstyled">
              <div className="row">
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="Terminal Number"
                    value={inquiry_terminal}
                    InputLabelProps={{ shrink: true }}
                    onChange={inquiry_terminalChange}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="SHA1"
                    value={inquiry_sha1}
                    InputLabelProps={{ shrink: true }}
                    onChange={inquiry_sha1Change}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="Acceptor"
                    value={inquiry_acceptor}
                    InputLabelProps={{ shrink: true }}
                    onChange={inquiry_acceptorChange}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="Acceptor Code"
                    value={inquiry_acceptor_code}
                    InputLabelProps={{ shrink: true }}
                    onChange={inquiry_acceptor_codeChange}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="Passphrase"
                    value={inquiry_passphrase}
                    InputLabelProps={{ shrink: true }}
                    onChange={inquiry_passphraseChange}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="Public Key"
                    value={inquiry_public_key}
                    InputLabelProps={{ shrink: true }}
                    onChange={inquiry_public_keyChange}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="Response PSP Tracking Code"
                    value={response_psp_tracking_code}
                    InputLabelProps={{ shrink: true }}
                    onChange={response_psp_tracking_codeChange}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="Irankish Tracking Code"
                    value={response_tracking_code}
                    InputLabelProps={{ shrink: true }}
                    onChange={response_tracking_codeChange}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="Inquiry Description"
                    value={inquiry_description}
                    InputLabelProps={{ shrink: true }}
                    onChange={inquiry_descriptionChange}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="Inquiry Status"
                    value={inquiry_status}
                    InputLabelProps={{ shrink: true }}
                    onChange={inquiry_statusChange}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IrankishResponse;
