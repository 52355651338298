import React from "react";
import { NavHeader, NavMenu } from "containers/SideNav/components";

const ExchangePanel = () => (
  <>
    <NavHeader title="sidebar.exPanel" />

    <NavMenu
      title="sidebar.users"
      icon="zmdi zmdi-accounts zmdi-hc-fw"
      url="/app/exchange-panel/all-users"
    />
  </>
);

export default ExchangePanel;
