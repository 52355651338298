import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const Support = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Route
        exact
        path={`${match.url}/sms/new`}
        component={asyncComponent(() => import("./routes/SMS/NewSms"))}
      />
      <Route
        exact
        path={`${match.url}/sms/new-group`}
        component={asyncComponent(() => import("./routes/SMS/NewGroupSms"))}
      />
      <Route
        exact
        path={`${match.url}/sms/group-sms-list`}
        component={asyncComponent(() => import("./routes/SMS/GroupSmsList"))}
      />
      <Route
        exact
        path={`${match.url}/sms/list`}
        component={asyncComponent(() => import("./routes/SMS/SmsList"))}
      />
      <Route
        exact
        path={`${match.url}/sms/group-sms-list/detail`}
        component={asyncComponent(() => import("./routes/SMS/GroupSmsDetail"))}
      />
      <Route
        exact
        path={`${match.url}/all-announcement`}
        component={asyncComponent(() =>
          import("./routes/Announcement/components/AllAnnouncement")
        )}
      />
      <Route
        exact
        path={`${match.url}/announcement-add`}
        component={asyncComponent(() =>
          import("./routes/Announcement/components/AnnouncementAdd")
        )}
      />
      <Route
        exact
        path={`${match.url}/announcement-edit`}
        component={asyncComponent(() =>
          import("./routes/Announcement/components/AnnouncementEdit")
        )}
      />
    </Switch>
  </div>
);

export default Support;
