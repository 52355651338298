import React from "react";
import MaterialTable from "material-table";
import { boot } from "constants/constants";
import {
  createDateLookup,
  thousands_separators,
  updateDateLookup,
} from "constants/usefulFunctions";

function YouTubeTransactions({ data }) {
  const options = {
    columnsButton: true,
    search: true,
    exportButton: true,
    grouping: false,
    filtering: true,
    exportAllData: true,
    selection: false,
    editable: false,
    pageSize: 5,
    exportFileName: "All Transactions",
  };
  return (
    <div className="mt-5">
      <MaterialTable
        options={options}
        columns={[
          {
            title: "Admin",
            field: "admin_id",
            render: (rowData) =>
              rowData?.admin_id
                ? boot.admins.find((o) => o.id === rowData.admin_id)
                    .first_name +
                  " " +
                  boot?.admins.find((o) => o.id === rowData.admin_id).last_name
                : "-",
          },
          {
            title: "From Date",
            field: "from_date",
          },
          {
            title: "To Date",
            field: "to_date",
          },
          {
            title: "Total Amount",
            field: "total_amount",
            render: (rowData) =>
              thousands_separators(
                parseFloat(rowData.total_amount).toFixed(5)
              ) + " TRY",
          },
          {
            title: "Total IRR Amount",
            field: "total_irr_amount",
            render: (rowData) =>
              thousands_separators(rowData.total_irr_amount) + " IRR",
          },

          {
            title: "User Amount",
            field: "user_amount",
            render: (rowData) =>
              thousands_separators(parseFloat(rowData.user_amount).toFixed(5)) +
              " TRY",
          },
          {
            title: "User IRR Amount",
            field: "user_irr_amount",
            render: (rowData) =>
              thousands_separators(rowData.user_irr_amount) + " IRR",
          },
          {
            title: "Yekpay Commission Amount",
            field: "yekpay_commission_amount",
            render: (rowData) =>
              thousands_separators(rowData.yekpay_commission_amount) + " TRY",
          },
          {
            title: "Yekpay Commission Percent",
            field: "yekpay_commission_percent",
            render: (rowData) => rowData.yekpay_commission_percent + " %",
          },

          {
            title: "Affiliate User ",
            field: "affiliate_user_id",
          },
          {
            title: "Affiliate Commission Amount",
            field: "affiliate_commission_amount",
            render: (rowData) =>
              thousands_separators(rowData.affiliate_commission_amount) +
              " TRY",
          },
          {
            title: "Affiliate Commission Percent",
            field: "affiliate_commission_percent",
            render: (rowData) => rowData.affiliate_commission_percent + " %",
          },
          {
            title: "Affiliate IRR Amount",
            field: "affiliate_irr_amount",
            render: (rowData) =>
              thousands_separators(rowData.affiliate_irr_amount) + " IRR",
          },

          {
            title: "Rate",
            field: "currency_rate_id",
          },

          createDateLookup(),
          updateDateLookup(),
        ]}
        data={data?.transactions}
        title={
          ("",
          (
            <div className="mt-3">
              <h2>Youtube Transactions</h2>
              <h4>Balance: {thousands_separators(data?.balance)} IRR</h4>
            </div>
          ))
        }
      />
    </div>
  );
}

export default YouTubeTransactions;
