import React from 'react';
import {  Route, Switch } from 'react-router-dom';
import GatewayCurrency from './routes/GatewayCurrency';

function GatewayReports({ match }) {

return (
  <div className="app-wrapper">
    <Switch>
      <Route path={`${match.url}/:currency_code`} component={() => <GatewayCurrency />} />
    </Switch>
  </div>
);
      }
export default GatewayReports;





