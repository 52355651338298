import React from "react";
import MaterialTable from "material-table";
import { API_TICKET_REPORT, boot } from "constants/constants";
import Listing from "./Listing";
import withTable from "HOC/withTable/withTable";
import {
  createDateLookup,
  getUser,
  getUserEmail,
  lookup,
  lookup2,
  lookup3,
  renderTicket,
  renderTicketWithParent,
  updateDateLookup,
} from "constants/usefulFunctions";

const TicketsReport = ({ data, loading, options }) => {
  const columns = [
    {
      title: "Admin",
      field: "fullname",
      render: (rowData) =>
        getUser({
          ...rowData,
          full_name: `${rowData.first_name}  ${rowData.last_name}`,
        }),
    },
    { title: "Email", field: "email" },
    { title: "Admin Hold Tickets", field: "admin_hold" },
    { title: "User Hold Tickets", field: "user_hold" },
    { title: "Waiting Tickets", field: "not_answered" },
    { title: "Open Tickets", field: "open" },
    {
      title: "Response AVG Time",
      field: "avg",
    },
    { title: "Reopen Tickets", field: "reopen" },
  ];

  const reopenColumns = [
    {
      title: "User",
      field: "fullname",
      editable: "never",
      render: (rowData) => getUser(rowData.user),
    },
    {
      title: "Email",
      field: "user.email",
      render: (rowData) => getUserEmail(rowData.user),
    },
    {
      title: "Subject",
      field: "subject",
      editable: "never",
      render: (rowData) => renderTicketWithParent(rowData),
    },
    {
      title: "Assignee",
      field: "assignee_id",
      render: (rowData) =>
        boot.admins && rowData.assignee_id
          ? boot.admins.find((o) => o.id === rowData.assignee_id).first_name +
            " " +
            boot.admins.find((o) => o.id === rowData.assignee_id).last_name
          : "-",
      lookup: lookup2(boot.admins),
    },
    {
      title: "Department",
      field: "department_id",
      render: (rowData) =>
        boot.departments && rowData.department_id
          ? boot.departments.find((o) => o.id === rowData.department_id).name_en
          : "-",
      lookup: lookup(boot.departments),
    },
    createDateLookup(),
    updateDateLookup(),
    { title: "Source", field: "source" },

    {
      title: "Category",
      field: "category.category_en",
    },
    {
      title: "Sub Category",
      field: "category.category_en",
      lookup: lookup3(boot.ticketCategories),
    },
  ];

  const delayColumns = [
    {
      title: "User",
      field: "fullname",
      editable: "never",
      render: (rowData) => getUser(rowData.user),
    },
    {
      title: "Email",
      field: "user.email",
      render: (rowData) => getUserEmail(rowData.user),
    },
    {
      title: "Subject",
      field: "subject",
      editable: "never",
      render: (rowData) => renderTicket(rowData),
    },
    {
      title: "Assignee",
      field: "assignee_id",
      render: (rowData) =>
        boot.admins && rowData.assignee_id
          ? boot.admins.find((o) => o.id === rowData.assignee_id).first_name +
            " " +
            boot.admins.find((o) => o.id === rowData.assignee_id).last_name
          : "-",
      lookup: lookup2(boot.admins),
    },
    {
      title: "Department",
      field: "department_id",
      render: (rowData) =>
        boot.departments && rowData.department_id
          ? boot.departments.find((o) => o.id === rowData.department_id).name_en
          : "-",
      lookup: lookup(boot.departments),
    },
    { title: "Average Response Time", field: "avg" },

    createDateLookup(),
    updateDateLookup(),
    { title: "Source", field: "source" },

    {
      title: "Category",
      field: "category.category_en",
    },
    {
      title: "Sub Category",
      field: "category.category_en",
      lookup: lookup3(boot.ticketCategories),
    },
  ];

  const lastColumns = [
    { title: "Day", field: "day" },
    { title: "Count", field: "count" },
  ];

  return (
    <>
      {data.overall && (
        <Listing key={data.overall.open_range} overall={data.overall} />
      )}
      <MaterialTable
        isLoading={loading}
        options={options}
        columns={columns}
        data={data.admins}
        title="Ticket Reports"
      />
      <div className="mt-5">
        <MaterialTable
          isLoading={loading}
          options={options}
          columns={lastColumns}
          data={data.last}
          title="Ticket Reports"
        />
      </div>
      <div className="mt-5">
        <MaterialTable
          isLoading={loading}
          options={options}
          columns={reopenColumns}
          data={data.reopen}
          title="Reopen Tickets"
        />
      </div>
      <div className="mt-5">
        <MaterialTable
          isLoading={loading}
          options={options}
          columns={delayColumns}
          data={data.delay}
          title="Delay Tickets"
        />
      </div>
    </>
  );
};

export default withTable(TicketsReport, {
  title: "Ticket Reports",
  apiAddress: API_TICKET_REPORT,
  fromDate: "month",
  pageSize: 7,
  enableWrapperClassname: true,
});
