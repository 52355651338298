import React from "react";
import ChartCard from "components/ChartCard/ChartCard";

const Listing = ({ overall }) => (
  <div className="dashboard animated slideInUpTiny animation-duration-3">
    <div className="row">
      <ChartCard
        chartProperties={{
          title: "All Tickets",
          prize: overall.all,
          icon: "stats",
          bgColor: "indigo",
          styleName: "up",
          desc: "In selected date",
          percent: overall.all_range,
        }}
      />

      <ChartCard
        chartProperties={{
          title: "Hold Tickets",
          prize: overall.user_hold,
          icon: "stats",
          bgColor: "pink accent-2",
          styleName: "up",
          desc: "In selected date",
          percent: overall.user_hold_range,
        }}
      />

      <ChartCard
        chartProperties={{
          title: "Open Tickets",
          prize: overall.open,
          icon: "stats",
          bgColor: "info",
          styleName: "down",
          desc: "In selected date",
          percent: overall.open_range,
        }}
      />

      <ChartCard
        chartProperties={{
          title: "Wating Tickets",
          prize: overall.not_answered,
          icon: "stats",
          bgColor: "success",
          desc: "In selected date",
          styleName: "up",
          percent: overall.not_answered_range,
        }}
      />
    </div>
  </div>
);

export default Listing;
