import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { API_GET_BOOT, token } from "constants/constants";
import CustomScrollbars from "util/CustomScrollbars";
import Main from "./List/Main";
import Financial from "./List/Financial";
import Settings from "./List/Settings";
import Reports from "./List/Reports";
import ExchangePanel from "./List/ExcahngePanel";
import PaymentGateway from "./List/PeymentGateways";
import Youtube from "./List/Youtube";
import Support from "./List/Support";
import Tools from "./List/Tools";
import { connect } from "react-redux";
import CRM from "./List/CRM/index";

class SidenavContent extends Component {
  constructor() {
    super();
    this.state = {
      openCount: 0,
    };
  }
  componentDidMount() {
    const { history } = this.props;
    const that = this;
    const pathname = `${history.location.pathname}`; // get current path

    const menuLi = document.getElementsByClassName("menu");
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function (event) {
        const parentLiEle = that.closest(this, "li");
        if (menuLi[i].classList.contains("menu") && parentLiEle !== null) {
          event.stopPropagation();

          if (menuLi[i].classList.contains("open")) {
            menuLi[i].classList.remove("open", "active");
          } else {
            menuLi[i].classList.add("open", "active");
          }
        } else {
          for (let j = 0; j < menuLi.length; j++) {
            const parentLi = that.closest(this, "li");
            if (
              menuLi[j] !== this &&
              (parentLi === null || !parentLi.classList.contains("open"))
            ) {
              menuLi[j].classList.remove("open");
            } else {
              if (menuLi[j].classList.contains("open")) {
                menuLi[j].classList.remove("open");
              } else {
                menuLi[j].classList.add("open");
              }
            }
          }
        }
      };
    }

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) {}

    function axiosTest() {
      return axios.post(API_GET_BOOT, {}, token()).then((response) => {
        return response.data.data;
      });
    }

    axiosTest().then((data) => {
      localStorage.setItem("boot", JSON.stringify(data));
    });
    const boot = JSON.parse(localStorage.getItem("boot"));
  }

  componentWillReceiveProps(nextProps) {
    const { history } = nextProps;
    const pathname = `${history.location.pathname}`; // get current path

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) {}
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      [
        "matches",
        "webkitMatchesSelector",
        "mozMatchesSelector",
        "msMatchesSelector",
        "oMatchesSelector",
      ].some(function (fn) {
        if (typeof document.body[fn] === "function") {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {}

    return null;
  }

  render() {
    return (
      <CustomScrollbars className=" scrollbar">
        <ul className="nav-menu">
          <Main />
          {this.props.auth.adminId !== 50 && (
            <>
              <CRM />
              <Youtube />
              <Support />
              <PaymentGateway />
              <Financial />
              <Settings />
              <Reports />
              <Tools />
              <ExchangePanel />
            </>
          )}
        </ul>
      </CustomScrollbars>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.authReducer,
  };
};
export default withRouter(connect(mapStateToProps)(SidenavContent));
