import {
  GET_CHART_ACTIONS_FAILURE,
  GET_CHART_ACTIONS_REQUEST,
  GET_CHART_ACTIONS_STARTED,
  GET_CHART_ACTIONS_SUCCESS,
  GET_CHART_FAILURE,
  GET_CHART_REQUEST,
  GET_CHART_STARTED,
  GET_CHART_SUCCESS,
} from "redux/types";
// --------------------------------------------------------------------------------
export const getChartActionsRequest = (data) => ({
  type: GET_CHART_ACTIONS_REQUEST,
  payload: data,
});

export const getChartActionsStarted = () => ({
  type: GET_CHART_ACTIONS_STARTED,
});

export const getChartActionsSuccess = (data) => ({
  type: GET_CHART_ACTIONS_SUCCESS,
  payload: data,
});

export const getChartActionsFailure = (data) => ({
  type: GET_CHART_ACTIONS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getChartRequest = (data) => ({
  type: GET_CHART_REQUEST,
  payload: data,
});

export const getChartStarted = () => ({
  type: GET_CHART_STARTED,
});

export const getChartSuccess = (data) => ({
  type: GET_CHART_SUCCESS,
  payload: data,
});

export const getChartFailure = (data) => ({
  type: GET_CHART_FAILURE,
  payload: data,
});
