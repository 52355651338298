import React from "react";
import { API_GET_ALL_PENDING_GATEWAY_DOCUMENT } from "constants/constants";
import {
  createDateLookup,
  getUser,
  renderStatus,
} from "constants/usefulFunctions";
import withTable from "HOC/withTable/withTable";
import MainTable from "components/MainTable";

const PendingGatewayDocs = ({ loading, data }) => {
  const columns = [
    {
      title: "User",
      field: "fullname",
      render: (user) => getUser(user.gateway.user),
    },
    { title: "Email", field: "gateway.user.email" },
    {
      title: "Gateway Document",
      field: "gateway.website_name",
      render: (rowData) => (
        <>
          <div className="jr-profile-banner-top-left">
            <div className="jr-profile-banner-avatar-info">
              <a
                href={`/app/gateway/${rowData.gateway_id}`}
                className="mb-0 jr-fs-lg"
              >
                {rowData.gateway.website_name}
              </a>
            </div>
          </div>
        </>
      ),
    },
    createDateLookup(),
    {
      title: "Status",
      field: "status",
      render: (rowData) => renderStatus(rowData.status),
    },
  ];

  return (
    <MainTable
      loading={loading}
      columns={columns}
      data={data}
      title="Pending Gateway Documents"
    />
  );
};

export default withTable(PendingGatewayDocs, {
  title: "Pending Company Info",
  apiAddress: API_GET_ALL_PENDING_GATEWAY_DOCUMENT,
  hasDatePicker: false,
});
