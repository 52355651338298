import React, { useCallback, useEffect, useState } from "react";
import StatusLoading from "../StatusLoading";
import AppTextInput from "components/AppTextInput";
import Divider from "@mui/material/Divider";
import { formatLables } from "constants/usefulFunctions";
import MainModal from "components/MainModal";
import AppButton from "components/AppButton";
import {
  getUserItsaazRequest,
  getUserUIdRequest,
} from "redux/actions/UserProfile";
import { useDispatch, useSelector } from "react-redux";
import { GET_USER_ITSAAZ_STARTED } from "redux/types";

const UId = ({ user_id }) => {
  const dispatch = useDispatch();
  const { rType, uIdData } = useSelector((state) => state.userProfileReducer);
  const [modalProps, setModalProps] = useState({
    isOpen: false,
    title: null,
    image: null,
  });
  const loading = rType === GET_USER_ITSAAZ_STARTED;

  const closeModal = () => setModalProps({ isOpen: false });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    dispatch(getUserUIdRequest({ user_id }));
  };

  const visibleFields = useCallback((key) => {
    let visible = false;
    switch (key) {
      case "user_id":
      case "name":
      case "family":
      //   case "itsaz_redirect_url":
      //   case "redirect_url":
      case "national_code":
      //   case "verify_time":
      //   case "status":
      //   case "order_id":
      //   case "national_card_expiration_date":
      //   case "mobile":
      //   case "is_dead":
      //   case "identity_id":
      //   case "form_detail_zip_code":
      //   case "form_detail_iban":
      //   case "form_detail_address":
      //   case "father_name":
      //   case "callback_time":
      case "birth_date":
      case "mobile_number":
      case "verify_time":
      //   case "address_inquiry_zip_code":
      //   case "address_inquiry_house_number":
      //   case "address_inquiry_address":
      //   case "account_inquiry_iBan":
      //   case "account_inquiry_bank_name":
      //   case "account_inquiry_bank_code":
      //   case "account_inquiry_account_owners_last_name":
      //   case "account_inquiry_account_owners_first_name":
      case "bank_name":
      case "card_number":
      case "account_number":
      case "shaba_number":
      case "state":
      case "city":
      case "user_address":
      case "postal_code":
        visible = true;
        break;

      default:
        visible = false;
        break;
    }
    return visible;
  }, []);

  const renderRow = useCallback(
    (row) => {
      return (
        <div key={row.id}>
          <div className="col-12 mb-3 px-0 mx-0 d-flex  ">
            <div className="col-8 px-0">
              {Object.keys(row).map((key) => {
                if (
                  !key.includes("photo") &&
                  !key.includes("image") &&
                  visibleFields(key)
                )
                  return (
                    <AppTextInput
                      label={formatLables(key)}
                      value={row?.[key]}
                      key={Math.random()}
                      readOnly
                      dir="ltr"
                      margin="dense"
                    />
                  );
                return null;
              })}
            </div>
            <div className="col-4 d-flex flex-column align-items-center">
              {Object.keys(row).map((key) => {
                if (key.includes("photo") || key.includes("image"))
                  if (row[key])
                    return (
                      <div
                        style={{ float: "right", margin: "10px" }}
                        key={Math.random()}
                      >
                        {row[key] && (
                          <div className="d-flex flex-column align-items-center">
                            <img
                              width="240px"
                              src={`data:image/png;base64,${row[key]}`}
                              onClick={() =>
                                setModalProps({
                                  isOpen: true,
                                  title: key,
                                  image: row[key],
                                })
                              }
                            />
                          </div>
                        )}
                      </div>
                    );
                return null;
              })}
            </div>
          </div>
          <Divider className="my-3 d-block" />
        </div>
      );
    },
    [uIdData]
  );

  const renderFields = () => {
    return uIdData.map((row) => renderRow(row));
  };

  const imageModal = useCallback(
    () => (
      <MainModal
        mainContent={
          <div className="d-flex flex-column align-items-center">
            <img
              width="90%"
              src={`data:image/png;base64,${modalProps.image}`}
            />
          </div>
        }
        title={formatLables(modalProps.title)}
        handleToggle={closeModal}
        isOpen={true}
        hasRotation
        footer={<AppButton color="primary" text="Close" onClick={closeModal} />}
      />
    ),
    [modalProps]
  );

  if (loading)
    return (
      <div className="my-5">
        <StatusLoading />;
      </div>
    );

  return (
    <div className="jr-card jr-full-card py-3 px-2">
      {renderFields()}
      {modalProps.isOpen && imageModal()}
    </div>
  );
};

export default UId;
