import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import { API_GET_CITY_ONE, API_GET_PROVINCE_ALL, API_GET_AREA, token } from 'constants/constants';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

function AcceptorBilling({
  email_yekpay,
  email_yekpayChange,
  acceptor_merchant_name,
  acceptor_merchant_nameChange,
  acceptor_zip_code,
  acceptor_zip_codeChange,
  acceptor_phone,
  acceptor_phoneChange,
  acceptor_mobile,
  acceptor_mobileChange,
  acceptor_email,
  acceptor_emailChange,
  acceptor_latin_merchant_name,
  acceptor_latin_merchant_nameChange,
  acceptor_province_id,
  acceptor_province_idChange,
  acceptor_city_id,
  acceptor_city_idChange,
  acceptor_area_id,
  acceptor_area_idChange,
  acceptor_ownership_type,
  acceptor_ownership_typeChange,
  acceptor_address,
  acceptor_addressChange
}) {
  const [loading, setLoading] = useState(false);
  const [province, setProvince] = useState([]);
  const [city, setCity] = useState([]);
  const [area, setArea] = useState([]);

  useEffect(() => {
    setLoading(true);

    async function fetchData() {
      await axios.post(API_GET_PROVINCE_ALL, {}, token()).then((res) => {
        const data = res.data.data;
        setProvince(data);
      });
      setLoading(false);
    }

    fetchData();
  }, []);

  useEffect(() => {
    setLoading(true);

    async function fetchData() {
      await axios
        .post(API_GET_CITY_ONE, { province_id: acceptor_province_id }, token())
        .then((res) => {
          const data = res.data.data;
          setCity(data);
        });
      setLoading(false);
    }

    fetchData();
  }, [acceptor_province_id]);

  useEffect(() => {
    setLoading(true);

    async function fetchData() {
      await axios.post(API_GET_AREA, {}, token()).then((res) => {
        const data = res.data.data;
        setArea(data);
      });
      setLoading(false);
    }

    fetchData();
  }, []);

  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '65%'
      }
    }
  }));
  const classes = useStyles();
  return (
    <div>
      <div className="row">
        <div className="jr-card p-0 col-xl-12 col-lg-12 col-md-12 col-12 ">
          <div className="jr-card-header card-img-top mb-0 p-4 bg-grey lighten-4">
            <h3 className="card-heading">Billing Acceptor</h3>
          </div>
          <div className="card-body text-center">
            <ul className="contact-list list-unstyled">
              <div className="row">
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="Email Yekpay"
                    value={email_yekpay}
                    InputLabelProps={{ shrink: true }}
                    onChange={email_yekpayChange}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="Acceptor Name"
                    value={acceptor_merchant_name}
                    InputLabelProps={{ shrink: true }}
                    onChange={acceptor_merchant_nameChange}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="Acceptor Latin Name"
                    value={acceptor_latin_merchant_name}
                    InputLabelProps={{ shrink: true }}
                    onChange={acceptor_latin_merchant_nameChange}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="Acceptor Zip Code"
                    value={acceptor_zip_code}
                    InputLabelProps={{ shrink: true }}
                    onChange={acceptor_zip_codeChange}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="Acceptor Phone"
                    value={acceptor_phone}
                    InputLabelProps={{ shrink: true }}
                    onChange={acceptor_phoneChange}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="Acceptor Mobile"
                    value={acceptor_mobile}
                    InputLabelProps={{ shrink: true }}
                    onChange={acceptor_mobileChange}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="Acceptor Email"
                    value={acceptor_email}
                    InputLabelProps={{ shrink: true }}
                    onChange={acceptor_emailChange}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    select
                    fullWidth
                    label="Acceptor Province ID"
                    value={acceptor_province_id}
                    InputLabelProps={{ shrink: true }}
                    onChange={acceptor_province_idChange}
                    defaultValue="تهران"
                    margin="normal"
                    variant="outlined">
                    {province &&
                      province.map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.province_id}>
                            {item.title}
                          </MenuItem>
                        );
                      })}
                  </TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    select
                    fullWidth
                    label="Acceptor City ID"
                    value={acceptor_city_id}
                    InputLabelProps={{ shrink: true }}
                    onChange={acceptor_city_idChange}
                    margin="normal"
                    variant="outlined">
                    {city &&
                      city.map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.city_id}>
                            {item.title}
                          </MenuItem>
                        );
                      })}
                  </TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    select
                    fullWidth
                    label="Acceptor Area ID"
                    value={acceptor_area_id}
                    InputLabelProps={{ shrink: true }}
                    onChange={acceptor_area_idChange}
                    margin="normal"
                    variant="outlined">
                    {area &&
                      area.map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.area_id}>
                            {item.code}
                          </MenuItem>
                        );
                      })}
                  </TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    select
                    fullWidth
                    label="Acceptor Ownership Type"
                    value={acceptor_ownership_type}
                    InputLabelProps={{ shrink: true }}
                    onChange={acceptor_ownership_typeChange}
                    margin="normal"
                    variant="outlined">
                    <MenuItem dense={true} key="Owner" value="Owner">
                      {' '}
                      <span>مالک</span>{' '}
                    </MenuItem>
                    <MenuItem key="Tenant" value="Tenant">
                      {' '}
                      <span>مستاجر</span>{' '}
                    </MenuItem>
                  </TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="Acceptor Address"
                    value={acceptor_address}
                    InputLabelProps={{ shrink: true }}
                    onChange={acceptor_addressChange}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AcceptorBilling;
