import {
  CHECK_YOUTUBE_CHANNELS_FAILURE,
  CHECK_YOUTUBE_CHANNELS_REQUEST,
  CHECK_YOUTUBE_CHANNELS_STARTED,
  CHECK_YOUTUBE_CHANNELS_SUCCESS,
  CREATE_YOUTUBE_CHANNEL_FAILURE,
  CREATE_YOUTUBE_CHANNEL_REQUEST,
  CREATE_YOUTUBE_CHANNEL_STARTED,
  CREATE_YOUTUBE_CHANNEL_SUCCESS,
  GET_ALL_YOUTUBE_CHANNELS_FAILURE,
  GET_ALL_YOUTUBE_CHANNELS_REQUEST,
  GET_ALL_YOUTUBE_CHANNELS_STARTED,
  GET_ALL_YOUTUBE_CHANNELS_SUCCESS,
  GET_ARZISHO_CONSULTANT_FORM_FAILURE,
  GET_ARZISHO_CONSULTANT_FORM_REQUEST,
  GET_ARZISHO_CONSULTANT_FORM_STARTED,
  GET_ARZISHO_CONSULTANT_FORM_SUCCESS,
  GET_YEKTUBER_SIGNUP_FAILURE,
  GET_YEKTUBER_SIGNUP_REQUEST,
  GET_YEKTUBER_SIGNUP_STARTED,
  GET_YEKTUBER_SIGNUP_SUCCESS,
  GET_YOUTUBE_ARZISHO_FORM_FAILURE,
  GET_YOUTUBE_ARZISHO_FORM_REQUEST,
  GET_YOUTUBE_ARZISHO_FORM_STARTED,
  GET_YOUTUBE_ARZISHO_FORM_SUCCESS,
  GET_YOUTUBE_CHECKING_LIST_FAILURE,
  GET_YOUTUBE_CHECKING_LIST_REQUEST,
  GET_YOUTUBE_CHECKING_LIST_STARTED,
  GET_YOUTUBE_CHECKING_LIST_SUCCESS,
  GET_YOUTUBE_CONTACT_LIST_FAILURE,
  GET_YOUTUBE_CONTACT_LIST_REQUEST,
  GET_YOUTUBE_CONTACT_LIST_STARTED,
  GET_YOUTUBE_CONTACT_LIST_SUCCESS,
  GET_YOUTUBE_COUNSELING_LIST_FAILURE,
  GET_YOUTUBE_COUNSELING_LIST_REQUEST,
  GET_YOUTUBE_COUNSELING_LIST_STARTED,
  GET_YOUTUBE_COUNSELING_LIST_SUCCESS,
  GET_YOUTUBE_PARTNER_LIST_FAILURE,
  GET_YOUTUBE_PARTNER_LIST_REQUEST,
  GET_YOUTUBE_PARTNER_LIST_STARTED,
  GET_YOUTUBE_PARTNER_LIST_SUCCESS,
  GET_YOUTUBE_PAYOUTS_FAILURE,
  GET_YOUTUBE_PAYOUTS_REQUEST,
  GET_YOUTUBE_PAYOUTS_STARTED,
  GET_YOUTUBE_PAYOUTS_SUCCESS,
} from "redux/types";
// --------------------------------------------------------------------------------
export const getAllYoutubeChannelsRequest = (data) => ({
  type: GET_ALL_YOUTUBE_CHANNELS_REQUEST,
  payload: data,
});

export const getAllYoutubeChannelsStarted = () => ({
  type: GET_ALL_YOUTUBE_CHANNELS_STARTED,
});

export const getAllYoutubeChannelsSuccess = (data) => ({
  type: GET_ALL_YOUTUBE_CHANNELS_SUCCESS,
  payload: data,
});

export const getAllYoutubeChannelsFailure = (data) => ({
  type: GET_ALL_YOUTUBE_CHANNELS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const createYoutubeChannelRequest = (data) => ({
  type: CREATE_YOUTUBE_CHANNEL_REQUEST,
  payload: data,
});

export const createYoutubeChannelStarted = () => ({
  type: CREATE_YOUTUBE_CHANNEL_STARTED,
});

export const createYoutubeChannelSuccess = () => ({
  type: CREATE_YOUTUBE_CHANNEL_SUCCESS,
});

export const createYoutubeChannelFailure = (data) => ({
  type: CREATE_YOUTUBE_CHANNEL_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getArzishoConsultantFormRequest = (data) => ({
  type: GET_ARZISHO_CONSULTANT_FORM_REQUEST,
  payload: data,
});

export const getArzishoConsultantFormStarted = () => ({
  type: GET_ARZISHO_CONSULTANT_FORM_STARTED,
});

export const getArzishoConsultantFormSuccess = (data) => ({
  type: GET_ARZISHO_CONSULTANT_FORM_SUCCESS,
  payload: data,
});

export const getArzishoConsultantFormFailure = (data) => ({
  type: GET_ARZISHO_CONSULTANT_FORM_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getYoutubeArzishoFormRequest = (data) => ({
  type: GET_YOUTUBE_ARZISHO_FORM_REQUEST,
  payload: data,
});

export const getYoutubeArzishoFormStarted = () => ({
  type: GET_YOUTUBE_ARZISHO_FORM_STARTED,
});

export const getYoutubeArzishoFormSuccess = (data) => ({
  type: GET_YOUTUBE_ARZISHO_FORM_SUCCESS,
  payload: data,
});

export const getYoutubeArzishoFormFailure = (data) => ({
  type: GET_YOUTUBE_ARZISHO_FORM_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getYoutubePayoutsRequest = () => ({
  type: GET_YOUTUBE_PAYOUTS_REQUEST,
});

export const getYoutubePayoutsStarted = () => ({
  type: GET_YOUTUBE_PAYOUTS_STARTED,
});

export const getYoutubePayoutsSuccess = (data) => ({
  type: GET_YOUTUBE_PAYOUTS_SUCCESS,
  payload: data,
});

export const getYoutubePayoutsFailure = (data) => ({
  type: GET_YOUTUBE_PAYOUTS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getYektuberSignupRequest = () => ({
  type: GET_YEKTUBER_SIGNUP_REQUEST,
});

export const getYektuberSignupStarted = () => ({
  type: GET_YEKTUBER_SIGNUP_STARTED,
});

export const getYektuberSignupSuccess = (data) => ({
  type: GET_YEKTUBER_SIGNUP_SUCCESS,
  payload: data,
});

export const getYektuberSignupFailure = (data) => ({
  type: GET_YEKTUBER_SIGNUP_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getYoutubeCounselingListRequest = () => ({
  type: GET_YOUTUBE_COUNSELING_LIST_REQUEST,
});

export const getYoutubeCounselingListStarted = () => ({
  type: GET_YOUTUBE_COUNSELING_LIST_STARTED,
});

export const getYoutubeCounselingListSuccess = (data) => ({
  type: GET_YOUTUBE_COUNSELING_LIST_SUCCESS,
  payload: data,
});

export const getYoutubeCounselingListFailure = (data) => ({
  type: GET_YOUTUBE_COUNSELING_LIST_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getYoutubeCheckingListRequest = () => ({
  type: GET_YOUTUBE_CHECKING_LIST_REQUEST,
});

export const getYoutubeCheckingListStarted = () => ({
  type: GET_YOUTUBE_CHECKING_LIST_STARTED,
});

export const getYoutubeCheckingListSuccess = (data) => ({
  type: GET_YOUTUBE_CHECKING_LIST_SUCCESS,
  payload: data,
});

export const getYoutubeCheckingListFailure = (data) => ({
  type: GET_YOUTUBE_CHECKING_LIST_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getYoutubeContactListRequest = () => ({
  type: GET_YOUTUBE_CONTACT_LIST_REQUEST,
});

export const getYoutubeContactListStarted = () => ({
  type: GET_YOUTUBE_CONTACT_LIST_STARTED,
});

export const getYoutubeContactListSuccess = (data) => ({
  type: GET_YOUTUBE_CONTACT_LIST_SUCCESS,
  payload: data,
});

export const getYoutubeContactListFailure = (data) => ({
  type: GET_YOUTUBE_CONTACT_LIST_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getYoutubePartnerListRequest = () => ({
  type: GET_YOUTUBE_PARTNER_LIST_REQUEST,
});

export const getYoutubePartnerListStarted = () => ({
  type: GET_YOUTUBE_PARTNER_LIST_STARTED,
});

export const getYoutubePartnerListSuccess = (data) => ({
  type: GET_YOUTUBE_PARTNER_LIST_SUCCESS,
  payload: data,
});

export const getYoutubePartnerListFailure = (data) => ({
  type: GET_YOUTUBE_PARTNER_LIST_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const checkYoutubeChannelRequest = (data) => ({
  type: CHECK_YOUTUBE_CHANNELS_REQUEST,
  payload: data,
});

export const checkYoutubeChannelStarted = () => ({
  type: CHECK_YOUTUBE_CHANNELS_STARTED,
});

export const checkYoutubeChannelSuccess = (data) => ({
  type: CHECK_YOUTUBE_CHANNELS_SUCCESS,
  payload: data,
});

export const checkYoutubeChannelFailure = (data) => ({
  type: CHECK_YOUTUBE_CHANNELS_FAILURE,
  payload: data,
});
