import React from "react";
import PendingCompanyInfo from "./PendingCompanyInfo";
import PendingGatewayDocs from "./PendingGatewayDocs";

const PendingGateways = () => (
  <div className="row">
    <div className="col-lg-6">
      <PendingGatewayDocs />
    </div>
    <div className="col-lg-6">
      <PendingCompanyInfo />
    </div>
  </div>
);

export default PendingGateways;
