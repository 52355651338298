import {
  ADD_TICKET_COMMENT_FAILURE,
  ADD_TICKET_COMMENT_REQUEST,
  ADD_TICKET_COMMENT_STARTED,
  ADD_TICKET_COMMENT_SUCCESS,
  CHANGE_TICKET_ROLE_FAILURE,
  CHANGE_TICKET_ROLE_REQUEST,
  CHANGE_TICKET_ROLE_STARTED,
  CHANGE_TICKET_ROLE_SUCCESS,
  CHANGE_TICKET_STATUS_FAILURE,
  CHANGE_TICKET_STATUS_REQUEST,
  CHANGE_TICKET_STATUS_STARTED,
  CHANGE_TICKET_STATUS_SUCCESS,
  CREATE_NEW_TICKET_FAILURE,
  CREATE_NEW_TICKET_REQUEST,
  CREATE_NEW_TICKET_STARTED,
  CREATE_NEW_TICKET_SUCCESS,
  CREATE_REPLY_TICKET_FAILURE,
  CREATE_REPLY_TICKET_REQUEST,
  CREATE_REPLY_TICKET_STARTED,
  CREATE_REPLY_TICKET_SUCCESS,
  DELETE_TICKET_FAILURE,
  DELETE_TICKET_REQUEST,
  DELETE_TICKET_STARTED,
  DELETE_TICKET_SUCCESS,
  GET_ALL_PENDING_BANK_ACCOUNT_FAILURE,
  GET_ALL_PENDING_BANK_ACCOUNT_REQUEST,
  GET_ALL_PENDING_BANK_ACCOUNT_STARTED,
  GET_ALL_PENDING_BANK_ACCOUNT_SUCCESS,
  GET_ALL_PENDING_BILLING_ADDRESS_FAILURE,
  GET_ALL_PENDING_BILLING_ADDRESS_REQUEST,
  GET_ALL_PENDING_BILLING_ADDRESS_STARTED,
  GET_ALL_PENDING_BILLING_ADDRESS_SUCCESS,
  GET_ALL_PENDING_IDENTITY_FAILURE,
  GET_ALL_PENDING_IDENTITY_REQUEST,
  GET_ALL_PENDING_IDENTITY_STARTED,
  GET_ALL_PENDING_IDENTITY_SUCCESS,
  GET_ALL_TICKETS_FAILURE,
  GET_ALL_TICKETS_REQUEST,
  GET_ALL_TICKETS_STARTED,
  GET_ALL_TICKETS_SUCCESS,
  GET_ALL_USERS_FAILURE,
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_STARTED,
  GET_ALL_USERS_SUCCESS,
  GET_AYANDE_BALANCE_FAILURE,
  GET_AYANDE_BALANCE_REQUEST,
  GET_AYANDE_BALANCE_STARTED,
  GET_AYANDE_BALANCE_SUCCESS,
  GET_BLOCKED_USERS_FAILURE,
  GET_BLOCKED_USERS_REQUEST,
  GET_BLOCKED_USERS_STARTED,
  GET_BLOCKED_USERS_SUCCESS,
  GET_BUSINESS_USERS_FAILURE,
  GET_BUSINESS_USERS_REQUEST,
  GET_BUSINESS_USERS_STARTED,
  GET_BUSINESS_USERS_SUCCESS,
  GET_DASHBOARD_INFO_FAILURE,
  GET_DASHBOARD_INFO_REQUEST,
  GET_DASHBOARD_INFO_STARTED,
  GET_DASHBOARD_INFO_SUCCESS,
  GET_ELASTIC_BALANCE_FAILURE,
  GET_ELASTIC_BALANCE_REQUEST,
  GET_ELASTIC_BALANCE_STARTED,
  GET_ELASTIC_BALANCE_SUCCESS,
  GET_FRAUD_SUSPECT_USERS_FAILURE,
  GET_FRAUD_SUSPECT_USERS_REQUEST,
  GET_FRAUD_SUSPECT_USERS_STARTED,
  GET_FRAUD_SUSPECT_USERS_SUCCESS,
  GET_KAVENEGAR_BALANCE_FAILURE,
  GET_KAVENEGAR_BALANCE_REQUEST,
  GET_KAVENEGAR_BALANCE_STARTED,
  GET_KAVENEGAR_BALANCE_SUCCESS,
  GET_OPEN_TICKETS_FAILURE,
  GET_OPEN_TICKETS_REQUEST,
  GET_OPEN_TICKETS_STARTED,
  GET_OPEN_TICKETS_SUCCESS,
  GET_PARSIAN_BALANCE_FAILURE,
  GET_PARSIAN_BALANCE_REQUEST,
  GET_PARSIAN_BALANCE_STARTED,
  GET_PARSIAN_BALANCE_SUCCESS,
  GET_PASARGAD_BALANCE_FAILURE,
  GET_PASARGAD_BALANCE_REQUEST,
  GET_PASARGAD_BALANCE_STARTED,
  GET_PASARGAD_BALANCE_SUCCESS,
  GET_STRIPE_INVOICE_FAILURE,
  GET_STRIPE_INVOICE_REQUEST,
  GET_STRIPE_INVOICE_STARTED,
  GET_STRIPE_INVOICE_SUCCESS,
  GET_TICKET_DETAILS_FAILURE,
  GET_TICKET_DETAILS_REQUEST,
  GET_TICKET_DETAILS_STARTED,
  GET_TICKET_DETAILS_SUCCESS,
} from "redux/types";
// --------------------------------------------------------------------------------
export const getDashboardInfoRequest = () => ({
  type: GET_DASHBOARD_INFO_REQUEST,
});

export const getDashboardInfoStarted = () => ({
  type: GET_DASHBOARD_INFO_STARTED,
});

export const getDashboardInfoSuccess = (data) => ({
  type: GET_DASHBOARD_INFO_SUCCESS,
  payload: data,
});

export const getDashboardInfoFailure = (data) => ({
  type: GET_DASHBOARD_INFO_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getParsianBalanceRequest = () => ({
  type: GET_PARSIAN_BALANCE_REQUEST,
});

export const getParsianBalanceStarted = () => ({
  type: GET_PARSIAN_BALANCE_STARTED,
});

export const getParsianBalanceSuccess = (data) => ({
  type: GET_PARSIAN_BALANCE_SUCCESS,
  payload: data,
});

export const getParsianBalanceFailure = (data) => ({
  type: GET_PARSIAN_BALANCE_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getPasargadBalanceRequest = () => ({
  type: GET_PASARGAD_BALANCE_REQUEST,
});

export const getPasargadBalanceStarted = () => ({
  type: GET_PASARGAD_BALANCE_STARTED,
});

export const getPasargadBalanceSuccess = (data) => ({
  type: GET_PASARGAD_BALANCE_SUCCESS,
  payload: data,
});

export const getPasargadBalanceFailure = (data) => ({
  type: GET_PASARGAD_BALANCE_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getAyandeBalanceRequest = () => ({
  type: GET_AYANDE_BALANCE_REQUEST,
});

export const getAyandeBalanceStarted = () => ({
  type: GET_AYANDE_BALANCE_STARTED,
});

export const getAyandeBalanceSuccess = (data) => ({
  type: GET_AYANDE_BALANCE_SUCCESS,
  payload: data,
});

export const getAyandeBalanceFailure = (data) => ({
  type: GET_AYANDE_BALANCE_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getKavenegarBalanceRequest = () => ({
  type: GET_KAVENEGAR_BALANCE_REQUEST,
});

export const getKavenegarBalanceStarted = () => ({
  type: GET_KAVENEGAR_BALANCE_STARTED,
});

export const getKavenegarBalanceSuccess = (data) => ({
  type: GET_KAVENEGAR_BALANCE_SUCCESS,
  payload: data,
});

export const getKavenegarBalanceFailure = (data) => ({
  type: GET_KAVENEGAR_BALANCE_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getElasticBalanceRequest = () => ({
  type: GET_ELASTIC_BALANCE_REQUEST,
});

export const getElasticBalanceStarted = () => ({
  type: GET_ELASTIC_BALANCE_STARTED,
});

export const getElasticBalanceSuccess = (data) => ({
  type: GET_ELASTIC_BALANCE_SUCCESS,
  payload: data,
});

export const getElasticBalanceFailure = (data) => ({
  type: GET_ELASTIC_BALANCE_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getAllPendingIdentityRequest = () => ({
  type: GET_ALL_PENDING_IDENTITY_REQUEST,
});

export const getAllPendingIdentityStarted = () => ({
  type: GET_ALL_PENDING_IDENTITY_STARTED,
});

export const getAllPendingIdentitySuccess = (data) => ({
  type: GET_ALL_PENDING_IDENTITY_SUCCESS,
  payload: data,
});

export const getAllPendingIdentityFailure = (data) => ({
  type: GET_ALL_PENDING_IDENTITY_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getAllPendingBankAccountRequest = () => ({
  type: GET_ALL_PENDING_BANK_ACCOUNT_REQUEST,
});

export const getAllPendingBankAccountStarted = () => ({
  type: GET_ALL_PENDING_BANK_ACCOUNT_STARTED,
});

export const getAllPendingBankAccountSuccess = (data) => ({
  type: GET_ALL_PENDING_BANK_ACCOUNT_SUCCESS,
  payload: data,
});

export const getAllPendingBankAccountFailure = (data) => ({
  type: GET_ALL_PENDING_BANK_ACCOUNT_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getAllPendingBillingAddressRequest = () => ({
  type: GET_ALL_PENDING_BILLING_ADDRESS_REQUEST,
});

export const getAllPendingBillingAddressStarted = () => ({
  type: GET_ALL_PENDING_BILLING_ADDRESS_STARTED,
});

export const getAllPendingBillingAddressSuccess = (data) => ({
  type: GET_ALL_PENDING_BILLING_ADDRESS_SUCCESS,
  payload: data,
});

export const getAllPendingBillingAddressFailure = (data) => ({
  type: GET_ALL_PENDING_BILLING_ADDRESS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getFraudSuspectUsersRequest = () => ({
  type: GET_FRAUD_SUSPECT_USERS_REQUEST,
});

export const getFraudSuspectUsersStarted = () => ({
  type: GET_FRAUD_SUSPECT_USERS_STARTED,
});

export const getFraudSuspectUsersSuccess = (data) => ({
  type: GET_FRAUD_SUSPECT_USERS_SUCCESS,
  payload: data,
});

export const getFraudSuspectUsersFailure = (data) => ({
  type: GET_FRAUD_SUSPECT_USERS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getBlockedUsersRequest = () => ({
  type: GET_BLOCKED_USERS_REQUEST,
});

export const getBlockedUsersStarted = () => ({
  type: GET_BLOCKED_USERS_STARTED,
});

export const getBlockedUsersSuccess = (data) => ({
  type: GET_BLOCKED_USERS_SUCCESS,
  payload: data,
});

export const getBlockedUsersFailure = (data) => ({
  type: GET_BLOCKED_USERS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getAllUsersRequest = (data) => ({
  type: GET_ALL_USERS_REQUEST,
  payload: data,
});

export const getAllUsersStarted = () => ({
  type: GET_ALL_USERS_STARTED,
});

export const getAllUsersSuccess = (data) => ({
  type: GET_ALL_USERS_SUCCESS,
  payload: data,
});

export const getAllUsersFailure = (data) => ({
  type: GET_ALL_USERS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getBusinessUsersRequest = () => ({
  type: GET_BUSINESS_USERS_REQUEST,
});

export const getBusinessUsersStarted = () => ({
  type: GET_BUSINESS_USERS_STARTED,
});

export const getBusinessUsersSuccess = (data) => ({
  type: GET_BUSINESS_USERS_SUCCESS,
  payload: data,
});

export const getBusinessUsersFailure = (data) => ({
  type: GET_BUSINESS_USERS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getAllTicketsRequest = (data) => ({
  type: GET_ALL_TICKETS_REQUEST,
  payload: data,
});

export const getAllTicketsStarted = () => ({
  type: GET_ALL_TICKETS_STARTED,
});

export const getAllTicketsSuccess = (data) => ({
  type: GET_ALL_TICKETS_SUCCESS,
  payload: data,
});

export const getAllTicketsFailure = (data) => ({
  type: GET_ALL_TICKETS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getOpenTicketsRequest = (data) => ({
  type: GET_OPEN_TICKETS_REQUEST,
  payload: data,
});

export const getOpenTicketsStarted = () => ({
  type: GET_OPEN_TICKETS_STARTED,
});

export const getOpenTicketsSuccess = (data) => ({
  type: GET_OPEN_TICKETS_SUCCESS,
  payload: data,
});

export const getOpenTicketsFailure = (data) => ({
  type: GET_OPEN_TICKETS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const createNewTicketRequest = (data) => ({
  type: CREATE_NEW_TICKET_REQUEST,
  payload: data,
});

export const createNewTicketStarted = () => ({
  type: CREATE_NEW_TICKET_STARTED,
});

export const createNewTicketSuccess = () => ({
  type: CREATE_NEW_TICKET_SUCCESS,
});

export const createNewTicketFailure = (data) => ({
  type: CREATE_NEW_TICKET_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getTicketDetailsRequest = (data) => ({
  type: GET_TICKET_DETAILS_REQUEST,
  payload: data,
});

export const getTicketDetailsStarted = () => ({
  type: GET_TICKET_DETAILS_STARTED,
});

export const getTicketDetailsSuccess = (data) => ({
  type: GET_TICKET_DETAILS_SUCCESS,
  payload: data,
});

export const getTicketDetailsFailure = (data) => ({
  type: GET_TICKET_DETAILS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const changeTicketStatusRequest = (data) => ({
  type: CHANGE_TICKET_STATUS_REQUEST,
  payload: data,
});

export const changeTicketStatusStarted = () => ({
  type: CHANGE_TICKET_STATUS_STARTED,
});

export const changeTicketStatusSuccess = () => ({
  type: CHANGE_TICKET_STATUS_SUCCESS,
});

export const changeTicketStatusFailure = (data) => ({
  type: CHANGE_TICKET_STATUS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const createReplyTicketRequest = (data) => ({
  type: CREATE_REPLY_TICKET_REQUEST,
  payload: data,
});

export const createReplyTicketStarted = () => ({
  type: CREATE_REPLY_TICKET_STARTED,
});

export const createReplyTicketSuccess = () => ({
  type: CREATE_REPLY_TICKET_SUCCESS,
});

export const createReplyTicketFailure = (data) => ({
  type: CREATE_REPLY_TICKET_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const changeTicketRoleRequest = (data) => ({
  type: CHANGE_TICKET_ROLE_REQUEST,
  payload: data,
});

export const changeTicketRoleStarted = () => ({
  type: CHANGE_TICKET_ROLE_STARTED,
});

export const changeTicketRoleSuccess = () => ({
  type: CHANGE_TICKET_ROLE_SUCCESS,
});

export const changeTicketRoleFailure = (data) => ({
  type: CHANGE_TICKET_ROLE_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const deleteTicketRequest = (data) => ({
  type: DELETE_TICKET_REQUEST,
  payload: data,
});

export const deleteTicketStarted = () => ({
  type: DELETE_TICKET_STARTED,
});

export const deleteTicketSuccess = () => ({
  type: DELETE_TICKET_SUCCESS,
});

export const deleteTicketFailure = (data) => ({
  type: DELETE_TICKET_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const addTicketCommentRequest = (data) => ({
  type: ADD_TICKET_COMMENT_REQUEST,
  payload: data,
});

export const addTicketCommentStarted = () => ({
  type: ADD_TICKET_COMMENT_STARTED,
});

export const addTicketCommentSuccess = () => ({
  type: ADD_TICKET_COMMENT_SUCCESS,
});

export const addTicketCommentFailure = (data) => ({
  type: ADD_TICKET_COMMENT_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getStripeInvoiceRequest = (data, response) => ({
  type: GET_STRIPE_INVOICE_REQUEST,
  payload: { data, response },
});

export const getStripeInvoiceStarted = () => ({
  type: GET_STRIPE_INVOICE_STARTED,
});

export const getStripeInvoiceSuccess = (data) => ({
  type: GET_STRIPE_INVOICE_SUCCESS,
  payload: data,
});

export const getStripeInvoiceFailure = (data) => ({
  type: GET_STRIPE_INVOICE_FAILURE,
  payload: data,
});
