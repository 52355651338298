import axiosMiddleware from "../index";
// --------------------------------------------------------------------------------
export const getDashboardInfoAPI = () =>
  axiosMiddleware({
    method: "post",
    path: "dashboard",
  });
export const getParsianBalanceAPI = () =>
  axiosMiddleware({
    method: "post",
    path: "parsian-balance",
  });
export const getAyandeBalanceAPI = () =>
  axiosMiddleware({
    method: "post",
    path: "ayande-balance",
  });
export const getPasargadBalanceAPI = () =>
  axiosMiddleware({
    method: "post",
    path: "pasargad-balance",
  });
export const getKavenegarBalanceAPI = () =>
  axiosMiddleware({
    method: "post",
    path: "kavehnegar-balance",
  });
export const getElasticBalanceAPI = () =>
  axiosMiddleware({
    method: "post",
    path: "elastic-balance",
  });
export const getAllPendingIdentityAPI = () =>
  axiosMiddleware({
    method: "post",
    path: "user-identity/all-pending",
  });
export const getAllPendingBankAccountAPI = () =>
  axiosMiddleware({
    method: "post",
    path: "bank-account/all-pending",
  });
export const getAllPendingBillingAddressAPI = () =>
  axiosMiddleware({
    method: "post",
    path: "user-address/all-pending",
  });
export const getFraudSuspectUsersAPI = () =>
  axiosMiddleware({
    method: "post",
    path: "user/fraud-suspect",
  });
export const getBlockedUsersAPI = () =>
  axiosMiddleware({
    method: "post",
    path: "user/blocked",
  });
export const getAllUsersAPI = (data) => {
  let perPage = `per_page=${data.pageSize}`;
  let page = `&page=${data.page}`;
  return axiosMiddleware({
    method: "post",
    path: `user/all?${perPage}${page}`,
    body: data,
  });
};
export const getBusinessUsersAPI = () =>
  axiosMiddleware({
    method: "post",
    path: "user/business",
  });
export const getAllTicketsAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "ticket/all",
    body: data,
  });
export const getOpenTicketsAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "ticket/open",
    body: data,
  });
export const createNewTicketAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "ticket/new-ticket",
    body: data,
  });
export const getTicketDetailsAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "ticket/show",
    body: data,
  });
export const changeTicketStatusAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "ticket/change-status",
    body: data,
  });
export const createReplyTicketAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "ticket/reply",
    body: data,
  });
export const changeTicketRoleAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "ticket/change-role",
    body: data,
  });
export const deleteTicketAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "ticket/delete",
    body: data,
  });
export const addTicketCommentAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "ticket/add-comment",
    body: data,
  });
export const getStripeInvocesAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "invoices/stripe",
    body: data,
  });
export const getStripeInvoiceAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "invoices/stripe/items",
    body: data,
  });
