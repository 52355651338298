import {
  LOGIN_ADMIN_FAILURE,
  LOGIN_ADMIN_REQUEST,
  LOGIN_ADMIN_STARTED,
  LOGIN_ADMIN_SUCCESS,
  LOGOUT_ADMIN_REQUEST,
  LOGOUT_ADMIN_SUCCESS,
  VERIFY_ADMIN_FAILURE,
  VERIFY_ADMIN_REQUEST,
  VERIFY_ADMIN_STARTED,
  VERIFY_ADMIN_SUCCESS,
} from "redux/types";
// --------------------------------------------------------------------------------
export const loginAdminRequest = (data) => ({
  type: LOGIN_ADMIN_REQUEST,
  payload: data,
});

export const loginAdminStarted = () => ({
  type: LOGIN_ADMIN_STARTED,
});

export const loginAdminSuccess = () => ({
  type: LOGIN_ADMIN_SUCCESS,
});

export const loginAdminFailure = (data) => ({
  type: LOGIN_ADMIN_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const verifyAdminRequest = (data) => ({
  type: VERIFY_ADMIN_REQUEST,
  payload: data,
});

export const verifyAdminStarted = () => ({
  type: VERIFY_ADMIN_STARTED,
});

export const verifyAdminSuccess = (data) => ({
  type: VERIFY_ADMIN_SUCCESS,
  payload: data,
});

export const verifyAdminFailure = (data) => ({
  type: VERIFY_ADMIN_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const logoutAdminRequest = () => ({
  type: LOGOUT_ADMIN_REQUEST,
});

export const logoutAdminSuccess = (data) => ({
  type: LOGOUT_ADMIN_SUCCESS,
  payload: data,
});
