import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { API_CHANGE_STATUS, API_USER_EDIT, token } from "constants/constants";
import Button from "@mui/material/Button";
import axios from "axios";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import IdCard from "assets/images/default/id-card.png";
import CircularProgress from "@mui/material/CircularProgress";
import AppSwitch from "components/AppSwitch";
import AppButton from "components/AppButton";

const UserInfo = ({ user }) => {
  const [modal, setModal] = useState(false);
  const [rotation, setRotation] = useState(0);
  const [loadingUser, setLoadingUser] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);

  const rotateLeft = () => {
    let newRotation = rotation + 90;
    setRotation(newRotation);
  };
  const rotateRight = () => {
    let newRotation = rotation - 90;
    setRotation(newRotation);
  };

  const toggle = () => setModal(!modal);
  const [values, setValues] = useState({
    first_name: user.first_name,
    last_name: user.last_name,
    full_name: user.full_name,
    username: user.username,
    mobile: user.mobile,
    national_code: user.national_code,
    phone: user.phone,
    parent_id: user.parent_id,
    status: user.status,

    payment_otp: user.payment_otp,
    otp: user.otp,
    renew: user.renew,
    login_otp: user.login_otp,
    email_notification: user.email_notification === "Active",
    success: false,
    ip: user.ip,
    error: false,
  });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleChange2 = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.checked });
  };

  function handleSave() {
    setLoadingStatus(true);
    axios
      .post(
        API_CHANGE_STATUS,
        {
          status_field: "identity_status",
          value: values.identity_status,
          comment: values.identity_comment,
          user_id: user.id,
        },
        token()
      )
      .then((response) => {
        if (response.status === 200 && response.data.status === "success") {
          setValues({ ...values, success: true });
        } else {
          setValues({ ...values, error: true });
        }
        setLoadingStatus(false);
      })
      .catch((error) => {
        let failedValue = "";
        if (typeof error.response.data.data === "string") {
          failedValue = error.response.data.data;
        } else {
          for (var prop in error.response.data.data) {
            failedValue += prop + ": " + error.response.data.data[prop] + " - ";
          }
        }
        setValues({ ...values, error: true, code: failedValue });
        setLoadingStatus(false);
      });
  }

  const data = [{ value: user.strength }, { value: 100 - user.strength }];
  const COLORS = ["#792987", "#d9d9d9", "#FA8C16", "#f5222d"];

  function handleSaveUser() {
    setLoadingUser(true);
    axios
      .post(
        API_USER_EDIT,
        {
          first_name: values.first_name,
          last_name: values.last_name,
          username: values.username,
          mobile: values.mobile,
          phone: values.phone,
          parent_id: values.parent_id,
          national_code: values.national_code,
          otp: values.otp,
          renew: values.renew,
          sms_notification: values.sms_notification,
          email_notification: values.email_notification,
          user_id: user.id,
        },
        token()
      )
      .then((response) => {
        if (response.status === 200 && response.data.status === "success") {
          // setValues({...values, success: true});
          let data = response.data.data;
          console.log(response, "rrr");
          setValues({
            first_name: data.first_name,
            last_name: data.last_name,
            username: data.username,
            mobile: data.mobile,
            national_code: data.national_code,
            phone: data.phone,
            parent_id: data.parent_id,
            identity_status: data.identity_status,
            identity_comment: data.identity_comment,
            otp: data.otp,
            renew: data.renew,
            sms_notification: data.sms_notification,
            email_notification: data.email_notification,
            success: true,
            error: false,
          });
        } else {
          setValues({ ...values, error: true });
        }
        setLoadingUser(false);
      })
      .catch((error) => {
        let failedValue = "";
        if (typeof error.response.data.data === "string") {
          failedValue = error.response.data.data;
        } else {
          for (var prop in error.response.data.data) {
            failedValue += prop + ": " + error.response.data.data[prop] + " - ";
          }
        }

        setLoadingUser(false);
      });
  }

  return (
    <div className="row">
      <h3 className="p-3">User Info</h3>
      <div className="col-12  d-flex justify-content-start flex-wrap align-items-center mb-3">
        <div className={"col-md-6 col-sm-12"}>
          <TextField
            className="w-100"
            label="First Name"
            value={values.first_name}
            onChange={handleChange("first_name")}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className={"col-md-6 col-sm-12"}>
          <TextField
            className="w-100"
            label="last Name"
            value={values.last_name}
            onChange={handleChange("last_name")}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className={"col-md-6 col-sm-12"}>
          <TextField
            className="w-100"
            label="Full Name"
            value={values.full_name}
            onChange={handleChange("full_name")}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className={"col-md-6 col-sm-12"}>
          <TextField
            className="w-100"
            label={"Email "}
            value={user.email}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className={"col-md-6 col-sm-12"}>
          <TextField
            className="w-100"
            label="Mobile"
            value={values.mobile}
            onChange={handleChange("mobile")}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className={"col-md-6 col-sm-12"}>
          <TextField
            className="w-100"
            label="National Code"
            value={values.national_code}
            onChange={handleChange("national_code")}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className={"col-md-6 col-sm-12"}>
          <TextField
            className="w-100"
            label="IP"
            value={values.ip}
            onChange={handleChange("IP")}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className={"col-md-6 col-sm-12"}>
          <TextField
            className="w-100"
            label="Phone"
            value={values.phone}
            onChange={handleChange("phone")}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>

        <div className={"col-md-6 col-sm-12"}>
          <TextField
            className="w-100"
            label="Updated at"
            value={user.updated_at}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>

        <div className="row d-flex align-items-center">
          <div className="col-xl-3 col-lg-6 col-md-12 col-12  ">
            <AppSwitch
              title="Payement OTP"
              checked={values.payment_otp}
              onChange={handleChange2("payment_otp")}
            />
          </div>
          <div className="col-xl-3 col-lg-6 col-md-12 col-12 ">
            <AppSwitch
              title="Login OTP"
              checked={values.otp}
              onChange={handleChange2("login_otp")}
            />
          </div>
          <div className="col-xl-3 col-lg-6 col-md-12 col-12 ">
            <AppSwitch
              title="Renew"
              checked={values.renew}
              onChange={handleChange2("renew")}
            />
          </div>
          <div className="col-xl-3 col-lg-6 col-md-12 col-12 ">
            <AppSwitch
              title="Email Notification"
              checked={values.email_notification}
              onChange={handleChange2("email_notification")}
            />
          </div>
        </div>

        <div className="d-flex flex-row align-items-center mt-2">
          {
            <AppButton
              disabled={loadingUser}
              onClick={handleSaveUser}
              loading={loadingUser}
              text="Save"
            />
          }
        </div>
      </div>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Passport</ModalHeader>
        <ModalBody style={{ transform: `rotate(${rotation}deg)` }}>
          <div>
            <img
              alt="Passport"
              style={{ width: "100%" }}
              src={user.identity_attachment ? user.identity_attachment : IdCard}
              title={user.last_name}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            Ok
          </Button>

          <div>
            <label
              style={{
                fontSize: 12,
                marginRight: 10,
                fontWeight: 600,
              }}
            ></label>
            <button
              className="MuiButtonBase-root MuiButton-root jr-btn bg-teal text-white MuiButton-contained"
              onClick={rotateLeft}
            >
              <span className="MuiButton-label">
                <i className="zmdi zmdi-rotate-cw"></i>
              </span>
            </button>
            <button
              className="MuiButtonBase-root MuiButton-root jr-btn bg-teal text-white MuiButton-contained"
              onClick={rotateRight}
            >
              <span className="MuiButton-label">
                <i className="zmdi zmdi-rotate-ccw"></i>
              </span>
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default UserInfo;
