import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const AdminProfile = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Redirect
        exact
        from={`${match.url}/`}
        to={`${match.url}/ChangePassword`}
      />
      <Route
        path={`${match.url}/change-password`}
        component={asyncComponent(() => import("./routes/ChangePassword"))}
      />
      <Route
        path={`${match.url}/rollcall`}
        component={asyncComponent(() => import("./routes/Rollcall"))}
      />
    </Switch>
  </div>
);

export default AdminProfile;
