import React from "react";
import { API_GET_ALL_PENDING_COMPANY } from "constants/constants";
import {
  createDateLookup,
  getUser,
  renderStatus,
} from "constants/usefulFunctions";
import withTable from "HOC/withTable/withTable";
import MainTable from "components/MainTable";

const PendingCompanyInfo = ({ loading, data }) => {
  const columns = [
    {
      title: "User",
      field: "fullname",
      render: (rowData) => getUser(rowData),
    },
    { title: "Email", field: "email" },
    createDateLookup(),
    {
      title: "Status",
      field: "status",
      lookup: {
        Approved: "Approved",
        Declined: "Declined",
        Pending: "Pending",
      },
      render: (rowData) => renderStatus(rowData.status),
    },
  ];

  return (
    <MainTable
      loading={loading}
      columns={columns}
      data={data}
      title="Pending Company Info"
    />
  );
};

export default withTable(PendingCompanyInfo, {
  title: "Pending Company Info",
  apiAddress: API_GET_ALL_PENDING_COMPANY,
  hasDatePicker: false,
});
