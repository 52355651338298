import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const Cryptocurrency = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Route
        path={`${match.url}/estchange/billing`}
        component={asyncComponent(() => import("./routes/Estchange/Billing"))}
      />
      <Route
        path={`${match.url}/estchange/trading`}
        component={asyncComponent(() => import("./routes/Estchange/Trading"))}
      />
      <Route
        path={`${match.url}/binance/rates`}
        component={asyncComponent(() => import("./routes/Binance/Rates"))}
      />
      <Route
        path={`${match.url}/fullnode/rpc`}
        component={asyncComponent(() => import("./routes/FullNode/RPC"))}
      />
      <Route
        path={`${match.url}/fullnode/wallet`}
        component={asyncComponent(() => import("./routes/FullNode/Wallet"))}
      />
      <Route
        path={`${match.url}/bitcoin/bitcoinTransactions`}
        component={asyncComponent(() =>
          import("./routes/Bitcoin/BitcoinTransactions")
        )}
      />
      <Route
        path={`${match.url}/bitcoin/bitcoinWallets`}
        component={asyncComponent(() =>
          import("./routes/Bitcoin/BitcoinWallets")
        )}
      />
      <Route
        path={`${match.url}/blockcypher/bitcoin`}
        component={asyncComponent(() => import("./routes/Blockcypher/Bitcoin"))}
      />
      <Route
        path={`${match.url}/blockcypher/ethereum`}
        component={asyncComponent(() =>
          import("./routes/Blockcypher/Ethereum")
        )}
      />
    </Switch>
  </div>
);

export default Cryptocurrency;
