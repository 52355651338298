import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import { API_GET_ACTIVITY_ONE, API_GET_BUSINESS_ALL, token } from 'constants/constants';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

function AcceptorType({
  acceptor_type_id,
  acceptor_type_idChange,
  acceptor_qty,
  acceptor_qtyChange,
  acceptor_enamad_verified,
  acceptor_enamad_verifiedChange,
  acceptor_enamad_category,
  acceptor_enamad_categoryChange,
  acceptor_enamad_type,
  acceptor_enamad_typeChange,
  acceptor_enamad_issue_date,
  acceptor_enamad_issue_dateChange,
  acceptor_enamad_expire_date,
  acceptor_enamad_expire_dateChange,
  business_id,
  business_idChange,
  acceptor_identifier,
  acceptor_identifierChange,
  acceptor_website,
  acceptor_websiteChange,
  acceptor_ip_address,
  acceptor_ip_addressChange
}) {
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState([]);
  const [identifier, setIdentifier] = useState([]);

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      await axios.post(API_GET_ACTIVITY_ONE, { business_id: business_id }, token()).then((res) => {
        const data = res.data.data;
        setIdentifier(data);
      });
      setLoading(false);
    }
    fetchData();
  }, [business_id]);

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      await axios.post(API_GET_BUSINESS_ALL, {}, token()).then((res) => {
        const data = res.data.data;
        setCode(data);
      });
      setLoading(false);
    }
    fetchData();
  }, []);

  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '65%'
      }
    }
  }));
  const classes = useStyles();
  return (
    <div>
      <div className="row">
        <div className="jr-card p-0 col-xl-12 col-lg-12 col-md-12 col-12 ">
          <div className="jr-card-header card-img-top mb-0 p-4 bg-grey lighten-4">
            <h3 className="card-heading">Type Acceptor</h3>
          </div>
          <div className="card-body text-center">
            <ul className="contact-list list-unstyled">
              <div className="row">
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="Acceptor Type"
                    value={acceptor_type_id}
                    InputLabelProps={{ shrink: true }}
                    onChange={acceptor_type_idChange}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="Number of Terminals"
                    value={acceptor_qty}
                    InputLabelProps={{ shrink: true }}
                    onChange={acceptor_qtyChange}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    select
                    fullWidth
                    label="Enamad"
                    value={acceptor_enamad_verified}
                    InputLabelProps={{ shrink: true }}
                    onChange={acceptor_enamad_verifiedChange}
                    margin="normal"
                    variant="outlined">
                    <MenuItem dense={true} key="Yes" value="Yes">
                      {' '}
                      <span>بله</span>{' '}
                    </MenuItem>
                    <MenuItem key="No" value="No">
                      {' '}
                      <span>خیر</span>{' '}
                    </MenuItem>
                  </TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    select
                    fullWidth
                    label="Enamad Category"
                    value={acceptor_enamad_category}
                    InputLabelProps={{ shrink: true }}
                    onChange={acceptor_enamad_categoryChange}
                    margin="normal"
                    variant="outlined">
                    <MenuItem dense={true} key="Governmental" value="Governmental">
                      {' '}
                      <span>دولتی</span>{' '}
                    </MenuItem>
                    <MenuItem key="NonGovernmental" value="NonGovernmental">
                      {' '}
                      <span>عمومی غیر دولتی</span>{' '}
                    </MenuItem>
                    <MenuItem key="Educational" value="Educational">
                      {' '}
                      <span>دانشگاه و مراکز آموزش عالی</span>{' '}
                    </MenuItem>
                  </TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    select
                    fullWidth
                    label="Enamad Type"
                    value={acceptor_enamad_type}
                    InputLabelProps={{ shrink: true }}
                    onChange={acceptor_enamad_typeChange}
                    margin="normal"
                    variant="outlined">
                    <MenuItem dense={true} key="OneStar" value="OneStar">
                      {' '}
                      <span>یک ستاره</span>{' '}
                    </MenuItem>
                    <MenuItem key="TwoStar" value="TwoStar">
                      {' '}
                      <span>دو ستاره</span>{' '}
                    </MenuItem>
                  </TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="Enamad Issue Date"
                    value={acceptor_enamad_issue_date}
                    InputLabelProps={{ shrink: true }}
                    onChange={acceptor_enamad_issue_dateChange}
                    margin="normal"
                    placeholder="0000/00/00"
                    variant="outlined"></TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="Enamad Expire Date"
                    value={acceptor_enamad_expire_date}
                    InputLabelProps={{ shrink: true }}
                    onChange={acceptor_enamad_expire_dateChange}
                    margin="normal"
                    placeholder="0000/00/00"
                    variant="outlined"></TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    select
                    fullWidth
                    label="Corporation Code"
                    value={business_id}
                    InputLabelProps={{ shrink: true }}
                    onChange={business_idChange}
                    margin="normal"
                    variant="outlined">
                    {code &&
                      code.map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.business_id}>
                            {item.title}
                          </MenuItem>
                        );
                      })}
                  </TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    select
                    fullWidth
                    label="Identifier Code"
                    value={acceptor_identifier}
                    InputLabelProps={{ shrink: true }}
                    onChange={acceptor_identifierChange}
                    margin="normal"
                    variant="outlined">
                    {identifier &&
                      identifier.map((item) => {
                        console.log(item, 'www');
                        return (
                          <MenuItem key={item.id} value={item.identifier}>
                            {item.title}
                          </MenuItem>
                        );
                      })}
                  </TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="Acceptor Website"
                    value={acceptor_website}
                    InputLabelProps={{ shrink: true }}
                    onChange={acceptor_websiteChange}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
                <div className=" col-xl-4 col-lg-4 col-md-6 col-12 ">
                  <TextField
                    fullWidth
                    label="IP Address"
                    value={acceptor_ip_address}
                    InputLabelProps={{ shrink: true }}
                    onChange={acceptor_ip_addressChange}
                    margin="normal"
                    variant="outlined"></TextField>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AcceptorType;
