import apiService from "services";
import {
  API_CHANGE_STATUS_ANNOUNCEMENT,
  API_DELETE_ANNOUNCEMENT,
} from "constants/constants";
import axiosMiddleware from "..";

const apiDeleteAnnouncement = (params) =>
  apiService.post(API_DELETE_ANNOUNCEMENT, params);

const apiChangeAnnounceStatus = (params) =>
  apiService.post(API_CHANGE_STATUS_ANNOUNCEMENT, params);

export default {
  apiDeleteAnnouncement,
  apiChangeAnnounceStatus,
};

// --------------------------------------------------------------------------------
export const sendSingleSmsAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "send-single-sms",
    body: data,
  });
export const getAllTextMessagesAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "text-message/all",
    body: data,
  });
export const getAllannouncementAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "announcement/all",
    body: data,
  });
