import React, { useEffect, useState } from "react";
import { Currencies, boot } from "constants/constants";
import {
  getIdentityStatus,
  createDateLookup,
  deleteDateLookup,
  downloadBase64,
} from "constants/usefulFunctions";
import Avatar from "@mui/material/Avatar";
import MainTable from "components/MainTable";
import { useDispatch, useSelector } from "react-redux";
import {
  changeUserBankAccountStatusRequest,
  createUserBankAccountRequest,
  deleteUserBankAccountRequest,
  getUserBankAccountsRequest,
  restoreUserBankAccountRequest,
} from "redux/actions/UserProfile";
import {
  CHANGE_USER_BANK_ACCOUNT_STATUS_STARTED,
  DELETE_USER_BANK_ACCOUNT_STARTED,
  GET_USER_BANK_ACCOUNTS_STARTED,
  RESTORE_USER_BANK_ACCOUNT_STARTED,
} from "redux/types";

const BankAccount = ({ user_id }) => {
  const dispatch = useDispatch();
  const { rType, userBankAccounts } = useSelector(
    (state) => state.userProfileReducer
  );
  const { adminId } = useSelector((state) => state.authReducer);
  const [data, setData] = useState([]);
  const loading =
    rType === GET_USER_BANK_ACCOUNTS_STARTED ||
    rType === CHANGE_USER_BANK_ACCOUNT_STATUS_STARTED ||
    rType === DELETE_USER_BANK_ACCOUNT_STARTED ||
    rType === RESTORE_USER_BANK_ACCOUNT_STARTED;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    dispatch(getUserBankAccountsRequest({ user_id }));
  };

  const updateRow = async (id, status, description) => {
    dispatch(
      changeUserBankAccountStatusRequest({ user_id, id, status, description })
    );
  };

  const deleteRow = async (bank_account_id, user_id) => {
    dispatch(deleteUserBankAccountRequest({ bank_account_id, user_id }));
  };

  const RestoreDeleteRow = async (bank_account_id, user_id) => {
    dispatch(restoreUserBankAccountRequest({ user_id, bank_account_id }));
  };

  const addRow = async (
    currency_id,
    account_holder_name,
    iban,
    bank_branch,
    swift_code
  ) => {
    dispatch(
      createUserBankAccountRequest({
        user_id,
        currency_id,
        account_holder_name,
        bank_branch,
        iban,
        swift_code,
      })
    );
  };

  const columns = [
    {
      title: "Currency",
      field: "currency_id",
      lookup: Currencies,
      render: (rowData) => Currencies[rowData.currency_id],
    },
    { title: "Name", field: "account_holder_name" },
    {
      title: "Response Full Name",
      field: "response_full_name",
      editable: "never",
    },
    {
      title: "Response Account Number",
      field: "bank_response_account_number",
      editable: "never",
    },
    {
      title: "Response Status",
      field: "bank_response_status",
      editable: "never",
    },
    {
      title: "Response Comment",
      field: "bank_response_comment",
      editable: "never",
    },
    { title: "Branch", field: "bank_branch" },
    {
      title: "Attachment",
      field: "file",
      editable: "never",
      render: (rowData) =>
        rowData && rowData.file ? (
          <>
            <div className="jr-profile-banner-top-left">
              <div className="jr-profile-banner-avatar">
                <a
                  role="button"
                  target="_blank"
                  // href={rowData.file}
                  onClick={() => downloadBase64(data?.attachment)}
                >
                  <Avatar
                    className="size-50 border"
                    alt="Bank Account File"
                    src={rowData.file}
                  />
                </a>
              </div>
            </div>
          </>
        ) : (
          "-"
        ),
    },
    { title: "IBAN", field: "iban" },
    { title: "Swift", field: "swift_code" },
    createDateLookup(),
    deleteDateLookup({ restoreFunc: RestoreDeleteRow }),
    { title: "Description", field: "description" },
    {
      title: "Admin",
      field: "admin_id",
      editable: "never",
      initialEditValue: Number(adminId),
      render: (rowData) =>
        boot.admins && rowData.admin_id
          ? boot.admins.find((o) => o.id === rowData.admin_id).first_name +
            " " +
            boot.admins.find((o) => o.id === rowData.admin_id).last_name
          : boot.admins.find((o) => o.id === Number(adminId)).first_name +
            " " +
            boot.admins.find((o) => o.id === Number(adminId)).last_name,
    },
    {
      title: "Status",
      field: "status",
      initialEditValue: "Pending",
      lookup: {
        Approved: "Approved",
        Declined: "Declined",
        Pending: "Pending",
        Suspend: "Suspend",
      },
      render: (rowData) => rowData && getIdentityStatus(rowData.status),
    },
  ];
  const options = {
    actionsColumnIndex: columns.length,
  };

  return (
    <div className="card">
      <MainTable
        customOptions={options}
        isLocalization
        loading={loading}
        columns={columns}
        data={userBankAccounts}
        title="Bank Accounts"
        onRowAdd={(newData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              addRow(
                newData.currency_id,
                newData.account_holder_name,
                newData.iban,
                newData.bank_branch,
                newData.swift_code
              );
              resolve();
            }, 600);
          })
        }
        onRowUpdate={(newData, oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              updateRow(oldData.id, newData.status, newData.description);
              resolve();
            }, 500);
          })
        }
        onRowDelete={(oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              const updatedData = data?.filter(
                (item) => item.id !== oldData.id
              );
              deleteRow(oldData.id, oldData.user_id, updatedData);
              resolve();
            }, 600);
          })
        }
      />
    </div>
  );
};

export default BankAccount;
