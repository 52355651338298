import {
  CHANGE_HOLD_TRANSACTION_STATUS_FAILURE,
  CHANGE_HOLD_TRANSACTION_STATUS_REQUEST,
  CHANGE_HOLD_TRANSACTION_STATUS_STARTED,
  CHANGE_HOLD_TRANSACTION_STATUS_SUCCESS,
  CHANGE_REFUND_REQUEST_STATUS_FAILURE,
  CHANGE_REFUND_REQUEST_STATUS_REQUEST,
  CHANGE_REFUND_REQUEST_STATUS_STARTED,
  CHANGE_REFUND_REQUEST_STATUS_SUCCESS,
  CONFIRM_VIP_PAYOUT_FAILURE,
  CONFIRM_VIP_PAYOUT_REQUEST,
  CONFIRM_VIP_PAYOUT_STARTED,
  CONFIRM_VIP_PAYOUT_SUCCESS,
  GET_ALL_EXCHANGES_FAILURE,
  GET_ALL_EXCHANGES_REQUEST,
  GET_ALL_EXCHANGES_STARTED,
  GET_ALL_EXCHANGES_SUCCESS,
  GET_ALL_PAYOUTS_FAILURE,
  GET_ALL_PAYOUTS_REQUEST,
  GET_ALL_PAYOUTS_STARTED,
  GET_ALL_PAYOUTS_SUCCESS,
  GET_ALL_TOPUPS_FAILURE,
  GET_ALL_TOPUPS_REQUEST,
  GET_ALL_TOPUPS_STARTED,
  GET_ALL_TOPUPS_SUCCESS,
  GET_ALL_TRANSACTIONS_FAILURE,
  GET_ALL_TRANSACTIONS_REQUEST,
  GET_ALL_TRANSACTIONS_STARTED,
  GET_ALL_TRANSACTIONS_SUCCESS,
  GET_ALL_TRANSFERS_FAILURE,
  GET_ALL_TRANSFERS_REQUEST,
  GET_ALL_TRANSFERS_STARTED,
  GET_ALL_TRANSFERS_SUCCESS,
  GET_BANKS_REPORT_FAILURE,
  GET_BANKS_REPORT_REQUEST,
  GET_BANKS_REPORT_STARTED,
  GET_BANKS_REPORT_SUCCESS,
  GET_HOLD_TRANSACTIONS_FAILURE,
  GET_HOLD_TRANSACTIONS_REQUEST,
  GET_HOLD_TRANSACTIONS_STARTED,
  GET_HOLD_TRANSACTIONS_SUCCESS,
  GET_PAYMENT_LINK_TRANSACTIONS_FAILURE,
  GET_PAYMENT_LINK_TRANSACTIONS_REQUEST,
  GET_PAYMENT_LINK_TRANSACTIONS_STARTED,
  GET_PAYMENT_LINK_TRANSACTIONS_SUCCESS,
  GET_PENDING_PAYOUTS_FAILURE,
  GET_PENDING_PAYOUTS_REQUEST,
  GET_PENDING_PAYOUTS_STARTED,
  GET_PENDING_PAYOUTS_SUCCESS,
  GET_PERSONAL_TRANSACTIONS_FAILURE,
  GET_PERSONAL_TRANSACTIONS_REQUEST,
  GET_PERSONAL_TRANSACTIONS_STARTED,
  GET_PERSONAL_TRANSACTIONS_SUCCESS,
  GET_REFUND_REQUESTS_FAILURE,
  GET_REFUND_REQUESTS_REQUEST,
  GET_REFUND_REQUESTS_STARTED,
  GET_REFUND_REQUESTS_SUCCESS,
  GET_TRANSACTIONS_PAYOUT_PAY_FAILURE,
  GET_TRANSACTIONS_PAYOUT_PAY_REQUEST,
  GET_TRANSACTIONS_PAYOUT_PAY_STARTED,
  GET_TRANSACTIONS_PAYOUT_PAY_SUCCESS,
  GET_VIP_PENDING_PAYOUTS_FAILURE,
  GET_VIP_PENDING_PAYOUTS_REQUEST,
  GET_VIP_PENDING_PAYOUTS_STARTED,
  GET_VIP_PENDING_PAYOUTS_SUCCESS,
  GET_WEBSITE_GATEWAYS_FAILURE,
  GET_WEBSITE_GATEWAYS_REQUEST,
  GET_WEBSITE_GATEWAYS_STARTED,
  GET_WEBSITE_GATEWAYS_SUCCESS,
  GET_WIRE_PAYOUTS_FAILURE,
  GET_WIRE_PAYOUTS_REQUEST,
  GET_WIRE_PAYOUTS_STARTED,
  GET_WIRE_PAYOUTS_SUCCESS,
} from "redux/types";
// --------------------------------------------------------------------------------
export const getAllTransactionsRequest = (data) => ({
  type: GET_ALL_TRANSACTIONS_REQUEST,
  payload: data,
});

export const getAllTransactionsStarted = () => ({
  type: GET_ALL_TRANSACTIONS_STARTED,
});

export const getAllTransactionsSuccess = (data) => ({
  type: GET_ALL_TRANSACTIONS_SUCCESS,
  payload: data,
});

export const getAllTransactionsFailure = (data) => ({
  type: GET_ALL_TRANSACTIONS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getHoldTransactionsRequest = () => ({
  type: GET_HOLD_TRANSACTIONS_REQUEST,
});

export const getHoldTransactionsStarted = () => ({
  type: GET_HOLD_TRANSACTIONS_STARTED,
});

export const getHoldTransactionsSuccess = (data) => ({
  type: GET_HOLD_TRANSACTIONS_SUCCESS,
  payload: data,
});

export const getHoldTransactionsFailure = (data) => ({
  type: GET_HOLD_TRANSACTIONS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const changeHoldTransactionStatusRequest = (data) => ({
  type: CHANGE_HOLD_TRANSACTION_STATUS_REQUEST,
  payload: data,
});

export const changeHoldTransactionStatusStarted = () => ({
  type: CHANGE_HOLD_TRANSACTION_STATUS_STARTED,
});

export const changeHoldTransactionStatusSuccess = () => ({
  type: CHANGE_HOLD_TRANSACTION_STATUS_SUCCESS,
});

export const changeHoldTransactionStatusFailure = (data) => ({
  type: CHANGE_HOLD_TRANSACTION_STATUS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getAllPayoutsRequest = (data) => ({
  type: GET_ALL_PAYOUTS_REQUEST,
  payload: data,
});

export const getAllPayoutsStarted = () => ({
  type: GET_ALL_PAYOUTS_STARTED,
});

export const getAllPayoutsSuccess = (data) => ({
  type: GET_ALL_PAYOUTS_SUCCESS,
  payload: data,
});

export const getAllPayoutsFailure = (data) => ({
  type: GET_ALL_PAYOUTS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getPendingPayoutsRequest = (data) => ({
  type: GET_PENDING_PAYOUTS_REQUEST,
  payload: data,
});

export const getPendingPayoutsStarted = () => ({
  type: GET_PENDING_PAYOUTS_STARTED,
});

export const getPendingPayoutsSuccess = (data) => ({
  type: GET_PENDING_PAYOUTS_SUCCESS,
  payload: data,
});

export const getPendingPayoutsFailure = (data) => ({
  type: GET_PENDING_PAYOUTS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getTransactionsPayoutPayRequest = (data) => ({
  type: GET_TRANSACTIONS_PAYOUT_PAY_REQUEST,
  payload: data,
});

export const getTransactionsPayoutPayStarted = () => ({
  type: GET_TRANSACTIONS_PAYOUT_PAY_STARTED,
});

export const getTransactionsPayoutPaySuccess = (data) => ({
  type: GET_TRANSACTIONS_PAYOUT_PAY_SUCCESS,
  payload: data,
});

export const getTransactionsPayoutPayFailure = (data) => ({
  type: GET_TRANSACTIONS_PAYOUT_PAY_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getVIPPendingPayoutsRequest = (data) => ({
  type: GET_VIP_PENDING_PAYOUTS_REQUEST,
  payload: data,
});

export const getVIPPendingPayoutsStarted = () => ({
  type: GET_VIP_PENDING_PAYOUTS_STARTED,
});

export const getVIPPendingPayoutsSuccess = (data) => ({
  type: GET_VIP_PENDING_PAYOUTS_SUCCESS,
  payload: data,
});

export const getVIPPendingPayoutsFailure = (data) => ({
  type: GET_VIP_PENDING_PAYOUTS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const confirmVIPPayoutRequest = (data) => ({
  type: CONFIRM_VIP_PAYOUT_REQUEST,
  payload: data,
});

export const confirmVIPPayoutStarted = () => ({
  type: CONFIRM_VIP_PAYOUT_STARTED,
});

export const confirmVIPPayoutSuccess = (data) => ({
  type: CONFIRM_VIP_PAYOUT_SUCCESS,
  payload: data,
});

export const confirmVIPPayoutFailure = (data) => ({
  type: CONFIRM_VIP_PAYOUT_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getWirePayoutsRequest = (data) => ({
  type: GET_WIRE_PAYOUTS_REQUEST,
  payload: data,
});

export const getWirePayoutsStarted = () => ({
  type: GET_WIRE_PAYOUTS_STARTED,
});

export const getWirePayoutsSuccess = (data) => ({
  type: GET_WIRE_PAYOUTS_SUCCESS,
  payload: data,
});

export const getWirePayoutsFailure = (data) => ({
  type: GET_WIRE_PAYOUTS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getAllTopupsRequest = (data) => ({
  type: GET_ALL_TOPUPS_REQUEST,
  payload: data,
});

export const getAllTopupsStarted = () => ({
  type: GET_ALL_TOPUPS_STARTED,
});

export const getAllTopupsSuccess = (data) => ({
  type: GET_ALL_TOPUPS_SUCCESS,
  payload: data,
});

export const getAllTopupsFailure = (data) => ({
  type: GET_ALL_TOPUPS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getRefundRequestsRequest = (data) => ({
  type: GET_REFUND_REQUESTS_REQUEST,
  payload: data,
});

export const getRefundRequestsStarted = () => ({
  type: GET_REFUND_REQUESTS_STARTED,
});

export const getRefundRequestsSuccess = (data) => ({
  type: GET_REFUND_REQUESTS_SUCCESS,
  payload: data,
});

export const getRefundRequestsFailure = (data) => ({
  type: GET_REFUND_REQUESTS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const changeRefundRequestStatusRequest = (data) => ({
  type: CHANGE_REFUND_REQUEST_STATUS_REQUEST,
  payload: data,
});

export const changeRefundRequestStatusStarted = () => ({
  type: CHANGE_REFUND_REQUEST_STATUS_STARTED,
});

export const changeRefundRequestStatusSuccess = () => ({
  type: CHANGE_REFUND_REQUEST_STATUS_SUCCESS,
});

export const changeRefundRequestStatusFailure = (data) => ({
  type: CHANGE_REFUND_REQUEST_STATUS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getWebsiteGatewaysRequest = (data) => ({
  type: GET_WEBSITE_GATEWAYS_REQUEST,
  payload: data,
});

export const getWebsiteGatewaysStarted = () => ({
  type: GET_WEBSITE_GATEWAYS_STARTED,
});

export const getWebsiteGatewaysSuccess = (data) => ({
  type: GET_WEBSITE_GATEWAYS_SUCCESS,
  payload: data,
});

export const getWebsiteGatewaysFailure = (data) => ({
  type: GET_WEBSITE_GATEWAYS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getPaymentLinkTransactionsRequest = (data) => ({
  type: GET_PAYMENT_LINK_TRANSACTIONS_REQUEST,
  payload: data,
});

export const getPaymentLinkTransactionsStarted = () => ({
  type: GET_PAYMENT_LINK_TRANSACTIONS_STARTED,
});

export const getPaymentLinkTransactionsSuccess = (data) => ({
  type: GET_PAYMENT_LINK_TRANSACTIONS_SUCCESS,
  payload: data,
});

export const getPaymentLinkTransactionsFailure = (data) => ({
  type: GET_PAYMENT_LINK_TRANSACTIONS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getPersonalTransactionsRequest = (data) => ({
  type: GET_PERSONAL_TRANSACTIONS_REQUEST,
  payload: data,
});

export const getPersonalTransactionsStarted = () => ({
  type: GET_PERSONAL_TRANSACTIONS_STARTED,
});

export const getPersonalTransactionsSuccess = (data) => ({
  type: GET_PERSONAL_TRANSACTIONS_SUCCESS,
  payload: data,
});

export const getPersonalTransactionsFailure = (data) => ({
  type: GET_PERSONAL_TRANSACTIONS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getAllExchangesRequest = (data) => ({
  type: GET_ALL_EXCHANGES_REQUEST,
  payload: data,
});

export const getAllExchangesStarted = () => ({
  type: GET_ALL_EXCHANGES_STARTED,
});

export const getAllExchangesSuccess = (data) => ({
  type: GET_ALL_EXCHANGES_SUCCESS,
  payload: data,
});

export const getAllExchangesFailure = (data) => ({
  type: GET_ALL_EXCHANGES_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getAllTransfersRequest = (data) => ({
  type: GET_ALL_TRANSFERS_REQUEST,
  payload: data,
});

export const getAllTransfersStarted = () => ({
  type: GET_ALL_TRANSFERS_STARTED,
});

export const getAllTransfersSuccess = (data) => ({
  type: GET_ALL_TRANSFERS_SUCCESS,
  payload: data,
});

export const getAllTransfersFailure = (data) => ({
  type: GET_ALL_TRANSFERS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getBanksReportRequest = (data) => ({
  type: GET_BANKS_REPORT_REQUEST,
  payload: data,
});

export const getBanksReportStarted = () => ({
  type: GET_BANKS_REPORT_STARTED,
});

export const getBanksReportSuccess = (data) => ({
  type: GET_BANKS_REPORT_SUCCESS,
  payload: data,
});

export const getBanksReportFailure = (data) => ({
  type: GET_BANKS_REPORT_FAILURE,
  payload: data,
});
